import { BehaviorSubject, forkJoin, Observable, of, Subject, throwError, zip } from 'rxjs';
import { catchError, mergeMap, map, retry, tap } from 'rxjs/operators';
import moment_ from 'moment';
import { JwtHelper } from './jwt-helper';
import { CurrentUser } from '../models/user/current-user';
import __, { merge } from 'lodash';
import { GruulsHttpProxyInterface } from '../service-interfaces/gruuls-http-proxy-interface';
import { GruulsUrlRoutingInterface } from '../service-interfaces/gruuls-url-routing-interface';
import { GruulsLocalStorageInterface } from '../service-interfaces/gruuls-local-storage-interface';
import { GruulsCookieInterface } from '../service-interfaces/gruuls-cookie-interface';
import { Utils } from '@gruuls-core/utils/Utils';

const moment: any = moment_;
const _: any = __;
const JWT_TOKEN_PARAM = "jwt";
const JWT_IMPERSONATE_TOKEN_PARAM = "jwtImpersonate"
export class GruulsLogin {
    private jwtHelper = new JwtHelper();
    private currentUser: CurrentUser | undefined = undefined;
    private userLoginSubject: Subject<CurrentUser> = new Subject(); // used to inform of user change
    private loginSubject?: Subject<CurrentUser>; //used to avoid multiple consecutive calls

    public static httpHeaders = { 'Content-Type': 'application/json' };
    isImpersonating$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

    public static assembleAs = {
        personId: true,
        firstName: true,
        lastName: true,
        birthdate: true,
        localeLanguage: true,
        localeCountry: true,
        account: {
            username: true,
            email: true,
            accountId: true
        },
        roles: {
            isDefault: true,
            hasRole: {
                roleTemplateId: true,
                name: true,
                // resourcesMap: true,
                settings: true,
                serviceRoleMapping: {
                    _parent: {
                        // service Aggregate
                        pages: {
                            id: true,
                            name: true,
                            urlPath: true,
                            type: true,
                            useAuth: true,
                            loadOrder: true
                            // config: true,
                            // contextName: true,
                            // domainName: true
                        },
                        name: true
                    },
                    navigationResources: {
                        title: true,
                        subtitle: true,
                        i18nTitle: true,
                        i18nSubtitle: true,
                        type: true,
                        link: true,
                        externalLink: true,
                        exactMatch: true,
                        icon: true
                    },

                }
            },
            inOrganization: {
                organizationId: true,
                name: true,
                organizationType: true,
                settings: true,
                administrativeEntities: {
                    id: true,
                    administrativeEntityId: true,
                    address: true,
                    zip: true,
                    lat: true,
                    lon: true,
                    name: true,
                    description: true,
                    externalId: true,
                    //deviceSetups:true,
                    mo_hours: true,
                    tu_hours: true,
                    we_hours: true,
                    th_hours: true,
                    fr_hours: true,
                    sa_hours: true,
                    su_hours: true,
                    active: true,
                    analyzeVisits: true,
                    analyzeKpi: true,
                    windowInactivityGapMs: true,
                    deltaTimeConsideringNewVisit: true,
                    deltaTimeFrequencyMs: true,
                    deltaTimeRoundingToStartEvaluatingFreqRecMs: true,
                    deltaTimeShiftAfterRoundingToStartEvaluatingFreqRecMs: true,
                    deltaTimeForFrequencyMs: true,
                    type: true,
                    locationDetails: true,
                    internalVisitsQuery: true,
                    externalVisitsQuery: true,
                    mediumVisitsQuery: true,
                    physicalInternalVisitsQuery: true,
                    physicalExternalVisitsQuery: true,
                    internalVisitPartitioning: true,
                    antiCorrupltionLayers: true,
                    globalCorrectionFactors: true,
                    dataCleanupConfig: true,
                    correctionFactors: true,
                    //deprecated properties
                    tags: true
                }
            }
        }
    };

    constructor(
        private httpProxy: GruulsHttpProxyInterface,
        private gruulsLocalStorage: GruulsLocalStorageInterface,
        private gruulsCookie: GruulsCookieInterface,
        private qrnerUrlRouting?: GruulsUrlRoutingInterface
    ) {

    }

    public currentUserObservable(): Observable<CurrentUser> {
        return this.userLoginSubject.asObservable();
    }

    /**
     * It returns the logged user. If currentUser is already loaded it returns it
     * otherwise it tries to load user via url token or local storage and eventually
     * making proper call to BE.
     */
    public tryLoadUser(token?: string): Observable<CurrentUser | undefined> {
        return this.isAuthenticated(token)
            .pipe(
                mergeMap((maybeToken: any) => {
                    if (maybeToken) {
                        return this.loginViaJwtToken(maybeToken);
                    }
                    return of(undefined);
                }),
                catchError(err => this.unsetSession().pipe(mergeMap(r => throwError(err))))
            );
        // const token = externalJwtToken ? externalJwtToken : this.iottacleLocalStorage.getItem(JWT_TOKEN_PARAM);
        // if (!token || token === 'null') {
        //     return of(false);
        // } else {
        //     const decoded = this.jwtHelper.decodeToken(token);
        //     const expired = moment(decoded.exp * 1000).isBefore(moment());
        //     if (expired) {
        //         return of(false);
        //     }
        //     if (externalJwtToken && !this.currentUser) {
        //         //token arrives from other sources (such as from url) and user details has not been loaded yet
        //         this.iottacleLocalStorage.setItem(JWT_TOKEN_PARAM, externalJwtToken);
        //         return this.loadUserLoginDataFromJwtToken(externalJwtToken).pipe(retry(3), map(() => true));
        //     } else {
        //         return of(true);
        //     }
        //
        // }
    }

    public isAuthenticated(t?: string): Observable<any> {
        let token;
        let isImpersonating = false;
        if (t) {
            token = t;
        }
        if (!token && this.qrnerUrlRouting) {
            token = this.qrnerUrlRouting.getUrlParams()['llt'];
        }
        if (!token) {
            // TODO: should I check for impersonate token?
            token = this.gruulsLocalStorage.getItem(JWT_IMPERSONATE_TOKEN_PARAM);
            if (!token || token === 'null') {
                isImpersonating = false;
                token = this.gruulsLocalStorage.getItem(JWT_TOKEN_PARAM);
            } else {
                isImpersonating = true;
            }
        }
        if (!token || token === 'null') {
            return of(false);
        } else {
            const decoded = this.jwtHelper.decodeToken(token);
            const expired = moment(decoded.exp * 1000).isBefore(moment());
            if (!expired) {
                // token is stored synchronously, it is not necessary to subscribe()
                // the returning observable is used for chaining asynchronously
                return this.loginViaJwtToken(token, isImpersonating);
            } else {
                return this.unsetSession();
            }

        }
    }

    public checkJwtTokenValidity(jwtToken: any): Observable<string> {
        try {
            if (!jwtToken || jwtToken === true) {
                return throwError('TokenInvalid!');
            }
            const decoded = this.jwtHelper.decodeToken(jwtToken);
            const expired = moment(decoded.exp * 1000).isBefore(moment());
            return expired ? throwError('TokenExpired!') : of(jwtToken);
        } catch (e) {
            console.warn('THIS IS THE TOKEN RECEIVED:', jwtToken);
            throw e;
        }
    }

    public login(username: string, password: string): Observable<CurrentUser> {
        const h: any = _.clone(GruulsLogin.httpHeaders);
        h['onLogin'] = 'true';
        return this.httpProxy.doPost({
            url: 'api/executor/login',
            body: { username, password },
            headers: h,
            options: { observe: 'response' }
        }).pipe(
            mergeMap((serverLoginResponse: any) => this.storeJwtToken(serverLoginResponse.body.hits[0].token)),
            mergeMap(token => this.loginViaJwtToken(token)),
        );
        // }).pipe(
            // mergeMap((serverLoginResponse: any) => this.storeJwtToken(serverLoginResponse.body.hits[0].token)),
        //     mergeMap(t => this.getUserSettings(t)),
        //     // retry(2),
        //     mergeMap(t => this.saveCurrentLoggedUser(t)),
        //     mergeMap((t: CurrentUser) => this.informCurrentUserObservers(t)),
        //     catchError(err => this.unsetSession().pipe(mergeMap(r => throwError(err))))
        // );
    }

    public impersonate(username: string): Observable<CurrentUser> {
        return this.httpProxy.doPost({
            url: 'api/executor/command',
            body: { 
                contextName: "Core",
                domainName:"Person",
                commandName: "IMPERSONATE",
                commandType: "NORMAL_COMMAND",
                body: {
                    username
                }
            },
            headers: GruulsLogin.httpHeaders,
            options: { observe: 'response' }
        }).pipe(
            mergeMap(serverImpersonateResponse => this.loginViaJwtToken(serverImpersonateResponse.body.hits[0].token, true, true)),
        );
    }

    public stopImpersonate(mainToken: string): Observable<CurrentUser> {
        this.gruulsLocalStorage.removeItem(JWT_IMPERSONATE_TOKEN_PARAM);
        return this.loginViaJwtToken(mainToken);
    }

    public getImpersonatingUpdates(): Observable<boolean> {
        return this.isImpersonating$.asObservable();
    }

    public loginViaJwtToken(jwtToken: string, isImpersonating: boolean = false, forceRelogin: boolean = false): Observable<CurrentUser> {
        if (!this.loginSubject || forceRelogin) {
            this.loginSubject = new Subject();
            this.checkJwtTokenValidity(jwtToken)
                .pipe(
                    mergeMap(t => isImpersonating ? this.storeJwtImpersonateToken(t) : this.storeJwtToken(t)),
                    mergeMap(t => this.getUserSettings(t)),
                    // retry(2),
                    mergeMap(t => this.saveCurrentLoggedUser(t)),
                    mergeMap(t => this.informCurrentUserObservers(t)),
                    tap(() => this.isImpersonating$.next(isImpersonating)),
                    catchError(err => this.unsetSession().pipe(mergeMap(r => throwError(err))))
                )
                .subscribe(
                    (res) => {
                        if (this.loginSubject) {
                            this.loginSubject.next(res);
                            this.loginSubject.complete();
                        }
                    },
                    this.loginSubject.error
                );
            return this.loginSubject.asObservable();
        } else {
            if (!this.loginSubject.isStopped) {
                return this.loginSubject.asObservable();
            } else {
                if (this.currentUser) {
                    return of(this.currentUser);
                } else {
                    this.loginSubject = undefined;
                    return this.loginViaJwtToken(jwtToken);
                }
            }
        }

    }

    private informCurrentUserObservers(currentUser: CurrentUser): Observable<CurrentUser> {
        this.userLoginSubject.next(currentUser);
        return of(currentUser);
    }

    private storeJwtToken(jwtToken: string): Observable<string> {
        this.gruulsLocalStorage.setItem(JWT_TOKEN_PARAM, jwtToken);
        this.gruulsCookie.setItem('_dc_auth', jwtToken, 30, '/', this.gruulsCookie.getFirstLevelDomain(window.location.hostname));
        return of(jwtToken);
    }

    private storeJwtImpersonateToken(jwtToken: string): Observable<string> {
        this.gruulsLocalStorage.setItem(JWT_IMPERSONATE_TOKEN_PARAM, jwtToken);
        this.gruulsCookie.setItem('_dc_auth', jwtToken, 30, '/', this.gruulsCookie.getFirstLevelDomain(window.location.hostname));
        return of(jwtToken);
    }

    private getUserSettings(jwtToken: any): Observable<any> {
        // NOTE:    this method is not placing "Authentication" headers.
        //          because it is expected that an HttpInterceptors deals with it
        const remoteCall: Observable<any> = this.httpProxy.doPost({
            url: 'api/executor/query',
            body: {
                'contextName': 'Core', 'domainName': 'Person',
                'queryName': 'GET_MYSELF',
                'queryId': '1234',
                'where': {},
                'assembleAs': GruulsLogin.assembleAs
            },
            headers: GruulsLogin.httpHeaders
        });
        return remoteCall.pipe(
            map(res => res.hits[0])
        ).pipe(
            catchError((err) => {
                const t = this;
                return err;
            })
        );
    }

    private saveCurrentLoggedUser(result: any): Observable<CurrentUser> {
        const user = result;
        user.localeLanguage = user.localeLanguage || 'it';
        user.localeCountry = user.localeCountry || 'IT';
        user.userSettings = {};
        user.userSettings.myOrganizations = [];
        user.organizations = [];
        for (const role of user.roles) {
            role.inOrganization.administrativeEntities = role.inOrganization.administrativeEntities || [];
            role.inOrganization.myAdministrativeEntities = role.inOrganization.administrativeEntities.map((a: any) => {
                a.administrativeEntityDetails = _.clone(a);
                a.administrativeEntityDetails.weeklyOpeningHours = {
                    monday: a.mo_hours,
                    tuesday: a.tu_hours,
                    wednesday: a.we_hours,
                    thursday: a.th_hours,
                    friday: a.fr_hours,
                    saturday: a.sa_hours,
                    sunday: a.su_hours,
                };
                a.administrativeEntityDetails.installedDevices = [];
                a.administrativeEntityDetails.dvrs = [];
                a.administrativeEntityDetails.validityTimestamps = [];
                a.administrativeEntityDetails.locations = a.locations || [];
                return a;
            });
            role.inOrganization.settings = role.inOrganization.settings || (role.hasRole ? role.hasRole[0].settings : '{"map":{}}') || '{"map":{}}';
            const or = _.clone(role.inOrganization);
            or.settings = role.inOrganization.settings ? { map: JSON.parse(role.inOrganization.settings) } : role.hasRole ? { map: JSON.parse(role.hasRole[0].settings) } : { map: {} };
            user.organizations.push(or);
            user.userSettings.myOrganizations.push(role.inOrganization);
        }

        this.gruulsLocalStorage.setItem('currentLoggedUser', JSON.stringify(user));
        this.currentUser = new CurrentUser(user);
        return of(this.currentUser);
    }

    public userAvailable(): boolean {
        return !!this.getCurrentLoggedUser();
    }

    public getCurrentLoggedUser(): CurrentUser | undefined {
        if (!this.currentUser) {
            let cu: any = this.gruulsLocalStorage.getItem('currentLoggedUser');
            const user = JSON.parse(cu);
            if (user) {
                let cu1: any = this.currentUser;
                this.currentUser = cu1 ? cu1.init(user) : new CurrentUser(user);
                return this.currentUser;
            } else {
                console.info("Current User not available");
                return undefined;
            }
        } else {
            return this.currentUser;
        }
    }

    logout(): Observable<any> {
        return this.unsetSession();
    }

    forgotPassword(email: string): Observable<CurrentUser> {
        const h: any = _.clone(GruulsLogin.httpHeaders);

        const q = {
            contextName: 'Core',
            domainName: 'Person',
            commandName: 'RETRIEVE_PASSWORD',
            commandType: 'SKIP_AUTH_COMMAND',
            body: {
                aggregate: {
                    url: window.location.origin,
                    email: email
                }
            },
        }

        return this.httpProxy.doPost({
            url: 'api/executor/command',
            body: q,
            headers: h,
            options: { observe: 'response' }
        })
            .pipe(
                retry(1),
                catchError(err => this.unsetSession().pipe(mergeMap(r => throwError(err))))
            );
    }

    resetPassword(password: string, token: string): Observable<CurrentUser> {
        const h: any = _.clone(GruulsLogin.httpHeaders);
        h['Authorization'] = 'Bearer ' + token;

        const jwtToken = this.jwtHelper.decodeToken(token);

        const aggregate = {
            account: {
                password: password,
                passwordVerify: password
            }
        }

        const q = {
            contextName: 'Core',
            domainName: 'Person',
            commandName: 'SET_NEW_ACCOUNT_PASSWORD',
            commandType: 'SET_NEW_ACCOUNT_PASSWORD',
            body: {
                aggregate: aggregate,
                // where: where
            },
        }

        return this.httpProxy.doPost({
            url: 'api/executor/command',
            body: q,
            headers: h,
            options: { observe: 'response' }
        })
            .pipe(
                retry(1),
                catchError(err => this.unsetSession().pipe(mergeMap(r => throwError(err))))
            );
    }

    public sendWelcomeEmail(accountEmail: string): Observable<CurrentUser> {

        const aggregate = {
            url: window.location.origin,
            email: accountEmail,
        };

        let body: any = {
            aggregate
        };

        const qWelcome = {
            contextName: 'Core',
            domainName: 'Person',
            commandName: 'WELCOME_ACCOUNT_PASSWORD',
            commandType: 'NORMAL_COMMAND',
            body: body
        };

        return this.httpProxy.doPost({
            url: 'api/executor/command',
            body: qWelcome
        })
    }


    private unsetSession(): Observable<any> {
        this.currentUser = undefined;
        this.loginSubject = undefined;
        this.gruulsLocalStorage.removeItem(JWT_TOKEN_PARAM);
        this.gruulsLocalStorage.removeItem(JWT_IMPERSONATE_TOKEN_PARAM);
        this.gruulsLocalStorage.removeItem('currentLoggedUser');
        this.gruulsCookie.removeItem('_dc_auth', this.gruulsCookie.getFirstLevelDomain(window.location.hostname));
        this.isImpersonating$.next(false);
        return of(true);
    }
}
