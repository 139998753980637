import {Domain} from './domain';
import {Observable, of} from 'rxjs';
import {GruulsHttpProxyInterface} from '../../service-interfaces/gruuls-http-proxy-interface';
import {GruulsAuthServiceInterface} from '../../service-interfaces/gruuls-auth-service-interface';

export class DomainCatalog {

    static instance: DomainCatalog;
    catalog: {
        [entityName: string]: Domain;
    } = {};

    constructor(
        private httpClient: GruulsHttpProxyInterface,
        private auth: GruulsAuthServiceInterface
    ) {
        DomainCatalog.instance = this;
    }

    public static getInstance(): DomainCatalog{
        return this.instance;
    }

    public addDomain(domain: Domain): void {
        this.catalog[domain.contextName + '.' + domain.domainName] = domain;
    }

    public getDomain(contextName: string, domainName: string): Observable<Domain> {
        const domain = this.catalog[contextName + '.' + domainName];
        if (domain){
            return of(domain);
        }
        const d = Domain.ofRemoteConfig(contextName, domainName, this.httpClient, this.auth);
        return d;
    }

    public getDomainByFqdn(contextAndDomainDottedName: string): Observable<Domain> {
        const split = contextAndDomainDottedName.split('.');
        return this.getDomain(split[0], split[1]);
    }

    public  getDomainFromDomainName(domainName: string): Domain {
        return this.catalog[domainName];
    }

}
