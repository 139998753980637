import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfigService } from '../@fuse/services/config';
import { GruulsAuthService } from '../@gruuls-fe/services/gruuls-auth.service';
import { map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { GruulsConstants } from './mock-api/gruuls/gruuls-constants';
import { GruulsAngularRouteFactory } from '../@gruuls-fe/routing/gruuls-angular-route-factory';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { ExampleNextComponent } from './modules/admin/example-next/example-next.component';
import { LayoutComponent } from './layout/layout.component';
import { InitialDataResolver } from './app.resolvers';
import { CorePersonDomain } from './beautycians/domains/core-person.domain';
import { GruulsAngularTranslateService } from '../@gruuls-fe/services/gruuls-angular-translate.service';
import { DomainCatalog } from '../@gruuls-core/domain/domain/domain-catalog';
import { GruulsAngularHttpProxyService } from '../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { ClientListComponent } from './beautycians/modules/clients/list/client-list.component';
import { ClientDetailsComponent } from './beautycians/modules/clients/details/client-details.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { StoresListComponent } from './beautycians/modules/stores/list/stores-list.component';
import { StoreDetailsComponent } from './beautycians/modules/stores/details/store-details.component';
import { OperatorListComponent } from './beautycians/modules/operators/list/operator-list.component';
import { OperatorDetailsComponent } from './beautycians/modules/operators/details/operator-details.component';
import { OwnerListComponent } from './beautycians/modules/owners/list/owner-list.component';
import { OwnerDetailsComponent } from './beautycians/modules/owners/details/owner-details.component';
import { OrderListComponent } from './beautycians/modules/orders/list/order-list.component';
import { OrderDetailsComponent } from './beautycians/modules/orders/details/order-details.component';

import { AuthSignInComponent } from './beautycians/modules/auth/sign-in/sign-in.component';
import { AuthSignOutComponent } from './beautycians/modules/auth/sign-out/sign-out.component';
import { AuthForgotPasswordComponent } from './beautycians/modules/auth/forgot-password/forgot-password.component';
import { AuthResetPasswordComponent } from './beautycians/modules/auth/reset-password/reset-password.component';

import { AppConfig } from './core/config/app.config';
import { Layout } from './layout/layout.types';
import { DOCUMENT } from "@angular/common";
import { combineLatest, Subject } from "rxjs";
import { FuseMediaWatcherService } from "../@fuse/services/media-watcher";

import { Page404Component } from './beautycians/modules/e404/e404.component';
import { ClientPrecompileComponent } from './beautycians/modules/clients/precompile/client-precompile.component';
import { AppointmentComponent } from './beautycians/modules/clients/appointment/appointment.component';
import { OrderDetailsSupplierComponent } from './beautycians/modules/orders/details/order-details-supplier.component';
import { CentriListComponent } from './beautycians/modules/centri/list/centri-list.component';
import { StudentListComponent } from './beautycians/modules/students/list/student-list.component';
import { Utils } from '@gruuls-core/utils/Utils';
import { SettingsComponent } from './beautycians/modules/settings/settings.component';
import { AccountsService } from './beautycians/modules/owners/allowner.service';
import { Role } from '@gruuls-core/models/acl/role';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    /**
     * Constructor
     */
    loaded = false;

    public static gruulsApp: any;

    config: AppConfig;
    layout: Layout;
    scheme: 'dark' | 'light';
    theme: string;

    constructor(
        private _httpClient: GruulsAngularHttpProxyService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _fuseConfigService: FuseConfigService,
        private authService: GruulsAuthService,
        private translateService: GruulsAngularTranslateService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private _accountService: AccountsService,
        @Inject(DOCUMENT) private _document: any,
        private _fuseMediaWatcherService: FuseMediaWatcherService
    ) {
        // Class dedicated to creating Angular routes.
        // With this method we add the custom routes to the routes catalog.
        GruulsAngularRouteFactory.addCustomRoutesToCatalog(AppComponent.customRoutes);
    }

    ngOnInit(): void {

        // Create domain catalog instance (Singleton)
        new DomainCatalog(this._httpClient, this.authService);
        this._fuseConfigService.config$
            .pipe(
                take(1),
                // Call BE to get information about all routes and pages
                mergeMap((config) => {
                    const q = {
                        contextName: 'Core',
                        domainName: 'App',
                        queryName: 'FIND_BY_URL',
                        url: window.location.href
                    };
                    return this._httpClient.doPost({
                        url: GruulsConstants.QUERY_API_URL,
                        body: q
                    });
                }),
                map(res => res.hits[0]),
                map((app: any) => {
                    app.hasBeenAlreadyLoaded = true;
                    this._fuseConfigService.config = app;
                    // create routes based on server response and load on this.router.resetConfig()
                    const appRoutes = GruulsAngularRouteFactory.createRoutes(app.routes);
                    // Resets the route configuration used for navigation and generating links
                    this.router.resetConfig(appRoutes);

                    // configure favicon
                    const favIcon: HTMLLinkElement = document.querySelector('#appIcon');
                    favIcon.href = app.favicon;

                    // configure splash-loader //TODO

                    // load custom icons
                    if (app.icons) {
                        for (const icon of app.icons) {
                            if (icon.type === 'SVG') {
                                this.iconRegistry.addSvgIconLiteral(icon.name, this.sanitizer.bypassSecurityTrustHtml(icon.value));
                            }
                        }
                    }

                    // Redirect current URL to correct route
                    let redirectTo = this.router.url;

                    if (app.specificRedirects) {
                        if (this.authService && this.authService.userAvailable() && this.authService.getCurrentLoggedUser()) {
                            app.specificRedirects.forEach(redirect => {
                                if (Utils.hasUniqueRole(this.authService.getCurrentLoggedUser().roles, redirect.role)) {
                                    window.location.href = redirect.goto;
                                }
                            });
                        }
                    }
                    this.router.navigateByUrl(redirectTo);


                    // if (route.routeConfig.data?.qrnerConfigResolver?.redirectOnOriginalUrlAfterLoad) {
                    //     this.router.navigateByUrl(state.url, { skipLocationChange: true, replaceUrl: true});
                    // }
                    return app;
                }),
                tap((el) => {
                    CorePersonDomain.loadOnDomainCatalog(this.authService, this._httpClient, this.translateService);
                }),
                tap(el => this.loaded = true),
            )
            .subscribe();

        // Set the theme and scheme based on the configuration
        combineLatest([
            this._fuseConfigService.config$,
            this._fuseMediaWatcherService.onMediaQueryChange$(['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)'])
        ]).pipe(
            map(([config, mql]) => {

                const options = {
                    scheme: config.scheme,
                    theme: config.theme
                };

                // If the scheme is set to 'auto'...
                if (config.scheme === 'auto') {
                    // Decide the scheme using the media query
                    options.scheme = mql.breakpoints['(prefers-color-scheme: dark)'] ? 'dark' : 'light';
                }

                return options;
            })
        ).subscribe((options) => {

            // Store the options
            this.scheme = options.scheme;
            this.theme = options.theme;

            // Update the scheme and theme
            this._updateScheme();
            this._updateTheme();
        });

        // Subscribe to config changes
        this._fuseConfigService.config$
            .subscribe((config: AppConfig) => {

                // Store the config
                this.config = config;

            });
        // if (this.authService.userAvailable() && this.authService.getCurrentLoggedUser() && (Utils.hasRole(this.authService.getCurrentLoggedUser().roles, ['Beautycians-HQ'], this.authService.getCurrentLoggedUser().getSelectedOrganization().organizationId))) {
        //     this._accountService.refreshAccounts().subscribe();
        // }
    }

    /**
     * Update the selected scheme
     *
     * @private
     */
    private _updateScheme(): void {
        // Remove class names for all schemes
        this._document.body.classList.remove('light', 'dark');

        // Add class name for the currently selected scheme
        this._document.body.classList.add(this.scheme);
    }

    /**
     * Update the selected theme
     *
     * @private
     */
    private _updateTheme(): void {
        // Find the class name for the previously selected theme and remove it
        this._document.body.classList.forEach((className: string) => {
            if (className.startsWith('theme-')) {
                this._document.body.classList.remove(className, className.split('-')[1]);
            }
        });

        // Add class name for the currently selected theme
        this._document.body.classList.add(`theme-${this.theme}`);
    }

    // only Angular Routes
    static customRoutes = [
        {
            customRouteName: 'welcomesurvey',
            path: 'welcomesurvey/:id',
            component: ClientPrecompileComponent
        },
        {
            customRouteName: 'client',
            path: 'client',
            //canActivate: [AuthGuard],
            //canActivateChild: [AuthGuard],
            children: [
                {
                    path: 'list',
                    component: ClientListComponent
                },
                {
                    path: 'list/:page',
                    component: ClientListComponent
                },
                {
                    path: 'detail/:id',
                    component: ClientDetailsComponent
                },
                {
                    path: 'appointment',
                    component: AppointmentComponent
                },
            ]
        },
        {
            customRouteName: 'centri',
            path: 'centri',
            //canActivate: [AuthGuard],
            //canActivateChild: [AuthGuard],
            children: [
                {
                    path: 'list',
                    component: CentriListComponent
                },
                // {
                //     path: 'detail/:id',
                //     component: CentriDetailsComponent
                // }
            ]
        },
        {
            customRouteName: 'student',
            path: 'student',
            //canActivate: [AuthGuard],
            //canActivateChild: [AuthGuard],
            children: [
                {
                    path: 'list',
                    component: StudentListComponent
                },
            ]
        },
        {
            customRouteName: 'owner',
            path: 'owner',
            //canActivate: [AuthGuard],
            //canActivateChild: [AuthGuard],
            children: [
                {
                    path: 'list',
                    component: OwnerListComponent
                },
                {
                    path: 'detail/:id',
                    component: OwnerDetailsComponent
                }
            ]
        },
        {
            customRouteName: 'operator',
            path: 'operator',
            //canActivate: [AuthGuard],
            //canActivateChild: [AuthGuard],
            children: [
                {
                    path: 'list',
                    component: OperatorListComponent
                },
                {
                    path: 'detail/:id',
                    component: OperatorDetailsComponent
                }
            ]
        },
        {
            customRouteName: 'store',
            path: 'store',
            //canActivate: [AuthGuard],
            //canActivateChild: [AuthGuard],
            children: [
                {
                    path: 'list',
                    component: StoresListComponent
                },
                {
                    path: 'detail/:id',
                    component: StoreDetailsComponent
                }
            ]
        },
        {
            customRouteName: 'product',
            path: 'product',
            // canActivate: [AuthGuard],
            // canActivateChild: [AuthGuard],
            loadChildren: () => import('app/beautycians/modules/products/product.module').then(m => m.ProductModule),
        },
        {
            customRouteName: 'invoices',
            path: 'invoices',
            // canActivate: [AuthGuard],
            // canActivateChild: [AuthGuard],
            loadChildren: () => import('app/beautycians/modules/file-manager/file-manager.module').then(m => m.FileManagerModule),
        },
        {
            customRouteName: 'order',
            path: 'order',
            //canActivate: [AuthGuard],
            //canActivateChild: [AuthGuard],
            children: [
                {
                    path: 'list',
                    component: OrderListComponent
                },
                {
                    path: 'detail',
                    component: OrderDetailsComponent

                },
                {
                    path: 'detail/:id',
                    component: OrderDetailsComponent
                },
                {
                    path: 'supplierdetail/:id',
                    component: OrderDetailsSupplierComponent
                }

            ]
        },
        {
            customRouteName: 'settings',
            path: 'settings',
            component: SettingsComponent
        },
        {
            customRouteName: 'login',
            path: 'login',
            component: AuthSignInComponent
        },
        {
            customRouteName: 'logout',
            path: 'logout',
            component: AuthSignOutComponent
        },
        {
            customRouteName: 'forgot-password',
            path: 'forgot-password',
            component: AuthForgotPasswordComponent
        },
        {
            customRouteName: 'reset-password',
            path: 'reset-password',
            component: AuthResetPasswordComponent
        },
        {
            customRouteName: 'beauty404',
            path: 'e404',
            component: Page404Component
        },
        {
            customRouteName: 'exampleNext',
            path: '',
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            component: ExampleNextComponent,
            children: [
                //{path: 'example', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)},
                { path: 'example-next', loadChildren: (): any => import('app/modules/admin/example-next/example-next.module').then(m => m.ExampleNextModule) },
            ]
        },
        {
            customRouteName: 'gruuls-demo',
            path: 'gruuls-demo',
            //canActivate: [AuthGuard],
            //canActivateChild: [AuthGuard],
            component: LayoutComponent,
            resolve: {
                initialData: InitialDataResolver,
            },
            children: [
                { path: 'example-01', loadChildren: (): any => import('app/modules/gruuls-demo/gruuls-basics/example-01/project.module').then(m => m.ProjectModule) },
                { path: 'example-02', loadChildren: (): any => import('app/modules/gruuls-demo/gruuls-basics/example-02-base-element-component/base-element-component.module').then(m => m.BaseElementComponentModule) },
            ]
        },
    ];
}
