import {ElementCommandMessage} from './element-command-message';
import {Observable, of, throwError} from 'rxjs';
import {ElementCommandReply} from './element-command-reply';
import {BaseElement} from './base-element';
import {SandboxedContext} from '../context-element/sandboxed-context';
import {map} from 'rxjs/operators';

export class ElementCommandExecutor{

    private functions: {
        [fnName: string]: (message: any, context: SandboxedContext, thisElement: BaseElement) => Observable<any> | any ;
    } = {};

    registerCommand(commandName: string, commandExe: (message: any, context: SandboxedContext, thisElement: BaseElement) => Observable<any> | any): void{
        this.functions[commandName] = commandExe;
    }

    execute(message: ElementCommandMessage, sandboxedContext: SandboxedContext, element: BaseElement): Observable<ElementCommandReply>{
        const fn = this.functions[message.name];
        if (!fn){
            return throwError('Command not available');
        }else{
            const res = fn(message.body, sandboxedContext, element);
            if (res instanceof Observable){
                return res.pipe(
                    map(el => ({result: el}))
                );
            }else{
                return of({
                    result: res
                });
            }
        }
    }

    hasCommands(): boolean {
        return !!Object.keys(this.functions).length;
    }
}
