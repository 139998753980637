import _lodash from 'lodash';
import { CollectionType } from '../../collection-type';
import { Field } from '../../field';
import { FieldType } from '../../field-type';
import { Fields } from '../../fields';
import {GeopointFieldConfig} from './geopoint-field-config';
import {FieldModelTypes} from '../../field-model-types';
import {FormElementsAngularUtils} from '../../../../../@gruuls-fe/utils/form-elements-angular-utils';

const _ = _lodash;


export class GeopointField extends Field {

    public constructor(
        name: string,
        subFields: Field[],
        type: FieldType,
        collectionType: CollectionType,
        fieldConfig: GeopointFieldConfig,
        modelType?: string,
        public latFieldName?: string,
        public lonFieldName?: string
    ) {
        super(name, subFields, undefined, type, collectionType, fieldConfig, modelType);
    }

    public static ofConfig(config: {
                               name: string;
                               subFields?: Field[];
                               type?: FieldType;
        collectionType?: CollectionType;
                               latFieldName?: string; // for geoPoint and address
                               lonFieldName?: string; // for geoPoint and address
                               fieldConfig?: GeopointFieldConfig;
                               modelType?: string;
                           }
    ): Field {
        Fields.counter = Fields.counter++;
        if (config.modelType === 'flat' && (config.collectionType === 'LIST' || config.collectionType === 'SET')) {
            throw new Error('geopoint field (name: ' + config.name + ') has a flat modelType. However it cannot be a collectionType (collectionType: ' + config.collectionType + '). If you want a collectionType you should select anotherModel type (eg. valueObject)');
        }

        config = _.defaultsDeep(
            {

                type: FieldType.geoPoint,
                subFields: [
                    Fields.long({
                        name: config.latFieldName
                    }),
                    Fields.long({
                        name: config.lonFieldName
                    })
                ]
            },
            config,
            {
                collectionType: CollectionType.NONE,
                latFieldName: 'lat',
                lonFieldName: 'lon',
                modelType: FieldModelTypes.flat,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.maybeFlatValueObjectFormControlElement
                }
            });

        return new GeopointField(config.name, config.subFields, config.type, config.collectionType, config.fieldConfig, config.modelType, config.latFieldName, config.lonFieldName);
    }

    public populateAssembleAs(assembleAs: any, subFieldsAssembly?: any): void {
        if (this.modelType === 'flat') {
            assembleAs[this.latFieldName] = true;
            assembleAs[this.lonFieldName] = true;
        } else {
            assembleAs[this.name] = true;
        }
    }

}
