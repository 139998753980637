<div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 w-screen h-full">
    <div class="md:flex md:items-center md:justify-end w-full md:h-full py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Logo -->
            <div class="w-12">
                <img src="assets/images/logo/logo.svg">
            </div>

            <!-- Title -->
            <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">{{translateStrings.generic.resetPassword}}</div>
            <div class="mt-0.5 font-medium">{{translateStrings.generic.createNewPassword}}</div>

            <!-- Alert -->
            <fuse-alert
                class="mt-8 -mb-4"
                *ngIf="showAlert"
                [appearance]="'outline'"
                [showIcon]="false"
                [type]="alert.type"
                [@shake]="alert.type === 'error'">
                {{alert.message}}
            </fuse-alert>

            <ng-container *ngIf="!(showAlert && alert.type === 'success')">

                <!-- Reset password form -->
                <form
                    class="mt-8"
                    [formGroup]="resetPasswordForm"
                    #resetPasswordNgForm="ngForm">

                    <!-- Password field -->
                    <mat-form-field class="w-full">
                        <mat-label>Password</mat-label>
                        <input
                            id="password"
                            matInput
                            type="password"
                            [formControlName]="'password'"
                            #passwordField>
                        <button
                            mat-icon-button
                            type="button"
                            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                            matSuffix>
                            <mat-icon
                                class="icon-size-5"
                                *ngIf="passwordField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon
                                class="icon-size-5"
                                *ngIf="passwordField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                        </button>
                        <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                            {{translateStrings.generic.passwordNotMatchingCriteria}}
                        </mat-error>
                    </mat-form-field>

                    <!-- Password confirm field -->
                    <mat-form-field class="w-full">
                        <mat-label>Password (Confirm)</mat-label>
                        <input
                            id="password-confirm"
                            matInput
                            type="password"
                            [formControlName]="'passwordConfirm'"
                            #passwordConfirmField>
                        <button
                            mat-icon-button
                            type="button"
                            (click)="passwordConfirmField.type === 'password' ? passwordConfirmField.type = 'text' : passwordConfirmField.type = 'password'"
                            matSuffix>
                            <mat-icon
                                class="icon-size-5"
                                *ngIf="passwordConfirmField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon
                                class="icon-size-5"
                                *ngIf="passwordConfirmField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                        </button>
                        <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                            {{translateStrings.generic.passwordConfirmationRequired}}
                        </mat-error>
                        <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('mustMatch')">
                            {{translateStrings.generic.passwordsDoNotMatch}}
                        </mat-error>
                    </mat-form-field>
                    
                    <div class="flex items-center leading-5 text-md text-secondary pb-1">
                        <mat-icon role="img" [ngClass]="(!resetPasswordFormControl.password.errors?.required && resetPasswordFormControl.password.errors?.minlength==null) ? 'text-green-600' : 'text-gray-300'" class="notranslate mat-mdc-tooltip-trigger icon-size-5 mat-icon-no-color ng-star-inserted" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="check-badge" data-mat-icon-namespace="heroicons_solid" aria-describedby="cdk-describedby-message-ng-1-10" cdk-describedby-host="ng-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                            <path fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd"></path>
                        </svg></mat-icon>
                        <div class="ml-1.5">{{translateStrings.generic.passwordLength}}</div>
                    </div>
                    <div class="flex items-center leading-5 text-md text-secondary pb-1">
                        <mat-icon role="img" [ngClass]="(!resetPasswordFormControl.password.errors?.required && resetPasswordFormControl.password.errors?.pattern==null) ? 'text-green-600' : 'text-gray-300'" class="notranslate mat-mdc-tooltip-trigger icon-size-5 mat-icon-no-color ng-star-inserted" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="check-badge" data-mat-icon-namespace="heroicons_solid" aria-describedby="cdk-describedby-message-ng-1-10" cdk-describedby-host="ng-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                            <path fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd"></path>
                        </svg></mat-icon>
                        <div class="ml-1.5">{{translateStrings.generic.upperLowerNumeric}}</div>
                    </div>
                    <div class="flex items-center leading-5 text-md text-secondary pb-1">
                        <mat-icon role="img" [ngClass]="(!resetPasswordFormControl.passwordConfirm.errors?.required && !resetPasswordFormControl.passwordConfirm.errors?.mustMatch) ? 'text-green-600' : 'text-gray-300'" class="notranslate mat-mdc-tooltip-trigger icon-size-5 mat-icon-no-color ng-star-inserted" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="check-badge" data-mat-icon-namespace="heroicons_solid" aria-describedby="cdk-describedby-message-ng-1-10" cdk-describedby-host="ng-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                            <path fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd"></path>
                        </svg></mat-icon>
                        <div class="ml-1.5">{{translateStrings.generic.passwordsCheckMatch}}</div>
                    </div>

                    <!-- Submit button -->
                    <button
                        class="fuse-mat-button-large w-full mt-3"
                        mat-flat-button
                        [color]="'primary'"
                        [disabled]="resetPasswordForm.disabled"
                        (click)="resetPassword()">
                        <span *ngIf="!resetPasswordForm.disabled">
                            {{translateStrings.generic.resetPassword}}
                        </span>
                        <mat-progress-spinner
                            *ngIf="resetPasswordForm.disabled"
                            [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>
                </form>
            </ng-container>

            <!-- Form footer -->
            <div class="mt-8 text-md font-medium text-secondary">
                <span>{{translateStrings.generic.returnTo}}</span>
                <a class="ml-1 text-primary-500 hover:underline"
                    [routerLink]="['/login']">{{translateStrings.generic.loginPage}}
                </a>
            </div>

        </div>
    </div>
    <div class="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-44 overflow-hidden bg-primary-400 dark:border-l w-full" style="background: url('assets/images/ui/beautycians/shell.jpeg') no-repeat; background-size: cover">
        <!-- Background - @formatter:off -->
        <!-- Rings -->
        <svg class="absolute inset-0 pointer-events-none"
             viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
            <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                <circle r="234" cx="196" cy="23"></circle>
                <circle r="234" cx="790" cy="491"></circle>
            </g>
        </svg>
        <!-- Dots -->
        <svg class="absolute -top-16 -right-16 text-gray-700"
             viewBox="0 0 220 192" width="220" height="192" fill="none">
            <defs>
                <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
                </pattern>
            </defs>
            <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
        </svg>
        <!-- @formatter:on -->
        <!-- Content -->
        <div class="z-10 relative w-full pr-12<">
            <div class="text-7xl font-bold leading-none text-gray-100">
                <div>Welcome to</div>
                <div>BEAUTYCIANS</div>
            </div>
        </div>
    </div>
</div>
