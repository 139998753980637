import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, NgForm, Validators } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { map, catchError, Observable, Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Contact, Tag } from './client-precompile.types';
import { GruulsConstants } from '../../../../mock-api/gruuls/gruuls-constants';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import moment_ from 'moment';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import { FuseConfigService } from '@fuse/services/config';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';

@Component({
    selector: 'client-precompile',
    templateUrl: './client-precompile.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientPrecompileComponent implements OnInit, OnDestroy {

    @ViewChild('anamnesiForm') anamnesiForm: NgForm;

    clientId: string;
    tags: Tag[];
    contact: any;
    newSurvey: any = [];
    translateStrings: any = { generic: {}, medicalHistory: {} };
    sent: boolean = false;
    isLoadingError: boolean = false;
    isLoading: boolean = false;

    // private _tagsPanelOverlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private personId: string;
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);


    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseConfirmationService: FuseConfirmationService,
        private _httpClient: GruulsAngularHttpProxyService,
        private _fuseConfigService: FuseConfigService,
        // private _httpClient: GruulsMockApi,
        private _fb: FormBuilder,
        private _translate: GruulsAngularTranslateService,
        private _authService: GruulsAuthService,
    ) {
    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    anamnesiFormGroup: any;

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isLoading = true;

        this._fuseConfigService.config = { appConfig: { layout: 'empty' } };

        const genericTranslations = ['send', 'close', 'cancel', 'error', 'save', 'edit', 'download', 'view', 'clone', 'reset', 'noSession', 'thanks'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });
        const mhTranslations = ['welcomeToSurvey', 'thanksForFilling', 'errorWhileSaving'];
        mhTranslations.forEach((translation) => {
            this.translateStrings['medicalHistory'][translation] = this._translate.translate('medicalHistory.' + translation);
        });

        this._activatedRoute.params.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((params) => {
            this.clientId = params.id;

            // Get new survey
            this.compileEmptySurvey();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();

        // Dispose the overlays if they are still on the DOM
        // if (this._tagsPanelOverlayRef) {
        //     this._tagsPanelOverlayRef.dispose();
        // }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    translateAnamnesiQuestions(key): any {
        const translation = this._translate.translate('survey.' + key + '.' + 'question');
        return (translation.toString() !== 'survey.' + key + '.' + 'question') ? translation : key;
    }

    translateAnamnesiAnswers(key, valueKey): any {
        const translation = this._translate.translate('survey.' + key + '.values.' + valueKey);
        return (translation.toString() !== 'survey.' + key + '.values.' + valueKey) ? translation : valueKey;
    }

    submitAnamnesi($event): void {
        if (this[$event.srcElement.id].invalid) {
            return;
        }

        this[$event.srcElement.id + 'Group'].disable();
        const answerAggregate = {};

        for (const [question, answer] of Object.entries(this[$event.srcElement.id].value)) {
            if (answer !== '') {
                answerAggregate[question] = answer;
            }
        }

        answerAggregate['REFERENCE_NAME'] = "Compilazione iniziale";

        this._apiCaller.saveWelcomeAnamnesi(answerAggregate, this.clientId).subscribe({
            next: (res) => {
                this[$event.srcElement.id].resetForm();
                this[$event.srcElement.id + 'Group'].enable();
                this.sent = true;
                this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                console.error("Error while sending: " + err);
                this._fuseConfirmationService.open({
                    "title": this.translateStrings.generic.error,
                    "message": this.translateStrings.medicalHistory.errorWhileSavingWelcome + ": " + err.error,
                    "icon": {
                        "show": true,
                        "name": "warning",
                        "color": "error"
                    },
                    "actions": {
                        "confirm": {
                            "show": false,
                            "label": "OK",
                            "color": "primary"
                        },
                        "cancel": {
                            "show": false,
                            "label": this.translateStrings.generic.cancel
                        }
                    },
                    "dismissible": true
                });
                this[$event.srcElement.id + 'Group'].enable();
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    compileEmptySurvey(): void {
        this.compileSurvey('anamnesiFormGroup');
    }

    resetAnamnesiForm(): void {
        this.anamnesiForm.resetForm();
    }

    compileSurvey(formGroup: any, survey: any = null): void {
        if (!['anamnesiFormGroup', 'editAnamnesiFormGroup'].includes(formGroup)) {
            return;
        }
        this.getClientEmptySurvey().subscribe({
            next: (res) => {
                // This define the survey structure
                // const excludQuestions = [ ];
                // this.newSurvey = res.hits[0]?.hits.filter((el) => !excludQuestions.includes(el.key));
                const group = {};
                for (const formControl of res) {

                    group[formControl.key] = formControl.type.toLowerCase() === 'long' ?
                        ['', [Validators.pattern('[0-9]*')]] :
                        formControl.type.toLowerCase() === 'double' ?
                            ['', [Validators.pattern('^[0-9]+(\\.[0-9]*)?$')]] : ['', []];

                    if (formControl.key in this.surveyAddon) {
                        group[formControl.key][1].push(...this.surveyAddon[formControl.key].validators);
                    } /*else {
                        group[formControl.key][1].push(Validators.required);
                    }*/

                }
                this[formGroup] = this._fb.group(group);
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                console.error("Error while compiling survey: " + err);
                this.isLoading = false;
                this.isLoadingError = true;
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    surveyAddon: any = {
        'IBP_figli': {
            validators: [this.generateRelatedTrueValidator(this, 'IBP_figli_num')]
        },
        'IBP_lavora': {
            validators: [this.generateRelatedTrueValidator(this, 'IBP_sodd_lavoro'), this.generateRelatedTrueValidator(this, 'IBP_tipo_lavoro_hours'), this.generateRelatedTrueValidator(this, 'IBP_tipo_lavoro'), this.generateRelatedTrueValidator(this, 'IBP_tipo_lavoro_Description')]
        },
        'IBP_tipo_lavoro': {
            validators: [this.generateRelatedTrueValidator(this, 'IBP_tipo_lavoro_Description')]
        },
        'IBP_att_fisica': {
            validators: [this.generateRelatedTrueValidator(this, 'IBP_att_fisica_hours')]
        },
        'IBP_hobby': {
            validators: [this.generateRelatedTrueValidator(this, 'IBP_hobby_Description')]
        },
        'IBF_fumo': {
            validators: [this.generateRelatedTrueValidator(this, 'IBF_fumo_Description')]
        },
        'IBF_alcolici': {
            validators: [this.generateRelatedTrueValidator(this, 'IBF_alcolici_Description')]
        },
        'IBF_allg_farm': {
            validators: [this.generateRelatedTrueValidator(this, 'IBF_allg_farm_Description')]
        },
        'IBF_allg_poll': {
            validators: [this.generateRelatedTrueValidator(this, 'IBF_allg_poll_Description')]
        },
        'IBF_allg_altre': {
            validators: [this.generateRelatedTrueValidator(this, 'IBF_allg_altre_Description')]
        },
        'IBF_allg_alimen': {
            validators: [this.generateRelatedTrueValidator(this, 'IBF_allg_alimen_Description')]
        },
        'IBF_allg_alimen_Description': {
            validators: []
        },
        'IBF_allg_metal': {
            validators: [this.generateRelatedTrueValidator(this, 'IBF_allg_metal_Description')]
        },
        'IBF_cibi_malessere': {
            validators: [this.generateRelatedTrueValidator(this, 'IBF_cibi_malessere_Description')]
        },
        'IBD_rughe': {
            validators: [this.generateRelatedTrueValidator(this, 'IBD_localiz_rughe'), this.generateRelatedTrueValidator(this, 'IBD_prof_rughe')]
        },
    };

    getClientEmptySurvey(): Observable<any> {

        // return newSurvey if available
        if (this.newSurvey.length > 0) {
            return of(this.newSurvey);
        }

        // const jwtToken = this.jwtHelper.decodeToken(token);

        const query = {
            contextName: 'Beautycians',
            domainName: 'MedicalHistory',
            queryName: 'GET_SURVEY_CLIENT',
            queryType: 'SKIP_AUTH_QUERY',
        };
        // TODO: add where condition for client-targeted questions
        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => {
                if (res.hits[0]?.hits) {
                    // const questions = res.hits[0]?.hits.filter((question) => this.isInWelcome(question.key));
                    const questions = res.hits[0]?.hits;
                    this.newSurvey = questions
                    return questions;
                }
            }),
        );
    }

    generateRelatedTrueValidator(thisObject: this, relatedField: string) {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.value) {
                if (relatedField in thisObject.anamnesiFormGroup.controls)
                    thisObject.anamnesiFormGroup.get(relatedField).enable();
                else
                    console.warn('relatedField not found: ' + relatedField);
            } else {
                thisObject.anamnesiFormGroup?.get(relatedField)?.setValue('');
                thisObject.anamnesiFormGroup?.get(relatedField)?.disable();
            }

            return null;
        };
    }

}
