import {Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {Domain} from '../domain/domain';
import {GruulsHttpProxyInterface, PostData} from '../../service-interfaces/gruuls-http-proxy-interface';
import {GruulsAuthServiceInterface} from '../../service-interfaces/gruuls-auth-service-interface';
import {GruulsConstants} from '../../../app/mock-api/gruuls/gruuls-constants';
import {Utils} from '../../utils/Utils';
import {GruulsSort} from './gruuls-sort';
import {GruulsPagination} from './gruuls-pagination';

export class QuerySchemaRepository {

    constructor(
        private proxy: GruulsHttpProxyInterface,
        private domain: Domain,
        private authService: GruulsAuthServiceInterface,
        private queryExecutorUrl: string,
    ) {
        if (!queryExecutorUrl){
            this.queryExecutorUrl = GruulsConstants.QUERY_API_URL;
        }
    }

    public getDomainConfig(): Observable<any> {
        const currentUser = this.authService.getCurrentLoggedUser();
        const body = {
            contextName: this.domain.getContextName(),
            domainName: this.domain.getDomainName(),
            queryName: 'GET_DOMAIN_CONFIG',
            userOrganizationId: currentUser ? currentUser.getSelectedOrganization().organizationId : undefined
        };

        const postData: PostData = {
            url: this.queryExecutorUrl,
            body: body,
            headers: {
                // token sky
                // authorization: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpIjpudWxsLCJ1Ijoic2t5Lml0YWxpYSIsImYiOiJTa3kiLCJsIjoiSXRhbGlhIiwicyI6bnVsbCwicCI6Ijg4NmQxOGZhLTNiMWQtNDRlYS1hOTI1LWZhZGIxMWExYjI1NyIsImV4cCI6MTU5NTA2Nzk4NX0.6dm1GNYxQ5nRM7hcTa1i_MtLgBKmkpXuvdrf0HtVi3Ig4cgKYsjb5cYALkCOpQVTGmFFBHHtOmhc6fNzJH4kdg"
            }
        };

        return this.proxy.doPost(postData)
            .pipe(
                map((res: any) => res.hits[0])
            );
    }

    /**
     * get a specific Entity for this domain by its given entityId
     *
     * @param entityId: the unique identifier of the entity to be found
     * @param assembleAs: the shape of the returned entity
     */
    public getEntityById(entityId: any, assembleAs?: any): Observable<any> {
        if (!assembleAs) {
            assembleAs = this.domain.getSchema().getDefaultAssembleAs();
        }

        for (const sf of this.domain.getSchema().getSystemFields()) {
            sf.populateAssembleAs(assembleAs);
        }

        const keyFieldName: string = this.domain.getSchema().getKeyFieldName();

        const where: any = {bool: {must: []}};
        const m: any = {match_phrase: {}};
        m.match_phrase[keyFieldName] = {query: entityId};
        where.bool.must.push(m);
        const currentUser = this.authService.getCurrentLoggedUser();
        const body = {
            contextName: this.domain.getContextName(),
            domainName: this.domain.getDomainName(),
            queryName: 'FIND_ALL',
            queryId: Utils.guid(),
            userOrganizationId: currentUser.getSelectedOrganization().organizationId,
            where: where,
            assembleAs: assembleAs
        };

        const postData: PostData = {
            url: this.queryExecutorUrl,
            body: body,
            headers: {
                // token sky
                authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpIjpudWxsLCJ1Ijoic2t5Lml0YWxpYSIsImYiOiJTa3kiLCJsIjoiSXRhbGlhIiwicyI6bnVsbCwicCI6Ijg4NmQxOGZhLTNiMWQtNDRlYS1hOTI1LWZhZGIxMWExYjI1NyIsImV4cCI6MTU5NTA2Nzk4NX0.6dm1GNYxQ5nRM7hcTa1i_MtLgBKmkpXuvdrf0HtVi3Ig4cgKYsjb5cYALkCOpQVTGmFFBHHtOmhc6fNzJH4kdg'
            }
        };

        return this.proxy.doPost(postData)
            .pipe(
                map((res: any) => res.hits[0])
            );
    }

    /**
     * get entities given a field name and value. The search is performed with "equals" semantics
     *
     * @param fieldName: the field to search against
     * @param fieldValue: the value of the field
     * @param assembleAs: the shape to return of the entities found
     * @param sort
     * @param pagination
     */
    public getEntitiesByField(fieldName: string, fieldValue: any, assembleAs?: any, sort?: GruulsSort, pagination?: GruulsPagination): Observable<any> {
        if (!assembleAs) {
            assembleAs = this.domain.getSchema().getDefaultAssembleAs();
        }

        const where: any = {bool: {must: []}};
        const m: any = {match_phrase: {}};
        m.match_phrase[fieldName] = {query: fieldValue};
        where.bool.must.push(m);
        const currentUser = this.authService.getCurrentLoggedUser();
        const body = {
            contextName: this.domain.getContextName(),
            domainName: this.domain.getDomainName(),
            queryName: 'FIND_ALL',
            queryId: Utils.guid(),
            userOrganizationId: currentUser.getSelectedOrganization().organizationId,
            where: where,
            assembleAs: assembleAs,
            sort: sort,
            pagination: pagination
        };

        const postData: PostData = {
            url: this.queryExecutorUrl,
            body: body,
            headers: {
                // token sky
                authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpIjpudWxsLCJ1Ijoic2t5Lml0YWxpYSIsImYiOiJTa3kiLCJsIjoiSXRhbGlhIiwicyI6bnVsbCwicCI6Ijg4NmQxOGZhLTNiMWQtNDRlYS1hOTI1LWZhZGIxMWExYjI1NyIsImV4cCI6MTU5NTA2Nzk4NX0.6dm1GNYxQ5nRM7hcTa1i_MtLgBKmkpXuvdrf0HtVi3Ig4cgKYsjb5cYALkCOpQVTGmFFBHHtOmhc6fNzJH4kdg'
            }
        };

        return this.proxy.doPost(postData)
            .pipe(
                map((res: any) => res.hits)
            );
    }

    public getEntitiesByElasticQuery(elasticQuery: any, assembleAs?: any, sort?: GruulsSort, pagination?: GruulsPagination, distinct?: boolean): Observable<any> {
        if (!assembleAs) {
            assembleAs = this.domain.getSchema().getDefaultAssembleAs();
        }
        const currentUser = this.authService.getCurrentLoggedUser();
        const where: any = elasticQuery;
        const body = {
            contextName: this.domain.getContextName(),
            domainName: this.domain.getDomainName(),
            queryName: 'FIND_ALL',
            queryId: Utils.guid(),
            userOrganizationId: currentUser.getSelectedOrganization().organizationId,
            where: where,
            assembleAs: assembleAs,
            distinct: distinct,
            sort: sort,
            pagination: pagination
        };

        const postData: PostData = {
            url: this.queryExecutorUrl,
            body: body,
            headers: {
                // token sky
                authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpIjpudWxsLCJ1Ijoic2t5Lml0YWxpYSIsImYiOiJTa3kiLCJsIjoiSXRhbGlhIiwicyI6bnVsbCwicCI6Ijg4NmQxOGZhLTNiMWQtNDRlYS1hOTI1LWZhZGIxMWExYjI1NyIsImV4cCI6MTU5NTA2Nzk4NX0.6dm1GNYxQ5nRM7hcTa1i_MtLgBKmkpXuvdrf0HtVi3Ig4cgKYsjb5cYALkCOpQVTGmFFBHHtOmhc6fNzJH4kdg'
            }
        };

        return this.proxy.doPost(postData)
            .pipe(
                map((res: any) => res.hits)
            );
    }

    public getAllEntities(assembleAs?: any, sort?: GruulsSort, pagination?: GruulsPagination): Observable<any[]> {
        if (!assembleAs) {
            assembleAs = this.domain.getSchema().getDefaultAssembleAs();
        }
        const currentUser = this.authService.getCurrentLoggedUser();
        const where: any = {};
        const body = {
            contextName: this.domain.getContextName(),
            domainName: this.domain.getDomainName(),
            queryName: 'FIND_ALL',
            queryId: Utils.guid(),
            userOrganizationId: currentUser.getSelectedOrganization().organizationId,
            where: where,
            assembleAs: assembleAs,
            sort: sort,
            pagination: pagination
        };

        const postData: PostData = {
            url: this.queryExecutorUrl,
            body: body,
            headers: {
                // token sky
                authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpIjpudWxsLCJ1Ijoic2t5Lml0YWxpYSIsImYiOiJTa3kiLCJsIjoiSXRhbGlhIiwicyI6bnVsbCwicCI6Ijg4NmQxOGZhLTNiMWQtNDRlYS1hOTI1LWZhZGIxMWExYjI1NyIsImV4cCI6MTU5NTA2Nzk4NX0.6dm1GNYxQ5nRM7hcTa1i_MtLgBKmkpXuvdrf0HtVi3Ig4cgKYsjb5cYALkCOpQVTGmFFBHHtOmhc6fNzJH4kdg'
            }
        };

        return this.proxy.doPost(postData)
            .pipe(
                map((res: any) => res.hits)
            );
    }


    public getEntitiesByMapQuery(mapQuery: any, assembleAs?: any, sort?: GruulsSort, pagination?: GruulsPagination): Observable<any> {
        if (!assembleAs) {
            assembleAs = this.domain.getSchema().getDefaultAssembleAs();
        }
        // current user may be undefined => it means that the query
        // is performed anonymously
        const currentUser = this.authService.getCurrentLoggedUser();
        const where: any = mapQuery;
        const body = {
            contextName: this.domain.getContextName(),
            domainName: this.domain.getDomainName(),
            queryName: 'FIND_ALL',
            queryId: Utils.guid(),
            userOrganizationId: currentUser?.getSelectedOrganization().organizationId,
            where: where,
            assembleAs: assembleAs,
            sort: sort,
            pagination: pagination
        };

        const postData: PostData = {
            url: this.queryExecutorUrl,
            body: body,
            headers: {
                // token sky
                authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpIjpudWxsLCJ1Ijoic2t5Lml0YWxpYSIsImYiOiJTa3kiLCJsIjoiSXRhbGlhIiwicyI6bnVsbCwicCI6Ijg4NmQxOGZhLTNiMWQtNDRlYS1hOTI1LWZhZGIxMWExYjI1NyIsImV4cCI6MTU5NTA2Nzk4NX0.6dm1GNYxQ5nRM7hcTa1i_MtLgBKmkpXuvdrf0HtVi3Ig4cgKYsjb5cYALkCOpQVTGmFFBHHtOmhc6fNzJH4kdg'
            }
        };

        return this.proxy.doPost(postData)
            .pipe(
                map((res: any) => res.hits)
            );
    }

    public doSpecificQuery(queryName: string, queryParams?: any, assembleAs?: any, sort?: GruulsSort, pagination?: GruulsPagination): Observable<any> {
        return throwError('Not yet Implemented');
    }

    public getAvailableQueries(): Observable<any> {
        return throwError('Not yet Implemented');
    }


}
