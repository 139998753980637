import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GruulsRoutingServiceInterface} from '@gruuls-core/service-interfaces/gruuls-routing-service-interface';

@Injectable()
export class GruulsAngularRoutingService implements GruulsRoutingServiceInterface{

    constructor(
        private router: Router,
        private activatedRouteSnapshot: ActivatedRoute
    ) {
    }

    getUrlParams(): { [param: string]: string } {
        return this.activatedRouteSnapshot.snapshot.params;
    }

    goToInternalRouteByUrl(route: string): void {
        this.router.navigateByUrl(route);
    }

    getUrlDomain(): string {
        return GruulsAngularRoutingService.domainFromUrl(this.getUrl());
    }

    getUrlPath(): string {
        return this.router.url;
    }

    getUrl(): string{
        return window.location.href;
    }


    public static domainFromUrl(url): string{
        let result;
        const match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
        if (match) {
            result = match[1];
            if (match === result.match(/^[^\.]+\.(.+\..+)$/)) {
                result = match[1];
            }
        }
        return result;
    }
}
