<div class=" inset-0 flex flex-col min-w-0 overflow-hidden">

    <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent" (backdropClick)="onBackdropClicked()">

        <!-- Drawer -->
        <mat-drawer class="w-full md:w-160 dark:bg-gray-900" [mode]="drawerMode" [opened]="false" [position]="'end'"
            [disableClose]="true" #matDrawer>
        </mat-drawer>

        <mat-drawer-content class="flex flex-col">

            <!-- Main -->
            <div class="flex-auto">

                <!-- Header -->
                <div class="flex flex-col sm:flex-row md:flex-col flex-auto justify-between py-8 px-6 md:px-8 border-b">

                    <!-- Title -->
                    <div>
                        <div class="text-4xl font-extrabold tracking-tight leading-none">
                            {{translateStrings.store.plural}}</div>
                        <div class="ml-0.5 font-medium text-secondary">
                            <ng-container *ngIf="storesDataSource?.data?.length">
                                {{storesDataSource?.data?.length}}
                            </ng-container>
                            {{storesDataSource?.data?.length | i18nPlural: {
                            '=0': translateStrings.store.noStores,
                            '=1': translateStrings.store.singular,
                            'other': translateStrings.store.plural
                            } }}
                        </div>
                    </div>

                    <!-- Main actions -->
                    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
                        <!-- Search -->
                        <div class="flex-auto">
                            <mat-form-field
                                class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                                <input matInput [formControl]="searchInputControl" [autocomplete]="'off'"
                                    [placeholder]="translateStrings.store.search">
                            </mat-form-field>
                        </div>

                        <!-- Add button -->
                        <button class="ml-4" mat-flat-button [color]="'primary'"
                            (click)="selectedStore = undefined; openModal()">
                            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            <span class="ml-2 mr-1">{{translateStrings.store.addNew}}</span>
                        </button>
                    </div>
                </div>
                <!-- Loader -->
                <div class="absolute inset-x-0" *ngIf="isLoading">
                    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                </div>

                <ng-container *ngIf="storesDataSource?.data?.length; else noStores">

                    <!-- Stores list -->
                    <div class="w-full py-8 px-6 md:px-8">
                        <!-- stores table -->
                        <div
                            class="xl:col-span-2 flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden pb-5">
                            <div class="overflow-x-auto mx-6">
                                <table class="w-full bg-transparent" mat-table matSort [dataSource]="storesDataSource"
                                    [trackBy]="trackByFn" #storesTable>

                                    <!-- Store -->
                                    <ng-container matColumnDef="store">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            {{translateStrings.store.singular}}
                                        </th>
                                        <td mat-cell *matCellDef="let store">
                                            <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                                                <!-- <a [routerLink]="['../detail', store.storeId]">{{store.name}}</a> -->
                                                {{store.name}}
                                            </span>
                                        </td>
                                    </ng-container>

                                    <!-- Address -->
                                    <ng-container matColumnDef="address">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                            {{translateStrings.store.address}}
                                        </th>
                                        <td mat-cell *matCellDef="let store">
                                            <span class="pr-6 whitespace-nowrap">
                                                {{store.address}}
                                            </span>
                                        </td>
                                    </ng-container>

                                    <!-- update -->
                                    <ng-container matColumnDef="update">
                                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                        </th>
                                        <td mat-cell *matCellDef="let store">
                                            <!-- <mat-icon class="pl-3 icon-size-8 cursor-pointer"
                                                [svgIcon]="'heroicons_solid:pencil'"
                                                (click)="selectedStore = store; openModal();"></mat-icon> -->


                                            <button
                                                (click)="$event.stopPropagation();  $event.preventDefault(); selectedStore = store;"
                                                mat-icon-button [matMenuTriggerFor]="menu" aria-label="Toggle menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>

                                            <mat-menu #menu="matMenu" (click)="$event.stopPropagation()">
                                                <button mat-menu-item (click)="openModal()">
                                                    <mat-icon>edit</mat-icon>
                                                    <span>{{translateStrings.generic.edit}}</span>
                                                </button>
                                                <button mat-menu-item class="text-red-400" (click)="deleteStore()">
                                                    <mat-icon class="text-red-400">delete</mat-icon>
                                                    <span>{{translateStrings.generic.delete}}</span>
                                                </button>
                                            </mat-menu>


                                        </td>
                                    </ng-container>


                                    <!-- Footer -->

                                    <tr mat-header-row *matHeaderRowDef="storeTableColumns"></tr>
                                    <tr class="order-row h-16" mat-row
                                        *matRowDef="let row; columns: storeTableColumns;"></tr>

                                </table>
                            </div>
                        </div>

                    </div>
                </ng-container>

                <!-- No  -->
                <ng-template #noStores>
                    <div *ngIf="!isLoading"
                        class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">
                        {{translateStrings.store.noStores}}</div>
                </ng-template>


            </div>
        </mat-drawer-content>

    </mat-drawer-container>
</div>

<ng-template #storeModal>
    <h3 mat-dialog-title>{{translateStrings.store.addNew}}</h3>
    <mat-dialog-content>
        <form [formGroup]="addNewStoreForm" (ngSubmit)="addNewStoreFormSubmit($event)" #newStoreForm="ngForm">
            <div class="flex flex-col gt-xs:flex-row p-7">

                <mat-form-field class="flex-auto gt-xs:pr-3">
                    <mat-label>{{translateStrings.store.name}}</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:office-building'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="fuse-mat-textarea flex-auto">
                    <mat-label>{{translateStrings.store.description}}</mat-label>
                    <textarea matInput [rows]="3" formControlName="description" matTextareaAutosize></textarea>
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:annotation'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="fuse-mat-textarea flex-auto">
                    <mat-label>{{translateStrings.store.address}}</mat-label>
                    <input matInput [rows]="3" formControlName="address" matTextareaAutosize>
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:location-marker'"></mat-icon>
                </mat-form-field>

                <div class="flex flex-row gap-x-4">
                    <div class="flex-none">
                        <mat-form-field>
                            <mat-label>{{translateStrings.store.zip}}</mat-label>
                            <input matInput maxlength="5" formControlName="zip" #postalCode>
                            <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                            <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="flex-auto w-full">
                        <mat-form-field class="w-full">
                            <mat-label>{{translateStrings.store.city}}</mat-label>
                            <input matInput formControlName="city" #city>
                        </mat-form-field>
                    </div>
                    <div class="flex-auto">
                        <mat-form-field>
                            <mat-label>{{translateStrings.store.province}}</mat-label>
                            <mat-select matInput formControlName="province" #province>
                                <mat-select-trigger>
                                    {{addNewStoreForm.controls['province'].value?.key}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let province of provinces" [value]="province">
                                    {{province.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>

                <!-- <mat-form-field
                    class="flex-auto gt-xs:pr-3">
                    <mat-label>{{translateStrings.store.type}}</mat-label>
                    <input matInput formControlName="type">
                    <mat-icon
                        class="icon-size-5"
                        matSuffix
                        [svgIcon]="'heroicons_solid:tag'"></mat-icon>
                </mat-form-field> -->

            </div>

            <!-- Modal footer -->
            <div
                class="flex justify-between items-center py-6 mx-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <div>
                    <button mat-button mat-dialog-close type="button"
                        class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{{translateStrings.generic.close}}</button>
                </div>

                <div class="flex space-x-2">
                    <button id="save" type="submit" mat-flat-button [color]="'primary'" class="rounded-lg"
                        [disabled]="!addNewStoreForm.valid">
                        <span *ngIf="!isSpecificLoading['save']">
                            {{translateStrings.generic.save}}
                        </span>
                        <mat-progress-spinner *ngIf="isSpecificLoading['save']" [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>
                </div>
            </div>

        </form>
    </mat-dialog-content>
</ng-template>