export class ServiceDispatcher {

    private static services: {
        [serviceName: string]: any;
    } = {};


    public static registerService(serviceName: string, service: any): void {
        ServiceDispatcher.services[serviceName] = service;
    }

    public static getService(serviceName: string): any {
        return ServiceDispatcher.services[serviceName];
    }
}
