<!-- cdkDropListOrientation="horizontal" -->
<!--{{element.runtimeId}} -({{element.elementList.length}})- {{allConnectedIds}}-->
<!--({{element.elementList.length}}-{{element.config1.elements.length}})-->
<ng-container *ngIf="(element.config.status !== 'initializing') && element.config.status !== 'toInitialize'">
    <ng-container *ngIf="!enableDragDrop">
        <div [ngClass]="classes()" class="box-content" style="min-width: 10px;">
            <ng-container *ngFor="let child of element?.elementList">
                <div *ngIf="!child.config.isBackgroundElement"
                    gruuls-element-factory
                    [element]="child"
                    [allConnectedIds]="allConnectedIds"
                > </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="enableDragDrop">
        <div [ngClass]="classes()" class="box-content" style="min-width: 10px;"
             cdkDropList
             [id]="element.runtimeId"
             [cdkDropListConnectedTo]="allConnectedIds"
             [cdkDropListData]="element"
             [cdkDropListOrientation] = "dropListOrientation"
             [cdkDropListSortingDisabled]="!element.config._dropSortEnabled"
             (cdkDropListEntered)="entered($event)"
             (cdkDropListExited)="exited($event)">
            <!--     (cdkDropListDropped)="drop($event)"-->
        <!--    my: {{id}} - others: {{allConnectedIds}}-->
            <div cdkDrag
                 [id]="element.runtimeId"
                 (cdkDragDropped)="drop($event)"
                 [cdkDragDisabled] = "!element.config._dragEnabled"

                 gruuls-element-factory
                 *ngFor="let child of element?.elementList"
                 [element]="child"
                 [allConnectedIds]="allConnectedIds">
            </div>
        </div>
    </ng-container>
</ng-container>
