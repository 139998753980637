<div class="relative flex flex-col w-full h-full">

  <!-- Dismiss button -->
  <ng-container *ngIf="data.dismissible">
    <div class="absolute top-0 right-0 pt-4 pr-4">
      <button mat-icon-button [matDialogClose]="undefined">
        <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
      </button>
    </div>
  </ng-container>

  <!-- Content -->
  <div class="flex flex-col w-full sm:flex-row flex-auto items-center sm:items-start p-8 pb-0">

    <!-- Icon -->
    <ng-container *ngIf="data.icon.show">
      <div class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full" [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': data.icon.color === 'primary',
                          'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600': data.icon.color === 'accent',
                          'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data.icon.color === 'warn',
                          'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600': data.icon.color === 'basic',
                          'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600': data.icon.color === 'info',
                          'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500': data.icon.color === 'success',
                          'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500': data.icon.color === 'warning',
                          'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': data.icon.color === 'error'
                          }">
        <mat-icon class="text-current" [svgIcon]="data.icon.name"></mat-icon>
      </div>
    </ng-container>

    <ng-container *ngIf="data.title || data.message">
      <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

        <!-- Title -->
        <ng-container *ngIf="data.title">
          <div class="text-xl leading-6 font-medium" [innerHTML]="data.title"></div>
        </ng-container>

        <!-- Message -->
        <ng-container *ngIf="order">
          <div class="text-secondary mb-4">
            <span>{{totalQuantity}} articoli</span>
          </div>
          <span>Totale: <strong>{{order.total | currency:'EUR'}}</strong></span>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="p-8 pt-1">
    <input type="area" [(ngModel)]="order._note" placeholder="{{translateStrings.order.notePlaceholder}}" class="w-full mt-4" />
  </div>
  

  <div class="border-t-2">
    <!-- Loader -->
    <div class="inset-x-0 top-0" *ngIf="isSpecificLoading['stores']">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <div *ngIf="!isEditShipping && stores.length>0" class="flex px-8 py-3">
      <div *ngIf="selectedShippingStore" class="flex-1">
        <div class="pb-2">
          <label class="text-secondary">{{translateStrings['order']['shippingTo']}}: </label>
        </div>
        <p><strong>{{selectedShippingStore.name}}</strong></p>
        <p>{{selectedShippingStore.address}}</p>
        <p>{{selectedShippingStore.zip}} {{selectedShippingStore.city}} <span *ngIf="selectedShippingStore.province">({{selectedShippingStore.province}})</span></p>
        <p>{{selectedShippingStore.phone}}</p>
      </div>
      <div class="flex-none">
        <a class="text-primary" (click)="editShipping()">{{translateStrings['generic']['edit']}}</a>
      </div>
    </div>
    <div *ngIf="!isEditShipping && (!stores || stores?.length==0) && !isSpecificLoading['stores']" class="px-8 py-3">
      <p class="">Per procedere è necessario inserire almeno un Punto di Vendita per il ritiro della merce.</p>
      <p class="">Vai alla <a [routerLink]="['/beauty/store/list']" class="text-primary">sezione dei Punti Vendita</a>.
      </p>
    </div>

    <div *ngIf="isEditShipping" class="flex px-8 py-3 overflow-scroll max-h-60">
      <div class="w-full">
        <div class="pb-2">
          <label id="shipping-group-label">{{translateStrings['order']['shippingTo']}}: </label>
        </div>
        <mat-radio-group aria-labelledby="shipping-group" [(ngModel)]="selectedShippingStore" (change)="changeShippingStoreHandler()">
          <div class="flex flex-col gap-y-3">
            <mat-radio-button *ngFor="let store of stores" [value]="store" class=":checked:text-accent">
              <p><strong>{{store.name}}</strong></p>
              <p>{{store.address}}</p>
              <p>{{store.zip}} {{store.city}} <span *ngIf="store.province">({{store.province}})</span></p>
              <p>{{store.phone}}</p>
            </mat-radio-button>
          </div>
        </mat-radio-group>
        <div class="py-4">
          <a class="text-primary"
            [routerLink]="['/beauty/store/list']">{{translateStrings['order']['addCustomAddress']}}</a>
        </div>
      </div>
      <div class="flex-none">
        <a class="text-primary" (click)="isEditShipping = false">{{translateStrings['generic']['close']}}</a>
      </div>
    </div>
    <div *ngIf="!allInfo && (stores && stores.length>0) && !isSpecificLoading['stores']" class="px-8 py-3">
      <p class="text-red-600">Prima di procedere completare l'indirizzo del punto vendita in tutte le sue parti</p>
    </div>

    <!-- Actions -->
    <ng-container *ngIf="data.actions.confirm.show || data.actions.cancel.show">
      <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <!-- Cancel -->
        <ng-container *ngIf="data.actions.cancel.show">
          <button mat-stroked-button [matDialogClose]="{message: 'cancel'}">
            {{data.actions.cancel.label}}
          </button>
        </ng-container>

        <!-- Confirm -->
        <ng-container *ngIf="data.actions.confirm.show">
          <button mat-flat-button [disabled]="!selectedShippingStore || isSpecificLoading['save'] || !allInfo"
            [color]="data.actions.confirm.color" (click)="sendOrder()">
            <span *ngIf="!isSpecificLoading['save']">
              {{data.actions.confirm.label}}
            </span>
            <mat-progress-spinner *ngIf="isSpecificLoading['save']" [diameter]="24"
              [mode]="'indeterminate'"></mat-progress-spinner>
          </button>
        </ng-container>

      </div>
    </ng-container>

  </div>
</div>