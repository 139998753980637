import {ModalElementConfig, ModalElementConfigInterface} from './modal-element-config';
import {DynamicFlexContainerElement} from '../../ui-builders/dynamic-flex-container-element/dynamic-flex-container-element';
export class ModalElement extends DynamicFlexContainerElement {

    constructor(config: ModalElementConfigInterface) {
        super(config);
        this.config = new ModalElementConfig(config, this);
    }

}
