import {AbstractControl, Validators} from '@angular/forms';
import {Field} from './field';
import {DataSchema} from './data-schema';

export interface SyncValidator {

    errorMessage: string;
    name: string;

    validate(schemaDefinition: DataSchema, fieldDefinition: Field, formValue: any, fieldValue: any): any;
}

export interface SyncValidatorJson {
    name: string;
    param?: any;
}

export interface SyncValidatorFactory {
    rehydrate(config: SyncValidatorJson, schema: DataSchema, fieldDefinition: Field): any;
}

export class AngularSyncValidatorFactory implements SyncValidatorFactory {

    public static catalog: any = {
        group_lt: AngularSyncValidatorFactory.groupLtFn,
        group_lte: AngularSyncValidatorFactory.groupLteFn,
        group_gt: AngularSyncValidatorFactory.groupGtFn,
        group_gte: AngularSyncValidatorFactory.groupGteFn,
        group_fieldEquals: AngularSyncValidatorFactory.groupFieldEqualsFn,
        // inverseOneToManyRequired: AngularSyncValidatorFactory.inverseOneToManyRequiredFn
    };

    public static groupLtFn(config: SyncValidatorJson, schema: DataSchema, fieldDefinition: Field): (control: AbstractControl) => {[p: string]: any} {
        const r = (control: AbstractControl): { [key: string]: any } | null => {
            const message = {};
            message[config.name] = true;
            return (control.value[config.param[0]] < control.value[config.param[1]]) ? null : message;
        };
        return r;
    }

    public static groupLteFn(config: SyncValidatorJson, schema: DataSchema, fieldDefinition: Field): (control: AbstractControl) => {[p: string]: any} {
        const r = (control: AbstractControl): { [key: string]: any } | null => {
            const message = {};
            message[config.name] = true;
            return (control.value[config.param[0]] <= control.value[config.param[1]]) ? null : message;
        };
        return r;
    }

    public static groupGtFn(config: SyncValidatorJson, schema: DataSchema, fieldDefinition: Field): (control: AbstractControl) => {[p: string]: any} {
        const r = (control: AbstractControl): { [key: string]: any } | null => {
            const message = {};
            message[config.name] = true;
            return (control.value[config.param[0]] > control.value[config.param[1]]) ? null : message;
        };
        return r;
    }

    public static groupGteFn(config: SyncValidatorJson, schema: DataSchema, fieldDefinition: Field): (control: AbstractControl) => {[p: string]: any} {
        const r = (control: AbstractControl): { [key: string]: any } | null => {
            const message = {};
            message[config.name] = true;
            return (control.value[config.param[0]] >= control.value[config.param[1]]) ? null : message;
        };
        return r;
    }

    public static groupFieldEqualsFn(config: SyncValidatorJson, schema: DataSchema, fieldDefinition: Field): (control: AbstractControl) => {[p: string]: any} {
        const r = (control: AbstractControl): { [key: string]: any } | null => {
            const message = {};
            message[config.name] = true;
            return (control.value[config.param[0]] === control.value[config.param[1]]) ? null : message;
        };
        return r;
    }

    // public static inverseOneToManyRequiredFn(config: SyncValidatorJson, schema: DataSchema, fieldDefinition: Field): (control: AbstractControl) => {[p: string]: any} {
    //     const r = (control: AbstractControl): { [key: string]: any } | null => {
    //         const otherDomainKeyFieldName = DomainCatalog.getInstance().getDomainByFqdn((fieldDefinition as InverseOneToManyField).otherDomain).getSchema().getKeyFieldName();
    //         if (control.value && control.value[otherDomainKeyFieldName]) {
    //             return null;
    //         } else {
    //             const message = {};
    //             message[config.name] = "true";
    //             return message;
    //         }
    //     };
    //     return r;
    // }

    public rehydrate(config: SyncValidatorJson, schema: DataSchema, fieldDefinition: Field | DataSchema): any {

        switch (config.name) {
            case 'required':
                return Validators.required;
            case 'minlength':
                return Validators.minLength(config.param);
            case 'maxlength':
                return Validators.maxLength(config.param);
            case 'min':
                return Validators.min(config.param);
            case 'max':
                return Validators.max(config.param);
            case 'email':
                return Validators.email;
            case 'regex':
                return Validators.pattern(config.param);
            default:
                if (AngularSyncValidatorFactory.catalog[config.name]) {
                    return AngularSyncValidatorFactory.catalog[config.name](config, schema, fieldDefinition);
                } else {
                    console.warn('the requested sync validator (' + config.name + ') has not been found');
                    return (control: AbstractControl): { [key: string]: any } | null => null;
                }
        }
    }

}
