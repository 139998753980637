import {
    DynamicFlexContainerElementConfig, DynamicFlexContainerElementConfigInterface
} from '../../ui-builders/dynamic-flex-container-element/dynamic-flex-container-element-config';
import {BaseElement} from '../../misc/base-element/base-element';


export interface ModalElementConfigInterface extends DynamicFlexContainerElementConfigInterface{
    foo?: string;
}

export class ModalElementConfig extends DynamicFlexContainerElementConfig implements ModalElementConfigInterface{
    static category: string = 'Ui';
    static type: string = 'ModalElement';


    constructor(config: DynamicFlexContainerElementConfigInterface, element: BaseElement) {
        super(config, element);

        config.category = ModalElementConfig.category;
        config.type = ModalElementConfig.type;
        this.type = config.type;
        this.category = config.category;
    }

}
