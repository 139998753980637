import {Inject, Injectable} from '@angular/core';
import {GruulsNumberFormatterServiceInterface} from '../../@gruuls-core/service-interfaces/gruuls-number-formatter-service-interface';
import {DecimalPipe} from '@angular/common';

@Injectable()
export class GruulsAngularNumberFormatterService implements GruulsNumberFormatterServiceInterface{

    constructor(
        private decimalPipe: DecimalPipe
    ) {}


    format(value: number, formatterTemplate: string, useLocale: string): any {
        return this.decimalPipe.transform(value, formatterTemplate, useLocale);
    }
}
