import {Field} from './field';
import {SyncValidatorJson} from './sync-validator';
import {AsyncValidatorJson} from './async-validator';
import {FieldType} from './field-type';
import {FieldConfig} from '../domain/templates-config';
import lodash_ from 'lodash';
import {Fields} from './fields';

const _: any = lodash_;

export class DataSchema {

    private contextName: string;
    private domainName: string;
    private systemFields: Field[];

    public constructor(
        public fields: Field[],
        public defaultAssembleAs: any,
        public groupSyncValidators?: SyncValidatorJson[],
        public groupAsyncValidators?: AsyncValidatorJson[],
    ) {
        for (const f of fields) {
            f.setPathName([]);
            f.setSchema(this);
        }
        if (!this.groupSyncValidators) {
            this.groupSyncValidators = [];
        }
        if (!this.groupAsyncValidators) {
            this.groupAsyncValidators = [];
        }

        // adding system fields
        this.systemFields = [
            // Fields.string({
            //     name: '_id'
            // }),
            // Fields.date({
            //     name: '_creationDate'
            // }),
            // Fields.date({
            //     name: '_updateDate'
            // }),
            // Fields.string({
            //     name: '_creationUser'
            // }),
            // Fields.string({
            //     name: '_updateUser'
            // }),
            // Fields.enum({
            //     name: '_status',
            //     enumValues: ['ACTIVE', 'INACTIVE', 'DELETED']
            // }),
            // Fields.referenceOrganization({
            //     name: '_referenceOrganizationId'
            // })
        ];

    }

    public static of(
        fields: Field[],
        defaultAssembleAs: any,
        groupSyncValidators?: SyncValidatorJson[],
        groupAsyncValidators?: AsyncValidatorJson[]
    ): DataSchema {
        return new DataSchema(fields, defaultAssembleAs, groupSyncValidators, groupAsyncValidators);
    }

    public static ofConfig(
        config: {
            fields: Field[];
            defaultAssembleAs: any;
            groupSyncValidators?: SyncValidatorJson[];
            groupAsyncValidators?: AsyncValidatorJson[];
        }
    ): DataSchema {
        return new DataSchema(config.fields, config.defaultAssembleAs, config.groupSyncValidators, config.groupAsyncValidators);
    }

    static mergeFieldsConfigsStatic(contextName: string, domainName: string, fields: Field[], fieldsToShow?: (string | FieldConfig)[], filterFn?: (field: Field) => boolean, initialDefault?: FieldConfig): FieldConfig[] {
        if (!fieldsToShow || (fieldsToShow && !fieldsToShow.length)) {
            // filterFn = (!fieldsToShow && !filterFn) ? f => f.type === FieldType.uniqueKey || f.type === FieldType.string || f.type === FieldType.enum || f.type === FieldType.long : filterFn;
            fieldsToShow = fields.map(f => ({fieldName: f.name}));
        }
        return fieldsToShow
            .map((f) => {
                const fieldToShow: FieldConfig = (typeof f === 'string' || f instanceof String) ? {fieldName: f as string} : f;
                const field: Field = fields.find(f1 => f1.name === fieldToShow.fieldName);
                if (!field) {
                    console.warn('[' + contextName + '.' + domainName + '][DataSchema][mergeFieldsConfigs()] - field \'' + fieldToShow.fieldName + '\' does not exists in this schema. Skipping it.');
                    return null;
                }
                if (!filterFn || (filterFn && filterFn(field))) {
                    const def = initialDefault ? _.cloneDeep(initialDefault) : {};
                    return _.defaultsDeep(fieldToShow, field.fieldConfig, def);
                } else {
                    return null;
                }
            })
            .filter(f => !!f);
    }

    setContextName(n: string): void {
        this.contextName = n;
    }

    setDomainName(n: string): void {
        this.domainName = n;
    }

    getContextName(): string {
        return this.contextName;
    }

    getDomainName(): string {
        return this.domainName;
    }

    getFields(): Field[] {
        return this.fields;
    }

    mergeFieldsConfigs(fieldsToShow?: (string | FieldConfig)[], filterFn?: (field: Field) => boolean, initialDefault?: FieldConfig): FieldConfig[] {
        return DataSchema.mergeFieldsConfigsStatic(this.contextName, this.domainName, this.getFields(), fieldsToShow, filterFn, initialDefault);
    }

    getFieldsMarkedAsVisualReferences(): Field[]{
        return this.fields.filter(f => f.fieldConfig?.useAsVisualReference);
    }

    getField(name: string): Field | undefined {
        return this.fields.find(el => el.getName() === name);
    }

    getDefaultAssembleAs(): any {
        return this.defaultAssembleAs;
    }

    getSystemFields(): Field[] {
        return this.systemFields;
    }

    getKeyField(): Field {
        return this.fields.filter(f => f.type === FieldType.uniqueKey)[0];
    }

    getKeyFieldName(): string {
        return this.fields.filter(f => f.type === FieldType.uniqueKey)[0].name;
    }

    serialize(): string {
        // TODO
        return 'serialized schema!';
    }

    rehydrate(cfg: any): DataSchema {
        return DataSchema.of(null, null, null, null);
    }

}
