import { FieldConfig } from '@gruuls-core/domain/domain/templates-config';
import _lodash from 'lodash';
import { CollectionType } from '../collection-type';
import { Field } from '../field';
import { FieldType } from '../field-type';
import { Fields } from '../fields';
import {FieldModelTypes} from '../field-model-types';
import {FormElementsAngularUtils} from '../../../../@gruuls-fe/utils/form-elements-angular-utils';

const _ = _lodash;


export class OpeningHoursField extends Field {

    // public static latFieldNameDefaultName = "lat";
    // public static lonFieldNameDefaultName = "lon";

    public constructor(
        name: string,
        subFields: Field[],
        type: FieldType,
        collectionType: CollectionType,
        fieldConfig: FieldConfig,
        modelType?: string,
        public monFieldName?: string,
        public tueFieldName?: string,
        public wedFieldName?: string,
        public thuFieldName?: string,
        public friFieldName?: string,
        public satFieldName?: string,
        public sunFieldName?: string
    ) {
        super(name, subFields, undefined, type, collectionType, fieldConfig, modelType);

    }

    public static ofConfig(config: {
                               name: string;
                               subFields?: Field[];
                               type?: FieldType;
        collectionType?: CollectionType;
                               fieldConfig?: FieldConfig;
                               monFieldName?: string;
                               tueFieldName?: string;
                               wedFieldName?: string;
                               thuFieldName?: string;
                               friFieldName?: string;
                               satFieldName?: string;
                               sunFieldName?: string;
                               modelType?: string;
                           }
    ): Field {
        Fields.counter = Fields.counter++;

        const subFields: Field[] = [];

        if (config.monFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.monFieldName
                })
            );
        }

        if (config.tueFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.tueFieldName
                })
            );
        }

        if (config.wedFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.wedFieldName
                })
            );
        }

        if (config.thuFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.thuFieldName
                })
            );
        }

        if (config.friFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.friFieldName
                })
            );
        }

        if (config.satFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.satFieldName
                })
            );
        }

        if (config.sunFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.sunFieldName
                })
            );
        }

        config = _.defaultsDeep(
            {
                type: FieldType.openingHours,
                subFields: subFields
            },
            config,
            {
                collectionType: CollectionType.NONE,
                storeLatLon: false,
                modelType: FieldModelTypes.flat,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.maybeFlatValueObjectFormControlElement
                }
            });

        return new OpeningHoursField(config.name, config.subFields, config.type, config.collectionType, config.fieldConfig, config.modelType, config.monFieldName, config.tueFieldName,
            config.wedFieldName, config.thuFieldName, config.friFieldName, config.satFieldName, config.sunFieldName);
    }

    public populateAssembleAs(assembleAs: any, subFieldsAssembly?: any): void {
        if (this.modelType === 'flat') {
            assembleAs[this.monFieldName] = true;

            assembleAs[this.tueFieldName] = true;
            assembleAs[this.wedFieldName] = true;
            assembleAs[this.thuFieldName] = true;
            assembleAs[this.friFieldName] = true;
            assembleAs[this.satFieldName] = true;
            assembleAs[this.sunFieldName] = true;
        } else {
            assembleAs[this.name] = true;
        }
    }

}
