import atob from 'atob';

/**
 * Created by marcoabi on 12/02/18.
 */

export class JwtHelper {
    private urlBase64Decode(str: string): string {
        let output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0: {
                break;
            }
            case 2: {
                output += '==';
                break;
            }
            case 3: {
                output += '=';
                break;
            }
            default: {
                throw new Error('Illegal base64url string!');
            }
        }

        let windoz: any;
        if (typeof (window as any).global != 'undefined') {
            const globalAny: any = (window as any).global;
            windoz = globalAny ? globalAny : window;
        } else {
            windoz = window;
        }

        if (windoz && windoz.atob) {
            //browser env
            return decodeURIComponent(window.atob(output));
        } else {
            //node env
            return decodeURIComponent(atob(output)); // output st);
        }
    }

    public decodeToken(token: string): any {
        try {
            const parts = token.split('.');
            if (parts.length !== 3) {
                throw new Error('JWT must have 3 parts');
            }
            const decoded = this.urlBase64Decode(parts[1]);
            if (!decoded) {
                throw new Error('Cannot decode the token');
            }
            return JSON.parse(decoded);
        } catch (e) {
            console.warn('THIS IS THE TOKEN RECEIVED:', token);
            throw e;
        }
    }
}
