/**
 * Created by marcoabi on 25/11/18.
 */
import {User} from './user';
import {UserAction} from './user-action';
import {UserSettings} from './user-settings';
import {MyOrganization} from '../organization/my-organization';
import {OrganizationAcl} from '../organization/organization-acl';
import {Observable} from 'rxjs';


export class CurrentUser extends User {

    public userSettings: UserSettings;

    public constructor(user: any) {
        super(user);
        const defaultOrg = user.roles.find((r: any) => r.isDefault);
        let defaultOrgId;
        if (defaultOrg) {
            defaultOrgId = defaultOrg.inOrganization.organizationId;
        }
        this.userSettings = UserSettings.of(user.userSettings, defaultOrgId);

    }

    getSelectedOrganization(): MyOrganization | undefined {
        return this.userSettings.getSelectedOrganization();
    }

    selectOrganization(o: MyOrganization): void {
        this.userSettings.selectOrganization(o);
    }

    selectOrganizationById(organizationId: string): void {
        this.userSettings.selectOrganizationById(organizationId);
    }

    observeSelectedOrganizationChange(): Observable<MyOrganization | undefined> {
        return this.userSettings.observeSelectedOrganization();
    }

    getFavouriteOrganization(): MyOrganization | undefined {
        return this.userSettings.getFavouriteOrganization();
    }

    getMyOrganizations(): MyOrganization[] | undefined {
        return this.userSettings.getMyOrganizations();
    }

    public canUserDoOnResourcePathWithCurrentOrganization(resourcePath: string, action: UserAction): boolean {
        const selectedOrg = this.getSelectedOrganization();
        if (selectedOrg && selectedOrg.organizationId) {
            return this.canUserDoOnResourcePath(selectedOrg.organizationId, resourcePath, action);
        } else {
            return false;
        }

    }

    public getHomePageUrl(): string | undefined {
        const selectedOrg = this.getSelectedOrganization();
        if (this.organizations && selectedOrg) {
            const myO: MyOrganization = selectedOrg;
            const o: OrganizationAcl | undefined = this.organizations.find(el => el.organizationId === myO.organizationId);
            if (o) {
                return o.getHomePageUrl();
            }
        }
        return undefined;
    }

    public getNavigationItemsByOrganizationId(organizationId: string): any[] {
        const r = this.roles ? this.roles.find(el => el.inOrganization.organizationId === organizationId) : undefined;
        if (r) {
            let navigation: any[] = [];
            for (const role of r.hasRole) {
                if (role.serviceRoleMapping) {
                    for (const roleMapping of role.serviceRoleMapping) {
                        const n = roleMapping.navigationResources;
                        if (n) {
                            navigation = navigation.concat(n.children);
                        }
                    }
                }

            }
            return navigation;
        }
        return [];
    }

}
