import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input, OnInit,
    ViewEncapsulation
} from '@angular/core';
import {GruulsBaseElementComponent} from '../../misc/base-element/gruuls-base-element.component';
import {InputElement} from '@gruuls-core/elements/ui/input-element/input-element';
import {takeUntil, tap} from 'rxjs/operators';

@Component({
    selector: '[gruuls-input-element]',
    templateUrl: './input-element.component.html',
    styleUrls: ['./input-element.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputElementComponent extends GruulsBaseElementComponent implements OnInit{

    @Input()
    element: InputElement;

    inputClasses(): any{
        return {
            [`w-${this.element.config.width}`]: !!this.element.config.width,
            //     [`mt-${properties.marginTop}`]: properties.marginTop,
            // [`mb-${properties.marginBottom}`]: properties.marginBottom,
            // [`ml-${properties.marginLeft}`]: properties.marginLeft,
            // [`mr-${properties.marginRight}`]: properties.marginRight,

    };
    }
}
