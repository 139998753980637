import {RoleSettings} from './role-settings';

export class Role {


    constructor(
        public roleId: string,
        public roleName: string,
        public roleFlatName: string,
        public keyValue: any,
        public settings: RoleSettings
    ) {
    }

    public static of(role: any): Role {
        return new Role(
            role.roleId,
            role.roleName,
            role.roleFlatName,
            role.keyValue,
            role.settings ? role.settings.map : undefined
        );
    }

    public getHomePageUrl(): string | undefined {
        if (this.settings && this.settings.home && this.settings.home.route) {
            return this.settings.home.route;
        } else {
            return undefined;
        }
    }

    public isFavourite(): boolean {
        return (this.keyValue && this.keyValue.isFavourite === 'true');
    }
}
