import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation, inject } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import { Appointment, BeautyPath, MedicalHistory, Session, Treatment } from '../client.types';
import { BehaviorSubject, Subject, forkJoin, map, takeUntil, tap } from 'rxjs';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { GruulsAngularAppointmentsService } from '@gruuls-fe/services/gruuls-angular-appointments-service';
import { animate, style, transition, trigger } from '@angular/animations';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'beauty-path',
  templateUrl: './beauty-path.component.html',
  styleUrls: ['./beauty-path.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('500ms ease-out',
              style({ height: 150, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('500ms ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class BeautyPathComponent implements OnInit, OnDestroy, OnChanges {

  @Input() client: any;
  @Input() treatments: Treatment[] = [];
  @Input('medicalHistory') referenceMedicalHistory: MedicalHistory;

  @Output() isLoadingEvent = new EventEmitter<boolean>();

  MAX_PROPOSALS = 3;
  private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
  beautyPaths: BeautyPath[] = [];
  unassignedAppointments$: BehaviorSubject<Appointment[]> = new BehaviorSubject<Appointment[]>([]);
  selectedBeautyPath: BeautyPath;
  translateStrings: any = { beautyPath: {}, generic: {} };
  proposedTreatments: any = [];
  isPartialLoading: boolean[] = [];
  isSpecificLoading: boolean[] = [];

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private lastAnamnesiWithScores;

  /**
   * Constructor
   */
  constructor(
    private _authService: GruulsAuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _httpClient: GruulsAngularHttpProxyService,
    private _translate: GruulsAngularTranslateService,
    private _appointmentsService: GruulsAngularAppointmentsService
  ) {
  }

  @HostBinding('class') get classList(): any {
    return {
      'w-full': true
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    const clientId = (this.client && 'personId' in this.client) ? this.client.personId : null;

    this._appointmentsService.appointments$.pipe(
      takeUntil(this._unsubscribeAll),
      map((appointments: Appointment[]) => appointments.filter((appointment: Appointment) => (appointment.client && clientId && appointment.client.personId === clientId))),
      tap((appointments) => {
        this.unassignedAppointments$.next([...appointments]);
        this._changeDetectorRef.markForCheck();
      })
    ).subscribe();

    forkJoin({
      beautyPaths: this._apiCaller.getBeautyPaths(clientId).pipe(
        tap((res) => {
          this.beautyPaths = res;
          // if (this.beautyPaths && this.beautyPaths.length > 0)
          //   this.selectedBeautyPath = this.beautyPaths[0];
          this._changeDetectorRef.markForCheck();
        })
      ),
    }).subscribe({
      next: (response) => {
        // this.proposedTreatments = this.calculateReccomendation(this.lastAnamnesiWithScores, this.priorities);
        // this._changeDetectorRef.markForCheck();
      },
      error: (err) => {
        console.error("Error while loading information for Beauty Paths: " + err);
      }
    });

    const beautyPathTranslations = ['singular', 'plural', 'notPlanned', 'addSession', 'treatment', 'advisedSessions'];
    beautyPathTranslations.forEach((translation) => {
      this.translateStrings['beautyPath'][translation] = this._translate.translate('beautyPath.' + translation);
    });

    const genericTranslations = ['and'];
    genericTranslations.forEach((translation) => {
      this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
    });

    this.setLoadingState('appointments', true);
    this._appointmentsService.refreshAppointments().subscribe({
      next: (response) => {
        this.setLoadingState('appointments', false);
        this._changeDetectorRef.markForCheck();
      },
      error: (err) => {
        this.setLoadingState('appointments', false);
        console.error("Error while loading Beauty Paths: " + err);
        this._changeDetectorRef.markForCheck();
      }
    });
  }

  ngOnChanges(): void {

    if (this.referenceMedicalHistory) {
      forkJoin({
        lastAnamnesiWithScores: this._apiCaller.getMedicalHistoryScoresAndMerge(this.referenceMedicalHistory),
        priorities: this._apiCaller.getPriorities()
      }).subscribe({
        next: (res) => {
          this.lastAnamnesiWithScores = res.lastAnamnesiWithScores;
          this.proposedTreatments = this.calculateReccomendation(this.lastAnamnesiWithScores, res.priorities);
          this._changeDetectorRef.markForCheck();
        },
        error: (err) => {
          console.error("Error while loading information for Beauty Paths: " + err);
        }
      });
    }

    // if (this.client && ('personId' in this.client)) {
    //   this._appointmentsService.getClientAppointments(this.client.personId).pipe(
    //     takeUntil(this._unsubscribeAll),
    //     tap((unassignedAppointments) => {
    //       this.unassignedAppointments = unassignedAppointments;
    //       this._changeDetectorRef.markForCheck();
    //     })
    //   ).subscribe({
    //     next: (response) => {
    //       this._changeDetectorRef.markForCheck();
    //     },
    //     error: (err) => {
    //       console.error("Error while loading Beauty Paths: " + err);
    //       this._changeDetectorRef.markForCheck();
    //     }
    //   });
    // }

  }

  calculateReccomendation(lastAnamnesiWithScores: [], priorities: []): string[] {
    let advisedTreatmentsPerPriority = [];
    let proposedTreatments: string[] = [];

    for (const key of Object.keys(lastAnamnesiWithScores)) {
      let values;

      if (key in priorities && 'score' in lastAnamnesiWithScores[key]) {

        const score = lastAnamnesiWithScores[key].score;
        // TODO: to modify native array mgmt on BE
        if (typeof (lastAnamnesiWithScores[key].value) === 'object' && 'value' in lastAnamnesiWithScores[key].value)
          values = lastAnamnesiWithScores[key].value.value.map((v) => v.toString().toUpperCase());
        else
          values = [lastAnamnesiWithScores[key].value];

        values.forEach((v) => {
          let priorityTreatment;
          // TODO: should probably iterate over results
          if ('specifPriorityForResponse' in priorities[key] && v in priorities[key]['specifPriorityForResponse']) {
            priorityTreatment = priorities[key]['specifPriorityForResponse'][v];
          } else {
            priorityTreatment = priorities[key];
          }

          if ('priorityScore' in priorityTreatment && score in priorityTreatment['priorityScore']) {
            const priority = priorityTreatment['priorityScore'][score];
            const advisedTreatment = priorityTreatment['advisedTreatment'];

            // Add treatment with priority
            if (advisedTreatment && advisedTreatment != '') {
              if (!advisedTreatmentsPerPriority.includes(priority)) advisedTreatmentsPerPriority[priority] = [];
              advisedTreatmentsPerPriority[priority].push(advisedTreatment);
            }
          }
        });
      }
    }

    for (const key of Object.keys(advisedTreatmentsPerPriority)) {
      const treatments = advisedTreatmentsPerPriority[key];
      proposedTreatments = proposedTreatments.concat(treatments);
    }

    return proposedTreatments.slice(0, this.MAX_PROPOSALS);
  }

  isLoading(): boolean {
    const state = Object.values(this.isPartialLoading).reduce((acc, curr) => acc || curr, false);
    return state;
  }

  setLoadingState(key: string, loading: boolean): void {
    this.isPartialLoading[key] = loading;
    this.isLoadingEvent.emit(this.isLoading());
    this._changeDetectorRef.markForCheck();
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedBeautyPath.sessions, event.previousIndex, event.currentIndex);
  }

  addSession(treatmentCode: string | null = null): void {
    this.isSpecificLoading['add'] = true;
    this._changeDetectorRef.markForCheck();

    if (this.selectedBeautyPath) {
      // CURRENTLY NOT USED
      // const session: Session = {
      //   uuid: uuid.v4()
      // };
      // this.selectedBeautyPath.sessions.push(session);
      this._changeDetectorRef.markForCheck();
    } else {
      // Add Session to the unassigned Appointments
      const newAppointment: Appointment = {
        name: this.client.lastName,
        client: { personId: this.client.personId },
        durationMin: 30,
      };
      if (treatmentCode && this.treatments && this.treatments.length > 0) {
        newAppointment['treatmentId'] = this.treatments.find((treatment) => treatment.code === treatmentCode)?.uuid;
      }

      this._appointmentsService.createAppointment(newAppointment).subscribe({
        next: (appointment) => {
          this.isSpecificLoading['add'] = false;
          this._changeDetectorRef.markForCheck();
        },
        error: (err) => {
          console.error("Error while creating new Appointment: " + err);
          this.isSpecificLoading['add'] = false;
          this._changeDetectorRef.markForCheck();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  trackByFn(index: number, item: any): any {
    return item.appointmentId || index;
  }

}

