import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector       : 'e404',
    templateUrl    : './e404.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class Page404Component
{
    /**
     * Constructor
     */
    constructor()
    {
        
    }
}
