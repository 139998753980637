import _lodash from 'lodash';
import { Field } from '../../field';
import { FieldType } from '../../field-type';
import {GeopolygonFieldConfig} from './geopolygon-field-config';
import {CollectionType} from '../../collection-type';
import {Fields} from '../../fields';
import {FieldModelTypes} from '../../field-model-types';
import {FormElementsAngularUtils} from '../../../../../@gruuls-fe/utils/form-elements-angular-utils';

const _ = _lodash;


export class GeopolygonField extends Field {

    public constructor(
        name: string,
        subFields: Field[],
        type: FieldType,
        collectionType: CollectionType,
        fieldConfig: GeopolygonFieldConfig,
        modelType?: string,
        public polygonFieldName?: string,
        public storeGeohashesFieldName?: string
    ) {
        super(name, subFields, undefined, type, collectionType, fieldConfig, modelType);
    }

    public static ofConfig(config: {
                               name: string;
                               subFields?: Field[];
                               type?: FieldType;
                               collectionType?: CollectionType;
                               modelType?: string;
                               fieldConfig?: GeopolygonFieldConfig;
                               polygonFieldName?: string;
                               storeGeohashesFieldName?: string;
                           }
    ): Field {
        Fields.counter = Fields.counter++;
        if (config.modelType === 'flat' && (config.collectionType === 'LIST' || config.collectionType === 'SET')) {
            throw new Error('geopolygon field (name: ' + config.name + ') has a flat modelType. However it cannot be a collectionType (collectionType: ' + config.collectionType + '). If you want a collectionType you should select anotherModel type (eg. valueObject)');
        }

        config.polygonFieldName = config.polygonFieldName ? config.polygonFieldName : config.name;
        const subFields = [
            Fields.string_({
                name: config.polygonFieldName
            })
        ];
        if (config.storeGeohashesFieldName) {
            subFields.push(Fields.string_({
                name: config.storeGeohashesFieldName
            }));
        }

        config = _.defaultsDeep(
            {
                type: FieldType.geoPolygon,
                subFields: subFields
            },
            config,
            {
                collectionType: CollectionType.NONE,
                modelType: FieldModelTypes.flat,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.maybeFlatValueObjectFormControlElement
                }
            });

        return new GeopolygonField(config.name, config.subFields, config.type, config.collectionType, config.fieldConfig, config.modelType, config.polygonFieldName, config.storeGeohashesFieldName);
    }

    public populateAssembleAs(assembleAs: any, subFieldsAssembly?: any): void {
        if (this.storeGeohashesFieldName) {
            assembleAs[this.storeGeohashesFieldName] = true;
        }
        assembleAs[this.name] = true;
    }

}
