import {
    DynamicFlexContainerElementConfig, DynamicFlexContainerElementConfigInterface
} from '../../ui-builders/dynamic-flex-container-element/dynamic-flex-container-element-config';
import {BaseElement} from '../base-element/base-element';

export interface ContextElementConfigInterface extends DynamicFlexContainerElementConfigInterface{
    editMode?: boolean;
    enableDragDrop?: boolean;
}

export class ContextElementConfig extends DynamicFlexContainerElementConfig implements ContextElementConfigInterface{
    static category: string = 'Misc';
    static type: string = 'ContextElement';

    _editMode?: boolean;
    set editMode(newValue: boolean){
        this.internalSetValue('editMode', newValue);
    }
    get editMode(): boolean{
        return this._editMode;
    }

    _enableDragDrop?: boolean;
    set enableDragDrop(newValue: boolean){
        this.internalSetValue('enableDragDrop', newValue);
    }
    get enableDragDrop(): boolean{
        return this._enableDragDrop;
    }


    constructor(config: DynamicFlexContainerElementConfigInterface, element: BaseElement) {
        super(config, element);

        this._editMode = config.editMode || false;
        this._enableDragDrop = config.enableDragDrop || false;

        config.category = ContextElementConfig.category;
        config.type = ContextElementConfig.type;
        this.type = config.type;
        this.category = config.category;
    }

}
