/**
 * Sort follows the same elastic semantics.
 * For more details please see here: https://www.elastic.co/guide/en/elasticsearch/reference/7.8/search-request-body.html#request-body-search-sort
 */

export enum SortDirection {
    asc = 'asc',
    desc = 'desc'
}

export interface GruulsSort {
    sort: string | {
        [fieldName: string]: SortDirection;
    }[];
}
