import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { finalize, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { FuseConfigService } from '@fuse/services/config';
import { GruulsAngularTranslateService } from '@gruuls-fe/services/gruuls-angular-translate.service';

@Component({
    selector     : 'auth-sign-out',
    templateUrl  : './sign-out.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AuthSignOutComponent implements OnInit, OnDestroy
{
    countdown: number = 5;
    logoImageSrc: string;
    translateStrings: any = { generic: {} };
    title: string;
    countdownMapping: any = {
        '=1'   : '# second',
        'other': '# seconds'
    };
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _authService: GruulsAuthService,
        private _fuseConfigService: FuseConfigService,
        private _translate: GruulsAngularTranslateService,
        private _router: Router
    )
    {
    }

    @HostBinding('class') get classList(): any {
        return {
          'm-auto': true
        };
      }
    
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Sign out
        this._authService.logout();

        this.logoImageSrc = this._fuseConfigService.config.logoImgSrc;
        this.title = this._fuseConfigService.config.name;

        const genericTranslations = ['signedOut', 'redirectToLoginIn'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });

        // Redirect after the countdown
        timer(1000, 1000)
            .pipe(
                finalize(() => {
                    this._router.navigate(['login']);
                }),
                takeWhile(() => this.countdown > 0),
                takeUntil(this._unsubscribeAll),
                tap(() => this.countdown--)
            )
            .subscribe();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }
}
