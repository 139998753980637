<div class="inset-0 flex flex-col min-w-0 overflow-hidden">
    <!-- Loader -->
    <div *ngIf="isLoading" class="">
        <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>

    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">

        <!-- Title -->
        <div>
            <ng-container *ngIf="!isLoading">
                <div class="text-4xl font-extrabold tracking-tight">{{order.name ?? 'Carrello'}}
                    <!-- <span class="text-sm font-bold tracking-normal rounded-xl bg-green-200 px-2 py-1 ml-2">beta</span> -->
                </div>

                <div *ngIf="order.date" class="ml-0.5 font-medium text-secondary">
                    {{translateStrings.order.singular}} <strong>B{{order.id}}</strong> del {{order.date | date:
                    'dd/MM/yyyy HH:mm'}}
                </div>
            </ng-container>
            
            <mat-form-field class="flex flex-col mt-5 w-100">
                <!-- <mat-label>Condizioni di pagamento</mat-label> -->
                <mat-select placeholder="Condizioni di pagamento" [(value)]="order.externalPaymentConditionsId" [disabled]="!this.canChange" (selectionChange)="updateOrder(order)">
                    <ng-container *ngFor="let paymentCondition of paymentConditions">
                        <mat-option [value]="paymentCondition.id">
                            {{paymentCondition.label}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="defaultPaymentTerms"><span class="text-gray-500">{{translateStrings.order.defaultPaymentTerms}} {{defaultPaymentTerms}}</span></ng-container>
    </div>

        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Actions -->
            <ng-container *ngIf="order.status=='WAITING'">
                <button mat-flat-button type="submit" color="primary" (click)="executeOrder()" class="text-md"
                    [disabled]="isSpecificLoading['executing']">
                    <span *ngIf="!isSpecificLoading['executing']">{{translateStrings.orderDetail.executeOrder}}</span>
                    <div *ngIf="isSpecificLoading['executing']" class="w-full items-center">
                        <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
                    </div>
                </button>
            </ng-container>

            <!-- Badge -->
            <div *ngIf="order.status && order.status!='WAITING'"
                class="ml-auto flex flex-row items-center py-0.5 px-3 rounded-full text-sm font-semibold dark:text-{{getStatusColor(order.status)}}-50 dark:bg-{{getStatusColor(order.status)}}-500 text-{{getStatusColor(order.status)}}-700 bg-{{getStatusColor(order.status)}}-100">
                <mat-icon *ngIf="getStatusIcon(order.status)" class="icon-size-4 mr-2"
                    svgIcon="{{getStatusIcon(order.status)}}"></mat-icon>
                <div class="text-base">{{order.status | lowercase}}</div>
            </div>

            <!-- <mat-form-field class="w-50">

                <mat-select placeholder="Status" [formControl]="statusControl">
                    <mat-select-trigger>
                        <span class="flex items-center rounded-xl text-{{getStatusColor(statusControl?.value)}}-700">
                            <mat-icon
                                class="icon-size-4 text-{{getStatusColor(statusControl?.value)}}-700">{{getStatusIcon(statusControl?.value)}}</mat-icon>
                            <span
                                class="sm:mx-0.5 font-medium text-default pl-2 text-{{getStatusColor(statusControl?.value)}}-700">{{statusControl?.value
                                | lowercase}}</span>
                        </span>
                    </mat-select-trigger>
                    <ng-container *ngFor="let singleStatus of orderStatus">
                        <mat-option [value]="singleStatus">
                            <span class="flex items-center text-{{getStatusColor(singleStatus)}}-800">
                                <mat-icon class="icon-size-4">{{getStatusIcon(singleStatus)}}</mat-icon>
                                <span class="ml-2">{{singleStatus | lowercase}}</span>
                            </span>
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field> -->
            <button mat-icon-button (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu"
                aria-label="Toggle menu" class="">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="donwloadXls()" [disabled]="!order">
                    <mat-icon>download</mat-icon>
                    <span>{{translateStrings.order.downloadInXls}}</span>
                </button>
            </mat-menu>
            <!-- Send order button -->
            <button class="ml-4" mat-flat-button *ngIf="order && !order.status && order.cart.products?.length > 0"
                (click)="proceedWithOrder()" [color]="'primary'">
                <span class="ml-2 mr-1">{{translateStrings.orderDetail.proceed}}</span>
            </button>
        </div>
    </div>

    <ng-container *ngIf="!isLoading">
        <div *ngIf="shippingStore" class="flex sm:items-center py-8 px-6 md:px-8 border-b bg-gray-100">
            <mat-icon>place</mat-icon>
            <div class="ml-6 leading-6">
                <div class="font-medium">{{shippingStore?.name}}</div>
                <p *ngIf="shippingStore?.phone">Tel.:{{shippingStore?.phone}}</p>
                <p>{{shippingStore?.address}}</p>
                <p>{{shippingStore?.zip}} {{shippingStore?.city}} ({{shippingStore?.province}})</p>
            </div>
        </div>

        <div *ngIf="order.orderId" class="p-6">
            <div class="py-3 leading-6">
                <ng-container *ngFor="let comment of order.infoObject">
                    <div (mouseover)="showRemove[comment.uuid]=true" (mouseleave)="showRemove[comment.uuid]=false"><span
                            class="text-secondary">{{comment.date | date:'dd-MMM HH:mm'}}</span>&nbsp;<span
                            [ngClass]="(comment.isPublic ? 'font-medium' : 'text-secondary') + ' ' + (comment.type=='note' ? 'text-green-500' : '')">{{comment.comment}}
                            <ng-container *ngIf="showRemove[comment.uuid] && comment.uuid">
                                <a class="text-primary cursor-pointer"
                                    (click)="deleteComment(comment)">{{translateStrings.generic.delete}}</a>
                            </ng-container></span></div>
                </ng-container>
            </div>
            <form #addCommentForm="ngForm" (ngSubmit)="addComment()">
                <div class="flex flex-row gap-x-6 align-middle place-items-center">
                    <div class="flex-none w-1/3">
                        <input matInput type="text" id="comment"
                            class="fuse-mat-rounded w-full fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded"
                            [placeholder]="'Commento'" [autocomplete]="'off'" [required]="'true'" #commentInput />
                    </div>
                    <div class="flex-none w-48">
                        <mat-slide-toggle id="context" disabled="true" #contextToggle>
                            <span>{{contextToggle.checked ? translateStrings.generic.public :
                                translateStrings.generic.internal
                                }}</span>
                        </mat-slide-toggle>
                    </div>
                    <div class="flex-none">
                        <button mat-flat-button type="submit" [disabled]="!addCommentForm.valid" color="accent"
                            class="text-md">
                            {{translateStrings.generic.insert}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>

    <!-- Main -->
    <div *ngIf="!isLoading" class="flex flex-auto overflow-hidden w-full">
        <order-table [order]="order" [canChange]="canChange" (onOrderChange)="updateOrder($event)" [isAdmin]="true" class="w-full"></order-table>
    </div>
</div>