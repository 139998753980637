import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, NgForm, Validators} from '@angular/forms';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {mergeMap, Observable, Subject, of} from 'rxjs';
import {delay, map, takeUntil, tap} from 'rxjs/operators';
import {FuseConfirmationService} from '@fuse/services/confirmation';
import {Contact, Tag} from './store-details.types';
import {GruulsConstants} from '../../../../mock-api/gruuls/gruuls-constants';
import {GruulsAngularHttpProxyService} from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import {BeautyciansUtils} from '../../../utils/utils';
import {MatDialog} from '@angular/material/dialog';
import {GruulsAngularTranslateService} from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import moment from 'moment/moment';
import {GruulsAuthService} from '../../../../../@gruuls-fe/services/gruuls-auth.service';

@Component({
    selector: 'store-details',
    templateUrl: './store-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreDetailsComponent implements OnInit, OnDestroy {
    @ViewChild('avatarFileInput') private _avatarFileInput: ElementRef;
    @ViewChild('tagsPanel') private _tagsPanel: TemplateRef<any>;
    @ViewChild('tagsPanelOrigin') private _tagsPanelOrigin: ElementRef;
    @ViewChild('newUserForm') newUserForm: NgForm;
    @ViewChild('userModal') userModal: TemplateRef<any>;

    editMode: boolean = false;
    tags: Tag[];
    store: any;
    searchInputControl: FormControl = new FormControl();
    filteredContacts: any[] = [];
    private _contacts: any[] = [];
    private _tagsPanelOverlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    addNewUserFormTitle: Observable<string>;

    translateStrings: any = {client: {}, user: {}, generic: {}};
    selectedUser: any;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _renderer2: Renderer2,
        private _router: Router,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _httpClient: GruulsAngularHttpProxyService,
        private _fb: FormBuilder,
        private dialog: MatDialog,
        private _translate: GruulsAngularTranslateService,
        private _authService: GruulsAuthService
    ) {
    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    addNewUserForm = this._fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        address: ['', Validators.required],
        sex: ['', Validators.required],
        birthdate: ['', Validators.required]
    });

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Get route params
        this._activatedRoute.params.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((params) => {
            // Get the store
            const q = {
                contextName: 'Beautycians',
                domainName: 'Store',
                queryName: 'FIND_ALL',
                where: {
                    storeId: params.id
                },
                assembleAs: {
                    name: true,
                    address: true
                }
            };
            this._httpClient.doPost({
                url: GruulsConstants.QUERY_API_URL,
                body: q
            }).subscribe({
                next: (res) => {
                    this.store = res.hits[0];
                    this._changeDetectorRef.markForCheck();
                }
            });

            // get the client list
            this.getStoreClients(params.id).subscribe({
                next: (response) => {
                    this._contacts = BeautyciansUtils.sortByKeys(response, ['firstName', 'lastName']);
                    this.filteredContacts = this._contacts;
                    this._changeDetectorRef.markForCheck();
                }
            });
        });

        // Subscribe to search input field value changes
        this.searchInputControl.valueChanges.pipe(
            takeUntil(this._unsubscribeAll),
            map(query => BeautyciansUtils.searchStringByKeys(this._contacts, query, ['firstName', 'lastName'])),
            map(queryResult => BeautyciansUtils.sortByKeys(queryResult, ['firstName', 'lastName'])),
            tap(filteredContacts => this.filteredContacts = filteredContacts)
        ).subscribe(() => this._changeDetectorRef.markForCheck());

        this.dialog.afterOpened.pipe(
            takeUntil(this._unsubscribeAll),
            delay(0)
        ).subscribe(() => {
            if (this.selectedUser) {
                this.addNewUserForm.controls['firstName'].setValue(this.selectedUser.firstName);
                this.addNewUserForm.controls['lastName'].setValue(this.selectedUser.lastName);
                this.addNewUserForm.controls['address'].setValue(this.selectedUser.address);
                this.addNewUserForm.controls['sex'].setValue(this.selectedUser.sex);
                this.addNewUserForm.controls['birthdate'].setValue(this.selectedUser.birthdate ? moment(this.selectedUser.birthdate) : undefined);
            } else if (this.newUserForm) {
                this.newUserForm.resetForm();
            }
        });

        // Translations
        const clientTranslations = ['plural', 'singular', 'noClients', 'search', 'addNew', 'noSearchResults'];
        clientTranslations.forEach((translation) => {
            this.translateStrings['client'][translation] = this._translate.translate('clients.' + translation);
        });

        const userTranslations = ['firstName', 'lastName', 'address', 'sex', 'male', 'female', 'ND', 'birthdate', 'pictureUrl', 'roles'];
        userTranslations.forEach((translation) => {
            this.translateStrings['user'][translation] = this._translate.translate('users.' + translation);
        });

        this.translateStrings['generic']['send'] = this._translate.translate('generic.send');
        this.translateStrings['generic']['close'] = this._translate.translate('generic.close');
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();

        // Dispose the overlays if they are still on the DOM
        if (this._tagsPanelOverlayRef) {
            this._tagsPanelOverlayRef.dispose();
        }
    }

    userFormSubmit(): void {
        if (this.addNewUserForm.invalid) {
            return;
        }

        this.addNewUserForm.disable();

        const aggregate = {
            ...this.addNewUserForm.value,
            picture: false,
            pictureUrl: '',
            authDati: true,
            authPrivacy: true,
            authImgs: true,
            authSms: true,
            roles: {
                inOrganization: {
                    organizationId: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId
                },
                hasRole:
                    [
                        {
                            roleTemplateId: '6d3a71b1-2750-4bbe-868e-946fdaf09e77'
                        }
                    ]
            }
        };

        if (aggregate.birthdate) {
            aggregate.birthdate = +aggregate.birthdate.format('x');
        } else {
            delete aggregate.birthdate;
        }

        let body: any = {
            aggregate,
            assembleAs: {
                firstName: true,
                lastNam: true,
                personId: true
            }
        };

        const where = this.selectedUser ? {personId: this.selectedUser.personId} : undefined;

        body = where ? {...body, where} : body;

        const q = {
            contextName: 'Core',
            domainName: 'Person',
            commandName: this.selectedUser ? 'UPDATE' : 'CREATE',
            commandType: this.selectedUser ? 'UPDATE_AGGREGATE_COMMAND' : 'CREATE_AGGREGATE_COMMAND',
            body
        };
        this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: q
        }).pipe(
            mergeMap((res) => {
                if (!this.selectedUser) {
                    return this.relatePersonToStore(res.hits[0].personId);
                }
                return of(res);
            })
        ).subscribe({
            next: (res) => {
                this.newUserForm.resetForm();
                this.addNewUserForm.enable();
                this._changeDetectorRef.markForCheck();
                window.location.reload();
            },
            error: (err) => {
                this.addNewUserForm.enable();
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    getStoreClients(storeId: number): Observable<Contact[]> {
        // Get the contacts
        const q = {
            contextName: 'Core',
            domainName: 'Person',
            queryName: 'GET_BY_STORE_ID',
            where: {
                storeId: storeId
            },
            assembleAs: {
                firstName: true,
                lastName: true,
                personId: true,
                pictureUrl: true,
                address: true,
                sex: true,
                birthdate: true
            }
        };
        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: q
        }).pipe(
            map(res => res.hits)
        );
    }

    relatePersonToStore(personId): Observable<any> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'Store',
            commandName: 'RELATE_CLIENT_PERSON',
            body: {
                store: {
                    storeId: this.store.storeId,
                    personId: personId
                },
                assembleAs: {
                    active: true,
                    address: true,
                    zip: true,
                    name: true
                }
            }
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: query
        });
    }

    openModal(): void {
        this.dialog.open(this.userModal, {
            panelClass: ['md:w-160', 'w-full']
        });
    }
}
