import {Injectable} from '@angular/core';
import {cloneDeep} from 'lodash-es';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {GruulsConstants} from './gruuls-constants';
import {GruulsMockDomainsCatalog} from './gruuls-mock-domains-catalog';
import {GruulsCoreAppMockDomain} from './mocked-domains/gruuls/gruuls-core-app-mock-domain';
import {GruulsCorePageMockDomain} from './mocked-domains/gruuls/gruuls-core-page-mock-domain';
import {GruulsCorePersonMockDomain} from './mocked-domains/gruuls/gruuls-core-person-mock-domain';
import {GruulsBeautyStoreMockDomain} from './mocked-domains/beautycians/gruuls-beauty-store-mock-domain';
import {GruulsMockApiService} from '../../../@gruuls-fe/mock-api/gruuls-mock-api.service';
import {GruulsBeautyProductServiceMockDomain} from './mocked-domains/beautycians/gruuls-beauty-product-service-mock-domain';
import {GruulsBeautyOrderMockDomain as BeautyOrderMockDomain} from './mocked-domains/beautycians/gruuls-beauty-order-mock-domain';
import { MedicalHistoryMock } from './mocked-domains/beautycians/medical-history';
import { BeautyPathMock } from './mocked-domains/beautycians/beauty-path-mock-domain';
import { BeautyTreatmentCatalogMock } from './mocked-domains/beautycians/beauty-treatments-catalog-mock-domain';
import { BeautyAppointmentMock as BeautyAppointmentMock } from './mocked-domains/beautycians/beauty-appointment-mock-domain';
import { BeautyRoleTemplatesMock } from './mocked-domains/beautycians/beauty-role-mock-domain';
import { BeautyAdvisedPathsMock } from './mocked-domains/beautycians/beauty-advised-paths-mock-domain';
import { BeautySurveyClientMock } from './mocked-domains/beautycians/beauty-get-survey-client-mock-domain';
import { BeautyPriorityMock } from './mocked-domains/beautycians/beauty-priority-mock-domain';
import { BeautyHealthPriorityMock } from './mocked-domains/beautycians/health-priority-mock-domain';
import { BeautyInvoicesMock } from './mocked-domains/beautycians/beauty-invoices-mock-domain';

@Injectable({
    providedIn: 'root'
})
export class GruulsMockApi {

    /**
     * Constructor
     */
    constructor(
        private _gruulsMockApiService: GruulsMockApiService,
        private httpClient: HttpClient
        ) {
        // Register Mock API handlers
        GruulsMockDomainsCatalog.addDomain('Core.App', new GruulsCoreAppMockDomain(httpClient));
        GruulsMockDomainsCatalog.addDomain('Core.Page', new GruulsCorePageMockDomain(httpClient));
        // GruulsMockDomainsCatalog.addDomain('Core.Person', new GruulsCorePersonMockDomain(httpClient));
        // GruulsMockDomainsCatalog.addDomain('Beautycians.Store', new GruulsBeautyStoreMockDomain(httpClient));
        // GruulsMockDomainsCatalog.addDomain('Beautycians.ProductService', new GruulsBeautyProductServiceMockDomain(httpClient));
        // GruulsMockDomainsCatalog.addDomain('Beautycians.MedicalHistory', new MedicalHistoryMock(httpClient));
        GruulsMockDomainsCatalog.addDomain('Beautycians.BeautyPath', new BeautyPathMock(httpClient));
        GruulsMockDomainsCatalog.addDomain('Beautycians.BeautyPriority', new BeautyPriorityMock(httpClient));
        GruulsMockDomainsCatalog.addDomain('Beautycians.BeautyHealthPriority', new BeautyHealthPriorityMock(httpClient));
        GruulsMockDomainsCatalog.addDomain('Beautycians.RoleTemplates', new BeautyRoleTemplatesMock(httpClient));
        GruulsMockDomainsCatalog.addDomain('Beautycians.Invoices', new BeautyInvoicesMock(httpClient));
        // GruulsMockDomainsCatalog.addDomain('Beautycians.Order', new BeautyOrderMockDomain(httpClient));
        GruulsMockDomainsCatalog.addDomain('Beautycians.TreatmentCatalog', new BeautyTreatmentCatalogMock (httpClient));
        GruulsMockDomainsCatalog.addDomain('Beautycians.AdvisedPaths', new BeautyAdvisedPathsMock (httpClient));
        // GruulsMockDomainsCatalog.addDomain('Beautycians.GetSurveyClient', new BeautySurveyClientMock (httpClient));
        // GruulsMockDomainsCatalog.addDomain('Beautycians.Appointment', new BeautyAppointmentMock (httpClient));
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {

        this._gruulsMockApiService
            .onPost(GruulsConstants.QUERY_API_URL)
            .reply((data: { request: HttpRequest<any>; urlParams: { [key: string]: string } }) => {

                if (!data.request.body.contextName || !data.request.body.domainName) {
                    console.warn('Mock API called. Request contextName or domainName is missing error: ' + data.request.body);
                    return [
                        500,
                        {
                            message: 'Request contextName or domainName is missing',
                            data: cloneDeep(data.request.body)
                        }
                    ];
                }

                return GruulsMockDomainsCatalog
                    .getDomain(data.request.body.contextName + '.' + data.request.body.domainName, this.httpClient)
                    .executeQuery(data);
            });


        /*this._gruulsMockApiService
            .onPost(GruulsConstants.LOGIN_API_URL)
            .reply((data: { request: HttpRequest<any>; urlParams: { [key: string]: string } }) => {

                if (data.request.body.username === 'admin@admin' && data.request.body.password === 'admin'){
                    return [
                        200,
                        adminUser
                    ];
                }else{
                    return [
                        401,
                        {
                            message: 'unauthorized',
                            data: cloneDeep(data.request.body)
                        }
                    ];
                }

            });*/

    }
}
