import {GroupElementConfig, GroupElementConfigInterface} from '../../misc/group-element/group-element-config';
import {BaseElement} from '../../misc/base-element/base-element';
import {BaseElementConfig} from '../../misc/base-element/base-element-config';
import * as lodash_ from 'lodash';
const _ = lodash_;

export interface DynamicFlexContainerElementConfigInterface extends GroupElementConfigInterface{
    layout?: 'col' | 'row';
    dropSortEnabled?: boolean;
    dragEnabled?: boolean;
}

export class DynamicFlexContainerElementConfig extends GroupElementConfig implements DynamicFlexContainerElementConfigInterface{
    static category: string = 'UiBuilder';
    static type: string = 'DynamicFlexContainer';


    // view config
    //for container, managing children
    _layout?: 'col' | 'row';
    set layout(newValue: 'col' | 'row'){
        this.internalSetValue('layout', newValue);
    }
    get layout(): 'col' | 'row'{
        return this._layout;
    }

    _dropSortEnabled?: boolean;
    set dropSortEnabled(newValue: boolean){
        this.internalSetValue('dropSortEnabled', newValue);
    }
    get dropSortEnabled(): boolean{
        return this._dropSortEnabled;
    }

    _dragEnabled?: boolean;
    set dragEnabled(newValue: boolean){
        this.internalSetValue('dragEnabled', newValue);
    }
    get dragEnabled(): boolean{
        return this._dragEnabled;
    }


    constructor(config: DynamicFlexContainerElementConfigInterface, element: BaseElement) {
        super(config, element);

        this._layout = config.layout || 'row';
        this._dropSortEnabled = config.dropSortEnabled || false;
        this._dragEnabled = config.dragEnabled || false;


        // For configuration panel ------ BEGIN
        const radioConfigElements = [
            {
                name: 'FlexDirection',
                type: 'SelectElement',
                itemDisposition: 'top-middle',
                bindTo: 'context.data.clickedElement.config.layout',
                label: 'Flex Direction',
                values: ['col', 'row']
            },
            //flexWrap //TODO
        ];

        this._elementConfigurationPanel = _.cloneDeep(BaseElementConfig.elementConfigurationPanel);
        const BaseElementConfiguration = this._elementConfigurationPanel.find(el => el.name === 'BaseElementConfiguration');
        const BaseElementConfigTabs = BaseElementConfiguration.elements.find(el => el.name === 'BaseElementConfigTabs');
        const FlexboxAndGrid = BaseElementConfigTabs.elements.find(el => el.name === 'FlexboxAndGrid');
        FlexboxAndGrid.elements.splice(1, 0, ...radioConfigElements);
        // For configuration panel ------ END

        config.category = DynamicFlexContainerElementConfig.category;
        config.type = DynamicFlexContainerElementConfig.type;
        this.type = config.type;
        this.category = config.category;
    }

}
