<div class=" inset-0 flex flex-col min-w-0 overflow-hidden">
  <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent" (backdropClick)="onBackdropClicked()">

    <!-- Drawer -->
    <mat-drawer class="w-full md:w-160 dark:bg-gray-900" [mode]="drawerMode" [opened]="false" [position]="'end'"
      [disableClose]="true" #matDrawer>
      <router-outlet></router-outlet>
    </mat-drawer>

    <mat-drawer-content class="flex flex-col">

      <!-- Main -->
      <div class="flex-auto">
        <!-- Header -->
        <div class="flex flex-col sm:flex-row md:flex-col flex-auto justify-between py-8 px-6 md:px-8 border-b">

          <!-- Title -->
          <div>
            <div class="text-4xl font-extrabold tracking-tight leading-none">
              {{translateStrings.organization.plural}}</div>
            <div class="ml-0.5 font-medium text-secondary">
              <ng-container *ngIf="filteredElements?.length">
                {{filteredElements.length}}
              </ng-container>
              {{filteredElements?.length | i18nPlural: {
              '=0' : translateStrings.organization.no,
              '=1' : translateStrings.organization.singular,
              'other': translateStrings.organization.plural
              } }}
            </div>
            <!-- Loader -->
            <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
              <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
            </div>

          </div>

          <!-- Main actions -->
          <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
            <!-- Search -->
            <div class="flex-auto">
              <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input matInput [formControl]="searchInputControl" [autocomplete]="'off'"
                  [placeholder]="translateStrings.organization.search">
              </mat-form-field>
            </div>
            <!-- Add element button -->
            <button class="ml-4" mat-flat-button [color]="'primary'"
              (click)="this.selectedElement = undefined; openModal()">
              <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
              <span class="ml-2 mr-1">{{ translateStrings.organization.addNew }}</span>
            </button>
          </div>
        </div>

        <!-- list -->
        <div class="relative">
          <ng-container *ngIf="(isLoading || !isLoadingError); else loadingerror">
            <ng-container *ngIf="filteredElements?.length; else noElements">
              <ng-container *ngFor="let element of filteredElements; let i = index; trackBy: trackByFn">
                <!-- Group -->
                <ng-container *ngIf="i === 0 || element.name.charAt(0) !== filteredElements[i - 1]?.name.charAt(0)">
                  <div
                    class="z-10 sticky top-0 -mt-px px-6 py-1 md:px-8 border-t border-b font-medium uppercase text-white bg-accent bg-opacity-50 dark:bg-gray-900">
                    {{element.name.charAt(0)}}
                  </div>
                </ng-container>

                <!-- Element -->
                <a class="z-20 flex items-center px-6 py-4 md:px-8 cursor-pointer border-b justify-between hover:bg-accent hover:bg-opacity-10 dark:hover:bg-hover"
                  (click)="selectedElement = element; openModal()">
                  <!-- [routerLink]="['../detail', element.personId]"> -->
                  <div class="flex items-center">
                    <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                      <ng-container *ngIf="element.avatar">
                        <img class="object-cover w-full h-full" [src]="element.avatar" alt="Element avatar" />
                      </ng-container>
                      <ng-container *ngIf="!element.avatar">
                        <div
                          class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-accent bg-opacity-70 text-white dark:bg-gray-700 dark:text-gray-200">
                          {{element?.name?.charAt(0)}}
                        </div>
                      </ng-container>
                    </div>
                    <div class="min-w-0 ml-4 ">
                      <div class="font-medium leading-5 truncate">{{element.name}}</div>
                    </div>
                  </div>

                  <button (click)="$event.stopPropagation();  $event.preventDefault(); selectedElement = element;"
                    mat-icon-button [matMenuTriggerFor]="menu" aria-label="Toggle menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" (click)="$event.stopPropagation()">
                    <!-- <button mat-menu-item (click)="openModal()" [routerLink]="['../detail', element.personId]">
                                      <mat-icon>edit</mat-icon>
                                      <span>Edit</span>
                                  </button> -->
                    <button mat-menu-item (click)="openModal()">
                      <mat-icon>edit</mat-icon>
                      <span>{{translateStrings.generic.edit}}</span>
                    </button>
                    <button mat-menu-item (click)="deleteOrganization(element)" class="text-red-400">
                      <mat-icon class="text-red-400">delete</mat-icon>
                      <span>{{translateStrings.generic.delete}}</span>
                    </button> <!-- <button mat-menu-item (click)="sendWelcomeHandler()">
                      <mat-icon>email</mat-icon>
                      <span>Send Welcome</span>
                    </button> -->
                  </mat-menu>
                </a>
              </ng-container>

              <!-- Load more -->
              <div *ngIf="!reachedLimitSubject$.value" class="mx-auto my-12 items-center w-64">
                <button *ngIf="!isSpecificLoading['loadMore']" class="w-64" mat-flat-button color="accent"
                  (click)="loadMore()" [disabled]="isSpecificLoading['loadMore']">
                  <span *ngIf="!isSpecificLoading['loadMore']" class="ml-2 mr-1">{{
                    translateStrings.generic.loadMore }}</span>
                </button>
                <div *ngIf="isSpecificLoading['loadMore']" class="w-1 mx-auto">
                  <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <!-- No elements -->
          <ng-template #noElements>
            <div *ngIf="!isLoading" class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">
              {{translateStrings.organization.noSearchResults}}</div>
          </ng-template>

        </div>
      </div>

    </mat-drawer-content>
  </mat-drawer-container>
</div>


<!-- Main modal -->
<ng-template #organizationModal>
  <h3 mat-dialog-title>{{ selectedElement ? translateStrings.organization.edit :
    translateStrings.organization.addNew }}</h3>
  <mat-dialog-content>
    <form [formGroup]="organizationFormGroup" (ngSubmit)="organizationFormSubmit($event.submitter.id)"
      #organizationForm="ngForm">
      <div class="flex flex-col gt-xs:flex-row p-7">
        <input matInput formControlName="organizationId" hidden="true">

        <mat-form-field class="flex-auto gt-xs:pr-3">
          <mat-label>{{translateStrings.organization.name}}</mat-label>
          <input matInput formControlName="name">
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_outline:building-storefront'"></mat-icon>
        </mat-form-field>

        <mat-form-field class="flex-auto gt-xs:pr-3">
          <mat-label>{{translateStrings.organization.vatNumber}}</mat-label>
          <input matInput formControlName="vatNumber">
          <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_outline:building-storefront'"></mat-icon>
          <div
            *ngIf="organizationFormGroup.controls['vatNumber'].invalid && (organizationFormGroup.controls['vatNumber'].dirty || organizationFormGroup.controls['vatNumber'].touched)"
            class="text-red-600">{{translateStrings['generic']['validatorVatNumber']}}
          </div>
        </mat-form-field>

        <mat-form-field class="flex-auto gt-xs:pl-3">
          <mat-label>{{translateStrings.organization.referent}}</mat-label>
          <mat-select formControlName="referent">
            <mat-option>---</mat-option>
            <ng-container *ngFor="let referent of referents">
              <mat-option [value]="referent">{{referent.firstName}} {{referent.lastName}}</mat-option>
            </ng-container>
          </mat-select>
          <!-- <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon> -->
        </mat-form-field>

        <mat-form-field class="flex-auto gt-xs:pl-3">
          <mat-label>{{translateStrings.organization.licence}}</mat-label>
          <mat-select formControlName="licence">
            <mat-option>---</mat-option>
            <mat-option value="1">{{translateStrings.licence.LEVEL_1}}</mat-option>
            <mat-option value="2">{{translateStrings.licence.LEVEL_2}}</mat-option>
            <mat-option value="3">{{translateStrings.licence.LEVEL_3}}</mat-option>
            <mat-option value="4">{{translateStrings.licence.LEVEL_4}}</mat-option>
          </mat-select>
        </mat-form-field>


      </div>

      <!-- Modal footer -->
      <div
        class="flex justify-between items-center py-6 mx-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        <div>
          <button mat-button mat-dialog-close type="button"
            class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{{translateStrings.generic.close}}</button>
        </div>

        <div class="flex space-x-2">
          <button id="save" type="submit" mat-flat-button [color]="'primary'" class="rounded-lg"
            [disabled]="!organizationFormGroup.valid">
            <span *ngIf="!isSpecificLoading['save']">
              {{translateStrings.generic.save}}
            </span>
            <mat-progress-spinner *ngIf="isSpecificLoading['save']" [diameter]="24"
              [mode]="'indeterminate'"></mat-progress-spinner>
          </button>
        </div>
      </div>

    </form>
  </mat-dialog-content>
</ng-template>

<ng-template #loadingerror>
  <img src="/assets/images/ui/beautycians/error_503.jpg" alt="Error" class="w-2/6 h-1/6 mx-auto">
  <div class="text-2xl font-semibold tracking-tight text-center w-4/6 sm:w-full mx-auto">
    {{translateStrings.generic.error503}}</div>
</ng-template>