<div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 w-screen h-full">
    <div class="md:flex md:items-center md:justify-end w-full md:h-full py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Logo -->
            <div class="w-12">
                <img src="assets/images/logo/logo.svg">
            </div>

            <!-- Title -->
            <div class="mt-8 text-3xl font-extrabold tracking-tight leading-tight">{{translateStrings.generic.forgotPassword}}</div>
            <div class="mt-0.5 font-medium">{{translateStrings.generic.fillTheFormToResetPassword}}</div>
            
            <!-- Alert -->
            <fuse-alert
                class="mt-8 -mb-4"
                *ngIf="showAlert"
                [appearance]="'outline'"
                [showIcon]="false"
                [type]="alert.type"
                [@shake]="alert.type === 'error'">
                {{alert.message}}
            </fuse-alert>

            <ng-container *ngIf="!(showAlert && alert.type === 'success')">
            
                <!-- Forgot password form -->
                <form
                    class="mt-8"
                    [formGroup]="forgotPasswordForm"
                    #forgotPasswordNgForm="ngForm">

                    <!-- Email field -->
                    <mat-form-field class="w-full">
                        <mat-label>{{translateStrings.generic.emailAddress}}</mat-label>
                        <input
                            id="email"
                            matInput
                            [formControlName]="'email'">
                        <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                            {{translateStrings.generic.emailRequired}}
                        </mat-error>
                        <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                            {{translateStrings.generic.emailInvalid}}
                        </mat-error>
                    </mat-form-field>

                    <!-- Submit button -->
                    <button
                        class="fuse-mat-button-large w-full mt-3"
                        mat-flat-button
                        [color]="'primary'"
                        [disabled]="forgotPasswordForm.disabled"
                        (click)="sendResetLink()">
                        <span *ngIf="!forgotPasswordForm.disabled">
                            {{translateStrings.generic.sendResetLink}}
                        </span>
                        <mat-progress-spinner
                            *ngIf="forgotPasswordForm.disabled"
                            [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>
                </form>
            </ng-container>
            <!-- Form footer -->
            <div class="mt-8 text-md font-medium text-secondary">
                <span>{{translateStrings.generic.returnTo}}</span>
                <a
                    class="ml-1 text-primary-500 hover:underline"
                    [routerLink]="['/login']">{{translateStrings.generic.loginPage}}
                </a>
            </div>

        </div>
    </div>

    <div class="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-44 overflow-hidden bg-primary-400 dark:border-l w-full" style="background: url('assets/images/ui/beautycians/shell.jpeg') no-repeat; background-size: cover">
        <!-- Background - @formatter:off -->
        <!-- Rings -->
        <svg class="absolute inset-0 pointer-events-none"
             viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
            <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                <circle r="234" cx="196" cy="23"></circle>
                <circle r="234" cx="790" cy="491"></circle>
            </g>
        </svg>
        <!-- Dots -->
        <svg class="absolute -top-16 -right-16 text-gray-700"
             viewBox="0 0 220 192" width="220" height="192" fill="none">
            <defs>
                <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
                </pattern>
            </defs>
            <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
        </svg>
        <!-- @formatter:on -->
        <!-- Content -->
        <div class="z-10 relative w-full pr-12<">
            <div class="text-7xl font-bold leading-none text-gray-100">
                <div>Welcome to</div>
                <div>BEAUTYCIANS</div>
            </div>
        </div>
    </div>
</div>
