import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Compiler,
    Component, ComponentFactoryResolver, Directive, ElementRef, Host, HostBinding,
    Input,
    OnDestroy,
    OnInit, ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {BehaviorSubject, defer, merge, Observable, of, Subject} from 'rxjs';
import {map, mergeMap, mergeWith, takeUntil, tap} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import {ElementsFactory} from '@gruuls-core/elements/elements-factory';
import {GruulsConstants} from '../../app/mock-api/gruuls/gruuls-constants';
import {HttpClient} from '@angular/common/http';
import {FuseConfigService} from '@fuse/services/config';
import {GruulsAngularTranslateService} from '../services/gruuls-angular-translate.service';
import {GruulsAngularNumberFormatterService} from '../services/gruuls-angular-number-formatter.service';
import {GruulsAngularRoutingService} from '../services/gruuls-angular-routing.service';
import {GruulsAngularHttpProxyService} from '../services/gruuls-angular-http-proxy.service';
import {GruulsElementFactoryComponent} from './gruuls-element-factory.component';
import { GruulsAuthServiceInterface } from '@gruuls-core/service-interfaces/gruuls-auth-service-interface';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';

@Component({
    selector: '[gruuls-element-page-factory]',
    styleUrls: ['./gruuls-element-factory.component.scss'],
    template: '',
    //template: '<ng-container #elementToBind></ng-container>',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GruulsElementPageFactoryComponent extends GruulsElementFactoryComponent implements OnInit {


    constructor(
        protected el: ElementRef,
        protected viewContainerRef: ViewContainerRef,
        protected compiler: Compiler,
        protected fuseConfigService: FuseConfigService,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected cdr: ChangeDetectorRef,
        protected activatedRoute: ActivatedRoute,
        protected httpClient: HttpClient,
        protected translateService: GruulsAngularTranslateService,
        protected numberFormatterService: GruulsAngularNumberFormatterService,
        protected routingService: GruulsAngularRoutingService,
        protected httpService: GruulsAngularHttpProxyService,
        protected authService: GruulsAuthService
    ) {
        super(el,viewContainerRef, compiler, fuseConfigService, componentFactoryResolver, cdr,
            activatedRoute, httpClient, translateService, numberFormatterService, routingService,
            httpService );
    }


    ngOnInit(): void {

        // Call to the BE to get the content of the specific page
        const q = {
            contextName: 'Core',
            domainName: 'Page',
            queryName: 'FIND_ALL',
            where: {
                // Qui vene preso l'id della specifica pagina tramite cui fare chiamata a DB e ricevere il JSON della pagina
                id: this.activatedRoute.snapshot.data.pageId
            }
        };
        this.httpClient.post<any>(GruulsConstants.QUERY_API_URL, q)
            .pipe(
                map(res => res.hits[0]),
                // Creating the new item from the configuration in the received response and passing the app services.
                // Viene passato a Elements factory (classe che crea tutti gli elementi) il JSON riferito all'intera pagina (che a sua volta è elemento) e i servizi della web app
                mergeMap(res => new ElementsFactory().create(res, {
                    translateService: this.translateService,
                    numberFormatterService: this.numberFormatterService,
                    routingService: this.routingService,
                    httpService: this.httpService,
                    authService: this.authService
                })),
                mergeMap(res => res.init(this.fuseConfigService.config.appContext)),
                tap( res => this.fuseConfigService.config.appContext = this.fuseConfigService.config.appContext ? this.fuseConfigService.config.appContext : res.getContext()),
                map((el) => {
                    el.processStream().subscribe();
                    return el;
                }),
                tap( res => this.element = res),
                tap( (res) => {
                    if (this.elementSubject){
                        this.elementSubject.next(res);
                    }else {
                        this.elementSubject = new BehaviorSubject(res);
                    }
                }),
                mergeMap(el => merge(el.valueChanged(), el.configChanged())),
                //mergeWith(this.element.configChanged()),
                takeUntil(this._unsubscribeAll),
                tap(v => this.cdr.markForCheck())
            )
            .subscribe();
    }



}
