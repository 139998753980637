import { ServiceDispatcher } from '@gruuls-core/services/service-dispatcher';
import { SyncSettingsStorage } from '@gruuls-core/services/sync-settings-storage';
import {BehaviorSubject, Observable} from 'rxjs';
import {MyOrganization} from '../organization/my-organization';

export class UserSettings {

  private static _FAVOURITE_ORG_ID = 'favouriteOrganizationId';

  private selectedOrganizationSubject: BehaviorSubject<MyOrganization|undefined> = new BehaviorSubject<MyOrganization|undefined>(undefined);
  private selectedOrganization: MyOrganization|undefined;
  private savedSettings: SyncSettingsStorage|undefined;
  myOrganizations: MyOrganization[]|undefined;
  settings: any;

  constructor(
    userSettings: any,
    defaultOrgId: string,
  ){
    this.init(userSettings,defaultOrgId);
  }

  public init(userSettings: any,defaultOrgId: string): UserSettings{

    this.myOrganizations = userSettings.myOrganizations.map((el: any) => MyOrganization.of(el));
    this.settings = userSettings.settings;

    this.savedSettings = ServiceDispatcher.getService('SyncSettingsStorage');

    if(this.myOrganizations && this.myOrganizations.length > 0){

      let favouriteOrg;
      if (this.settings && this.settings.favouriteOrganizationId){
        favouriteOrg = this.myOrganizations.find(el => el.organizationId === this.settings.favouriteOrganizationId);
      }
      // else {
      //   if (this.savedSettings) {
      //     let favouriteOrgId: string = this.savedSettings.getObject(UserSettings._FAVOURITE_ORG_ID);
      //     favouriteOrg = this.myOrganizations.find((el) => el.organizationId === favouriteOrgId);
      //   }
      // }
      if (!favouriteOrg && defaultOrgId) {
        favouriteOrg = this.myOrganizations.find(el => el.organizationId === defaultOrgId);
      }
      if (!favouriteOrg) {
        favouriteOrg = this.myOrganizations[0];
      }
      this.switchSelectedOrganization(favouriteOrg);
    }
    return this;
  }

  public static of(userSettings: any,defaultOrgId: string): UserSettings{
    return new UserSettings(userSettings,defaultOrgId);
  }


  public getMyOrganizations(): MyOrganization[]|undefined{
    return this.myOrganizations;
  }

  public getSelectedOrganization(): MyOrganization|undefined{
    return this.selectedOrganization;
  }

  public getFavouriteOrganization(): MyOrganization|undefined{
    if (this.myOrganizations && this.settings.favouriteOrganizationId){
      const favouriteOrg = this.myOrganizations.find(el => el.organizationId === this.settings.favouriteOrganizationId);
      return favouriteOrg ? favouriteOrg : undefined;
    }
    return undefined;
  }

  public selectOrganization(org: MyOrganization): void{
    if (this.myOrganizations) {
      const favouriteOrg = this.myOrganizations.find(el => el.organizationId === org.organizationId);
      if (favouriteOrg) {
        this.switchSelectedOrganization(favouriteOrg);
      }
    }
  }

  public selectOrganizationById(organizationId: string): void{
    if (this.myOrganizations) {
      const favouriteOrg = this.myOrganizations.find(el => el.organizationId === organizationId);
      if (favouriteOrg) {
        this.switchSelectedOrganization(favouriteOrg);
      }
    }
  }

  private switchSelectedOrganization(newOrg: MyOrganization): void {
    if (this.selectedOrganization) {
      this.selectedOrganization.unselect();
    }
    newOrg.select();
    if (this.savedSettings) {
      this.savedSettings.store(UserSettings._FAVOURITE_ORG_ID, newOrg.organizationId);
    }
    this.selectedOrganization = newOrg;
    if (!this.selectedOrganizationSubject) {
      this.selectedOrganizationSubject = new BehaviorSubject<MyOrganization|undefined>(this.selectedOrganization);
    } else {
      this.selectedOrganizationSubject.next(this.selectedOrganization);
    }
  }

  public observeSelectedOrganization(): Observable<MyOrganization|undefined>{
    return this.selectedOrganizationSubject.asObservable();
  }




}
