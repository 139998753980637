import { duration } from "moment";
import { CalendarEvent } from "../modules/myCalendar/mycalendar.types";
import { Appointment } from "../modules/clients/client.types";
import { Store } from "./dataTypes";

export class BeautyciansUtils {

    public static getNestedValue(obj: any, keyStructure: string[]): string {
        if (!obj || !keyStructure || keyStructure.length == 0)
            return '';
        if (keyStructure && keyStructure.length == 1)
            return obj.hasOwnProperty(keyStructure[0]) ? obj[keyStructure[0]] : '';
        else if(keyStructure[0] === '*') {
            return Object.keys(obj).reduce((acc, key) => {
                const nestedValue = this.getNestedValue(obj[key], keyStructure.slice(1));
                if (nestedValue) {
                    acc.push(nestedValue);
                }
                return acc;
            }, []).join(' ');
        } else
            return this.getNestedValue(obj[keyStructure[0]], keyStructure.slice(1));
    }

    // searches for elements of an array that contains the desired string and key
    public static searchStringByKeys(arr: any[], text: string, keys: any[]): any[] {
        if (!arr || arr.length == 0)
            return arr;
        return arr.filter((obj) => {
            let fullTextKey = '';
            for (const key of keys) {
                if (typeof (key) === 'object') {
                    const nestedValue = this.getNestedValue(obj, key);
                    if (nestedValue) {
                        fullTextKey += ` ${nestedValue}`;
                    }
                } else {
                    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined)
                        fullTextKey += ` ${obj[key]}`;
                }
                // TODO: check if this optimize
                // if (fullTextKey.toLowerCase()?.includes(text.toLowerCase()))
                //     return true;
            }
            return fullTextKey.toLowerCase()?.includes(text.toLowerCase());
        });
    }

    // sort array by key
    public static sortByKeys(arr: any[], keys: any[]): any[] {
        if (!arr || arr.length == 0)
            return arr;
        return arr.sort((a, b) => {
            let currentTextKey = '';
            let pastTextKey = '';
            for (const key of keys) {
                if (typeof (key) === 'object') {
                    currentTextKey += ` ${this.getNestedValue(a, key)}`;
                    pastTextKey += ` ${this.getNestedValue(b, key)}`;
                } else {
                    currentTextKey += ` ${a[key]}`;
                    pastTextKey += ` ${b[key]}`;
                }
            }

            return currentTextKey.localeCompare(pastTextKey);
        });
    }

    public static appointmentToEvent(appointment: Appointment): CalendarEvent {
        const defaultDurationInMin = 30;
        return {
            id: appointment.appointmentId,
            calendarId: appointment.treatmentId,
            recurringEventId: null,
            isFirstInstance: null,
            title: appointment.name,
            description: appointment.description,
            start: new Date(appointment.date),
            end: new Date(appointment.date + (appointment.durationMin ?? defaultDurationInMin) * 60 * 1000),
            durationInMin: appointment.durationMin ?? defaultDurationInMin,
            allDay: false,
            recurrence: '',
            extendedProps: {
                clientId: appointment.client?.personId,
                treatmentId: appointment.treatmentId,
                storeId: appointment.storeId?.storeId,
                // employeeId: appointment.employee.emptoyeeId,
            },
            // classNames: (appointment.client.personId === activePersonId) ? ['bg-accent'] : []
        }
    }

    public static eventToAppointment(event: CalendarEvent): Appointment {
        let appointment: Appointment = {};

        if (event.id) appointment['appointmentId'] = event.id;
        if (event.title) appointment['name'] = event.title;
        if (event.description) appointment['description'] = event.description;
        if (event.calendarId) appointment['treatmentId'] = event.calendarId;
        if (event.start) appointment['date'] = event.start.getTime();
        if (event.durationInMin) appointment['durationMin'] = event.durationInMin;
        // durationMin: (event.end && event.start) ? Math.round((event.end.getTime() - event.start.getTime()) / (1000*60)) : 30,

        if (event.extendedProps && event.extendedProps.clientId) {
            appointment['client'] = {
                personId: event.extendedProps.clientId,
            }
        }
        return appointment;
    }

    /**
     * 
     * @param message 
     * @param title 
     * @param OKlabel 
     * @returns 
     */
    public static getErrorDialogConfig(message: string, title: string = 'Error', color: string = 'Warn', OKlabel: string = 'OK'): any {
        return {
            "title": title,
            "message": message,
            "icon": {
                "show": true,
                "name": "warning",
                "color": color
            },
            "actions": {
                "confirm": {
                    "show": true,
                    "label": OKlabel,
                    "color": "primary"
                },
                "cancel": {
                    "show": false,
                    "label": "Cancel"
                }
            },
            "dismissible": true
        }
    }

    public static legalAddressFromClientInfo(mexalClientInfo: any): Store {
        let clientName: string;
        if (mexalClientInfo.ragione_sociale) {
            clientName = mexalClientInfo.ragione_sociale;
        } else {
            clientName = mexalClientInfo.nome + (mexalClientInfo.nome ? " " : "") + mexalClientInfo.cognome;
        }
        if (clientName && mexalClientInfo.indirizzo && mexalClientInfo.localita && mexalClientInfo.cap && mexalClientInfo.provincia) {
            const legalAddress = {
                name: clientName,
                address: mexalClientInfo.indirizzo,
                city: mexalClientInfo.localita,
                zip: mexalClientInfo.cap,
                province: mexalClientInfo.provincia
            }
            return legalAddress;
        } else {
            return null;
        }
    }

    public static formatDate(d: Date): string {
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-') + " " + d.toLocaleTimeString();
    }

}
