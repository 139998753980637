
<div
    class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 w-screen h-full">
    <div
        class="md:flex md:items-center md:justify-end w-full md:h-full py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Logo -->
            <div class="w-20">
                <img src="{{logoImageSrc}}">
            </div>

            <!-- Title -->
            <!-- <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Sign in</div>
            <div class="flex items-baseline mt-0.5 font-medium">
                <div>Don't have an account?</div>
                <a
                    class="ml-1 text-primary-500 hover:underline"
                    [routerLink]="['/sign-up']">Sign up
                </a>
            </div>-->

            <!-- Alert -->
            <fuse-alert class="mt-8 -mb-4" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false"
                [type]="alert.type" [@shake]="alert.type === 'error'">
                {{alert.message}}
            </fuse-alert>

            <!-- Sign in form -->
            <form class="mt-8" [formGroup]="signInForm" #signInNgForm="ngForm">

                <!-- Email field -->
                <mat-form-field class="w-full">
                    <mat-label>{{translateStrings.generic.emailAddress}}</mat-label>
                    <input id="email" matInput [formControlName]="'email'">
                    <mat-error *ngIf="signInForm.get('email').hasError('required')">
                        {{translateStrings.generic.emailRequired}}
                    </mat-error>
                    <mat-error *ngIf="signInForm.get('email').hasError('email')">
                        {{translateStrings.generic.emailInvalid}}
                    </mat-error>
                </mat-form-field>

                <!-- Password field -->
                <mat-form-field class="w-full">
                    <mat-label>Password</mat-label>
                    <input id="password" matInput type="password" autocomplete="on" [formControlName]="'password'"
                        #passwordField>
                    <button mat-icon-button type="button"
                        (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                        matSuffix>
                        <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                            [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                        <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                            [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                    </button>
                    <mat-error>
                        {{translateStrings.generic.passwordRequired}}
                    </mat-error>
                </mat-form-field>

                <!-- Actions -->
                <div class="inline-flex items-end justify-between w-full mt-1.5">
                    <mat-checkbox [color]="'primary'" [formControlName]="'rememberMe'">
                        {{translateStrings.generic.rememberMe}}
                    </mat-checkbox>
                    <a class="text-md font-medium text-primary-500 hover:underline"
                        [routerLink]="['/forgot-password']">{{translateStrings.generic.forgotPassword}}
                    </a>
                </div>

                <!-- Submit button -->
                <button class="fuse-mat-button-large w-full mt-6" mat-flat-button [color]="'primary'"
                    [disabled]="signInForm.disabled" (click)="signIn()">
                    <span *ngIf="!signInForm.disabled">
                        {{translateStrings.generic.signIn}}
                    </span>
                    <mat-progress-spinner *ngIf="signInForm.disabled" [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
                </button>

                <!-- Separator -->
                <!--<div class="flex items-center mt-8">
                    <div class="flex-auto mt-px border-t"></div>
                    <div class="mx-2 text-secondary">Or continue with</div>
                    <div class="flex-auto mt-px border-t"></div>
                </div>-->

                <!-- Single sign-on buttons -->
                <!--<div class="flex items-center mt-8 space-x-4">
                    <button
                        class="flex-auto"
                        type="button"
                        mat-stroked-button>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'feather:facebook'"></mat-icon>
                    </button>
                    <button
                        class="flex-auto"
                        type="button"
                        mat-stroked-button>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'feather:twitter'"></mat-icon>
                    </button>
                    <button
                        class="flex-auto"
                        type="button"
                        mat-stroked-button>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'feather:github'"></mat-icon>
                    </button>
                </div>-->
            </form>
        </div>
    </div>
    <div class="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-44 overflow-hidden bg-primary-400 dark:border-l w-full"
        style="background: url('assets/images/ui/beautycians/shell.jpeg') no-repeat; background-size: cover">
        <!-- Background - @formatter:off -->
        <!-- Rings -->
        <svg class="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%"
            preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
            <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                <circle r="234" cx="196" cy="23"></circle>
                <circle r="234" cx="790" cy="491"></circle>
            </g>
        </svg>
        <!-- Dots -->
        <svg class="absolute -top-16 -right-16 text-gray-700" viewBox="0 0 220 192" width="220" height="192"
            fill="none">
            <defs>
                <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                    patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
                </pattern>
            </defs>
            <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
        </svg>
        <!-- @formatter:on -->
        <!-- Content -->
        <div class="z-10 relative w-full pr-12<">
            <div class="text-7xl font-bold leading-none text-gray-100">
                <div>Welcome to</div>
                <div>{{title | uppercase}}</div>
            </div>
        </div>
    </div>
</div>