import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Compiler,
    Component, ComponentFactoryResolver, Directive, ElementRef, Host, HostBinding,
    Input,
    OnDestroy,
    OnInit, ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {BaseElement} from '@gruuls-core/elements/misc/base-element/base-element';
import {GruulsBaseElementComponent} from '../elements/misc/base-element/gruuls-base-element.component';
import {BehaviorSubject, defer, merge, Observable, of, Subject} from 'rxjs';
import {map, mergeMap, mergeWith, takeUntil, tap} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import {ElementsFactory} from '@gruuls-core/elements/elements-factory';
import {GruulsConstants} from '../../app/mock-api/gruuls/gruuls-constants';
import {HttpClient} from '@angular/common/http';
import {FuseConfigService} from '@fuse/services/config';
import {GruulsAngularTranslateService} from '../services/gruuls-angular-translate.service';
import {GruulsAngularNumberFormatterService} from '../services/gruuls-angular-number-formatter.service';
import {GruulsAngularRoutingService} from '../services/gruuls-angular-routing.service';
import {GruulsAngularHttpProxyService} from '../services/gruuls-angular-http-proxy.service';

@Component({
    selector: '[gruuls-element-factory]',
    styleUrls: ['./gruuls-element-factory.component.scss'],
    template: '',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GruulsElementFactoryComponent implements OnInit, OnDestroy, AfterViewInit {

    //@ViewChild('elementToBind', {read: ViewContainerRef}) public elementToBind: ViewContainerRef;

    @Input()
    protected element: BaseElement;
    @Input()
    protected allConnectedIds: string[];

    protected elementSubject: Subject<BaseElement>;
    protected _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        protected el: ElementRef,
        protected viewContainerRef: ViewContainerRef,
        protected compiler: Compiler,
        protected fuseConfigService: FuseConfigService,
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected cdr: ChangeDetectorRef,
        protected activatedRoute: ActivatedRoute,
        protected httpClient: HttpClient,
        protected translateService: GruulsAngularTranslateService,
        protected numberFormatterService: GruulsAngularNumberFormatterService,
        protected routingService: GruulsAngularRoutingService,
        protected httpService: GruulsAngularHttpProxyService
    ) {
    }

    // @HostBinding('class') get classList(): any {
    //     if (this.element) {
    //         const properties: any = {};
    //         GruulsBaseElementComponent.propertiesList.map((p) => {
    //             const containerPropName = 'container' + p.charAt(0).toUpperCase() + p.slice(1);
    //             if (this.element.config[containerPropName] !== undefined || this.element.config[p] !== undefined) {
    //                 properties[p] = this.element.config[containerPropName] !== undefined ? this.element.config[containerPropName] : this.element.config[p];
    //             }
    //         });
    //
    //         const classes = {
    //             'flex': !properties.disableFlex,
    //             'flex-auto': (properties.itemFillSpace === 'auto' || properties.itemFillSpace === undefined) && !properties.disableFlex,
    //             [`flex-${properties.itemFillSpace}`]: properties.itemFillSpace && !properties.disableFlex,
    //             [`flex-${properties.flexWrap}`]: properties.flexWrap && !properties.disableFlex,
    //             [`basis-${properties.flexBasis}`]: properties.flexBasis && !properties.disableFlex,
    //             [`order-${properties.flexOrder}`]: properties.flexOrder && !properties.disableFlex,
    //
    //             // justify --> horizontal alignment of all children elements
    //             'justify-center': properties.itemsDisposition === 'middle-middle' || properties.itemsDisposition === 'top-middle' || properties.itemsDisposition === 'bottom-middle',
    //             'justify-start': properties.itemsDisposition === 'top-left' || properties.itemsDisposition === 'middle-left' || properties.itemsDisposition === 'bottom-left',
    //             'justify-end': properties.itemsDisposition === 'top-right' || properties.itemsDisposition === 'middle-right' || properties.itemsDisposition === 'bottom-right',
    //             // items --> vertical alignment of all children elements
    //             'items-center': properties.itemsDisposition === 'middle-middle' || properties.itemsDisposition === 'middle-left' || properties.itemsDisposition === 'middle-right',
    //             'items-start': properties.itemsDisposition === 'top-left' || properties.itemsDisposition === 'top-middle' || properties.itemsDisposition === 'top-right',
    //             'items-end': properties.itemsDisposition === 'bottom-left' || properties.itemsDisposition === 'bottom-middle' || properties.itemsDisposition === 'bottom-right',
    //
    //             // justify --> horizontal alignment of this elements
    //             'justify-self-center': properties.itemDisposition === 'middle-middle' || properties.itemDisposition === 'top-middle' || properties.itemDisposition === 'bottom-middle' || properties.itemDisposition === 'stretch-middle' || properties.itemDisposition === 'auto-middle' || properties.itemDisposition === 'baseline-middle',
    //             'justify-self-start': properties.itemDisposition === 'top-left' || properties.itemDisposition === 'middle-left' || properties.itemDisposition === 'bottom-left' || properties.itemDisposition === 'stretch-left' || properties.itemDisposition === 'auto-left' || properties.itemDisposition === 'baseline-left',
    //             'justify-self-end': properties.itemDisposition === 'top-right' || properties.itemDisposition === 'middle-right' || properties.itemDisposition === 'bottom-right' || properties.itemDisposition === 'stretch-right' || properties.itemDisposition === 'auto-right' || properties.itemDisposition === 'baseline-right',
    //             'justify-self-stretch': properties.itemDisposition === 'top-stretch' || properties.itemDisposition === 'middle-stretch' || properties.itemDisposition === 'bottom-stretch' || properties.itemDisposition === 'stretch-stretch' || properties.itemDisposition === 'auto-stretch' || properties.itemDisposition === 'baseline-stretch',
    //             'justify-self-auto': properties.itemDisposition === 'top-auto' || properties.itemDisposition === 'middle-auto' || properties.itemDisposition === 'bottom-auto' || properties.itemDisposition === 'stretch-auto' || properties.itemDisposition === 'auto-auto' || properties.itemDisposition === 'baseline-auto',
    //             // items --> vertical alignment of this element
    //             'self-center': properties.itemDisposition === 'middle-middle' || properties.itemDisposition === 'middle-left' || properties.itemDisposition === 'middle-right' || properties.itemDisposition === 'middle-stretch' || properties.itemDisposition === 'middle-auto',
    //             'self-start': properties.itemDisposition === 'top-left' || properties.itemDisposition === 'top-middle' || properties.itemDisposition === 'top-right' || properties.itemDisposition === 'top-stretch' || properties.itemDisposition === 'top-auto',
    //             'self-end': properties.itemDisposition === 'bottom-left' || properties.itemDisposition === 'bottom-middle' || properties.itemDisposition === 'bottom-right' || properties.itemDisposition === 'bottom-stretch' || properties.itemDisposition === 'bottom-auto',
    //             'self-stretch': properties.itemDisposition === 'stretch-left' || properties.itemDisposition === 'stretch-middle' || properties.itemDisposition === 'stretch-right' || properties.itemDisposition === 'stretch-stretch' || properties.itemDisposition === 'stretch-auto',
    //             'self-auto': properties.itemDisposition === 'auto-left' || properties.itemDisposition === 'auto-middle' || properties.itemDisposition === 'auto-right' || properties.itemDisposition === 'auto-stretch' || properties.itemDisposition === 'auto-auto',
    //             'self-baseline': properties.itemDisposition === 'baseline-left' || properties.itemDisposition === 'baseline-middle' || properties.itemDisposition === 'baseline-right' || properties.itemDisposition === 'baseline-stretch' || properties.itemDisposition === 'baseline-auto',
    //             'edit-mode': this.element.getContext().config.editMode,
    //             'box-content': true,
    //             [`place-content-${properties.placeContent}`]: properties.placeContent,
    //             [`h-${properties.height}`]: properties.height,
    //             [`w-${properties.width}`]: properties.width,
    //             [`mt-${properties.marginTop}`]: properties.marginTop,
    //             [`mb-${properties.marginBottom}`]: properties.marginBottom,
    //             [`ml-${properties.marginLeft}`]: properties.marginLeft,
    //             [`mr-${properties.marginRight}`]: properties.marginRight,
    //             [`pt-${properties.paddingTop}`]: properties.paddingTop,
    //             [`pb-${properties.paddingBottom}`]: properties.paddingBottom,
    //             [`pl-${properties.paddingLeft}`]: properties.paddingLeft,
    //             [`pr-${properties.paddingRight}`]: properties.paddingRight,
    //             [`text-${properties.fontSize}`]: properties.fontSize,
    //             [`font-${properties.fontWeight}`]: properties.fontWeight,
    //             [`font-${properties.fontFamily}`]: properties.fontFamily,
    //             [`min-h-${properties.minHeight}`]: properties.minHeight,
    //             [`max-h-${properties.maxHeight}`]: properties.maxHeight,
    //             [`min-w-${properties.minWidth}`]: properties.minWidth,
    //             [`max-w-${properties.maxWidth}`]: properties.maxWidth,
    //
    //             [`tracking-${properties.letterSpacing}`]: properties.letterSpacing,
    //             [`leading-${properties.lineHeight}`]: properties.lineHeight,
    //             [`${properties.textDecoration}`]: properties.textDecoration,
    //             [`${properties.textTransform}`]: properties.textTransform,
    //             [`${properties.textOverflow}`]: properties.textOverflow,
    //             [`indent-${properties.textIndent}`]: properties.textIndent,
    //             [`whitespace-${properties.whitespace}`]: properties.whitespace,
    //             [`break-${properties.wordBreak}`]: properties.wordBreak,
    //             [`overflow-${properties.overflow}`]: properties.overflow,
    //             [`bg-${properties.backgroundAttachment}`]: properties.backgroundAttachment,
    //             [`bg-clip-${properties.backgroundClip}`]: properties.backgroundClip,
    //             [`bg-origin-${properties.backgroundOrigin}`]: properties.backgroundOrigin,
    //             [`bg-${properties.backgroundPosition}`]: properties.backgroundPosition,
    //             [`bg-repeat-${properties.backgroundRepeat}`]: properties.backgroundRepeat && properties.backgroundRepeat !== 'no-repeat',
    //             ['bg-no-repeat']: properties.backgroundRepeat === 'no-repeat',
    //             [`bg-${properties.backgroundSize}`]: properties.backgroundSize,
    //             [`ring-${properties.ringWidth}`]: properties.ringWidth,
    //             [`ring-${properties.ringColor}`]: properties.ringColor,
    //             [`ring-offset-${properties.ringOffsetWidth}`]: properties.ringOffsetWidth,
    //             [`ring-offset-${properties.ringOffsetColor}`]: properties.ringOffsetColor,
    //             [`shadow-${properties.boxShadow}`]: properties.boxShadow,
    //             [`shadow-${properties.boxShadowColor}`]: properties.boxShadowColor,
    //             [`opacity-${properties.opacity}`]: properties.opacity,
    //             [`drop-shadow-${properties.dropShadow}`]: properties.dropShadow,
    //             ['invisible']: properties.hidden,
    //             [`rounded-tl-${properties.borderRadiusTopLeft}`]: properties.borderRadiusTopLeft,
    //             [`rounded-tr-${properties.borderRadiusTopRight}`]: properties.borderRadiusTopRight,
    //             [`rounded-bl-${properties.borderRadiusBottomLeft}`]: properties.borderRadiusBottomLeft,
    //             [`rounded-br-${properties.borderRadiusBottomRight}`]: properties.borderRadiusBottomRight,
    //             [`border-t-${properties.borderWidthTop}`]: properties.borderWidthTop,
    //             [`border-r-${properties.borderWidthRight}`]: properties.borderWidthRight,
    //             [`border-l-${properties.borderWidthLeft}`]: properties.borderWidthLeft,
    //             [`border-b-${properties.borderWidthBottom}`]: properties.borderWidthBottom,
    //             [`border-${properties.borderColor}`]: properties.borderColor,
    //             [`border-${properties.borderStyle}`]: properties.borderStyle,
    //             [`outline-${properties.outlineWidth}`]: properties.outlineWidth,
    //             [`outline-${properties.outlineColor}`]: properties.outlineColor,
    //             [`outline-${properties.outlineStyle}`]: properties.outlineStyle,
    //             [`blur-${properties.blur}`]: properties.blur && properties.blur !== 'normal',
    //             ['blur']: properties.blur === 'normal',
    //             [`brightness-${properties.brightness}`]: properties.brightness,
    //             [`contrast-${properties.contrast}`]: properties.contrast,
    //             [`hue-rotate-${properties.hueRotate}`]: properties.hueRotate,
    //             ['invert']: properties.invert,
    //             [`saturate-${properties.saturate}`]: properties.saturate,
    //             ['sepia']: properties.sepia,
    //             [`backdrop-blur-${properties.backdropBlur}`]: properties.backdropBlur,
    //             [`backdrop-brightess-${properties.backdropBrightness}`]: properties.backdropBrightness,
    //             [`backdrop-contrast-${properties.backdropContrast}`]: properties.backdropContrast,
    //             [`backdrop-hue-rotate-${properties.backdropHueRotate}`]: properties.backdropHueRotate,
    //             ['backdrop-invert']: properties.backdropInvert,
    //             [`backdrop-opacity-${properties.backdropOpacity}`]: properties.backdropOpacity,
    //             [`backdrop-saturate-${properties.backdropSaturate}`]: properties.backdropSaturate,
    //             ['backdrop-sepia']: properties.backdropSepia
    //         };
    //
    //         if (properties.customClasses){
    //             const customClasses = properties.customClasses.split(' ');
    //             for (const c of customClasses){
    //                 classes[c] = true;
    //             }
    //         }
    //
    //         return classes;
    //     }else{
    //         return {};
    //     }
    // }
    //
    // @HostBinding('style') get styleList(): any {
    //     if (this.element) {
    //         const properties: any = {};
    //         GruulsBaseElementComponent.stylePropertiesList.map((p) => {
    //             const containerPropName = 'container' + p.charAt(0).toUpperCase() + p.slice(1);
    //             properties[p] = this.element.config[containerPropName] !== undefined ? this.element.config[containerPropName] : this.element.config[p];
    //         });
    //
    //         return {
    //             'background-image': properties.backgroundImage,
    //             'background-color': properties.backgroundColor,
    //             'color': properties.textColor
    //         };
    //     }else{
    //         return {};
    //     }
    // }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        this.elementSubject = new BehaviorSubject(this.element);
        this.element.valueChanged()
            .pipe(
                mergeWith(this.element.configChanged()),
                takeUntil(this._unsubscribeAll),
                tap(v => this.cdr.markForCheck())
            )
            .subscribe();
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.loadElement().pipe(tap(el => this.cdr.markForCheck() )).subscribe({
            complete: () => this.cdr.markForCheck(),
            error: err => console.log(err)
        }), 0);
    }

    static angularElementsCatalog = {
        BaseElement: {
            import: defer(() => import('@gruuls-fe/elements/misc/base-element/gruuls-base-element.module')),
            moduleName: 'GruulsBaseElementModule'
        },
        DynamicFlexContainer: {
            import: defer(() => import('@gruuls-fe/elements/ui-builders/container-flex/gruuls-container-flex-ui-builder.module')),
            moduleName: 'GruulsContainerFlexUiBuilderModule'
        },
        ListElement: {
            import: defer(() => import('@gruuls-fe/elements/misc/list-element/gruuls-list-element.module')),
            moduleName: 'GruulsListElementModule'
        },
        ButtonElement: {
            import: defer(() => import('@gruuls-fe/elements/input/button-element/button-element.module')),
            moduleName: 'ButtonElementModule'
        },
        RadioElement: {
            import: defer(() => import('@gruuls-fe/elements/input/radio-element/radio-element.module')),
            moduleName: 'RadioElementModule'
        },
        ColorPickerElement: {
            import: defer(() => import('@gruuls-fe/elements/input/color-picker-element/color-picker-element.module')),
            moduleName: 'ColorPickerElementModule'
        },
        IconElement: {
            import: defer(() => import('@gruuls-fe/elements/icon-element/icon-element.module')),
            moduleName: 'IconElementModule'
        },
        ImageElement: {
            import: defer(() => import('@gruuls-fe/elements/image-element/image-element.module')),
            moduleName: 'ImageElementModule'
        },
        SelectElement: {
            import: defer(() => import('@gruuls-fe/elements/input/select-element/select-element.module')),
            moduleName: 'SelectElementModule'
        },
        JsonElement: {
            import: defer(() => import('@gruuls-fe/elements/json-element/gruuls-json-element.module')),
            moduleName: 'GruulsJsonElementModule'
        },
        InputElement: {
            import: defer(() => import('@gruuls-fe/elements/input/input-element/input-element.module')),
            moduleName: 'InputElementModule'
        },
        InputNumberElement: {
            import: defer(() => import('@gruuls-fe/elements/input/input-number-element/input-number-element.module')),
            moduleName: 'InputNumberElementModule'
        },
        TextareaElement: {
            import: defer(() => import('@gruuls-fe/elements/input/textarea-element/textarea-element.module')),
            moduleName: 'TextareaElementModule'
        },
        DividerElement: {
            import: defer(() => import('@gruuls-fe/elements/input/divider-element/divider-element.module')),
            moduleName: 'DividerElementModule'
        },
        ToggleElement: {
            import: defer(() => import('@gruuls-fe/elements/input/toggle-element/toggle-element.module')),
            moduleName: 'ToggleElementModule'
        },
        ElementCatalog: {
            import: defer(() => import('@gruuls-fe/elements/catalog-element/element-catalog.module')),
            moduleName: 'ElementCatalogModule'
        },
        ElementCatalogList: {
            import: defer(() => import('@gruuls-fe/elements/catalog-list/element-catalog-list.module')),
            moduleName: 'ElementCatalogListModule'
        },
        ElementTab: {
            import: defer(() => import('@gruuls-fe/elements/element-tab/element-tab.module')),
            moduleName: 'ElementTabModule'
        },
        DrawerElement: {
            import: defer(() => import('@gruuls-fe/elements/misc/drawer/gruuls-drawer-element.module')),
            moduleName: 'GruulsDrawerElementModule'
        },
        RouterOutletElement: {
            import: defer(() => import('@gruuls-fe/elements/misc/router-outlet-element/gruuls-router-outlet-element.module')),
            moduleName: 'GruulsRouterOutletElementModule'
        },
        ContextElement: {
            import: defer(() => import('@gruuls-fe/elements/misc/context-element/gruuls-context-element.module')),
            moduleName: 'GruulsContextElementModule'
        },
        VerticalNavigationElement: {
            import: defer(() => import('@gruuls-fe/elements/layout/vertical-navigation-element/gruuls-vertical-navigation-element.module')),
            moduleName: 'VerticalNavigationElementModule'
        },
        TopHeaderForVerticalLayoutElement: {
            import: defer(() => import('@gruuls-fe/elements/layout/gruuls-top-header-for-vertical-layout-element/gruuls-top-header-for-vertical-layout-element.module')),
            moduleName: 'TopHeaderForVerticalLayoutElement'
        },
        ModalElement: {
            import: defer(() => import('@gruuls-fe/elements/modal-element/gruuls-modal-element.module')),
            moduleName: 'GruulsModalElementModule'
        }
    };


    loadElement(): Observable<any> {
        if (!this.elementSubject) {
            this.elementSubject = new Subject<BaseElement>();
        }
        return this.elementSubject
            .pipe(
                mergeMap((thisEl) => {
                    let loader: Observable<any>;
                    let elementCatalog = GruulsElementFactoryComponent.angularElementsCatalog[this.element.config.type];

                    if (elementCatalog){
                        loader = elementCatalog.import
                            .pipe(
                                map((el) => {
                                    const module = el[Object.keys(el)[0]];
                                    const componentModule = this.compiler.compileModuleAndAllComponentsSync(module);
                                    let factory;
                                    if (componentModule){
                                        factory = componentModule.componentFactories[0];
                                    }else{
                                        factory = undefined;
                                    }

                                    return factory;
                                })
                            );
                    }else{
                        elementCatalog = GruulsElementFactoryComponent.angularElementsCatalog.BaseElement;
                    }

                    return loader
                        .pipe(
                            map((factory) => {
                                // this.elementSubject.complete();
                                this.viewContainerRef.clear();
                                const template: any = this.viewContainerRef.createComponent(factory);
                                //const template: any = this.viewContainerRef.createComponent(factory);
                                template.instance.element = this.element;
                                template.instance.allConnectedIds = this.allConnectedIds;
                                //this.el.nativeElement.insertBefore(template.location.nativeElement.firstChild, this.el.nativeElement.firstChild);
                                return template;
                            })
                        );
                })
            );
    }


}
