import {
    BehaviorSubject,
    forkJoin,
    interval,
    merge,
    MonoTypeOperatorFunction,
    Observable,
    of,
    ReplaySubject,
    Subject,
    Subscription,
    throwError,
    timer
} from 'rxjs';
import {StreamChannelMessage} from './stream-channel-message';
import {ErrorCode, ErrorDetailsInterface} from '../../../utils/data-result-interface';

import {catchError, debounce, filter, map, mergeMap, mergeWith, tap} from 'rxjs/operators';
import _ from 'lodash';
import {ElementsFactory} from '../../elements-factory';
import {ContextElement} from '../context-element/context-element';
import {
    BaseElementConfig,
    BaseElementConfigInterface,
    BaseElementStatus,
    ElementsEventHook, ElementStructuredCommandEventHook
} from './base-element-config';
import {SandboxedContext} from '../context-element/sandboxed-context';
import jsonpath_ from 'jsonpath';
import {ProgrammaticFunction, UserFunctionInput} from '../../logic/user-function-element';
import {Utils} from '../../../utils/Utils';
import {LogHistoryMessage} from './log-history-message';
import {ElementCommandExecutor} from './element-command-executor';
import {ElementCommandMessage} from './element-command-message';
import {ElementCommandReply} from './element-command-reply';

const jsonpath = jsonpath_;

export interface ElementActionMessage {
    [otherParams: string]: any;

    action: ElementAction;
    element: BaseElement;
}

export interface ElementAction {
    name: string;
    description?: string;
}

export enum BaseElementPerformance {
    inputMessage = 'inputMessage',
    channelEnd = 'channelEnd',
    useFormattedValue = 'useFormattedValue',
    channelInValue = 'channelInValue',
    defaulting = 'defaulting',
    chainElements = 'chainElements',
    finalMessage = 'finalMessage',
    catchErrorStart = 'catchErrorStart',
    performanceEnd = 'performanceEnd',
    totalChannel = 'totalChannel',
    performance = 'performance'
}

export class BaseElement {
    // serializable params
    public config: BaseElementConfig;
    public configChangedSubject: Subject<{
        config: BaseElementConfig;
        oldValue: any;
        newValue: any;
    }> = new Subject<{
        config: BaseElementConfig;
        oldValue: any;
        newValue: any;
    }>();

    // runtime params
    public _value?: any; // the last value, computed after "chainElementConfigs" call, the value that has been sent out
    public formattedValue?: any;
    public set value(v: any){
        this._value = v;
        this.valueChangedSubject.next({
            value: this._value,
            formattedValue: this.formattedValue,
            topic: this.config.name
        });
    }
    public get value(): any {
        return this._value;
    }

    protected valueChangedSubject: Subject<StreamChannelMessage> = new Subject<StreamChannelMessage>();

    public inMessage?: StreamChannelMessage; // protected
    public error?: ErrorDetailsInterface; // valued if there are errors


    public runtimeId: string;
    public pathName: string;
    protected allPathNames: string[];
    protected allNames: string[];
    protected autoUpdateSubscription?: Subscription;

    protected inStreamSubscription?: Subscription;
    protected inStreamChannel: Subject<StreamChannelMessage>;

    public chainElements: (BaseElement | UserFunctionInput | ProgrammaticFunction | ((msg: StreamChannelMessage, context: ContextElement, thisElement: BaseElement) => Observable<StreamChannelMessage>))[] = []; //protected
    public chainElementsFinal: (BaseElement | UserFunctionInput | ProgrammaticFunction )[] = []; //protected
    //public inputChainElementsComplete: BaseElement[] = []; //protected

    // public outputChainElements: BaseElement[] = []; //protected
    //public outputChainElementsComplete: BaseElement[] = []; //protected

    // these streams are used to provide value to external observers
    // such as in case of element piping
    protected outStreamChannel: Subject<StreamChannelMessage>;
    protected outStreamChannelB?: BehaviorSubject<StreamChannelMessage>;
    protected outStreamChannelObservable?: Observable<StreamChannelMessage>;
    protected outStreamChannelObsSubscription?: Subscription;
    protected outStreamChannelSubscription?: Subscription;
    protected outStreamChannelBSubscription?: Subscription;

    // these streams are used to internally inject new values into this element pipe
    // protected inStreamChannelInternalSubject: Subject<StreamChannelMessage>;
    // protected inStreamChannelInternal: Observable<StreamChannelMessage>;
    // protected inStreamChannelInternalSubscription: Subscription;

    public context?: ContextElement; //protected
    protected sandboxedContext: SandboxedContext;
    protected availableActions: {
        [actionName: string]: ElementAction;
    };
    protected commandExecutor: ElementCommandExecutor;
    // protected queryExecutor: ElementQueryExecutor;
    // protected eventBusCommandSubscriptions: {
    //     [topic: string]: Subscription;
    // } = {};
    // protected eventBusQuerySubscriptions: {
    //     [topic: string]: Subscription;
    // } = {};

    protected logHistorySubject = new ReplaySubject<LogHistoryMessage>();
    public logHistoryObservable: Observable<LogHistoryMessage>;
    protected logHistorySubscription: Subscription;

    protected runtimeInStreamChannels: Observable<StreamChannelMessage>[] = [];

    public $parent: BehaviorSubject<BaseElement>;//private
    private $autoUpdateMs: BehaviorSubject<number>;

    private lastTimestampIn = performance.now();
    private msgCountForRateIn = 0;
    public msgSecRateIn = 0;
    private lastTimestampOut = performance.now();
    private msgCountForRateOut = 0;
    public msgSecRateOut = 0;

    protected eventsHook: ElementsEventHook = {};

    constructor(config: BaseElementConfigInterface) {
        this.config = new BaseElementConfig(config, this);

        this.runtimeId = Utils.guid(); //.substring(0,2);

        this.availableActions = {};

        this.inStreamChannel = new Subject<StreamChannelMessage>();
        this.outStreamChannel = new Subject<StreamChannelMessage>();
        this.outStreamChannelObservable = new Observable<StreamChannelMessage>((subscriber) => {
            if (!this.outStreamChannelObsSubscription) {
                this.outStreamChannelObsSubscription = this.outStreamChannel.subscribe(subscriber);
            }
        });

        this.logHistoryObservable = new Observable<LogHistoryMessage>((subscriber) => {
            if (!this.logHistorySubscription){
                this.logHistorySubscription = this.logHistorySubject
                    .subscribe(subscriber);
            }
        });


        this.commandExecutor = new ElementCommandExecutor();
        // this.queryExecutor = new ElementQueryExecutor();
        this.pathName = this.config.name; // initialized in init() function
        this.allPathNames = []; // initialized in init() function

        this.$parent = new BehaviorSubject<BaseElement>(undefined);
        this.$parent.subscribe((newParent) => {
            // Parent Lookup && Naming
            this.allPathNames = [];
            if (newParent) {
                const parentPathName = newParent.pathName;
                this.pathName = parentPathName + '.' + this.config.name;
                for (const n of this.config.otherNames) {
                    this.allPathNames.push(parentPathName + '.' + n);
                }
            }
            this.allPathNames.push(...this.config.otherNames);
            this.allPathNames.push(this.config.name);
            this.allPathNames.push(this.pathName);
            // remove duplicates
            this.allPathNames = this.allPathNames.filter((item, index) => (this.allPathNames.findIndex(el => el === item) === index));
        });



        // this.$autoUpdateMs = new BehaviorSubject<number>(this.config.autoUpdateMs);
        // this.$autoUpdateMs.subscribe((newAutoUpdateMs) => {
        //     this.config.autoUpdateMs = newAutoUpdateMs || 0;
        //     if (!newAutoUpdateMs) {
        //         this.stopAutoUpdate();
        //     } else if (!this.config.pauseAutoUpdate) {
        //         this.startAutoUpdate();
        //     }
        // });
    }

    destroy(): void {
        this._deInit().subscribe();
    }

    public reboot(options?: any): Observable<BaseElement>{
        const lastMessage = this.inMessage;
        return this.init(undefined, options)
            .pipe(
                tap(elm => this.inStreamChannel.next(lastMessage))
            );
    }
    protected configUpdated(changes: any): Observable<any> {
        return of();
    }

    /**
     * @deprecated use .subscribe()
     */
    getOutStreamChannel(): Observable<StreamChannelMessage> {
        if (this.value !== undefined) {
            this.outStreamChannelB = new BehaviorSubject({
                topic: this.config.name,
                value: this.value
            });
            this.outStreamChannelBSubscription = this.outStreamChannel.subscribe(this.outStreamChannelB);
            return this.outStreamChannelB;
        } else {
            return this.outStreamChannel;
        }
    }

    subscribe(): Observable<StreamChannelMessage> {
        return this.getOutStreamChannel();
    }

    public getAllRuntimeIdsRecursive(): string[]{
        return [];
    }

    public getContext(): ContextElement{
        return this.context;
    }

    init(context?: ContextElement, options?: any): Observable<BaseElement> {
        this.log('Init', undefined, 'Initializing... ' + this.config.name + '\t\t' + this.config._status);
        if (this.config._status === BaseElementStatus.offline) {
            this.log('Init', undefined, ' Initialized... ' + this.config.name + '\t\t' + this.config._status);
            return of(this);
        }
        this.context = context || this.context;
        this.sandboxedContext = new SandboxedContext(this.context);

        if (this.config._status !== BaseElementStatus.sleeping) {
            const toPause = this.config._status === BaseElementStatus.paused;
            this.config._status = BaseElementStatus.initializing;
            return this._init(options)
                .pipe(
                    tap(el => this.config._status = toPause ? BaseElementStatus.paused : BaseElementStatus.online),
                    tap(el => this.log('Init', undefined, ' Initialized... ' + this.config.name + '\t\t' + this.config._status)),
                    mergeMap(el => of(this))
                );
        } else {
            this.log('Init', undefined, ' Initialized... ' + this.config.name + '\t\t' + this.config._status);
            return of(this);
        }
    }

    protected _init(options?: any): Observable<BaseElement> {

        //reset all current subscriptions (if any)
        if (this.outStreamChannelSubscription) {
            this.outStreamChannelSubscription.unsubscribe();
            this.outStreamChannelSubscription = undefined;
            this.outStreamChannelObservable = undefined;
        }

        if (this.outStreamChannelObsSubscription){
            this.outStreamChannelObsSubscription.unsubscribe();
            this.outStreamChannelObsSubscription = undefined;
        }

        if (this.logHistorySubscription){
            this.logHistorySubscription.unsubscribe();
            this.logHistorySubscription = undefined;
        }

        if (this.outStreamChannelBSubscription) {
            this.outStreamChannelBSubscription.unsubscribe();
            this.outStreamChannelBSubscription = undefined;
        }

        this.chainElementsFinal = [];

        return of(true)
            .pipe(
                // prepare chain (1)
                mergeMap((res) => {
                    const inObs: any[] = [of(true)];
                    inObs.push(...this.config.chainElementConfigs.map((e) => {
                        if (typeof e === 'number' || typeof e === 'string' || typeof e === 'function' || e.value !== undefined){
                            const fn = this.parseUserInputValue(e);
                            return of({
                                order: e.order,
                                value: fn
                            } as ProgrammaticFunction);
                        } else {
                            // this is an Element
                            return new ElementsFactory().create(e);
                        }
                    }));
                    inObs.push(...this.chainElements.map((e, index) => {
                        if ( typeof e === 'function'){
                            const fn = this.parseUserInputValue(e);
                            return of({
                                order: 0,
                                value: fn
                            } as ProgrammaticFunction);
                        } else if ( e.value !== undefined){
                            const fn = this.parseUserInputValue(e.value);
                            return of({
                                order: (e as any).order || 0,
                                value: fn
                            } as ProgrammaticFunction);
                        } else {
                            // this is an Element
                            return new ElementsFactory().create((e as any));
                        }
                    }));

                    return forkJoin(inObs);
                }),
                // prepare chain (2)
                mergeMap((res) => {
                    res.splice(0, 1);
                    this.chainElementsFinal = res;
                    if (this.chainElementsFinal.length) {
                        this.chainElementsFinal = this.chainElementsFinal.sort((a: any, b: any) => a.order || a.config?.order - b.order || b.config?.order);
                        const inObs = this.chainElementsFinal.map((el: any) => {
                            if (el.init) {
                                el.parent = this;
                                return el.init(this.context);
                            } else {
                                return of(el);
                            }
                        });
                        return forkJoin(inObs);
                    }else{
                        return of(true);
                    }
                }),
                mergeMap(value1 => this.createChannelPipe()),
                // connect in-streams elements (if any)
                tap((res) => {
                    // IN-STREAMS
                    const s: Observable<StreamChannelMessage>[] = [];
                    if (this.config.inStreamElementIds) {
                        for (const e of this.config.inStreamElementIds) {
                            s.push(this.context.getElement(e).getOutStreamChannel());
                        }
                    }
                    if (this.runtimeInStreamChannels) {
                        for (const r of this.runtimeInStreamChannels) {
                            s.push(r);
                        }
                    }
                    if (s.length) {
                        if (this.inStreamSubscription) {
                            this.inStreamSubscription.unsubscribe();
                        }
                        this.inStreamSubscription = merge(...s)
                            .subscribe(this.inStreamChannel);
                    }
                }),
                // start auto-update (if requested)
                tap((res) => {
                    // AUTO UPDATE
                    if (this.config.autoUpdateMs && !this.autoUpdateSubscription && !this.config.pauseAutoUpdate) {
                        this.doAutoUpdate();
                    }
                }),
                //mergeMap(value1 => of(value1))
            );

        // // COMMANDS FUNCTIONS
        // for (const t of Object.values(this.eventBusCommandSubscriptions)) {
        //     t.unsubscribe();
        // }
        // this.eventBusCommandSubscriptions = {};
        // if (this.commandExecutor.hasCommands()) {
        //     const _commandEventBus: GruulsEventBus = this.contextRootElement.getCommandEventBus();
        //     this.eventBusCommandSubscriptions[this.allPathNames.join('_')] = _commandEventBus.subscribeToTopics(this.allPathNames, true)
        //         .pipe(
        //             flatMap((message: ElementCommandMessage) => this.commandExecutor.execute(message))
        //         )
        //         .subscribe();
        // }
        //
        // // QUERY FUNCTIONS
        // for (const t of Object.values(this.eventBusQuerySubscriptions)) {
        //     t.unsubscribe();
        // }
        // this.eventBusQuerySubscriptions = {};
        // if (this.queryExecutor.hasQueries()) {
        //     const _queryEventBus: GruulsEventBus = this.contextRootElement.getQueryEventBus();
        //     this.eventBusQuerySubscriptions[this.allPathNames.join('_')] = _queryEventBus.subscribeToTopics(this.allPathNames, true)
        //         .pipe(
        //             flatMap((message: ElementQueryMessage) => this.queryExecutor.execute(message))
        //         )
        //         .subscribe();
        // }

    }

    protected createChannelPipe(): Observable<BaseElement> {
        this.outStreamChannelSubscription = this.inStreamChannel
            .pipe(
                // update performance details
                tap((msg) => {
                    const now = performance.now();
                    if (now > this.lastTimestampIn + 5000){
                        this.msgSecRateIn = +((this.msgCountForRateIn / ((now - this.lastTimestampIn)/1000))).toFixed(2);
                        this.msgCountForRateIn = 1;
                        this.lastTimestampIn = now;
                    }else{
                        this.msgCountForRateIn++;
                    }
                    this.config._status = BaseElementStatus.loading;
                    // performance.mark(BaseElementPerformance.inputMessage);
                }),
                tap(msg1 => this.log(BaseElementPerformance.inputMessage, msg1)),
                filter(msg => this.config._status !== BaseElementStatus.paused),
                this.config.debounceIn ? debounce(t => interval(this.config.debounceIn)) : map(msg => msg),
                // make a copy
                map(msg => msg && !msg.error ? _.cloneDeep(msg): msg),
                // save inValue
                tap((msg) => {
                    //      performance.mark(BaseElementPerformance.channelInValue);
                    this.inMessage = msg;
                }),
                // mergeWith(this.inStreamChannelInternal),
                // coerce
                map(msg => this.coerce(msg)),
                // merge Config
                tap((msg) => {
                    if (msg && msg.config){
                        _.merge(this.config, msg.config);
                    }
                }),
                //if requested, select a specific value inside msg.value, may be useful in case you
                // have a big input value and you need just a single specific key
                // you can use jsonpath and can be even an UserInputFunction
                mergeMap((msg) => {
                    if (msg && msg.value !== undefined && this.config._selectValue) {
                        return this.parseUserInputValue(this.config._selectValue)(_.cloneDeep(msg))
                            .pipe(
                                map((selectValue) => {
                                    msg.value = jsonpath.query(msg.value, '$.' + selectValue.value)[0];
                                    return msg;
                                })
                            );
                    }
                    return of(msg);
                }),
                // default value
                mergeMap((msg) => {
                    //     performance.mark(BaseElementPerformance.defaulting);
                    try {
                        if ((!msg || (msg && msg.value === undefined)) && !!this.config.defaultValue) {
                            const defVal = this.parseUserInputValue(this.config.defaultValue);
                            return defVal(msg);
                                // .pipe(
                                //     map((msg1) => {
                                //         if (typeof msg1 !== 'object'){
                                //             msg1 = {
                                //                 value: msg1
                                //             };
                                //         } else if (msg1) {
                                //             msg1 = {
                                //                 value: msg1,
                                //                 topic:this.pathName
                                //             };
                                //         }
                                //         return msg1;
                                //     })
                                // );
                        } else {
                            return of(msg);
                        }
                    }catch (e) {
                        return throwError(e);
                    }
                }),
                tap(msg1 => this.log(BaseElementPerformance.defaulting, msg1)),
                // use formatted value
                map((msg) => {
                    //     performance.mark(BaseElementPerformance.useFormattedValue);
                    if (msg) {
                        if (this.config.useIncomingFormattedValue) {
                            msg.value = msg.formattedValue;
                        }
                        delete msg.formattedValue;
                    }
                    return msg;
                }),
                tap(msg1 => this.log(BaseElementPerformance.useFormattedValue, msg1)),
                // process internal chain
                mergeMap((msg) => {
                    if (this.chainElementsFinal && this.chainElementsFinal.length) {
                        let len = 0;
                        let obs: Observable<StreamChannelMessage> = of(msg);
                        for (const e of this.chainElementsFinal) {
                            obs = ((o: Observable<StreamChannelMessage>, el: BaseElement | ProgrammaticFunction ,l: number): Observable<StreamChannelMessage> => o.pipe(
                                mergeMap((msg1) => {
                                    //                    performance.mark(BaseElementPerformance.chainInput + '_' + l);
                                    this.log('passing to InputChain (' + l + '): ' + (el as any).pathName ? (el as any).pathName: '<userFunction>', msg1);
                                    return (e as any).processStream ? (e as any).processStream(msg1) : e.value(msg1)
                                        .pipe(
                                            tap(msg2 => this.log('received from InputChain (' + l + '): ' + (el as any).pathName ? (el as any).pathName: '<userFunction>', msg2)),
                                            filter(msg2 => this.config._status !== BaseElementStatus.paused),
                                            map(msg2 => this.coerce(msg2))
                                        );
                                })
                            ))(obs, e, len);
                            len++;
                        }
                        return obs;
                    }
                    return of(msg);
                }),
                tap(msg1 => this.log(BaseElementPerformance.chainElements, msg1)),
                filter(msg1 => this.config._status !== BaseElementStatus.paused),
                this.config.debounceOut ? debounce(t => interval(this.config.debounceOut)) : map(v => v),
                // composing final message
                map((msg) => {
                    //     performance.mark(BaseElementPerformance.finalMessage);
                    if (msg && !msg.error) {
                        if (msg) {
                            this.formattedValue = msg.formattedValue || msg.value;
                            this.value = msg.value;
                            this.error = undefined;
                            msg.topic = this.config.name;
                        }
                    }
                    return msg;
                }),
                filter(msg => this.config._status !== BaseElementStatus.paused),
                catchError((err) => {
                    // notify error via error variable
                    // recreate channel pipe
                    // error is not propagated downwards
                    //      performance.mark(BaseElementPerformance.catchErrorStart);
                    this.error = {
                        errorCode: ErrorCode.INTERNAL.toString(),
                        element: this,
                        errorDetails: err
                    };
                    const errObj = { error: this.error };
                    this.log(this.config.name + ' - ERROR!', errObj);
                    console.error(errObj);
                    return of(errObj);
                }),
                // check if message has error and se this.error accordingly
                tap((msg) => {
                    //     performance.mark(BaseElementPerformance.finalMessage);
                    if (msg?.error) {
                        this.error = msg.error;
                        this.config.status = BaseElementStatus.error;
                    }else{
                        this.config.status = BaseElementStatus.online;
                        this.error = undefined;
                    }
                }),
                filter((msg) => {
                    if ((msg && msg.error && this.config.forwardErrorMessage) || (msg && !msg.error) || !msg ){
                        return true;
                    }
                    return false;
                }),
                // update performance details
                tap((el) => {
                    const now = performance.now();
                    if (now > this.lastTimestampOut + 5000){
                        this.msgSecRateOut = +((this.msgCountForRateOut / ((now - this.lastTimestampOut)/1000))).toFixed(2);
                        this.msgCountForRateOut = 1;
                        this.lastTimestampOut = now;
                    }else{
                        this.msgCountForRateOut++;
                    }
                }),
                // forward error message??
                filter( (msg: StreamChannelMessage) => (msg && msg.error && this.config.forwardErrorMessage) || !msg || (msg && !msg.error)),
                // collect performance data
                tap((message) => {
                    // performance.mark(BaseElementPerformance.channelEnd);
                    // if (performance.getEntriesByName(BaseElementPerformance.defaulting).length && performance.getEntriesByName(BaseElementPerformance.useFormattedValue).length) {
                    //     performance.measure(BaseElementPerformance.defaulting, BaseElementPerformance.defaulting, BaseElementPerformance.useFormattedValue);
                    // }
                    // if (performance.getEntriesByName(BaseElementPerformance.useFormattedValue).length && performance.getEntriesByName(BaseElementPerformance.channelInValue).length) {
                    //     performance.measure(BaseElementPerformance.useFormattedValue, BaseElementPerformance.useFormattedValue, BaseElementPerformance.channelInValue);
                    // }
                    // if (inputChainElements && inputChainElements.length) {
                    //     if (performance.getEntriesByName(BaseElementPerformance.channelInValue).length && performance.getEntriesByName(BaseElementPerformance.chainInput + '_0').length) {
                    //         performance.measure(BaseElementPerformance.channelInValue, BaseElementPerformance.channelInValue, BaseElementPerformance.chainInput + '_0');
                    //     }
                    //     for (let i = 1; i < inputChainElements.length - 1; i++) {
                    //         if (performance.getEntriesByName(BaseElementPerformance.chainInput + '_' + i).length && performance.getEntriesByName(BaseElementPerformance.chainInput + '_' + (i + 1)).length) {
                    //             performance.measure(BaseElementPerformance.chainInput + '_' + (i - 1), BaseElementPerformance.chainInput + '_' + i, BaseElementPerformance.chainInput + '_' + (i + 1));
                    //         }
                    //     }
                    //     if (performance.getEntriesByName(BaseElementPerformance.chainInput + '_' + (inputChainElements.length - 1)).length && performance.getEntriesByName(BaseElementPerformance.chainElements).length) {
                    //         performance.measure(BaseElementPerformance.chainInput + '_' + (inputChainElements.length - 1), BaseElementPerformance.chainInput + '_' + (inputChainElements.length - 1), BaseElementPerformance.chainElements);
                    //     }
                    // } else {
                    //     if (performance.getEntriesByName(BaseElementPerformance.channelInValue).length && performance.getEntriesByName(BaseElementPerformance.chainElements).length) {
                    //         performance.measure(BaseElementPerformance.channelInValue, BaseElementPerformance.channelInValue, BaseElementPerformance.chainElements);
                    //     }
                    // }
                    // if (outputChainElements && outputChainElements.length) {
                    //     if (performance.getEntriesByName(BaseElementPerformance.chainElements).length && performance.getEntriesByName(BaseElementPerformance.chainOutput + '_0').length) {
                    //         performance.measure(BaseElementPerformance.chainElements, BaseElementPerformance.chainElements, BaseElementPerformance.chainOutput + '_0');
                    //     }
                    //     for (let i = 1; i < outputChainElements.length - 1; i++) {
                    //         if (performance.getEntriesByName(BaseElementPerformance.chainOutput + '_' + i).length && performance.getEntriesByName(BaseElementPerformance.chainOutput + '_' + (i + 1)).length) {
                    //             performance.measure(BaseElementPerformance.chainOutput + '_' + (i - 1), BaseElementPerformance.chainOutput + '_' + i, BaseElementPerformance.chainOutput + '_' + (i + 1));
                    //         }
                    //     }
                    //     if (performance.getEntriesByName(BaseElementPerformance.chainOutput + '_' + (outputChainElements.length - 1)).length && performance.getEntriesByName(BaseElementPerformance.finalMessage).length) {
                    //         performance.measure(BaseElementPerformance.chainOutput + '_' + (outputChainElements.length - 1), BaseElementPerformance.chainOutput + '_' + (outputChainElements.length - 1), BaseElementPerformance.finalMessage);
                    //     }
                    // } else {
                    //     if (performance.getEntriesByName(BaseElementPerformance.chainElements).length && performance.getEntriesByName(BaseElementPerformance.finalMessage).length) {
                    //         performance.measure(BaseElementPerformance.chainElements, BaseElementPerformance.chainElements, BaseElementPerformance.finalMessage);
                    //     }
                    // }
                    // if (performance.getEntriesByName(BaseElementPerformance.finalMessage).length && performance.getEntriesByName(BaseElementPerformance.channelEnd).length) {
                    //     performance.measure(BaseElementPerformance.finalMessage, BaseElementPerformance.finalMessage, BaseElementPerformance.channelEnd);
                    // }
                    //
                    // performance.mark(BaseElementPerformance.performanceEnd);
                    //
                    // if (performance.getEntriesByName(BaseElementPerformance.inputMessage).length && performance.getEntriesByName(BaseElementPerformance.channelEnd).length) {
                    //     performance.measure(BaseElementPerformance.totalChannel, BaseElementPerformance.inputMessage, BaseElementPerformance.channelEnd);
                    // }
                    // if (performance.getEntriesByName(BaseElementPerformance.channelEnd).length && performance.getEntriesByName(BaseElementPerformance.performanceEnd).length) {
                    //     performance.measure(BaseElementPerformance.performance, BaseElementPerformance.channelEnd, BaseElementPerformance.performanceEnd);
                    // }
                }),
                tap(msg1 => this.log(BaseElementPerformance.finalMessage, msg1))
            )
            .subscribe(this.outStreamChannel);

        return of(this);
    }

    //////////////////////////////
    //// Stream utilities - START
    //////////////////////////////

    coerce(msg): StreamChannelMessage{
        if (typeof msg !== 'object'){
        msg = {
            value: msg
        };
        }
        if (msg) {
            msg.topic = this.pathName;
        }
        return msg;
    }


    //////////////////////////////
    //// Stream utilities - END
    //////////////////////////////
    protected _deInit(): Observable<BaseElement> {
        // IN-STREAMS
        if (this.inStreamSubscription) {
            this.inStreamSubscription.unsubscribe();
        }
        if (this.autoUpdateSubscription) {
            this.autoUpdateSubscription.unsubscribe();
        }

        // if (this.inStreamChannelInternalSubject) {
        //     this.inStreamChannelInternalSubject.unsubscribe();
        //     this.inStreamChannelInternal = undefined;
        // }
        //
        // if (this.inStreamChannelInternalSubscription){
        //     this.inStreamChannelInternalSubscription.unsubscribe();
        //     this.inStreamChannelInternalSubscription = undefined;
        // }
        return this.destroyChannelPipe()
            .pipe(
                tap(el => this.config._status = BaseElementStatus.toInitialize)
            );
    }

    // used programmatically inside a pipe
    processStream(message?: StreamChannelMessage | any): Observable<StreamChannelMessage> {
        setTimeout(() => {
            this.inStreamChannel.next(message);
        }, 0);
        return this.outStreamChannelObservable;
    }

    // processStreamInternal(message?: StreamChannelMessage | any): Observable<StreamChannelMessage> {
    //     setTimeout(() => {
    //         this.inStreamChannelInternalSubject.next(message);
    //     }, 0);
    //     return this.outStreamChannelObservable;
    // }


    processOperator(): MonoTypeOperatorFunction<StreamChannelMessage> {
        return (inStream: Observable<StreamChannelMessage>): Observable<StreamChannelMessage> => {
            inStream.subscribe(this.inStreamChannel);
            return this.outStreamChannelObservable;
        };
    }

    stopAutoUpdate(): void {
        this.config.pauseAutoUpdate = true;
        if (this.autoUpdateSubscription) {
            this.autoUpdateSubscription.unsubscribe();
            this.autoUpdateSubscription = undefined;
        }
    }

    startAutoUpdate(): void {
        this.config.pauseAutoUpdate = false;
        if (this.config.autoUpdateMs && !this.autoUpdateSubscription) {
            this.doAutoUpdate();
        }
    }

    connectInStreamElement(elementId: string): void {
        this.config.inStreamElementIds.push(elementId);
        if (this.config._status !== BaseElementStatus.toInitialize) {
            this.init(this.context as any).subscribe();
        }
    }

    isInitialElement(): boolean {
        return !(this.config.inStreamElementIds && this.config.inStreamElementIds.length);
    }

    addInStreamRuntimeChannel(channel: Observable<StreamChannelMessage>): void {
        this.runtimeInStreamChannels.push(channel);
        // if (this.config._status !== BaseElementStatus.toInitialize) {
        //     this.init(this.context).subscribe();
        // }
    }

    removeInStreamRuntimeChannel(channel: Observable<StreamChannelMessage>): void {
        const i = this.runtimeInStreamChannels.indexOf(channel);
        this.runtimeInStreamChannels.splice(i,1);
        // if (this.config._status !== BaseElementStatus.toInitialize) {
        //     this.init(this.context).subscribe();
        // }
    }

    registerEvent(eventName: string, event: string
        | { type: 'function'; value: string }
        | ((value: any, context: SandboxedContext, thiz: BaseElement) => any)
        | ElementStructuredCommandEventHook): void{

        this.eventsHook[eventName] = event;
    }

    fireEvent(eventName: string, event: any): void {
        if (this.config.eventsHook && this.config.eventsHook[eventName]) {
            try {
                // TODO, Error management
                this.parseUserInputValue(this.config.eventsHook[eventName])(event)
                    .subscribe();
            }catch (e){
                console.error(e);
            }
        } else if (this.eventsHook && this.eventsHook[eventName]) {
            try {
                // TODO, Error management
                this.parseUserInputValue(this.eventsHook[eventName])(event)
                    .subscribe();
            }catch (e){
                console.error(e);
            }
        }else{
            console.warn('event ' + eventName + ' not found for element: ' + this.config.name);
        }
    }

    registerCommand(commandName: string, commandExe: (message: ElementCommandMessage, context: SandboxedContext, thisElement: BaseElement) => Observable<ElementCommandReply>): void{
        this.commandExecutor.registerCommand(commandName, commandExe);
    }

    executeCommand(command: ElementCommandMessage): Observable<ElementCommandReply>{
        return this.commandExecutor.execute(command, this.sandboxedContext, this);
    }

    wakeUp(): Observable<BaseElement> {
        if (this.config._status !== BaseElementStatus.sleeping) {
            return of(this);
        } else {
            this.config._status = BaseElementStatus.toInitialize;
            return this.init(this.context);
        }
    }

    sleep(): Observable<BaseElement> {
        if (this.config._status === BaseElementStatus.sleeping) {
            return of(this);
        } else {
            return this._deInit()
                .pipe(
                    tap(el => this.config._status = BaseElementStatus.sleeping)
                );
        }
    }

    // SERIALIZER
    toJson(): BaseElementConfig {
        return this.config;
    }

    protected parseUserInputValue(userValue: string | number | any | UserFunctionInput): (msg: StreamChannelMessage) => Observable<StreamChannelMessage> {
        // defaultValue?: { _isInnerValue: boolean; [key: string]: any } | string | boolean | number | StreamChannelMessage | UserFunctionInput;
        // userDoFunction?: string | UserFunctionInput;
        if (userValue === undefined || userValue === '') {
            return (msg: StreamChannelMessage): Observable<StreamChannelMessage> => of(msg);
        }

        if (typeof userValue === 'function') {
            return (msg: StreamChannelMessage): Observable<StreamChannelMessage> => {
                const res = userValue(msg, this.sandboxedContext, this);
                if (res instanceof Observable){
                    return res;
                }else{
                    if (typeof res !== 'object' && typeof res !== 'function'){
                        return of({value:res, formattedValue:res});
                    }
                    return of(res);
                }
            };
        }

        if (typeof userValue === 'object') {
            if (userValue.type && userValue.type === 'function') {
                const uip: (msg: StreamChannelMessage) => Observable<StreamChannelMessage> = this.parseUserInputFunctionValue(userValue.value);
                return uip;
            }
            if (typeof userValue.value === 'function') {
                return (msg: StreamChannelMessage): Observable<StreamChannelMessage> => {
                    const res = userValue.value(msg, this.sandboxedContext, this);
                    if (res instanceof Observable){
                        return res;
                    }else{
                        if (typeof res !== 'object' && typeof res !== 'function'){
                            return of({value:res, formattedValue:res});
                        }
                        return of(res);
                    }
                };
            }

            return this.parseUserInputSimpleOrInlineValue(userValue.value ? userValue.value : userValue);
        }

        const uisv: (msg: StreamChannelMessage) => Observable<StreamChannelMessage> = this.parseUserInputSimpleOrInlineValue(userValue);
        return uisv;

    }

    protected parseUserInputFunctionValue(valueFn: string): (msg: StreamChannelMessage) => Observable<StreamChannelMessage> {
        const fnImpl = Function('"use strict"; return (function(msg, context, thisElement) {' + valueFn + '})')();
        return (msg: StreamChannelMessage): Observable<StreamChannelMessage> => {
            const res = fnImpl(msg, this.sandboxedContext, this);
            if (res instanceof Observable){
                return res;
            }else {
                if (typeof res !== 'object' && typeof res !== 'function'){
                    return of({value:res, formattedValue:res});
                }
                return of(res);
            }
        };
    }

    protected parseUserInputSimpleOrInlineValue(value: string | number | any): (msg: StreamChannelMessage) => Observable<StreamChannelMessage> {
        let tick = '`';
        let openPar = '({value : ';
        let closedPar = '})';
        let finalValue = value;
        if (typeof value === 'object'){
            tick = '';
            finalValue = JSON.stringify(finalValue);
        }else{
            openPar = '';
            closedPar = '';
        }
        const fnImpl = Function('"use strict"; return (function(msg, context, thisElement) {return ' + tick + openPar + finalValue + closedPar + tick + ';})')();
        return (msg: StreamChannelMessage): Observable<StreamChannelMessage> => {
            let res = fnImpl(msg, this.sandboxedContext, this);
            res = (res === 'undefined' || res === 'null') ? undefined : res;
            try{
                res = JSON.parse(res);
            }catch (e) {
            }
            msg = msg ? msg : {};
            if (typeof res !== 'object' && typeof res !== 'function'){
                msg.value = res;
                msg.formattedValue = res;
            }else{
                msg = res;
            }
            return of(msg);
        };
    }

    // protected paramResolver(paramName: string, context: any): any {
    //     let s: any = paramName.split('.');
    //     s.splice(0, 1);
    //     s = s.join('.');
    //     const lookup = jsonpath.query(context, '$.' + s);
    //     return lookup[0];
    // }

    protected destroyChannelPipe(): Observable<BaseElement> {
        if (this.outStreamChannelSubscription) {
            this.outStreamChannelSubscription.unsubscribe();
            this.outStreamChannelSubscription = undefined;
            this.outStreamChannelObservable = undefined;
        }

        if (this.outStreamChannelObsSubscription){
            this.outStreamChannelObsSubscription.unsubscribe();
            this.outStreamChannelObsSubscription = undefined;
        }

        if (this.logHistorySubscription){
            this.logHistorySubscription.unsubscribe();
            this.logHistorySubscription = undefined;
        }

        if (this.outStreamChannelBSubscription) {
            this.outStreamChannelBSubscription.unsubscribe();
            this.outStreamChannelBSubscription = undefined;
        }
        return of(this);
    }

    // Logging
    protected log(logName: string, message?: StreamChannelMessage, notes?: string): void {
        if (this.config.logging) {
            const level: 'warn' | 'log' = message && message.error? 'warn' : 'log';
            const msg: LogHistoryMessage = {
                name: this.config.name,
                logName: logName,
                notes: notes,
                message: _.cloneDeep(message),
                timestamp: performance.now() / 1000,
            };
            const foo =  (level === 'warn') ? console.warn(msg) : console.log(msg);
            this.logHistorySubject.next(msg);
        }
    }

    //AUTO UPDATE
    protected doAutoUpdate(): void {
        if (this.config.autoUpdateMs) {
            this.autoUpdateSubscription = timer(this.config.autoUpdateMs).subscribe((res) => {
                if (!this.config.pauseAutoUpdate) {
                    this.inStreamChannel.next(this.config.useLastInValueWhenAutoUpdate? this.inMessage : {});
                    this.doAutoUpdate();
                }
            });
        }
    };

    // SETTERS
    set debounceIn(val: number) {
        const oldVal = this.config.debounceIn;
        this.config.debounceIn = val;
        if ((!oldVal && val) || (oldVal && !val)) {
            this.createChannelPipe();
        }
    }

    set debounceOut(val: number) {
        const oldVal = this.config.debounceOut;
        this.config.debounceOut = val;
        if ((!oldVal && val) || (oldVal && !val)) {
            this.createChannelPipe();
        }
    }

    set status(val: BaseElementStatus) {
        this.config._status = val;
    }

    set parent(parent: BaseElement) {
        this.$parent.next(parent);
    }

    get parent(): BaseElement {
        return this.$parent.value;
    }

    set autoUpdateMs(val: number) {
        this.$autoUpdateMs.next(val);
    }

    get autoUpdateMs(): number {
        return this.$autoUpdateMs.value;
    }

    public valueChanged(): Observable<StreamChannelMessage>{
        return this.valueChangedSubject.asObservable();
    }

    public configChanged(): Observable<{
        config: BaseElementConfig;
        oldValue: any;
        newValue: any;
    }>{
        return this.configChangedSubject.asObservable();
    }


}
