<div class="relative flex flex-col w-full h-full">

    <!-- Dismiss button -->
    <ng-container *ngIf="data.dismissible">
        <div class="absolute top-0 right-0 pt-4 pr-4">
            <button mat-icon-button [matDialogClose]="undefined">
                <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>
    </ng-container>

    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-2">

        <!-- Icon -->
        <ng-container *ngIf="data.icon.show">
            <div class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full" [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': data.icon.color === 'primary',
                            'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600': data.icon.color === 'accent',
                            'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data.icon.color === 'warn',
                            'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600': data.icon.color === 'basic',
                            'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600': data.icon.color === 'info',
                            'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500': data.icon.color === 'success',
                            'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500': data.icon.color === 'warning',
                            'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': data.icon.color === 'error'
                            }">
                <mat-icon class="text-current" [svgIcon]="data.icon.name"></mat-icon>
            </div>
        </ng-container>

        <ng-container *ngIf="data.title || data.message">
            <div
                class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

                <!-- Title -->
                <ng-container *ngIf="data.title">
                    <div class="text-xl leading-6 font-medium" [innerHTML]="data.title"></div>
                </ng-container>

                <!-- Message -->
                <ng-container *ngIf="0">
                    <div class="text-secondary">
                        <span></span>
                    </div>
                    <!-- <span>Totale: <strong>{{order.total | currency:'EUR'}}</strong></span> -->
                </ng-container>
            </div>
        </ng-container>

    </div>

    <div class="border-t-2">
        <!-- Loader -->
        <div class="inset-x-0 top-0" *ngIf="isSpecificLoading['suggestedProducts'] && !isLoadingError">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>

        <ng-container *ngIf="!isSpecificLoading['suggestedProducts']">
            <div *ngIf="!isLoadingError; else loadingError"
                class="flex flex-col px-8 py-3 overflow-scroll max-h-60 gap-y-4">
                <div class="w-full">
                    <div class="pb-2">
                        <label id="shipping-group-label">{{translateStrings['product']['advise']}}: </label>
                        <form [formGroup]="productsSelectionGroup" class="mt-4">
                            <ng-container *ngIf="products$ | async as products">
                                <div formArrayName="productSelection">
                                    <section class="advise-products-section">
                                        <div class="grid grid-cols-4 gap-4">
                                            <label *ngFor="let product of productSelection().controls; let i = index">
                                                <div [formGroupName]="i">
                                                    <mat-checkbox
                                                        formControlName="selected">{{product.value.name}}</mat-checkbox><br />
                                                    <!-- <span class="text-gray-400 text-sm	">{{product.value.dimension}}</span> -->
                                                </div>
                                            </label>
                                        </div>
                                    </section>
                                </div>
                            </ng-container>
                        </form>
                    </div>
                </div>
                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.healthPlan.advise}}</mat-label>
                    <mat-select [(value)]="suggestedHealthPlan">
                        <ng-container *ngFor="let plan of healthPlans; trackBy: trackByFn">
                            <mat-option [value]="plan.code">{{plan.label}}
                                <small>({{translateStrings.generic.affinity}}:
                                    {{translateStrings.generic[plan?.affinityPercentageText]}})</small></mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-icon matSuffix class="icon-size-5" svgIcon="restaurant_menu"></mat-icon>
                </mat-form-field>

            </div>
        </ng-container>

        <ng-template #loadingError>
            <div class="flex px-8 py-3 overflow-scroll max-h-60">
                <mat-icon class="text-amber-500" [svgIcon]="'heroicons_outline:exclamation-circle'"></mat-icon>
                <div class="text-lg pl-4">{{translateStrings['product']['adviseError']}}</div>
            </div>
        </ng-template>

        <!-- Actions -->
        <ng-container *ngIf="data.actions.confirm.show || data.actions.cancel.show">
            <div
                class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

                <!-- Cancel -->
                <ng-container *ngIf="data.actions.cancel.show">
                    <button mat-stroked-button [matDialogClose]="{message: 'cancel'}">
                        {{data.actions.cancel.label}}
                    </button>
                </ng-container>

                <ng-container *ngIf="data.actions.confirm.show">
                    <ng-container *ngIf="WA_ENABLED; then multipleConfirm; else singleConfirm"></ng-container>
                </ng-container>
                <!-- Confirm -->
                <ng-template #singleConfirm>
                    <button mat-flat-button [disabled]="isSpecificLoading['save']" [color]="data.actions.confirm.color"
                        (click)="onSubmit()">
                        <span *ngIf="!isSpecificLoading['save']">
                            {{data.actions.confirm.label}}
                        </span>
                        <mat-progress-spinner *ngIf="isSpecificLoading['save']" [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>
                </ng-template>

                <ng-template #multipleConfirm>
                    <button mat-flat-button [matMenuTriggerFor]="sendReportMenu" [disabled]="isSpecificLoading['save']"
                        [color]="data.actions.confirm.color" aria-label="{{data.actions.confirm.label}}">
                        <span *ngIf="!isSpecificLoading['save']">
                            {{data.actions.confirm.label}}
                        </span>
                        <mat-progress-spinner *ngIf="isSpecificLoading['save']" [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>
                    <mat-menu #sendReportMenu="matMenu">
                        <button mat-menu-item (click)="onSubmit()">
                            <mat-icon>picture_as_pdf</mat-icon>
                            <span>{{translateStrings.generic.viewReport}} PDF</span>
                        </button>
                        <button mat-menu-item (click)="onSubmit('whatsapp')">
                            <mat-icon>chat_bubble_outline</mat-icon>
                            <span>Send to Whatsapp</span>
                        </button>
                    </mat-menu>
                </ng-template>


            </div>
        </ng-container>

    </div>
</div>