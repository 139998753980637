import { NgModule } from '@angular/core';
import { LayoutComponent } from 'app/layout/layout.component';
import { ClassyLayoutModule } from 'app/layout/layouts/vertical/classy/classy.module';
import { SettingsModule } from 'app/layout/common/settings/settings.module';
import { SharedModule } from 'app/shared/shared.module';

const layoutModules = [
    // Empty
    // EmptyLayoutModule,
    //
    // // Horizontal navigation
    // CenteredLayoutModule,
    // EnterpriseLayoutModule,
    // MaterialLayoutModule,
    // ModernLayoutModule,
    //
    // // Vertical navigation
    // ClassicLayoutModule,
    ClassyLayoutModule,
    // CompactLayoutModule,
    // DenseLayoutModule,
    // FuturisticLayoutModule,
    // ThinLayoutModule
];

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports     : [
        SharedModule,
       // SettingsModule,
        ...layoutModules
    ],
    exports     : [
        LayoutComponent,
        ...layoutModules
    ]
})
export class LayoutModule
{
}
