import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { fromEvent, Observable, Subject } from 'rxjs';
import { filter, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Domain } from '../../../../../@gruuls-core/domain/domain/domain';
import { BeautyciansUtils } from '../../../utils/utils';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import { OrderStatus } from 'app/beautycians/utils/orderStatus';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { Utils } from '@gruuls-core/utils/Utils';
import { ApexOptions, ChartComponent } from 'ng-apexcharts';
import { Order, ORDERSTATUS } from '../order.types';
import { isNumber } from 'lodash';
import { GruulsAngularCartService } from '@gruuls-fe/services/gruuls-angular-cart.service';


@Component({
    selector: 'order-list',
    templateUrl: './order-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderListComponent implements OnInit, OnDestroy {
    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
    @ViewChild("ordersChart", { static: false }) ordersChart: ChartComponent;

    contacts$: Observable<any[]>;

    orderDetailPath: string = '../detail';
    isLoading: boolean = true;
    contactsCount: number = 0;
    contactsTableColumns: string[] = ['name', 'email', 'phoneNumber', 'job'];
    drawerMode: 'side' | 'over';
    searchInputControl: FormControl = new FormControl();
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    personDomain: Domain;
    isLoadingError: boolean = false;

    translateStrings: any = { order: {}, user: {}, generic: {} };

    chartOrdersAmountIssues: ApexOptions = {};
    data: any;
    
    selectedOrder: Order;
    filteredOrders: any[];
    private _orders: any[];
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);

    entities: any[];

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _authService: GruulsAuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router,
        private _translate: GruulsAngularTranslateService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _httpClient: GruulsAngularHttpProxyService,
        private _cartService: GruulsAngularCartService
    ) {
    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const MONTHS_TO_SHOW = 7;
        if (Utils.hasRole(this._authService.getCurrentLoggedUser().roles, ['Beautycians-HQ'], this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId))
            this.orderDetailPath = '../supplierdetail';

        // Get the orders
        this._apiCaller.getOrders().pipe(
            //TODO: filter last XX months
        ).subscribe({
            next: (orders) => {
                this._orders = BeautyciansUtils.sortByKeys(orders, ['date']).reverse();
                this.filteredOrders = this._orders.map((order) => ({
                    ...order,
                    dateMonth: new Date(order.date).toLocaleDateString('it-IT', { year: "numeric", month: "2-digit" }),
                }));
                // aggregate last 6 months of _orders data and push it to the chart series array
                this.data.ordersAmount.series['last-6-months'][0].data = this._orders.reduce((acc, order) => {
                    const date = new Date(order.date);
                    // Difference in months between today and order date
                    const diff = (new Date().getFullYear() - date.getFullYear()) * 12 + (new Date().getMonth() - date.getMonth());
                    if (diff >= 0 && diff < MONTHS_TO_SHOW)
                        acc[MONTHS_TO_SHOW - 1 - diff] += order.total ?? 0;
                    return acc;
                }, Array(MONTHS_TO_SHOW).fill(0));
                // TODO: improve as the last is NaN
                
                // Check Order status, order date to be in last week and sum totals
                const lastWeek = new Date();
                lastWeek.setDate(new Date().getDate() - 7);
                const lastWeekOrdersWaitingCount = this._orders.filter((order) => order.status === ORDERSTATUS.WAITING && order.date > lastWeek.getTime()).length;
                const lastWeekOrdersProcessedCount = this._orders.filter((order) => order.status === ORDERSTATUS.WAITING && order.date > lastWeek.getTime()).length;
                this.data.ordersAmount.overview['this-week']['Waiting'] = lastWeekOrdersWaitingCount;
                this.data.ordersAmount.overview['this-week']['Processed'] = lastWeekOrdersProcessedCount;

                const twoWeeksAgo = new Date();
                twoWeeksAgo.setDate(new Date().getDate() - 14);
                const twoWeeksAgoOrdersWaitingCount = this._orders.filter((order) => order.status === ORDERSTATUS.WAITING && order.date > twoWeeksAgo.getTime() && order.date < lastWeek.getTime()).length;
                const twoWeeksAgoOrdersProcessedCount = this._orders.filter((order) => order.status === ORDERSTATUS.WAITING && order.date > twoWeeksAgo.getTime() && order.date < lastWeek.getTime()).length;
                this.data.ordersAmount.overview['last-week']['Waiting'] = twoWeeksAgoOrdersWaitingCount;
                this.data.ordersAmount.overview['last-week']['Processed'] = twoWeeksAgoOrdersProcessedCount;

                // Update chart data
                this.ordersChart.updateSeries(this.data.ordersAmount.series['last-6-months']);
                this.ordersChart.updateOptions({
                    yaxis: {
                        min: 0,
                        max: Math.round((this.data.ordersAmount.series['last-6-months'][0].data.reduce((a,c) => a > c ? a : c, 0) + 1) * 1.1),
                        tickAmount: 4
                    },
                });
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                console.error(err);
                if (err.status >= 500)
                    this.isLoadingError = true;
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            }
        });

        // get last six months labels as an array 'Gen', ...
        const lastSixMonths = Array.from(Array(MONTHS_TO_SHOW).keys()).map((i) => {
            const date = new Date();
            date.setMonth(date.getMonth() - i);
            return date.toLocaleDateString('it-IT', { month: "short" });
        }).reverse();

        this.data = {
            ordersAmount: {
                overview: {
                    'this-week': {
                        'Waiting': 0,
                        'Processed': 0,
                    },
                    'last-week': {
                        'Waiting': 0,
                        'Processed': 0,
                    }
                },
                labels: lastSixMonths,
                series: {
                    'last-6-months': [
                        {
                            name: 'Ordini totali',
                            type: 'line',
                            data: []
                        },
                    ],
                    'previous-6-months': [
                        {
                            name: 'Ordini totali',
                            type: 'line',
                            data: [0, 0, 0, 0, 0, 0, 0]
                        },
                        {
                            name: 'Closed issues',
                            type: 'column',
                            data: [9, 8, 10, 12, 7, 11, 15]
                        }
                    ]
                }
            }
        }

        // Subscribe to MatDrawer opened change
        this.matDrawer.openedChange.subscribe((opened) => {
            if (!opened) {
                // Remove the selected contact when drawer closed
                // this.selectedContact = null;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            }
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Set the drawerMode if the given breakpoint is active
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                }
                else {
                    this.drawerMode = 'over';
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Listen for shortcuts
        fromEvent(this._document, 'keydown')
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter<KeyboardEvent>(event =>
                    (event.ctrlKey === true || event.metaKey) // Ctrl or Cmd
                    && (event.key === '/') // '/'
                )
            )
            .subscribe(() => {
                // this.createContact();
            });

        const orderTranslations = ['plural', 'singular', 'no', 'search', 'addNew', 'copyToNew', 'noSearchResults', 'replace'];
        orderTranslations.forEach((translation) => {
            this.translateStrings['order'][translation] = this._translate.translate('orders.' + translation);
        });

        this.translateStrings['generic']['error503'] = this._translate.translate('generic.error503');
        this._prepareChartData();
    }

    /**
 * Prepare the chart data from the data
 *
 * @private
 */
    private _prepareChartData(): void {
        // Github issues
        this.chartOrdersAmountIssues = {
            chart: {
                fontFamily: 'inherit',
                foreColor: 'inherit',
                height: '100%',
                type: 'line',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            colors: ['#64748B', '#94A3B8'],
            dataLabels: {
                enabled: true,
                formatter: (value) => {
                    // Convert value to number
                    if (isNumber(value))
                        return value.toFixed(0).toString() + ' €';
                },
                enabledOnSeries: [0],
                background: {
                    borderWidth: 0
                }
            },
            grid: {
                borderColor: 'var(--fuse-border)'
            },
            labels: this.data.ordersAmount.labels,
            legend: {
                show: false
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },
            series: this.data.ordersAmount.series,
            states: {
                hover: {
                    filter: {
                        type: 'darken',
                        value: 0.75
                    }
                }
            },
            stroke: {
                width: [3, 0],
            },
            tooltip: {
                followCursor: true,
                y: {
                    formatter: (value) => {
                        return value.toFixed(0).toString() + ' €';
                    }
                },
                // theme: 'dark'
            },
            xaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    color: 'var(--fuse-border)'
                },
                labels: {
                    style: {
                        colors: 'var(--fuse-text-secondary)'
                    }
                },
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                min: 0,
                labels: {
                    offsetX: -16,
                    style: {
                        colors: 'var(--fuse-text-secondary)'
                    }
                },
                tickAmount: 4
            }
        };

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    replaceCart(): void {
        const cart = {};
        this.selectedOrder.cart.products?.forEach((product) => {
            if (cart.hasOwnProperty(product.productId))
                cart[product.productId] += product.quantity;
            else
                cart[product.productId] = product.quantity;
        });
        this._cartService.setCart(cart);
    }
    /**
     * On backdrop clicked
     */
    onBackdropClicked(): void {
        // Go back to the list
        this._router.navigate(['./'], { relativeTo: this._activatedRoute });

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    getStatusColor(status: string = null): string {
        return OrderStatus.getStatusColor(status);
    }

    getStatusIcon(status: string): string {
        return OrderStatus.getStatusIcon(status);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
