import { FieldConfig } from '@gruuls-core/domain/domain/templates-config';
import _lodash from 'lodash';
import { CollectionType } from '../collection-type';
import { Field } from '../field';
import { FieldType } from '../field-type';
import {Fields} from '../fields';
import {FormElementsAngularUtils} from '../../../../@gruuls-fe/utils/form-elements-angular-utils';

const _ = _lodash;


export class EnumField extends Field {

    public constructor(
        name: string,
        subFields: Field[],
        type: FieldType,
        collectionType: CollectionType,
        fieldConfig: FieldConfig,
        modelType?: string,
        public enumValues?: string[]
    ) {
        super(name, subFields, undefined, type, collectionType, fieldConfig, modelType);
    }

    public static ofConfig(config: {
                               name: string;
                               subFields?: Field[];
                               type?: FieldType;
        collectionType?: CollectionType;
                               enumValues?: string[];
                               modelType?: string;
                               fieldConfig?: FieldConfig;
                           }
    ): Field {
        Fields.counter = Fields.counter++;
        config = _.defaultsDeep(
            {
                type: FieldType.enum
            },
            config,
            {
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.simpleFormControlElement
                }
            });

        return new EnumField(config.name, config.subFields, config.type, config.collectionType, config.fieldConfig, config.modelType, config.enumValues);
    }

}
