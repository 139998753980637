import {BaseElementConfig, BaseElementConfigInterface} from '../base-element/base-element-config';
import {BaseElement} from '../base-element/base-element';

export interface GroupElementConfigInterface extends BaseElementConfigInterface{
    elements?: BaseElementConfigInterface[];
}

export class GroupElementConfig extends BaseElementConfig implements GroupElementConfigInterface{
    static category: string = 'Misc';
    static type: string = 'GroupElement';

    _elements: BaseElementConfigInterface[];
    // set elements(newValue: BaseElementConfigInterface[]){
    //     this.internalSetValue('elements', newValue);
    // }
    get elements(): BaseElementConfigInterface[]{
        return this._elements;
    }


    constructor(config: GroupElementConfigInterface, element: BaseElement) {
        super(config, element);

        config.elements = config.elements || [];
        this._elements = config.elements;

        config.category = GroupElementConfig.category;
        config.type = GroupElementConfig.type;
        this.type = config.type;
        this.category = config.category;
    }

}
