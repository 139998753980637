import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {Router} from '@angular/router';
import {forkJoin, of, Subject, zip} from 'rxjs';
import {first, map, mergeMap, repeat, takeUntil, tap} from 'rxjs/operators';
import {ApexOptions} from 'ng-apexcharts';
import {ProjectService} from './project.service';
import {BaseElement} from '../../../../../@gruuls-core/elements/misc/base-element/base-element';
import {FormatNumberElement} from '../../../../../@gruuls-core/elements/formatters/format-number-element/format-number-element';
import {
    FormatNumberElementConfig,
    TemplateFormatPreset
} from '../../../../../@gruuls-core/elements/formatters/format-number-element/format-number-element-config';
import {ContextElement} from '../../../../../@gruuls-core/elements/misc/context-element/context-element';
import {GruulsAngularNumberFormatterService} from '../../../../../@gruuls-fe/services/gruuls-angular-number-formatter.service';
import {ChangeDetection} from '@angular/cli/lib/config/workspace-schema';
import {UserFunctionInput} from '../../../../../@gruuls-core/elements/logic/user-function-element';
import {BaseElementConfigInterface} from '../../../../../@gruuls-core/elements/misc/base-element/base-element-config';

@Component({
    selector       : 'project',
    templateUrl    : './project.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectComponent implements OnInit, OnDestroy
{
    chartGithubIssues: ApexOptions = {};
    chartTaskDistribution: ApexOptions = {};
    chartBudgetDistribution: ApexOptions = {};
    chartWeeklyExpenses: ApexOptions = {};
    chartMonthlyExpenses: ApexOptions = {};
    chartYearlyExpenses: ApexOptions = {};
    data: any;
    selectedProject: string = 'ACME Corp. Backend App';
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    element1_10: BaseElement;
    element2_10: BaseElement;
    element3_10: BaseElement;
    element4_10: BaseElement;
    element5_10: BaseElement;
    element6_10: BaseElement;

    context20: ContextElement;
    //
    // element1_20: BaseElement;
    // element2_20: BaseElement;
    //
    // element1_30: BaseElement;
    // element2_30: BaseElement;
    //
    // numberFormat30: FormatNumberElement;
    /**
     * Constructor
     */
    constructor(
        private _projectService: ProjectService,
        private _router: Router,
        private cdr: ChangeDetectorRef,
        private numberFromatterService: GruulsAngularNumberFormatterService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get the data
        this._projectService.data$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                // Store the data
                this.data = data;
                // Prepare the chart data
                this._prepareChartData();
            });

        // Attach SVG fill fixer to all ApexCharts
        window['Apex'] = {
            chart: {
                events: {
                    mounted: (chart: any, options?: any): void => {
                        this._fixSvgFill(chart.el);
                    },
                    updated: (chart: any, options?: any): void => {
                        this._fixSvgFill(chart.el);
                    }
                }
            }
        };



        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////// EXAMPLE 10
        //////// Create a series of elements that do somethinf and pipe everything together
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////

        // STEP1: Create a Context:
        // context is used into functions and provides services to elements
        const context10: ContextElement = new ContextElement({}, undefined, this.numberFromatterService, undefined, undefined, undefined);

        // STEP2: Create the first element
        // this element just takes the input stream, saves it into elements' value, then puts it into output stream
        this.element1_10 = new BaseElement({
            name: 'element1_10', // Name is optional, if not provided, an UUID will be placed
        });

        // STEP3: Create the second element
        // this element takes the value from input stream and formats it (it is supposed to receive a number)
        this.element2_10 = new BaseElement({
            name: 'element2_10',
            chainElementConfigs: [
                {
                    type: 'function',
                    // function is the body of the function, the surrounding pieces are like this
                    // myAnonymousFunction(context: SandboxedContext, thisElement, msg): (string | number | any | StreamChannelMessage) | (Observable<string | number | any | StreamChannelMessage>) {
                    value: 'return ({value:context.numberFormatterService.format(msg.value, \'1.2-2\', undefined)});'
                }
            ],
        });

        // STEP4: Create the third element
        // this element rounds the incoming value
        this.element3_10 = new BaseElement({
            name: 'element3_10',
            chainElementConfigs: [{
                type: 'function',
                // you can return a StreamChannelMessage or a simple value, in this case value and formatted value will take this
                value: 'return Math.round(msg.value);'
            }]
        });

        // STEP5: Create the fourth element
        // this element adds 1 to the incoming value
        this.element4_10 = new BaseElement({
            name: 'element4_10',
            // chainElementConfigs can be a simple sting, in such case you can write an inline expresion or just a simple value
            chainElementConfigs: ['${msg.value + 1}']
        });

        // STEP6: Create the fifth element
        this.element5_10 = new BaseElement({
            name: 'element5_10',
            chainElementConfigs: [ // chainElementConfigs can also be an array of (string | number | any | UserFunctionInput)[]
                {
                    order: 100, // all the "chainElementConfigs" will be ordered and executed.
                    //value can be a simple value. this will output always the same number
                    value: 2
                } as UserFunctionInput,
                {
                    order: 90, // this will be executed first
                    value: '${Math.floor(Math.random() * (10 - 1) + 1)}'
                } as UserFunctionInput
            ]
        });

        // STEP7: Create the sixth element
        this.element6_10 = new BaseElement({
            name: 'element6_10',
            chainElementConfigs: [
                {
                    order: 90,
                    value: 2
                } as UserFunctionInput,
                {
                    order: 100,
                    value: '${Math.floor(Math.random() * (10 - 1) + 1)}'
                } as UserFunctionInput
            ]
        });

        // FINAL STEP: Initialize all the elements, pipe the elements and start
        forkJoin([
            context10.init(),
            this.element1_10.init(), this.element2_10.init(context10),
            this.element3_10.init(context10), this.element4_10.init(context10),
            this.element5_10.init(context10), this.element6_10.init(context10)
        ])
            .pipe(
                mergeMap( prev => of(prev)
                    .pipe(
                        repeat({delay: 10000}),
                        mergeMap( prev1 => of(prev1).pipe(repeat({delay: Math.random() * (5000 - 2000) + 2000})))
                    )
                ),
                map(prev => ({value: this.getRandomNumber()})),
                mergeMap((prevValue => this.element1_10.processStream(prevValue))), // you can use .precessStream()
                this.element2_10.processOperator(), // or use the rx operator directly
                this.element3_10.processOperator(),
                mergeMap((prevValue => this.element4_10.processStream(prevValue))),
                this.element5_10.processOperator(),
                this.element6_10.processOperator(),
            )
            .subscribe({
                next: () => {
                    this.cdr.markForCheck();
                }
            });


        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////// EXAMPLE 20
        //////// similar to previous example, but embed everything into the context
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////

        // STEP2: Create the first element
        // no matter what arrives as input, just output a random value between: bg-indigo, bg-red....
        const element1_20: BaseElementConfigInterface = {
            name: 'element1_20',
            chainElementConfigs: ['${ ([\'bg-indigo\', \'bg-green\', \'bg-red\', \'bg-blue\'])[Math.floor(Math.random() * (3-0) + 0)] }']
        };

        // STEP3: Create the second element
        // takes the value from previous step (see: inStreamElementIds param to explicit this)
        // and create a complex value made of "color" (from prev step) e level (calculated here)
        // the outgoing value will be like {color: color, level:level}
        // moreover, it will be changed also the background color of element4_20
        //
        // In previous example piping has been made manually (see lines 200-205 )
        // here since all the elements will be "pushed" into the context, we have to explicitly create the pipe
        // which element is attached to the other? Well, you define it by placing values on inStreamElementIds
        // so this element will attach to the output Stream of all the elements defined in this array
        const element2_20: BaseElementConfigInterface = {
            name: 'element2_20',
            inStreamElementIds: ['element1_20'],
            chainElementConfigs: [{
                type: 'function',
                value:
                    ' const level = ([50, 100, 200, 300, 400])[Math.floor(Math.random() * (4-0) + 0)]; ' +
                    ' const color = msg.value; ' +
                    ' const colorAndLevel = color + \'-\' + level; ' +
                    ' context.elements.element4_20.config.backgroundColor = colorAndLevel; ' +
                    ' return ({value: {color: color, level: level}, formattedValue: level});'
            }]
        };

        // this element is "hidden", that is, does not shows on view (watch the view, there is no binding with it)
        // its duty is to concatenate color and level in order to have a proper class name for background colouring
        const element2hidden_20: BaseElementConfigInterface = {
            name: 'element2hidden_20',
            inStreamElementIds: ['element2_20'],
            chainElementConfigs: [{
                type: 'function',
                value: 'return msg.value.color + \'-\' + msg.value.level'
            }]
        };

        // this elements changes its own background color with incoming value and puts its value to a random number
        const element3_20: BaseElementConfigInterface = {
            name: 'element3_20',
            inStreamElementIds: ['element2hidden_20'],
            chainElementConfigs: [{
                type: 'function',
                value: 'thisElement.config.backgroundColor = msg.value; return Math.floor(Math.random() * (5-0) + 0);'
            }]
        };

        // it just creates a random number,
        // please note that this element background is changed by element2_20
        const element4_20: BaseElementConfigInterface = {
            name: 'element4_20',
            chainElementConfigs: [{
                type: 'function',
                value: 'return Math.floor(Math.random() * (5-0) + 0);'
            }]
        };

        // Create a Context
        // place all the elements into the context (from element1_20 to element4_20)
        // in this case you have only to init this element, all the inner elements will
        // follow this container's lifecycle
        this.context20 = new ContextElement({
            name: 'context20',
            elements: [element1_20,element2_20, element2hidden_20, element3_20, element4_20],
        }, undefined, this.numberFromatterService, undefined, undefined);


        // FINAL STEP: Initialize all the elements, pipe the elements and start
        forkJoin([
            this.context20.init()
        ])
            .pipe(
                mergeMap( prev => of(prev)
                    .pipe(
                        repeat({delay: 30000}),
                        mergeMap( prev1 => of(prev1).pipe(repeat({delay: Math.random() * (10000 - 5000) + 5000})))
                    )
                ),
                map(prev => ({value: this.getRandomNumber()})),
                this.context20.processOperator() // the context receives the incoming value, this value will be pushed internally to all the others elements
            )
            .subscribe({
                next: () => {
                    this.cdr.markForCheck();
                }
            });



        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // //////// EXAMPLE 30
        // //////// Create an element that receives random number and pipe with another element that formats the number
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //
        // // STEP2: Create the first element
        // const element1_20: BaseElementConfigInterface = {
        //     name: 'element1_20',
        //     chainElementConfigs: ['${ ([\'bg-indigo\', \'bg-green\', \'bg-red\', \'bg-blue\'])[Math.floor(Math.random() * (3-0) + 0)] }']
        // };
        //
        // // STEP3: Create the second element
        // const element2_20: BaseElementConfigInterface = {
        //     name: 'element2_20',
        //     inStreamElementIds: ['element1_20'],
        //     chainElementConfigs: [{
        //         type: 'function',
        //         value: 'return msg.value + \'-\' + ([50, 100, 200, 300, 400])[Math.floor(Math.random() * (4-0) + 0)];'
        //     }]
        // };
        //
        // const element3_20: BaseElementConfigInterface = {
        //     name: 'element3_20',
        //     inStreamElementIds: ['element2_20'],
        //     chainElementConfigs: [{
        //         type: 'function',
        //         value: 'thisElement.config.backgroundColor = msg.value; return Math.floor(Math.random() * (5-0) + 0);'
        //     }]
        // };
        //
        // // STEP1: Create a Context:
        // this.context20 = new ContextElement({
        //     name: 'context20',
        //     elements: [element1_20,element2_20, element3_20],
        // }, undefined, this.numberFromatterService, undefined, undefined);
        //
        //
        // // FINAL STEP: Initialize all the elements, pipe the elements and start
        // forkJoin([
        //     this.context20.init()
        // ])
        //     .pipe(
        //         mergeMap( prev => of(prev)
        //             .pipe(
        //                 repeat({delay: 30000}),
        //                 mergeMap( prev1 => of(prev1).pipe(repeat({delay: Math.random() * (10000 - 5000) + 5000})))
        //             )
        //         ),
        //         map(prev => ({value: this.getRandomNumber()})),
        //         this.context20.processOperator()
        //     )
        //     .subscribe({
        //         next: () => {
        //             this.cdr.markForCheck();
        //         }
        //     });

        //
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // //////// EXAMPLE 20
        // //////// Create an element that receives random number and pipe with another element that formats the number
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //
        // // STEP1: Create a Context:
        // const context20: ContextElement = new ContextElement({}, undefined, this.numberFromatterService, undefined, undefined);
        //
        // // STEP2: Create the first element
        // this.element1_20 = new BaseElement({
        //     //logging: true
        // });
        //
        // // STEP3: Create the second element
        // this.element2_20 = new BaseElement({
        //     chainElementConfigs: ['${context.numberFormatterService.format(msg.value, \'1.2-2\', undefined)}']
        // });
        //
        // // FINAL STEP: Initialize all the elements, pipe the elements and start
        // zip(context20.init(), this.element1_20.init(), this.element2_20.init(context20))
        //     .pipe(
        //         repeat({delay: 1000}),
        //         mergeMap(foo => this.element1_20.processStream({value: this.getRandomNumber()})),
        //         mergeMap((prevValue => this.element2_20.processStream(prevValue)))
        //     )
        //     .subscribe({
        //         next: () => {
        //             this.cdr.markForCheck();
        //         }
        //     });
        //
        //
        //
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // //////// EXAMPLE 3'
        // //////// Create an element that receives random number and pipe with another element that formats the number
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //
        // // STEP1: Create a Context:
        // const context30: ContextElement = new ContextElement({}, undefined, this.numberFromatterService, undefined, undefined);
        //
        // // STEP2: Create the first element
        // this.element1_30 = new BaseElement({
        //     //logging: true
        // });
        //
        // // STEP3: Create the second element
        // this.element2_30 = new BaseElement({
        //     chainElementConfigs: ['${context.numberFormatterService.format(msg.value, \'1.2-2\', undefined)}']
        // });
        //
        // // FINAL STEP: Initialize all the elements, pipe the elements and start
        // zip(context30.init(), this.element1_30.init(), this.element2_30.init(context20))
        //     .pipe(
        //         repeat({delay: 1000}),
        //         mergeMap(foo => this.element1_30.processStream({value: this.getRandomNumber()})),
        //         mergeMap((prevValue => this.element2_30.processStream(prevValue)))
        //     )
        //     .subscribe({
        //         next: () => {
        //             this.cdr.markForCheck();
        //         }
        //     });

        //
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // //////// EXAMPLE 40
        // //////// Create an element that receives random number and pipe with another element that formats the number
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //
        // // STEP1: Create a Context:
        // const context20: ContextElement = new ContextElement({}, undefined, this.numberFromatterService, undefined, undefined);
        //
        // // STEP2: Create the first element
        // this.element1_30 = new BaseElement({
        //     //logging: true
        // });
        //
        // // STEP3: Create the second element
        // this.element2_30 = new FormatNumberElement({
        //     templateFormatPreset: TemplateFormatPreset.custom,
        //     formatterTemplate: '1.2-2',
        // });
        //
        // // FINAL STEP: Initialize all the elements, pipe the elements and start
        // zip(context20.init(), this.element1_30.init(), this.element2_30.init(context20))
        //     .pipe(
        //         repeat({delay: 1000}),
        //         mergeMap(foo => this.element1_30.processStream({value: this.getRandomNumber()})),
        //         mergeMap((prevValue => this.element2_30.processStream(prevValue)))
        //     )
        //     .subscribe({
        //         next: () => {
        //             this.cdr.markForCheck();
        //         }
        //     });
        //
        //
        //
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // //////// EXAMPLE 30
        // //////// Do exactly as example 20 but with only one element
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // // STEP1: Create a Context:
        // const context30: ContextElement = new ContextElement({}, undefined, this.numberFromatterService, undefined, undefined);
        //
        // // STEP3: Create the second element
        // this.numberFormat30 = new FormatNumberElement({
        //     templateFormatPreset: TemplateFormatPreset.custom,
        //     formatterTemplate: '1.2-2',
        // });
        //
        // // FINAL STEP: Initialize all the elements, pipe the elements and start
        // zip(context30.init(), this.numberFormat30.init(context30))
        //     .pipe(
        //         repeat({delay: 1000}),
        //         mergeMap((prevValue => this.numberFormat30.processStream({value: this.getRandomNumber()})))
        //     )
        //     .subscribe({
        //         next: () => {
        //             this.cdr.markForCheck();
        //         }
        //     });


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Fix the SVG fill references. This fix must be applied to all ApexCharts
     * charts in order to fix 'black color on gradient fills on certain browsers'
     * issue caused by the '<base>' tag.
     *
     * Fix based on https://gist.github.com/Kamshak/c84cdc175209d1a30f711abd6a81d472
     *
     * @param element
     * @private
     */
    private _fixSvgFill(element: Element): void
    {
        // Current URL
        const currentURL = this._router.url;

        // 1. Find all elements with 'fill' attribute within the element
        // 2. Filter out the ones that doesn't have cross reference so we only left with the ones that use the 'url(#id)' syntax
        // 3. Insert the 'currentURL' at the front of the 'fill' attribute value
        Array.from(element.querySelectorAll('*[fill]'))
             .filter(el => el.getAttribute('fill').indexOf('url(') !== -1)
             .forEach((el) => {
                 const attrVal = el.getAttribute('fill');
                 el.setAttribute('fill', `url(${currentURL}${attrVal.slice(attrVal.indexOf('#'))}`);
             });
    }

    /**
     * Prepare the chart data from the data
     *
     * @private
     */
    private _prepareChartData(): void
    {
        // Github issues
        this.chartGithubIssues = {
            chart      : {
                fontFamily: 'inherit',
                foreColor : 'inherit',
                height    : '100%',
                type      : 'line',
                toolbar   : {
                    show: false
                },
                zoom      : {
                    enabled: false
                }
            },
            colors     : ['#64748B', '#94A3B8'],
            dataLabels : {
                enabled        : true,
                enabledOnSeries: [0],
                background     : {
                    borderWidth: 0
                }
            },
            grid       : {
                borderColor: 'var(--fuse-border)'
            },
            labels     : this.data.githubIssues.labels,
            legend     : {
                show: false
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },
            series     : this.data.githubIssues.series,
            states     : {
                hover: {
                    filter: {
                        type : 'darken',
                        value: 0.75
                    }
                }
            },
            stroke     : {
                width: [3, 0]
            },
            tooltip    : {
                followCursor: true,
                theme       : 'dark'
            },
            xaxis      : {
                axisBorder: {
                    show: false
                },
                axisTicks : {
                    color: 'var(--fuse-border)'
                },
                labels    : {
                    style: {
                        colors: 'var(--fuse-text-secondary)'
                    }
                },
                tooltip   : {
                    enabled: false
                }
            },
            yaxis      : {
                labels: {
                    offsetX: -16,
                    style  : {
                        colors: 'var(--fuse-text-secondary)'
                    }
                }
            }
        };

        // Task distribution
        this.chartTaskDistribution = {
            chart      : {
                fontFamily: 'inherit',
                foreColor : 'inherit',
                height    : '100%',
                type      : 'polarArea',
                toolbar   : {
                    show: false
                },
                zoom      : {
                    enabled: false
                }
            },
            labels     : this.data.taskDistribution.labels,
            legend     : {
                position: 'bottom'
            },
            plotOptions: {
                polarArea: {
                    spokes: {
                        connectorColors: 'var(--fuse-border)'
                    },
                    rings : {
                        strokeColor: 'var(--fuse-border)'
                    }
                }
            },
            series     : this.data.taskDistribution.series,
            states     : {
                hover: {
                    filter: {
                        type : 'darken',
                        value: 0.75
                    }
                }
            },
            stroke     : {
                width: 2
            },
            theme      : {
                monochrome: {
                    enabled       : true,
                    color         : '#93C5FD',
                    shadeIntensity: 0.75,
                    shadeTo       : 'dark'
                }
            },
            tooltip    : {
                followCursor: true,
                theme       : 'dark'
            },
            yaxis      : {
                labels: {
                    style: {
                        colors: 'var(--fuse-text-secondary)'
                    }
                }
            }
        };

        // Budget distribution
        this.chartBudgetDistribution = {
            chart      : {
                fontFamily: 'inherit',
                foreColor : 'inherit',
                height    : '100%',
                type      : 'radar',
                sparkline : {
                    enabled: true
                }
            },
            colors     : ['#818CF8'],
            dataLabels : {
                enabled   : true,
                formatter : (val: number): string | number => `${val}%`,
                textAnchor: 'start',
                style     : {
                    fontSize  : '13px',
                    fontWeight: 500
                },
                background: {
                    borderWidth: 0,
                    padding    : 4
                },
                offsetY   : -15
            },
            markers    : {
                strokeColors: '#818CF8',
                strokeWidth : 4
            },
            plotOptions: {
                radar: {
                    polygons: {
                        strokeColors   : 'var(--fuse-border)',
                        connectorColors: 'var(--fuse-border)'
                    }
                }
            },
            series     : this.data.budgetDistribution.series,
            stroke     : {
                width: 2
            },
            tooltip    : {
                theme: 'dark',
                y    : {
                    formatter: (val: number): string => `${val}%`
                }
            },
            xaxis      : {
                labels    : {
                    show : true,
                    style: {
                        fontSize  : '12px',
                        fontWeight: '500'
                    }
                },
                categories: this.data.budgetDistribution.categories
            },
            yaxis      : {
                max       : (max: number): number => parseInt((max + 10).toFixed(0), 10),
                tickAmount: 7
            }
        };

        // Weekly expenses
        this.chartWeeklyExpenses = {
            chart  : {
                animations: {
                    enabled: false
                },
                fontFamily: 'inherit',
                foreColor : 'inherit',
                height    : '100%',
                type      : 'line',
                sparkline : {
                    enabled: true
                }
            },
            colors : ['#22D3EE'],
            series : this.data.weeklyExpenses.series,
            stroke : {
                curve: 'smooth'
            },
            tooltip: {
                theme: 'dark'
            },
            xaxis  : {
                type      : 'category',
                categories: this.data.weeklyExpenses.labels
            },
            yaxis  : {
                labels: {
                    formatter: (val): any => `$${val}`
                }
            }
        };

        // Monthly expenses
        this.chartMonthlyExpenses = {
            chart  : {
                animations: {
                    enabled: false
                },
                fontFamily: 'inherit',
                foreColor : 'inherit',
                height    : '100%',
                type      : 'line',
                sparkline : {
                    enabled: true
                }
            },
            colors : ['#4ADE80'],
            series : this.data.monthlyExpenses.series,
            stroke : {
                curve: 'smooth'
            },
            tooltip: {
                theme: 'dark'
            },
            xaxis  : {
                type      : 'category',
                categories: this.data.monthlyExpenses.labels
            },
            yaxis  : {
                labels: {
                    formatter: (val): any => `$${val}`
                }
            }
        };

        // Yearly expenses
        this.chartYearlyExpenses = {
            chart  : {
                animations: {
                    enabled: false
                },
                fontFamily: 'inherit',
                foreColor : 'inherit',
                height    : '100%',
                type      : 'line',
                sparkline : {
                    enabled: true
                }
            },
            colors : ['#FB7185'],
            series : this.data.yearlyExpenses.series,
            stroke : {
                curve: 'smooth'
            },
            tooltip: {
                theme: 'dark'
            },
            xaxis  : {
                type      : 'category',
                categories: this.data.yearlyExpenses.labels
            },
            yaxis  : {
                labels: {
                    formatter: (val): any => `$${val}`
                }
            }
        };
    }

    private getRandomNumber(): number {
        return this.numberFromatterService.format(Math.random() , '1.0-4', undefined);
    }
}
