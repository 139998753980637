import {GruulsMockDomain, GruulsMockHttpRequest} from '../../gruuls-mock-domain';
import {HttpClient} from '@angular/common/http';

export class BeautyPathMock extends GruulsMockDomain {

    constructor(httpClient: HttpClient) {
        super(httpClient);

        this.entities = this.GET_BEAUTY_PATH;
    }

    GET_BEAUTY_PATH = 
    [
        {
            _creationTime: 1693417905000,
            id: 1,
            name: 'Avanzato',
            sessions: [
            {
                threatmentId: 'asdf-asdf-asdf-001',
                appointment: {
                    date: 1693417905000,
                    duration: 60,
                },
                note: 'Primo appuntamento dove effettuare la pulizia del viso e la prima seduta di trattamento idratante approfondito'
            },
            {
                threatmentId: 'asdf-asdf-asdf-002',
            },
        ]},
        { _creationTime: 1693417905000, id: 2, name: 'Iniziale', sessions: [ ] },
    ];
}
