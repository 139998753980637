import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputElementComponent} from './input-element.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        InputElementComponent
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule
    ],
    exports: [
        InputElementComponent
    ],
    schemas: []
})

export class InputElementModule {

}

