import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit, TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {GruulsAngularHttpProxyService} from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import {GruulsAngularTranslateService} from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import {MatDialog} from '@angular/material/dialog';
import {GruulsAuthService} from "../../../../../@gruuls-fe/services/gruuls-auth.service";
import { ApiCaller } from 'app/beautycians/utils/apiCaller';

@Component({
  selector: 'appointment',
  templateUrl: './appointment.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppointmentComponent {
  events: any[] = [];
  isLoading: boolean = false;
  translateStrings: any = {};
  apiCaller: ApiCaller;

  /**
   * Constructor
   */
  constructor(
      private _activatedRoute: ActivatedRoute,
      private _changeDetectorRef: ChangeDetectorRef,
      @Inject(DOCUMENT) private _document: any,
      private _router: Router,
      private _fuseMediaWatcherService: FuseMediaWatcherService,
      private _httpClient: GruulsAngularHttpProxyService,
      private _translate: GruulsAngularTranslateService,
      private dialog: MatDialog,
      private _authService: GruulsAuthService
  ) {
  }

}