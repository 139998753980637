import { AttachedRole } from "app/beautycians/utils/dataTypes";
import _ from "lodash";

export interface ComplexFilters {
    field: string;
    value: any;
    operator: string;
    affirmative: boolean;
}

export class Utils {

    public static guid() {
        let underscore = "_";
        let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        };
        return s4() + s4() + underscore + s4() + underscore + s4() + underscore + s4() + underscore + s4() + s4() + s4();
    }

    // public static domainFromUrl(url): string {
    //     let result;
    //     const match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
    //     if (match) {
    //         result = match[1];
    //         if (match === result.match(/^[^\.]+\.(.+\..+)$/)) {
    //             result = match[1];
    //         }
    //     }
    //     return result;
    // }

    public static exactMatchExpression(key: string, value: string): any {
        // return {
        //     [key]: value,
        // }
        return {
            bool: {
                must:
                {
                    match_phrase: {
                        [key]: {
                            query: value,
                        },
                    },
                },

            },
        };
    }

    public static notExactMatchExpression(key: string, value: string): any {
        // return {
        //     [key]: value,
        // }
        return {
            bool: {
                must_not:
                {
                    match_phrase: {
                        [key]: {
                            query: value,
                        },
                    },
                },

            },
        };
    }

    public static complexExpression(condition: 'must' | 'must_not', key: string, value: any, operator: string): any {
        if (operator === 'match_phrase') {
            return {
                bool: {
                    [condition]: {
                        match_phrase: {
                            [key]: {
                                query: value,
                            },
                        },
                    },
                },
            };
        }
        if (['gt', 'gte', 'lt', 'lte'].includes(operator)) {
            return {
                bool: {
                    [condition]: {
                        range: {
                            [key]: {
                                [operator]: value
                            }
                        }
                    }
                }
            }
        }
        return {
            bool: {
                [condition]: {
                    match: {
                        [key]: value
                    }
                }
            }
        }
    }

    /**
     * Builds a where expression based on the provided filters.
     * @param filters - The filters to build the where expression from. (e.g. { field: 'date', value: 123456789, operator: 'gt', affirmative: true })
     * @returns The built where expression.
     */
    public static buildWhereExpression(filters: ComplexFilters[]): any {
        let where: any = {};
        filters.forEach((filter) => {
            const must = filter['affirmative'] === false ? 'must_not' : 'must';
            const condition = this.complexExpression(must, filter['field'], filter['value'], filter['operator']);
            where = _.merge(where, condition);
            // if (typeof filter === 'object') {
            // } else {
            //     //where[key.toString()] = value;
            //     const condition = this.exactMatchExpression(filter['key'], filter['value']?.toString());
            //     where = _.merge(where, condition);
            // }
        });
        return where;
    }

    public static hasRole(roles: AttachedRole[], roleTemplateNames: string[] | null, inOrganization: string | null = null): boolean {
        if (!roles)
            return false;
        else if ((!roleTemplateNames || roleTemplateNames.length == 0) && inOrganization)
            return (roles.find(role => role.inOrganization?.organizationId === inOrganization) != undefined)
        else if (roleTemplateNames && !inOrganization)
            return (roles.find(role => role.hasRole?.filter(hasRole => hasRole?.name == roleTemplateNames).length > 0) != undefined)
        else
            return (roles.find(role => {
                return role.inOrganization?.organizationId === inOrganization && role?.hasRole.filter(hasRole => hasRole.name == roleTemplateNames).length > 0
            }) != undefined)
    }

    public static hasUniqueRole(roles: any[], name: string): boolean {
        if (!roles)
            return false;
        else
            return (roles.find(role => role.hasRole.filter(hasRole => hasRole.name == name).length > 0) != undefined) && roles.length == 1;
    }

    public static getPermissions(roles: any[], organizationId: string, name: string): string[] {
        const rolesOnOrganization = roles.find(role => role.inOrganization.organizationId == organizationId);
        if (rolesOnOrganization) {
            // TODO: probably introduce into a service
        }
        return [];
    }

    public static exactQLMatchExpression(key: string, value: string): any {
        return {
            bool: {
                must: [
                    {
                        match_phrase: {
                            [key]: {
                                query: value,
                            },
                        },
                    },
                ],
            },
        };
    }

    public static exactQLNotMatchExpression(key: string, value: string): any {
        return {
            bool: {
                must_not: [
                    {
                        match_phrase: {
                            [key]: {
                                query: value,
                            },
                        },
                    },
                ],
            },
        };
    }

    public static isValidString(value: any): boolean {
        return typeof value === 'string' && value.trim().length > 0;
    }


}