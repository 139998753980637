import {
    DropListOrientation
} from '@angular/cdk/drag-drop';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, HostBinding, HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {DynamicFlexContainerElement} from '@gruuls-core/elements/ui-builders/dynamic-flex-container-element/dynamic-flex-container-element';
import * as lodash_ from 'lodash';
import {mergeMap, mergeWith, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {GroupElement} from '../../../../@gruuls-core/elements/misc/group-element/group-element';
import {GruulsBaseElementComponent} from '../../misc/base-element/gruuls-base-element.component';
const _ = lodash_;

@Component({
    selector: '[gruuls-container-flex-ui-builder]',
    templateUrl: './gruuls-container-flex-ui-builder.component.html',
    styleUrls: ['./gruuls-container-flex-ui-builder.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class GruulsContainerFlexUiBuilderComponent implements OnInit, OnDestroy {

    @Input() element: DynamicFlexContainerElement;
    @Input() set allConnectedIds(ids: string[]){
        this.allIds = ids;
    };

    get allConnectedIds(): string[]{
        this.allIds = this.allIds || [];
        return _.uniq(this.allIds.concat(...this.element.getAllRuntimeIdsRecursive()));
        // return this.allIds;
    }

    protected _unsubscribeAll: Subject<any> = new Subject<any>();

    allIds: string[];
    dropListOrientation: DropListOrientation = 'vertical';
    enableDragDrop: boolean;

    constructor(
        protected cdr: ChangeDetectorRef
    ) {
    }

    @HostBinding('class') get classList(): any {

        const properties: any = {};
        GruulsBaseElementComponent.propertiesList.map((p) => {
            const containerPropName = 'container' + p.charAt(0).toUpperCase() + p.slice(1);
            if (this.element.config[containerPropName] !== undefined && this.element.config[p] !== undefined){
                properties[p] = this.element.config[p];
            }
        });

        const classes = {
            'flex': !properties.disableFlex,
            'flex-auto': (properties.itemFillSpace === 'auto' || properties.itemFillSpace === undefined) && !properties.disableFlex,
            [`flex-${properties.itemFillSpace}`]: properties.itemFillSpace && !properties.disableFlex,
            [`flex-${properties.flexWrap}`]: properties.flexWrap && !properties.disableFlex,
            [`basis-${properties.flexBasis}`]: properties.flexBasis && !properties.disableFlex,
            [`order-${properties.flexOrder}`]: properties.flexOrder && !properties.disableFlex,

            // justify --> horizontal alignment of all children elements
            'justify-center': properties.itemsDisposition === 'middle-middle' || properties.itemsDisposition === 'top-middle' || properties.itemsDisposition === 'bottom-middle',
            'justify-start': properties.itemsDisposition === 'top-left' || properties.itemsDisposition === 'middle-left' || properties.itemsDisposition === 'bottom-left',
            'justify-end': properties.itemsDisposition === 'top-right' || properties.itemsDisposition === 'middle-right' || properties.itemsDisposition === 'bottom-right',
            // items --> vertical alignment of all children elements
            'items-center': properties.itemsDisposition === 'middle-middle' || properties.itemsDisposition === 'middle-left' || properties.itemsDisposition === 'middle-right',
            'items-start': properties.itemsDisposition === 'top-left' || properties.itemsDisposition === 'top-middle' || properties.itemsDisposition === 'top-right',
            'items-end': properties.itemsDisposition === 'bottom-left' || properties.itemsDisposition === 'bottom-middle' || properties.itemsDisposition === 'bottom-right',

            // justify --> horizontal alignment of this elements
            'justify-self-center': properties.itemDisposition === 'middle-middle' || properties.itemDisposition === 'top-middle' || properties.itemDisposition === 'bottom-middle' || properties.itemDisposition === 'stretch-middle' || properties.itemDisposition === 'auto-middle' || properties.itemDisposition === 'baseline-middle',
            'justify-self-start': properties.itemDisposition === 'top-left' || properties.itemDisposition === 'middle-left' || properties.itemDisposition === 'bottom-left'  || properties.itemDisposition === 'stretch-left' || properties.itemDisposition === 'auto-left' || properties.itemDisposition === 'baseline-left',
            'justify-self-end': properties.itemDisposition === 'top-right' || properties.itemDisposition === 'middle-right' || properties.itemDisposition === 'bottom-right' || properties.itemDisposition === 'stretch-right' || properties.itemDisposition === 'auto-right' || properties.itemDisposition === 'baseline-right',
            'justify-self-stretch': properties.itemDisposition === 'top-stretch' || properties.itemDisposition === 'middle-stretch' || properties.itemDisposition === 'bottom-stretch' || properties.itemDisposition === 'stretch-stretch' || properties.itemDisposition === 'auto-stretch' || properties.itemDisposition === 'baseline-stretch',
            'justify-self-auto': properties.itemDisposition === 'top-auto' || properties.itemDisposition === 'middle-auto' || properties.itemDisposition === 'bottom-auto' || properties.itemDisposition === 'stretch-auto' || properties.itemDisposition === 'auto-auto' || properties.itemDisposition === 'baseline-auto',
            // items --> vertical alignment of this element
            'self-center': properties.itemDisposition === 'middle-middle' || properties.itemDisposition === 'middle-left' || properties.itemDisposition === 'middle-right'  || properties.itemDisposition === 'middle-stretch'  || properties.itemDisposition === 'middle-auto' ,
            'self-start': properties.itemDisposition === 'top-left' || properties.itemDisposition === 'top-middle' || properties.itemDisposition === 'top-right'  || properties.itemDisposition === 'top-stretch'  || properties.itemDisposition === 'top-auto' ,
            'self-end': properties.itemDisposition === 'bottom-left' || properties.itemDisposition === 'bottom-middle' || properties.itemDisposition === 'bottom-right'  || properties.itemDisposition === 'bottom-stretch'  || properties.itemDisposition === 'bottom-auto' ,
            'self-stretch': properties.itemDisposition === 'stretch-left' || properties.itemDisposition === 'stretch-middle' || properties.itemDisposition === 'stretch-right'  || properties.itemDisposition === 'stretch-stretch'  || properties.itemDisposition === 'stretch-auto' ,
            'self-auto': properties.itemDisposition === 'auto-left' || properties.itemDisposition === 'auto-middle' || properties.itemDisposition === 'auto-right'  || properties.itemDisposition === 'auto-stretch'  || properties.itemDisposition === 'auto-auto' ,
            'self-baseline': properties.itemDisposition === 'baseline-left' || properties.itemDisposition === 'baseline-middle' || properties.itemDisposition === 'baseline-right'  || properties.itemDisposition === 'baseline-stretch'  || properties.itemDisposition === 'baseline-auto' ,
            'edit-mode': this.element.getContext().config.editMode,
            'box-content': true,
            [`place-content-${properties.placeContent}`]: properties.placeContent,
            [`h-${properties.height}`]: properties.height,
            [`w-${properties.width}`]: properties.width,
            [`mt-${properties.marginTop}`]: properties.marginTop,
            [`mb-${properties.marginBottom}`]: properties.marginBottom,
            [`ml-${properties.marginLeft}`]: properties.marginLeft,
            [`mr-${properties.marginRight}`]: properties.marginRight,
            [`pt-${properties.paddingTop}`]: properties.paddingTop,
            [`pb-${properties.paddingBottom}`]: properties.paddingBottom,
            [`pl-${properties.paddingLeft}`]: properties.paddingLeft,
            [`pr-${properties.paddingRight}`]: properties.paddingRight,
            [`text-${properties.fontSize}`]: properties.fontSize,
            [`font-${properties.fontWeight}`]: properties.fontWeight,
            [`font-${properties.fontFamily}`]: properties.fontFamily,
            [`min-h-${properties.minHeight}`]: properties.minHeight,
            [`max-h-${properties.maxHeight}`]: properties.maxHeight,
            [`min-w-${properties.minWidth}`]: properties.minWidth,
            [`max-w-${properties.maxWidth}`]: properties.maxWidth,

            [`tracking-${properties.letterSpacing}`]: properties.letterSpacing,
            [`leading-${properties.lineHeight}`]: properties.lineHeight,
            [`${properties.textDecoration}`]: properties.textDecoration,
            [`${properties.textTransform}`]: properties.textTransform,
            [`${properties.textOverflow}`]: properties.textOverflow,
            [`indent-${properties.textIndent}`]: properties.textIndent,
            [`whitespace-${properties.whitespace}`]: properties.whitespace,
            [`break-${properties.wordBreak}`]: properties.wordBreak,
            [`overflow-${properties.overflow}`]: properties.overflow,
            [`bg-${properties.backgroundAttachment}`]: properties.backgroundAttachment,
            [`bg-clip-${properties.backgroundClip}`]: properties.backgroundClip,
            [`bg-origin-${properties.backgroundOrigin}`]: properties.backgroundOrigin,
            [`bg-${properties.backgroundPosition}`]: properties.backgroundPosition,
            [`bg-repeat-${properties.backgroundRepeat}`]: properties.backgroundRepeat && properties.backgroundRepeat !== 'no-repeat',
            ['bg-no-repeat']: properties.backgroundRepeat === 'no-repeat',
            [`bg-${properties.backgroundSize}`]: properties.backgroundSize,
            [`ring-${properties.ringWidth}`]: properties.ringWidth,
            [`ring-${properties.ringColor}`]: properties.ringColor,
            [`ring-offset-${properties.ringOffsetWidth}`]: properties.ringOffsetWidth,
            [`ring-offset-${properties.ringOffsetColor}`]: properties.ringOffsetColor,
            [`shadow-${properties.boxShadow}`]: properties.boxShadow,
            [`shadow-${properties.boxShadowColor}`]: properties.boxShadowColor,
            [`opacity-${properties.opacity}`]: properties.opacity,
            [`drop-shadow-${properties.dropShadow}`]: properties.dropShadow,
            ['invisible']: properties.hidden,
            [`rounded-tl-${properties.borderRadiusTopLeft}`]: properties.borderRadiusTopLeft,
            [`rounded-tr-${properties.borderRadiusTopRight}`]: properties.borderRadiusTopRight,
            [`rounded-bl-${properties.borderRadiusBottomLeft}`]: properties.borderRadiusBottomLeft,
            [`rounded-br-${properties.borderRadiusBottomRight}`]: properties.borderRadiusBottomRight,
            [`border-t-${properties.borderWidthTop}`]: properties.borderWidthTop,
            [`border-r-${properties.borderWidthRight}`]: properties.borderWidthRight,
            [`border-l-${properties.borderWidthLeft}`]: properties.borderWidthLeft,
            [`border-b-${properties.borderWidthBottom}`]: properties.borderWidthBottom,
            [`border-${properties.borderColor}`]: properties.borderColor,
            [`border-${properties.borderStyle}`]: properties.borderStyle,
            [`outline-${properties.outlineWidth}`]: properties.outlineWidth,
            [`outline-${properties.outlineColor}`]: properties.outlineColor,
            [`outline-${properties.outlineStyle}`]: properties.outlineStyle,
            [`blur-${properties.blur}`]: properties.blur && properties.blur !== 'normal',
            ['blur']: properties.blur === 'normal',
            [`brightness-${properties.brightness}`]: properties.brightness,
            [`contrast-${properties.contrast}`]: properties.contrast,
            [`hue-rotate-${properties.hueRotate}`]: properties.hueRotate,
            ['invert']: properties.invert,
            [`saturate-${properties.saturate}`]: properties.saturate,
            ['sepia']: properties.sepia,
            [`backdrop-blur-${properties.backdropBlur}`]: properties.backdropBlur,
            [`backdrop-brightess-${properties.backdropBrightness}`]: properties.backdropBrightness,
            [`backdrop-contrast-${properties.backdropContrast}`]: properties.backdropContrast,
            [`backdrop-hue-rotate-${properties.backdropHueRotate}`]: properties.backdropHueRotate,
            ['backdrop-invert']: properties.backdropInvert,
            [`backdrop-opacity-${properties.backdropOpacity}`]: properties.backdropOpacity,
            [`backdrop-saturate-${properties.backdropSaturate}`]: properties.backdropSaturate,
            ['backdrop-sepia']: properties.backdropSepia
        };

        if (properties.customClasses){
            const customClasses = properties.customClasses.split(' ');
            for (const c of customClasses){
                classes[c] = true;
            }
        }

        return classes;
    }

    @HostBinding('style') get styleList(): any {
        const properties: any = {};
        GruulsBaseElementComponent.stylePropertiesList.map((p) => {
            const containerPropName = 'container' + p.charAt(0).toUpperCase() + p.slice(1);
            if (this.element.config[containerPropName] !== undefined && this.element.config[p] !== undefined){
                properties[p] = this.element.config[p];
            }
        });

        return {
            'background-image': properties.backgroundImage,
            'background-color': properties.backgroundColor,
            'color': properties.textColor
        };
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
         this.allConnectedIds = this.allConnectedIds || [];

         this.enableDragDrop = this.element.getContext().config.enableDragDrop;
        // this.allConnectedIds.push(this.id);
        // this.allConnectedIds = ['1','2','4','7'].filter(el => el !== this.id);
        // this.allConnectedIds.push(this.id);

        this.element.valueChanged()
            .pipe(
                takeUntil(this._unsubscribeAll),
                mergeWith(this.element.configChanged()),
                tap(v => this.cdr.markForCheck())
            )
            .subscribe();

    }

    classes(): any{
        const classes = {
            'flex': true,
            'flex-auto': true,
            'flex-col': this.element.config1.layout === 'col',
            'flex-row': this.element.config1.layout === 'row',
            'item-builder': this.element.getContext().config.editMode
        };
        this.dropListOrientation = this.element.config1.layout
            ? this.element.config1.layout === 'col'
                ? 'vertical'
                : 'horizontal'
            : 'horizontal';
        return classes;
    }

    drop(event): void {
        if (event.previousContainer === event.container) {
            //moveItemInArray(event.container.data.elementList, event.previousIndex, event.currentIndex);
            // moveItemInArray(event.container.data._config.elements, event.previousIndex, event.currentIndex);
            event.container.data.moveChildElementPosition(event.previousIndex, event.currentIndex);
        } else {
            // remove from source
            // const el = event.previousContainer.data._config.elements.splice(event.previousIndex, 1)[0];
            event.previousContainer.data.removeChildElementAndReboot(event.previousIndex)
                .pipe(
                    mergeMap(el => event.container.data.addChildElementAndReboot(el, event.currentIndex))
                ).subscribe();
            // add in destination
            // event.container.data._config.elements = event.container.data._config.elements || [];
            // event.container.data._config.elements.splice(event.currentIndex,0, el);
            // event.container.data.addChildElementAndReboot(el, event.currentIndex);
            // reboot everything
            // event.previousContainer.data.reboot().subscribe(res1 => event.container.data.reboot().subscribe(res => this));
        }
    }

    entered(event): void{

    }

    exited(event): void{

    }

    @HostListener('click', ['$event'])
    okClick(event): void {
        if (this.element.getContext().config.editMode){
            event.stopPropagation();
            this.element.getContext().getContext().data.clickedElement = this.element;
            const configPanel: GroupElement = (this.element.getContext().getContext() as any).MainPage.ElementConfigPanel;
            configPanel.replaceAllChildrenElementsAndReboot(this.element.config.elementConfigurationPanel).subscribe();
        }else {
            event.stopPropagation();
            this.element.fireEvent('onClick', event);
        }
    }
}
