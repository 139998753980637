import {
  Component,
  ViewEncapsulation,
  ViewChild,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
  SimpleChange,
  SimpleChanges
} from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";
import { isDate } from "lodash";

/** @title Basic datepicker */
@Component({
  selector: "date-picker",
  templateUrl: "date-picker.component.html",
  styleUrls: ["date-picker.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent implements OnChanges {
  hourBlocks: number[] = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
  date: Date;
  hour: number = null;
  todayDate: Date = new Date();

  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;
  @Input('placeHolder') defaultText: string = "Select Date";
  @Input('value') inputDate: Date;
  @Output('onSelectedDate') selectedDateEmitter = new EventEmitter<Date>();

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.date = changes.inputDate.currentValue ? new Date(Number(changes.inputDate.currentValue)) : null;
    if (this.date != null)
      try {
        this.hour = this.date.getHours();
      } catch (e) {
        console.log("Exeption: " + this.date + " is not a valid date")
        this.hour = null;
      }
  }

  setHour(hour: number) {
    if (this.date == null)
      this.date = new Date();
    this.hour = hour;
    this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), hour, 0, 0, 0);
    this.selectedDateEmitter.emit(this.date);
    this._changeDetectorRef.markForCheck();
  }

  changeHandler($event) {
    this.date = $event.toDate();
    if (this.hour != null)
      this.setHour(this.hour);
  }

  getSelectedhourBlock(): number {
    if (isDate(this.date))
      return this.date.getHours();
    else
      return null;
  }
}