import {
    LOCALE_ID, NgModule, Injectable, InjectionToken, ErrorHandler, Inject, enableProdMode
} from '@angular/core';
import { environment } from '../environments/environment.prod';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { InputElementModule } from '@gruuls-fe/elements/input/input-element/input-element.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SharedModule } from './shared/shared.module';

import Rollbar from 'rollbar'

import { GruulsAngularTranslateService } from '@gruuls-fe/services/gruuls-angular-translate.service';
import { GruulsAngularNumberFormatterService } from '@gruuls-fe/services/gruuls-angular-number-formatter.service';
import { GruulsAngularRoutingService } from '@gruuls-fe/services/gruuls-angular-routing.service';
import { GruulsAngularHttpProxyService } from '@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsLocalStorageService } from '@gruuls-core/infrastructure/gruuls-local-storage-service';

import { GruulsMockApiModule } from '../@gruuls-fe/mock-api/gruuls-mock-api.module';

import { ProjectModule } from './modules/gruuls-demo/gruuls-basics/example-01/project.module';
import { LayoutModule } from './layout/layout.module';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

import { ClientModule } from './beautycians/modules/clients/client.module';
import { OperatorModule } from './beautycians/modules/operators/operator.module';
import { OrderModule } from './beautycians/modules/orders/order.module';
import { OwnerModule } from './beautycians/modules/owners/owner.module';

import { AuthSignInModule } from './beautycians/modules/auth/sign-in/sign-in.module';
import { AuthSignOutModule } from './beautycians/modules/auth/sign-out/sign-out.module';
import { AuthForgotPasswordModule } from './beautycians/modules/auth/forgot-password/forgot-password.module';
import { AuthResetPasswordModule } from './beautycians/modules/auth/reset-password/reset-password.module';

import { Page404Module } from './beautycians/modules/e404/e404.module';

import { GruulsAngularCartService } from '@gruuls-fe/services/gruuls-angular-cart.service';
import { CentriModule } from './beautycians/modules/centri/centri.module';
import { StudentModule } from './beautycians/modules/students/student.module';
import { GruulsAngularAppointmentsService } from '@gruuls-fe/services/gruuls-angular-appointments-service';
import { GruulsAngularClientService } from '@gruuls-fe/services/gruuls-angular-client-service';
import { GruulsAngularStoreService } from '@gruuls-fe/services/gruuls-angular-stores-service';
import { StoresModule } from './beautycians/modules/common/stores/stores.module';
import { StoreModule } from './beautycians/modules/stores/store.module';
import { DraggableTreeModule } from './beautycians/modules/common/draggable-tree/draggable-tree.module';
import { SettingsModule } from './beautycians/modules/settings/settings.module';

import { AccountService } from './_services';
import { GruulsAngularCollaboratorService } from '@gruuls-fe/services/gruuls-angular-collaborator-service';
import { CustomPipeHasRole } from './beautycians/pipes/hasRole.pipe';

registerLocaleData(localeIt);
if (environment.production) {
    enableProdMode();
}
// TODO: check https://medium.com/@kayvanbree/solving-angulars-environment-variable-problem-fc3ac5bbc305 

const rollbarConfig = {
    accessToken: '261b5c01f9874cc1a7d1a59fb675b4dd',
    captureUncaught: true,
    captureUnhandledRejections: true,
    code_version: '1.6.16',
    environment: window.location.origin,
    // environment: (environment.production === true ? 'production' : 'staging'),
    // url: window.location.origin
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

    handleError(err: any): void {
        if (0) {
            this.rollbar.error(err.originalError || err);
        }
    }

}

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

// export function appInitializer(accountService: AccountService) {
//     return () => new Promise<void>(resolve => {
//         window['fbAsyncInit'] = function () {
//             FB.init({
//                 appId: '468634265627745',
//                 xfbml: true,
//                 version: 'v19.0'
//             });

//             // auto authenticate with the api if already logged in with facebook
//             FB.getLoginStatus(({authResponse}) => {
//                 if (authResponse) {
//                     accountService.apiAuthenticate(authResponse.accessToken)
//                         .subscribe()
//                         .add(resolve);
//                 } else {
//                     resolve();
//                 }
//             });
//         };

//         (function (d, s, id) {
//             var js, fjs = d.getElementsByTagName(s)[0];
//             if (d.getElementById(id)) { return; }
//             js = d.createElement(s); js.id = id;
//             js.src = "https://connect.facebook.net/en_US/sdk.js";
//             fjs.parentNode.insertBefore(js, fjs);
//         }(document, 'script', 'facebook-jssdk'));
//     })
// }

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
};

@NgModule({
    declarations: [
        AppComponent,
        CustomPipeHasRole
    ],
    imports: [

        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        GruulsMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,
        SharedModule,
        // Layout module of your application

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),

        // modules that does not work with dynamic loading
        InputElementModule,
        ColorPickerModule,
        MatSelectModule,
        MatSnackBarModule,
        StoresModule,
        DraggableTreeModule,

        //custom modules
        LayoutModule,
        ProjectModule,
        // MyCalendarModule,

        //Beautycians Modules
        StoreModule,
        OperatorModule,
        ClientModule,
        OrderModule,
        // ProductModule, ==> when loaded dinamically there is no need to include it here
        OwnerModule,
        CentriModule,
        StudentModule,
        SettingsModule,

        AuthSignInModule,
        AuthSignOutModule,
        AuthForgotPasswordModule,
        AuthResetPasswordModule,

        //Owner Module
        Page404Module
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "it-it" },
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: RollbarService, useFactory: rollbarFactory },
        DecimalPipe,
        GruulsAngularTranslateService,
        GruulsAngularNumberFormatterService,
        GruulsAngularRoutingService,
        GruulsAngularHttpProxyService,
        GruulsAngularClientService,
        GruulsAngularCartService,
        GruulsAngularStoreService,
        GruulsAngularCollaboratorService,
        GruulsAngularAppointmentsService,
        GruulsLocalStorageService
    ]
})
export class AppModule {
}
