
// @dynamic
export class MyOrganization {


    public selected: boolean;

    constructor(
        public organizationId: string,
        public name: string,
        public organizationType: string,
        public children: MyOrganization[],
        public settings: any
    ) {
        this.selected = false;
    }


    public static of(myOrganization: any): MyOrganization {
        return new MyOrganization(
            myOrganization.organizationId,
            myOrganization.name,
            myOrganization.organizationType,
            myOrganization.children ? myOrganization.children.map((o: any) => o.myAdministrativeEntities.length > 0 ? o.myAdministrativeEntities.forEach((el: any) => {
                myOrganization.myAdministrativeEntities.push(el);
            }) : MyOrganization.of(o)) : [],
            myOrganization.settings ? JSON.parse(myOrganization.settings) : undefined
        );
    }

    getName(): string {
        return this.name;
    }

    select(): void {
        this.selected = true;
    }

    unselect(): void {
        this.selected = false;
    }

    getChildren(): MyOrganization[] {
        return this.children;
    }


}
