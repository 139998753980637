import {GruulsElementPageFactoryComponent} from '../element-factory/gruuls-element-page-factory.component';
import {GruulsAuthGuard} from '../auth/gruuls-auth.guard';


export class GruulsAngularRouteFactory{

    private static customRoutes = [];

    // routeModel is an array containing the routes
    static createRoutes(routesModel: any): any[]{

        if (!routesModel){
            return undefined;
        }
        const finalRoutes: any[] = [];
        for ( const r of routesModel){

            // Routes of type DEFAULT are copy - paste of the Angular route. Used to make redirects or default routes.
            // Is a redirect route type
            if (!r.type || r.type === 'DEFAULT' ){
                finalRoutes.push({
                    path: r.path,
                    redirectTo: r.redirectTo,
                    pathMatch: r.pathMatch,
                    data: {
                        pageId: r.pageId
                    },
                    canActivate: r.useAuth ? [GruulsAuthGuard] : undefined,
                    //component: r.redirectTo ? undefined : GruulsElementFactoryComponent,
                    children: GruulsAngularRouteFactory.createRoutes(r.children)
                });

                // A CUSTOM_ROUTE is a classic Angular route
            } else if (r.type === 'CUSTOM_ROUTE'){
                const customRoute = GruulsAngularRouteFactory.customRoutes.find(el => el.customRouteName === r.customRouteName);
                if (customRoute){
                    customRoute.path = r.path ? r.path : customRoute.path;
                    finalRoutes.push(customRoute);
                }

                // A route of type GRUULS_PAGE is a route that opens a GRUULS page. When it is "hydrated"
                // a specific controller is used that is able to "fish" it among the routes starting from the page id
            }else if (r.type === 'GRUULS_PAGE'){
                finalRoutes.push({
                    path: r.path,
                    //redirectTo: r.redirectTo,
                    canActivate: r.useAuth ? [GruulsAuthGuard] : undefined,
                    pathMatch: r.pathMatch,
                    data: {
                        pageId: r.pageId
                    },
                    component: GruulsElementPageFactoryComponent,
                    children: GruulsAngularRouteFactory.createRoutes(r.children)
                });
            }
        }

        return finalRoutes;
    }

    static addCustomRoutesToCatalog(customRoutes: any[]): void {
        GruulsAngularRouteFactory.customRoutes.push(...customRoutes);
    }
}
