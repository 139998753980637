import {GroupElement} from '../../misc/group-element/group-element';
import {
    DynamicFlexContainerElementConfig, DynamicFlexContainerElementConfigInterface
} from './dynamic-flex-container-element-config';

// è un gruppo applicato a un layouting (come dispone gli elementi di un gruppo?)
export class DynamicFlexContainerElement extends GroupElement {

    config1: DynamicFlexContainerElementConfig;

    constructor(
        config: DynamicFlexContainerElementConfigInterface) {
        super(config);
        this.config = new DynamicFlexContainerElementConfig(config, this);
        this.config1 = this.config as DynamicFlexContainerElementConfig;
    }

    public getAllRuntimeIdsRecursive(): string[]{
        let ids = [this.runtimeId];
        if (this.elementList.length) {
            this.elementList.forEach(el => ids = ids.concat(...el.getAllRuntimeIdsRecursive()));
        }
        return ids;
    }

}
