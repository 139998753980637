<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div class="flex flex-col shadow bg-card">

        <!-- Cover image -->
        <div>
            <img class="h-40 lg:h-60 object-cover"
                src="{{client?.background ? client.background : 'https://get.beautycians.datacream.com/resources/images/dna.jpg'}}"
                alt="Cover image">
        </div>

        <!-- User info -->
        <div class="flex flex-col flex-0 md:flex-row items-center max-w-5xl w-full mx-auto px-8 bg-card p-4">

            <!-- Avatar -->
            <div class="-mt-26 lg:-mt-22 rounded-full ">
                <ng-container [ngSwitch]="!!client?.avatar">
                    <img *ngSwitchCase="true" class="w-32 h-32 rounded-full ring-4 ring-bg-card"
                        src="assets/images/avatars/male-04.jpg" alt="User avatar">

                    <div class="w-32 h-32 rounded-full ring-4 ring-bg-card bg-white flex justify-center items-center text-4xl font-semibold border-4 border-accent "
                        *ngSwitchDefault>
                        {{client?.firstName?.charAt(0)?.toUpperCase()}}
                    </div>
                </ng-container>

            </div>

            <!-- Details -->
            <div class="flex flex-col items-center md:items-start mt-4 md:mt-0 md:ml-8">
                <div class="text-lg font-bold leading-none">{{client?.firstName}} {{client?.lastName}}</div>
            </div>

            <ng-container *ngFor="let menuItem of menuItems; index as i">
                <!-- Separator -->
                <div class="hidden md:flex mx-4"></div>

                <!-- Anamnesi -->
                <div class="flex flex-col items-center mt-6 md:mt-0 space-x-6">
                    <a mat-flat-button class="flex flex-row items-baseline" (click)="setClientDetailsTab(i)">
                        <!-- <span class="font-bold pr-2">{{surveyList?.length}}   </span> -->
                        <span class="text-sm font-medium text-secondary">{{menuItem.name | uppercase}}</span>
                    </a>
                    <div *ngIf="clientDetailsTabSelectedIndex==i" class='w-1/3 transform -translate-x-1/3 -translate-y-2 border-b-4 border-primary-400'></div>
                </div>
            </ng-container>
        </div>

    </div>

    <!-- Main -->
    <div>
        <!-- Loader -->
        <div class="inset-x-0 bottom-0" *ngIf="isGlobalLoading() && !selectedAnamnesi">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>

        <div class="flex flex-auto justify-center w-full max-w-5xl mx-auto sm:p-8">

            <!-- Column -->
            <div class="hidden lg:flex flex-col items-start mr-8">
            </div>

            <!-- Column -->
            <div class="flex-auto sm:px-4 sm:pb-4">
                <mat-tab-group #clientDetailsTab class="header-less-tabs" animationDuration="0ms" dynamicHeight>
                    <mat-tab id="Anamnesi" label="Anamnesi">
                        <medicalhistory [client]="client" (isLoadingEvent)="childLoading('medicalhistory',$event)" (onChange)="setReferenceAnamnesi($event)"></medicalhistory>
                    </mat-tab>
                    <ng-container *ngIf="client">
                        <mat-tab id="BeautyPath" label="Percorsi" *ngIf="experimentalFeatures">
                            <beauty-path *ngIf="client" [client]="client" [treatments]="treatments" [medicalHistory]="lastAnamnesi" (isLoadingEvent)="childLoading('beautypath',$event)" ></beauty-path>
                        </mat-tab>
                        <mat-tab id="CalendarTab" label="Calendario">
                            <mycalendar defaultEventColor="#cccccc" [personId]="personId" (isLoadingEvent)="childLoading('calendar',$event)"></mycalendar>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
            </div>

        </div>
    </div>

</div>