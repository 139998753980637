import { GruulsMockDomain, GruulsMockHttpRequest } from '../../gruuls-mock-domain';
import { HttpClient } from '@angular/common/http';

export class BeautyAdvisedPathsMock extends GruulsMockDomain {

  constructor(httpClient: HttpClient) {
    super(httpClient);

    this.entities = this.ADVIDED_PATHS;
  }

  ADVIDED_PATHS = [
    {
      uuid: "1234-1234-1234-1222",
      appointments: [
        {
          uuid: "1234-1234-1234-1222",
          duration: 60,
        },
      ],
    },
  ];
}