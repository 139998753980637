import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormBuilder, NgForm, Validators} from '@angular/forms';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FuseConfirmationService} from '@fuse/services/confirmation';
import {GruulsAngularHttpProxyService} from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import moment_ from 'moment';
import {GruulsAngularTranslateService} from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { Person } from '../../clients/client.types';

@Component({
    selector: 'owner-details',
    templateUrl: './owner-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwnerDetailsComponent implements OnInit, OnDestroy {

    @ViewChild('avatarFileInput') private _avatarFileInput: ElementRef;

    editMode: boolean = false;
    contact: Person;
    contacts: Person[];
    newSurvey: any = [];
    surveyList: any[] = [];
    private _tagsPanelOverlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private personId: string;
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _authService: GruulsAuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _renderer2: Renderer2,
        private _router: Router,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _httpClient: GruulsAngularHttpProxyService,
        private _fb: FormBuilder,
        private _translate: GruulsAngularTranslateService
    ) {
    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this._activatedRoute.params.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((params) => {
            this._apiCaller.getPerson(params.id).subscribe({
                next: (res) => {
                    this.contact = res.hits[0];
                    this.contact.birthdate = this.contact.birthdate ? moment_(this.contact.birthdate).format('MM/DD/YYYY') : undefined;
                    this._changeDetectorRef.markForCheck();
                }
            });

        });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();

        // Dispose the overlays if they are still on the DOM
        if (this._tagsPanelOverlayRef) {
            this._tagsPanelOverlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }


}
