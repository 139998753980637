import {Gender} from './gender';
import {OrganizationAcl} from '../organization/organization-acl';
import {UserAction} from './user-action';

export interface UserAccount {
    username?: string;
    email?: string;
    passwordExpirationDate?: number;
    accountId?: string;
}

export class User {
    public personId: string | undefined; //uuid;
    public firstName: string | undefined;
    public lastName: string | undefined;
    public birthdate: number | undefined;
    public sex: Gender | undefined;
    public account?: UserAccount;
    public locked: boolean | undefined;
    public active: boolean | undefined;
    public ldap: boolean | undefined;
    public authKey: string | undefined; //uuid;
    public roles: {
        isDefault?: boolean;
        hasRole: {
            roleTemplateId?: string;
            name?: string;
            settings?: any;
            serviceRoleMapping?: {
                navigationResources?: {
                    name?: string;
                    title: string;
                    subtitle: string;
                    i18nTitle: string;
                    i18nSubtitle: string;
                    type: 'aside' | 'basic' | 'collapsable' | 'divider' | 'group' | 'spacer';
                    link: string;
                    externalLink: boolean;
                    exactMatch: boolean;
                    icon: string;
                    children?: any[];
                };
                _parent: {
                    // service Aggregate
                    pages: {
                        id: string;
                        name: string;
                        urlPath: string;
                        type: string;
                        useAuth: boolean;
                        loadOrder: number;
                        // config: any,
                        // contextName: string,
                        // domainName: string
                    }[];
                    name: string;
                };
            }[];
        }[];
        inOrganization: any;
    }[] | undefined;
    public localeLanguage?: string;
    public localeCountry?: string;


    public organizations: OrganizationAcl[] | undefined;

    //UI-specific params
    public initials: string | undefined;

    public constructor(user: any) {
        this.initUser(user);
    }

    protected initUser(user: any): void {
        this.firstName = user.firstName || '';
        this.personId = user.personId;
        this.lastName = user.lastName || '';
        this.birthdate = user.birthate;
        this.account = user.account;
        this.locked = user.locked;
        this.active = user.active;
        this.ldap = user.ldap;
        this.authKey = user.authKey;
        this.localeLanguage = user.localeLanguage;
        this.localeCountry = user.localeCountry;
        this.roles = user.roles;
        this.organizations = user.organizations ? user.organizations.map((el: any) => OrganizationAcl.of(el)) : [];

        if (this.firstName && this.lastName) {
            this.initials = this.firstName.substr(0, 1) + this.lastName.substr(0, 1);
        } else {
            this.initials = '';
        }

        // this.flattenGrantsPerOrganization(this.grantsPerOrganization);

        // let actions = {
        //   Read : false
        // };
        //
        //this.fillUpResourcePathCache("AllResources", user.grants.AllResources, this.resourcePathCache, this.resourceIdsCache, actions);
    }

    // private flattenGrantsPerOrganization(grantsPerOrganization:GrantsPerOrganization|undefined){
    //   if (grantsPerOrganization) {
    //     for (let o in grantsPerOrganization) {
    //       grantsPerOrganization[o].flatResources = {};
    //       for (let r in grantsPerOrganization[o].roles) {
    //         for (let res in grantsPerOrganization[o].roles[r].resources) {
    //           let resource = grantsPerOrganization[o].roles[r].resources[res];
    //           grantsPerOrganization[o].flatResources[resource.flatName] = {};
    //           for (let g in resource.grants) {
    //             grantsPerOrganization[o].flatResources[resource.flatName][g] = resource.grants[g] === "ALLOW";
    //           }
    //
    //         }
    //       }
    //     }
    //   }
    // }


    // private fillUpResourcePathCache(resourcePath, subResource, resourcePathCache, resourceIdCache, parentActions){
    //   let actions = subResource._actions || parentActions;
    //   resourcePathCache[resourcePath] = actions;
    //   resourceIdCache[subResource._resourceId] = actions;
    //
    //   for (let s in subResource){
    //     if (s !== '_actions' && s !== '_resourceId'){
    //       this.fillUpResourcePathCache(resourcePath + '.' + s, subResource[s], resourcePathCache, resourceIdCache, actions);
    //     }
    //   }
    // }

    public canUserDoOnResourcePath(organizationId: string, resourcePath: string, action: UserAction): boolean {
        if (!this.roles) {
            return false;
        }

        const role = this.roles.find(r => r.inOrganization.organizationId === organizationId);
        if (!role || (role && !role.hasRole) || (role.hasRole && role.hasRole.length === 0)) {
            return false;
        }

        const resourceFound = !!role.hasRole.find((r: any) => r.resourcesMap && r.resourcesMap.resources && r.resourcesMap.resources[resourcePath] && r.resourcesMap.resources[resourcePath][action]);
        return resourceFound;
    }

    public canUserDoOnResourceArray(organizationId: string, resourcePathArray: string[], action: UserAction): boolean {
        const cacheResourcePath = resourcePathArray.join('.');
        return this.canUserDoOnResourcePath(organizationId, cacheResourcePath, action);
    }

}
