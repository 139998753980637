/* eslint-disable quotes */
import { DataSchema } from '@gruuls-core/domain/data-schema/data-schema';
import { Field } from '@gruuls-core/domain/data-schema/field';
import { AngularSyncValidatorFactory } from '@gruuls-core/domain/data-schema/sync-validator';
import { UiFrameworkFormElementBuilder } from '@gruuls-core/domain/data-schema/ui-framework-form-element-builder';
import { FieldConfig } from '@gruuls-core/domain/domain/templates-config';
import {CollectionType} from '../../@gruuls-core/domain/data-schema/collection-type';
import {AngularAsyncValidatorFactory} from '../../@gruuls-core/domain/data-schema/async-validator';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {Observable, of, throwError, zip} from 'rxjs';
import { map } from 'rxjs/operators';
import {DomainCatalog} from '../../@gruuls-core/domain/domain/domain-catalog';
import {OneToManyField} from '../../@gruuls-core/domain/data-schema/fields/one-to-many/one-to-many-field';
import {InverseOneToManyField} from '../../@gruuls-core/domain/data-schema/fields/inverse-one-to-many/inverse-one-to-many-field';

export class FormElementsAngularUtils {

    public static simpleFormControlElement: UiFrameworkFormElementBuilder = {
        build: FormElementsAngularUtils.simpleFormControlElementFn
    };

    public static subFieldsFormControlElement: UiFrameworkFormElementBuilder = {
        build: FormElementsAngularUtils.subFieldsFormControlElementFn
    };

    public static maybeFlatValueObjectFormControlElement: UiFrameworkFormElementBuilder = {
        build: FormElementsAngularUtils.maybeFlatValueObjectFormControlElementFn
    };

    public static oneToManyFormControlElement: UiFrameworkFormElementBuilder = {
        build: FormElementsAngularUtils.oneToManyformControlElementFn
    };

    public static inverseOneToManyFormControlElement: UiFrameworkFormElementBuilder = {
        build: FormElementsAngularUtils.inverseOneToManyFormControlElementFn
    };

    private static simpleFormControlElementFn(schema: DataSchema, field: Field, fieldConfig: FieldConfig, data: any, considerAsSingle: boolean): Observable<any> {
        const collection = considerAsSingle ? CollectionType.NONE : field.collectionType;
        const syncValidatorFactory = new AngularSyncValidatorFactory();
        const asyncValidatorFactory = new AngularAsyncValidatorFactory();
        if (collection === CollectionType.NONE) {
            const syncValidators: any = [];
            for (const v of fieldConfig.syncValidators) {
                syncValidators.push(syncValidatorFactory.rehydrate(v, schema, field));
            }
            const asyncValidators: any = [];
            for (const v of fieldConfig.asyncValidators) {
                asyncValidators.push(asyncValidatorFactory.rehydrate(v, schema, field));
            }
            return of(new FormControl('', {
                updateOn: 'blur',
                validators: syncValidators,
                asyncValidators: asyncValidators
            }));
        } else {
            const listSyncValidators: any = [];
            for (const v of fieldConfig.listSyncValidators) {
                listSyncValidators.push(syncValidatorFactory.rehydrate(v, schema, field));
            }
            const listAsyncValidators: any = [];
            for (const v of fieldConfig.listAsyncValidators) {
                listAsyncValidators.push(asyncValidatorFactory.rehydrate(v, schema, field));
            }
            const arr = new FormArray([], {
                updateOn: 'blur',
                validators: listSyncValidators,
                asyncValidators: listAsyncValidators
            });
            const tempArr: Observable<any>[] = [];
            if (data && data[field.getName()] && data[field.getName()].length > 0) {
                for (const i of data[field.getName()]) {
                    tempArr.push(FormElementsAngularUtils.simpleFormControlElement.build(schema, field, fieldConfig, undefined, true) as Observable<FormControl | FormArray>);
                }
            }

            if (tempArr.length) {
                return zip(...tempArr)
                    .pipe(
                        map((tempArrs) => {
                            for (const t of tempArrs) {
                                arr.push(t);
                            }
                            return arr;
                        })
                    );
            }else{
                return of(arr);
            }
        }
    }

    private static subFieldsFormControlElementFn(schema: DataSchema, field: Field, fieldConfig: FieldConfig, data: any, considerAsSingle: boolean): Observable<any> {
        const collection = considerAsSingle ? CollectionType.NONE : field.collectionType;
        const syncValidatorFactory = new AngularSyncValidatorFactory();
        const asyncValidatorFactory = new AngularAsyncValidatorFactory();
        if (collection === CollectionType.NONE) {
            let groupSyncValidators: any = [];
            for (const v of fieldConfig.groupSyncValidators) {
                groupSyncValidators.push(syncValidatorFactory.rehydrate(v, schema, field));
            }
            let groupAsyncValidators: any = [];
            for (const v of fieldConfig.groupAsyncValidators) {
                groupAsyncValidators.push(asyncValidatorFactory.rehydrate(v, schema, field));
            }
            const subFieldsControls: any = {};

            const subFieldsConfigList = DataSchema.mergeFieldsConfigsStatic(schema.getContextName(), schema.getDomainName(), field.subFields, fieldConfig.fieldsToShow, () => true);
            const subFieldsConfig: { [fieldName: string]: FieldConfig } = {};
            for (const f of subFieldsConfigList) {
                subFieldsConfig[f.fieldName] = f;
            }

            const arr: Observable<any>[] = [];
            for (const field1 of field.subFields) {
                if (subFieldsConfig[field1.name]) {
                    let childData;
                    if (considerAsSingle){
                        childData = data;
                    }else{
                        childData = data ? data[field.name] : undefined;
                    }
                    arr.push(subFieldsConfig[field1.name].uiFrameworkFormElementBuilder.build(schema, field1, subFieldsConfig[field1.name], childData, false)
                        .pipe(
                            map((res) => {
                                if (res instanceof FormControl || res instanceof FormGroup || res instanceof FormArray) {
                                    subFieldsControls[field1.name] = res;
                                } else {
                                    for (const fieldName in res.fields) {
                                        if (res.fields.hasOwnProperty(fieldName)) {
                                            subFieldsControls[fieldName] = res.fields[fieldName];
                                        }
                                    }
                                    groupAsyncValidators = groupAsyncValidators.concat(res.groupAsyncValidators);
                                    groupSyncValidators = groupSyncValidators.concat(res.groupSyncValidators);
                                }
                            })
                        ));
                }
            }

            const systemSubFieldsConfigList = DataSchema.mergeFieldsConfigsStatic(schema.getContextName(), schema.getDomainName(), field.systemSubFields, undefined, () => true);
            for (const systemField of systemSubFieldsConfigList) {
                subFieldsConfig[systemField.fieldName] = systemField;
                const systemFieldDef = field.systemSubFields.find(f => f.name === systemField.fieldName);
                arr.push(subFieldsConfig[systemField.fieldName].uiFrameworkFormElementBuilder.build(schema, systemFieldDef, systemField, data ? data[field.name] : undefined, false)
                    .pipe(
                        map((res) => {
                            if (res instanceof FormControl || res instanceof FormGroup || res instanceof FormArray) {
                                subFieldsControls[systemField.fieldName] = res;
                            } else {
                                for (const fieldName in res.fields) {
                                    if (res.fields.hasOwnProperty(fieldName)) {
                                        subFieldsControls[fieldName] = res.fields[fieldName];
                                    }
                                }
                            }
                        })
                    ));
            }

            if (arr.length) {
                return zip(...arr)
                    .pipe(
                        map(res => new FormGroup(subFieldsControls, {
                                updateOn: 'blur',
                                validators: groupSyncValidators,
                                asyncValidators: groupAsyncValidators
                            }))
                    );
            }else{
                return of(new FormGroup(subFieldsControls, {
                    updateOn: 'blur',
                    validators: groupSyncValidators,
                    asyncValidators: groupAsyncValidators
                }));
            }
        } else {
            const listSyncValidators: any = [];
            for (const v of fieldConfig.listSyncValidators) {
                listSyncValidators.push(syncValidatorFactory.rehydrate(v, schema, field));
            }
            const listAsyncValidators: any = [];
            for (const v of fieldConfig.listAsyncValidators) {
                listAsyncValidators.push(asyncValidatorFactory.rehydrate(v, schema, field));
            }

            const arr = new FormArray([], {
                updateOn: 'blur',
                validators: listSyncValidators,
                asyncValidators: listAsyncValidators
            });
            const tempArr: Observable<any>[] = [];
            if (data && data[field.getName()] && data[field.getName()].length > 0) {
                for (const i of data[field.getName()]) {
                    tempArr.push(FormElementsAngularUtils.subFieldsFormControlElement.build(schema, field, fieldConfig, i, true)
                        .pipe(
                            map((res) => {
                                if (res instanceof FormControl || res instanceof FormGroup || res instanceof FormArray) {
                                    arr.push(res);
                                } else {
                                    const subFieldsControls: any = {};
                                    for (const fieldName in res) {
                                        if (res.hasOwnProperty(fieldName)) {
                                            subFieldsControls[fieldName] = res[fieldName];
                                        }
                                    }
                                    const fg = new FormGroup(subFieldsControls, {
                                        updateOn: 'blur'
                                    });
                                    arr.push(fg);
                                }
                            })
                        )
                    );
                }
            }

            if (tempArr.length) {
                return zip(...tempArr).pipe(map(res => arr));
            }else{
                return of(arr);
            }
        }
    }

    private static maybeFlatValueObjectFormControlElementFn(schema: DataSchema, field: Field, fieldConfig: FieldConfig, data: any, considerAsSingle: boolean): Observable<any> {
        if (field.modelType !== 'flat') {
            return FormElementsAngularUtils.subFieldsFormControlElement.build(schema, field, fieldConfig, data, considerAsSingle);
        }
        const collection = considerAsSingle ? CollectionType.NONE : field.collectionType;
        const syncValidatorFactory = new AngularSyncValidatorFactory();
        const asyncValidatorFactory = new AngularAsyncValidatorFactory();
        if (collection === CollectionType.NONE) {
            const groupSyncValidators: any = [];
            for (const v of fieldConfig.groupSyncValidators) {
                groupSyncValidators.push(syncValidatorFactory.rehydrate(v, schema, field));
            }
            const groupAsyncValidators: any = [];
            for (const v of fieldConfig.groupAsyncValidators) {
                groupAsyncValidators.push(asyncValidatorFactory.rehydrate(v, schema, field));
            }
            const subFieldsControls: any = {
                fields: {},
                groupSyncValidators: groupSyncValidators,
                groupAsyncValidators: groupAsyncValidators
            };

            const subFieldsConfigList = DataSchema.mergeFieldsConfigsStatic(schema.getContextName(), schema.getDomainName(), field.subFields, undefined, () => true);
            const subFieldsConfig: { [fieldName: string]: FieldConfig } = {};
            for (const f of subFieldsConfigList) {
                subFieldsConfig[f.fieldName] = f;
            }

            const tempArr: Observable<any>[] = [];
            for (const field1 of field.subFields) {
                tempArr.push(subFieldsConfig[field1.name].uiFrameworkFormElementBuilder.build(schema, field1, subFieldsConfig[field1.name], undefined, false)
                    .pipe(
                        map((res) => {
                            if (res instanceof FormControl || res instanceof FormGroup || res instanceof FormArray) {
                                subFieldsControls.fields[field1.name] = res;
                            } else {
                                for (const fieldName in res) {
                                    if (res.hasOwnProperty(fieldName)) {
                                        subFieldsControls.fields[fieldName] = res[fieldName];
                                    }
                                }
                            }
                        })
                    ));
            }

            if (tempArr.length) {
                return zip(...tempArr).pipe(map(res => subFieldsControls));
            }else{
                return of(subFieldsControls);
            }
        } else {
            return throwError(new Error('field (name: ' + field.name + ') has a flat modelType. However it cannot be a collectionType (collectionType: ' + field.collectionType + '). If you want a collectionType you should select another Model type (eg. valueObject)'));
        }
    }

    private static oneToManyformControlElementFn(schema: DataSchema, field: Field, fieldConfig: FieldConfig, data: any, considerAsSingle: boolean): Observable<any> {

        if (considerAsSingle) {
            const syncValidatorFactory = new AngularSyncValidatorFactory();
            const asyncValidatorFactory = new AngularAsyncValidatorFactory();

            const groupSyncValidators: any = [];
            for (const v of fieldConfig.groupSyncValidators) {
                groupSyncValidators.push(syncValidatorFactory.rehydrate(v, schema, field));
            }
            const groupAsyncValidators: any = [];
            for (const v of fieldConfig.groupAsyncValidators) {
                groupAsyncValidators.push(asyncValidatorFactory.rehydrate(v, schema, field));
            }
            const subFieldsControls: any = {};

            return DomainCatalog.getInstance().getDomainByFqdn((field as OneToManyField).otherDomain)
                .pipe(
                    map((otherDomain) => {
                        const otherDomainKeyFieldName: string = otherDomain.getSchema().getKeyFieldName();

                        subFieldsControls[otherDomainKeyFieldName] = new FormControl();

                        return new FormGroup(subFieldsControls, {
                            updateOn: 'blur',
                            validators: groupSyncValidators,
                            asyncValidators: groupAsyncValidators
                        });
                    })
                );
        } else {
            const syncValidatorFactory = new AngularSyncValidatorFactory();
            const asyncValidatorFactory = new AngularAsyncValidatorFactory();
            const listSyncValidators: any = [];
            for (const v of fieldConfig.listSyncValidators) {
                listSyncValidators.push(syncValidatorFactory.rehydrate(v, schema, field));
            }
            const listAsyncValidators: any = [];
            for (const v of fieldConfig.listAsyncValidators) {
                listAsyncValidators.push(asyncValidatorFactory.rehydrate(v, schema, field));
            }

            const arr = new FormArray([], {
                updateOn: 'blur',
                validators: listSyncValidators,
                asyncValidators: listAsyncValidators
            });
            const tempArr: Observable<any>[] = [];
            if (data && data[field.getName()] && data[field.getName()].length > 0) {
                for (const i of data[field.getName()]) {
                    tempArr.push(FormElementsAngularUtils.oneToManyFormControlElement.build(schema, field, fieldConfig, data[field.getName()][i], true)
                        .pipe(
                            map((res) => {
                                if (res instanceof FormControl || res instanceof FormGroup || res instanceof FormArray) {
                                    arr.push(res);
                                } else {
                                    const subFieldsControls: any = {};
                                    for (const fieldName in res) {
                                        if (res.hasOwnProperty(fieldName)) {
                                            subFieldsControls[fieldName] = res[fieldName];
                                        }
                                    }
                                    const fg = new FormGroup(subFieldsControls, {
                                        updateOn: 'blur'
                                    });
                                    arr.push(fg);
                                }
                            })
                        ));
                }
            }
            if (tempArr.length){
                return zip(...tempArr).pipe(map(res => arr));
            }else{
                return of(arr);
            }
        }
    }

    private static inverseOneToManyFormControlElementFn(schema: DataSchema, field: Field, fieldConfig: FieldConfig, data: any, considerAsSingle: boolean): Observable<any> {
        const collection = considerAsSingle ? CollectionType.NONE : field.collectionType;
        if (collection === CollectionType.NONE) {
            const syncValidatorFactory = new AngularSyncValidatorFactory();
            const asyncValidatorFactory = new AngularAsyncValidatorFactory();

            const syncValidators: any = [];
            for (const v of fieldConfig.syncValidators) {
                syncValidators.push(syncValidatorFactory.rehydrate(v, schema, field));
            }
            const asyncValidators: any = [];
            for (const v of fieldConfig.asyncValidators) {
                asyncValidators.push(asyncValidatorFactory.rehydrate(v, schema, field));
            }
            const subFieldsControls: any = {};

            return DomainCatalog.getInstance().getDomainByFqdn((field as InverseOneToManyField).otherDomain)
                .pipe(
                    map((otherDomain) => {
                        const otherDomainKeyFieldName: string = otherDomain.getSchema().getKeyFieldName();

                        subFieldsControls[otherDomainKeyFieldName] = new FormControl();

                        return new FormGroup(subFieldsControls, {
                            updateOn: 'blur',
                            validators: syncValidators,
                            asyncValidators: asyncValidators
                        });
                    })
                );

        } else {
            const syncValidatorFactory = new AngularSyncValidatorFactory();
            const asyncValidatorFactory = new AngularAsyncValidatorFactory();
            const listSyncValidators: any = [];
            for (const v of fieldConfig.listSyncValidators) {
                listSyncValidators.push(syncValidatorFactory.rehydrate(v, schema, field));
            }
            const listAsyncValidators: any = [];
            for (const v of fieldConfig.listAsyncValidators) {
                listAsyncValidators.push(asyncValidatorFactory.rehydrate(v, schema, field));
            }

            const arr = new FormArray([], {
                updateOn: 'blur',
                validators: listSyncValidators,
                asyncValidators: listAsyncValidators
            });
            const tempArr: Observable<any>[] = [];
            if (data && data[field.getName()] && data[field.getName()].length > 0) {
                for (const i of  data[field.getName()]) {
                    tempArr.push(FormElementsAngularUtils.inverseOneToManyFormControlElement.build(schema, field, fieldConfig, data[field.getName()][i], true)
                        .pipe(
                            map ((res) => {
                                if (res instanceof FormControl || res instanceof FormGroup || res instanceof FormArray) {
                                    arr.push(res);
                                } else {
                                    const subFieldsControls: any = {};
                                    for (const fieldName in res) {
                                        if (res.hasOwnProperty(fieldName)) {
                                            subFieldsControls[fieldName] = res[fieldName];
                                        }
                                    }
                                    const fg = new FormGroup(subFieldsControls, {
                                        updateOn: 'blur'
                                    });
                                    arr.push(fg);
                                }
                            })
                        ));
                }
            }
            if (tempArr.length) {
                return zip(...tempArr).pipe(map(res => arr));
            }else{
                return of(arr);
            }
        }
    }

}
