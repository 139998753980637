import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GetData, GruulsHttpProxyInterface, PostData} from '@gruuls-core/service-interfaces/gruuls-http-proxy-interface';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class GruulsAngularHttpProxyService implements GruulsHttpProxyInterface{

  private endpoint: string = '';
  public constructor(
    private http: HttpClient
  ){

  }
  
  public doPost(postData: PostData): Observable<any> {
    let headers: HttpHeaders;
    if (postData.headers){
      headers = new HttpHeaders(postData.headers);
    }
    return this.http.post(postData.url, postData.body, {
      headers: headers,
      observe: postData.options ? postData.options.observe : undefined
    });
  }


  public doGet(getData: GetData): Observable<any> {
    let headers: HttpHeaders;
    if (getData.headers){
      headers = new HttpHeaders(getData.headers);
    }
    return this.http.get(getData.url, {
      headers: headers,
      observe: getData.options ? getData.options.observe : undefined
    });
  }
}
