import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import {GruulsConfigResolver} from '@gruuls-fe/routing/gruuls-config-resolver';
import {ExampleNextComponent} from './modules/admin/example-next/example-next.component';
import {AppComponent} from './app.component';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // {
    //     path: '',
    //     canActivate: [NoAuthGuard],
    //     canActivateChild: [NoAuthGuard],
    //     component: LayoutComponent,
    //     resolve: {
    //         appConfig: GruulsConfigResolver,
    //     },
    // },

    //
    // // Redirect empty path to '/example'
    // {path: '', pathMatch : 'full', redirectTo: 'example'},
    //
    // // Redirect signed in user to the '/example'
    // //
    // // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // // path. Below is another redirection for that path to redirect the user to the desired
    // // location. This is a small convenience to keep all main routes together here on this file.
    // {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'example'},
    //
    //
    // // Auth routes for guests
    // {
    //     path: '',
    //     canActivate: [NoAuthGuard],
    //     canActivateChild: [NoAuthGuard],
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         {path: 'confirmation-required', loadChildren: (): any => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
    //         {path: 'forgot-password', loadChildren: (): any => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
    //         {path: 'reset-password', loadChildren: (): any => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
    //         {path: 'sign-in', loadChildren: (): any => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
    //         {path: 'sign-up', loadChildren: (): any => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
    //     ]
    // },
    //
    // // Auth routes for authenticated users
    // {
    //     path: '',
    //     canActivate: [AuthGuard],
    //     canActivateChild: [AuthGuard],
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         {path: 'sign-out', loadChildren: (): any => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
    //         {path: 'unlock-session', loadChildren: (): any => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
    //     ]
    // },
    //
    // // Landing routes
    // {
    //     path: '',
    //     component  : LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children   : [
    //         {path: 'home', loadChildren: (): any => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
    //     ]
    // },
    //
    // // Admin routes
    // {
    //     path       : '',
    //     canActivate: [AuthGuard],
    //     canActivateChild: [AuthGuard],
    //     component  : ExampleComponent,
    //     // resolve    : {
    //     //     initialData: InitialDataResolver,
    //     // },
    //     children   : [
    //         {path: 'example', loadChildren: (): any => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)},
    //         //{path: 'example-next', loadChildren: () => import('app/modules/admin/example-next/example-next.module').then(m => m.ExampleNextModule)},
    //     ]
    // },

    {
        path: '**',
        component: AppComponent,
        // resolve    : {
        //     appConfig: GruulsConfigResolver,
        // }
    },
];
