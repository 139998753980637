import { FieldConfig } from '@gruuls-core/domain/domain/templates-config';
import { FormElementsAngularUtils } from '@gruuls-fe/utils/form-elements-angular-utils';
import _lodash from 'lodash';
import { CollectionType } from '../collection-type';
import { Field } from '../field';
import { FieldType } from '../field-type';
import { Fields } from '../fields';

const _ = _lodash;


export class ConditionalFieldByEnumField extends Field {

    enumValues: string[] = [];
    conditionalFields: string[] = [];

    public constructor(
        name: string,
        subFields: Field[],
        type: FieldType,
        collectionType: CollectionType,
        fieldConfig: FieldConfig,
        public conditionalFieldsByEnum: {
            [enumValue: string]: string[]; // enumvalue: fieldsPath[]
        },
        modelType?: string
    ) {
        super(name, subFields, undefined, type, collectionType, fieldConfig, modelType);
        for (const e of Object.keys(conditionalFieldsByEnum)) {
            if (this.enumValues.indexOf(e) < 0) {
                this.enumValues.push(e);
            }
            for (const cf of conditionalFieldsByEnum[e]) {
                if (this.conditionalFields.indexOf(cf) < 0) {
                    this.conditionalFields.push(cf);
                }
            }
        }
    }

    public static ofConfig(config: {
                               name: string;
                               subFields?: Field[];
                               type?: FieldType;
                               collectionType?: CollectionType;
                               conditionalFieldsByEnumValues?: {
                                   [enumValue: string]: string[]; // enumvalue: fieldName
                               };
                               modelType?: string;
                               fieldConfig?: FieldConfig;
                           }
    ): Field {
        Fields.counter = Fields.counter++;
        config = _.defaultsDeep(
            {
                type: FieldType.conditionalFieldsByEnumValues
            },
            config,
            {
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.simpleFormControlElement
                }
            });

        return new ConditionalFieldByEnumField(config.name, config.subFields, config.type, config.collectionType, config.fieldConfig, config.conditionalFieldsByEnumValues, config.modelType);
    }

}
