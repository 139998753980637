import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, HostBinding, Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {
    GruulsContainerFlexUiBuilderComponent
} from '../ui-builders/container-flex/gruuls-container-flex-ui-builder.component';
import {ModalElement} from '../../../@gruuls-core/elements/ui/modal-element/modal-element';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable, Subject} from "rxjs";

@Component({
    selector: '[gruuls-modal-element]',
    templateUrl: 'gruuls-modal-element.component.html',
    styleUrls: ['gruuls-modal-element.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class GruulsModalElementComponent extends GruulsContainerFlexUiBuilderComponent implements OnInit{


    note$: Observable<any>;
    labels$: Observable<any[]>;

    noteChanged: Subject<any> = new Subject<any>();


    @Input() element: ModalElement;

    constructor(
        protected cdr: ChangeDetectorRef,
        protected dialogRef: MatDialogRef<GruulsModalElementComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {element: ModalElement}
    ) {
        super(cdr);
        if (data?.element){
            this.element = data.element;
        }

    }


    ngOnInit(): void {
        const t = this;
        super.ngOnInit();
    }

}
