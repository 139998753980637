<!-- Button -->
<ng-container *ngIf="availableStores?.length">
    <button mat-icon-button [matMenuTriggerFor]="stores">
        <ng-container *ngTemplateOutlet="storePlaceholder; context: {$implicit: activeStore}"></ng-container>
    </button>

    <!-- Language menu -->
    <mat-menu [xPosition]="'before'" #stores="matMenu">
        <ng-container *ngFor="let store of availableStores; trackBy: trackByFn">
            <button mat-menu-item (click)="setActiveStore(store)">
                <span class="flex items-center">
                    <!-- <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: lang.id}"></ng-container> -->
                    <span class="ml-3">{{store.name}}</span>
                </span>
            </button>
        </ng-container>
    </mat-menu>

    <!-- Element template -->
    <ng-template #storePlaceholder>
        <ng-container *ngIf="activeStore">
            <span class="relative w-6 rounded-sm overflow-hidden">
                <span class="absolute inset-0 ring-1 ring-black ring-opacity-10 font-extrabold"></span>
                {{activeStore.name.charAt(0)}}
                <!-- <img
            class="w-full"
            [src]="'assets/images/flags/' + flagCodes[lang].toUpperCase() + '.svg'"
            [alt]="'Flag image for ' + lang"> -->
                
            </span>
        </ng-container>
    </ng-template>
</ng-container>