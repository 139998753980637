import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import { QrnerUrlRoutingInterface } from './qrner-url-routing-interface';


export class QrnerUrlRoutingAngular implements QrnerUrlRoutingInterface{

    constructor(
        private activatedRouteSnapshot: ActivatedRoute
    ) {
    }

    getUrlParams(): { [param: string]: string } {
        return this.activatedRouteSnapshot.snapshot.params;
    }



}
