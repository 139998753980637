import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit, TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, NgForm, Validators } from '@angular/forms';
import { BehaviorSubject, concat, forkJoin, fromEvent, merge, mergeMap, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, exhaustMap, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { BeautyciansUtils } from '../../../utils/utils';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import moment from 'moment/moment';
import { MatDialog } from '@angular/material/dialog';
import { GruulsAuthService } from "../../../../../@gruuls-fe/services/gruuls-auth.service";
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Person } from '../client.types';
import { GruulsAngularClientService } from '@gruuls-fe/services/gruuls-angular-client-service';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GruulsAngularStoreService } from '@gruuls-fe/services/gruuls-angular-stores-service';

@Component({
    selector: 'client-list',
    templateUrl: './client-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientListComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('newUserForm') newUserForm: NgForm;
    @ViewChild('userModal') userModal: TemplateRef<any>;
    @ViewChild('userFormModalContent') userFormModalContent: ElementRef;

    page: number = 0;
    reachedLimit: boolean = false;
    drawerMode: 'side' | 'over';
    searchInputControl: FormControl = new FormControl();
    filteredContacts: any[];

    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private reachedLimitSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    isLoading: boolean;
    isSpecificLoading: boolean[] = [];
    isLoadingError: boolean = false;
    entities: any[];

    translateStrings: any = { client: {}, user: {}, generic: {} };

    selectedUser: Person;
    stores: any[];

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _adapter: DateAdapter<any>,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _snackBar: MatSnackBar,
        private _httpClient: GruulsAngularHttpProxyService,
        private _translate: GruulsAngularTranslateService,
        private _fb: FormBuilder,
        private dialog: MatDialog,
        private _authService: GruulsAuthService,
        private _clientService: GruulsAngularClientService,
        private _storesService: GruulsAngularStoreService
    ) {

    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    addNewUserForm = this._fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: [''],
        address: [''],
        sex: [''],
        birthdate: [''],
        // mobilePhoneNumber: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
        mobilePhoneNumber: ['', Validators.required],
        store: [''],
        // privacyConsent: ['', Validators.required],
    });

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const paginate = false;
        this.isLoading = true;

        if (!paginate) {
            this.reachedLimitSubject$.next(true);
            this.reachedLimitSubject$.complete();
        }

        forkJoin({
            // clients: this._clientService.clients$.pipe(
            // this._apiCaller.getPersonsByRoleTemplateName(['Client'], { '_referenceOrganizationId': this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId }, CLIENT_SM_ASSEMBLE, paginate ? 0 : null).pipe(
            //     map((clients) => clients.hits),
            clients: this.loadContent(null).pipe(
                takeUntil(this._unsubscribeAll),
            ),
            stores: this._storesService.refreshStores().pipe(
                takeUntil(this._unsubscribeAll),
            )
        }).subscribe({
            next: (res) => {
                this.isLoading = false;
                this.isLoadingError = false;
                this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                if (err.status >= 500)
                    this.isLoadingError = true;
                console.error(err);
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            }
        });
        
        // Subscribe to search input field value changes
        this.searchInputControl.valueChanges.pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(300),
            switchMap(query =>
                this._clientService.filterClients(query)
            ),
            // map(query => BeautyciansUtils.searchStringByKeys(this._contacts, query, ['firstName', 'lastName'])),
            // map(queryResult => BeautyciansUtils.sortByKeys(queryResult, ['firstName', 'lastName'])),
            // tap(filteredContacts => this.filteredContacts = filteredContacts)
        ).subscribe(() => this._changeDetectorRef.markForCheck());

        this._storesService.stores$.pipe(
            takeUntil(this._unsubscribeAll),
            tap((responseStores) => {
                this.stores = responseStores;
            })
        ).subscribe();
        // TODO: new way to get clients
        this._clientService.clients$.pipe(
            takeUntil(this._unsubscribeAll),
            tap((clients) => {
                this.filteredContacts = BeautyciansUtils.sortByKeys(clients, ['firstName', 'lastName']);
                this._changeDetectorRef.markForCheck();
            })
        ).subscribe();

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Set the drawerMode if the given breakpoint is active
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                } else {
                    this.drawerMode = 'over';
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this.dialog.afterOpened.pipe(
            takeUntil(this._unsubscribeAll),
            delay(0)
        ).subscribe(() => {
            if (this.selectedUser) {
                this.addNewUserForm.controls['firstName'].setValue(this.selectedUser.firstName);
                this.addNewUserForm.controls['lastName'].setValue(this.selectedUser.lastName);
                this.addNewUserForm.controls['address'].setValue(this.selectedUser.address);
                this.addNewUserForm.controls['email'].setValue(this.selectedUser.account?.email ?? "");
                this.addNewUserForm.controls['sex'].setValue(this.selectedUser.sex);
                this.addNewUserForm.controls['mobilePhoneNumber'].setValue(this.selectedUser.mobilePhoneNumber);
                this.addNewUserForm.controls['birthdate'].setValue(this.selectedUser.birthdate ? moment(this.selectedUser.birthdate) : undefined);
                // TODO: load Role and linked CLIENT instances
                // this.addNewUserForm.controls['store'].setValue(this.selectedUser.storeId);
                // this.addNewUserForm.controls['store'].disable();
                // this.addNewUserForm.controls['privacyConsent'].setValue(this.selectedUser.privacyConsent.consenso ?? false);
            } else if (this.newUserForm) {
                this.newUserForm.resetForm();
                if (this.stores && this.stores.length > 0)
                    this.addNewUserForm.controls['store'].setValue(this.stores[0].storeId);
                this.addNewUserForm.controls['sex'].setValue("ND");
            }
        });

        // Translations
        // TODO: Chiedere a Marco per quale motivo ritorna una stringa e non un Observable, anche se il tipo richiesto è l'Obs
        const clientTranslations = ['plural', 'singular', 'noClients', 'search', 'addNew', 'noSearchResults', 'deleteWarning', 'deleteWarningMessage', 'sendMedicalHistory', 'referenceStore', 'privacy', 'sendMedicalHistoryMessage', 'sendMedicalHistoryOK', 'create'];

        clientTranslations.forEach((translation) => {
            this.translateStrings['client'][translation] = this._translate.translate('clients.' + translation);
        });

        const userTranslations = ['firstName', 'lastName', 'address', 'sex', 'male', 'female', 'ND', 'birthdate', 'pictureUrl', 'roles', 'mobilePhoneNumber', 'email'];
        userTranslations.forEach((translation) => {
            this.translateStrings['user'][translation] = this._translate.translate('users.' + translation);
        });

        const genericTranslations = ['save', 'OK', 'close', 'cancel', 'delete', 'edit', 'error503', 'loadMore', 'validatorMobilePhoneNumber', 'privacyLink'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });

        // Listener to scroll that create an infinite scroll effect by calling loadMore if the user reach the end of the page
        // TODO With Abi: check why stop condition is not working
        // const stopConditions$ = merge(this._unsubscribeAll, this.reachedLimitSubject$).pipe(
        //     tap((s) => {
        //         console.log("stop", s)
        //     })
        //   );

        // fromEvent(window, 'scroll').pipe(
        //     takeUntil(stopConditions$),
        //     map(() => window.scrollY),
        //     filter((offset) => {
        //         return offset >= document.body.offsetHeight - window.innerHeight - 200;
        //     }),
        //     exhaustMap(() => {
        //         return this.loadContent(this.page);
        //     }),
        //     tap(() => {
        //         this.page++;
        //     })
        // ).subscribe();

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit(): void {
        this._clientService.filterClients("").subscribe(
            (clients) => {
                this.filteredContacts = BeautyciansUtils.sortByKeys(clients, ['firstName', 'lastName']);
                this._changeDetectorRef.markForCheck();
            }
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    loadContent(page: number = null): Observable<any> {
        if (page == null) {
            this.reachedLimitSubject$.next(true);
            this.reachedLimitSubject$.complete();
        }
        this.isSpecificLoading['loadMore'] = true;
        this._changeDetectorRef.markForCheck();
        // return this._apiCaller.getPersonsByRoleTemplateName(['Client'], { '_referenceOrganizationId': this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId }, CLIENT_SM_ASSEMBLE, page).pipe(
        return this._clientService.refreshClients(page).pipe(
            takeUntil(this._unsubscribeAll),
            tap((responseClients) => {
                this.isSpecificLoading['loadMore'] = false;
                this._changeDetectorRef.markForCheck();
            })
        );
    }

    deleteContact(contact: any): void {
        // TODO: could be interesting to add anamnesi count using getAnamnesiCount
        const confirmation = this._fuseConfirmationService.open({
            title: this.translateStrings.client.deleteWarning + " " + contact.firstName + " " + contact.lastName,
            message: this.translateStrings.client.deleteWarningMessage,
            actions: {
                confirm: {
                    label: this.translateStrings.generic.delete
                }
            }
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) => {

            if (result === 'confirmed') {
                this._clientService.deleteClient(contact.personId).subscribe();
            }
        })
    }

    sendMedicalHistory(contact): void {
        const confirmation = this._fuseConfirmationService.open({
            title: "Invio anamnesi a " + contact.firstName + " " + contact.lastName,
            message: this.translateStrings.client.sendMedicalHistoryMessage + contact.firstName + " " + contact.lastName,
            icon: {
                show: true,
                name: 'send',
                color: 'primary'
            },
            actions: {
                confirm: {
                    label: this.translateStrings.client.sendMedicalHistory,
                    color: 'primary'
                },
                cancel: {
                    label: this.translateStrings.generic.cancel
                }

            }
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) => {

            if (result === 'confirmed') {
                this.isSpecificLoading['sendMedicalHistory'] = true;
                this._changeDetectorRef.markForCheck();
                this._apiCaller.sendMedicalHistoryEmail(contact.personId).subscribe((res) => {
                    this.isSpecificLoading['sendMedicalHistory'] = false;
                    // Show a confirm to the user
                    this._snackBar.open(this.translateStrings.client.sendMedicalHistoryOK, this.translateStrings['generic']['OK'], {
                        duration: 3000,
                        horizontalPosition: "left",
                        verticalPosition: "bottom"
                    });
                    this._changeDetectorRef.markForCheck();
                });
            }
        })


    }

    userFormSubmit(): void {
        if (this.addNewUserForm.invalid) {
            this.addNewUserForm.markAllAsTouched();
            this.userFormModalContent.nativeElement.scrollTop = 0;
            return;
        }
        this.isSpecificLoading['save'] = true;

        this.addNewUserForm.disable();

        let client: Person = {
            personId: this.selectedUser ? this.selectedUser.personId : null,
            firstName: this.addNewUserForm.value.firstName,
            lastName: this.addNewUserForm.value.lastName,
            address: this.addNewUserForm.value.address,
            sex: this.addNewUserForm.value.sex,
            mobilePhoneNumber: this.addNewUserForm.value.mobilePhoneNumber,
            birthdate: this.addNewUserForm.value.birthdate ? this.addNewUserForm.value.birthdate.format('YYYY-MM-DD') : null,
            // account: {
            //     email: this.addNewUserForm.value.email,
            //     username: this.addNewUserForm.value.email
            // },
            // privacyConsent: {
            //     versione: Date.now().toString(),
            //     consenso: this.addNewUserForm.value.privacyConsent
            // }
        }

        if (this.addNewUserForm.value.email) {
            client.account = {
                email: this.addNewUserForm.value.email,
                username: this.addNewUserForm.value.email
            }
        }


        let clientObservable: Observable<any>;
        if (this.selectedUser) {
            clientObservable = this._clientService.updateClient(client);
        } else {
            const storeId = this.addNewUserForm.controls['store'] ? this.addNewUserForm.controls['store'].value : null;
            clientObservable = this._clientService.createClient(client, storeId);
        }

        clientObservable.pipe(
            takeUntil(this._unsubscribeAll),
        ).subscribe({
            next: (res) => {
                this.isSpecificLoading['save'] = false;
                this.newUserForm.resetForm();
                this.addNewUserForm.enable();
                this.dialog.closeAll();
                this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                console.error('Error while saving client: ' + err);
                this.isSpecificLoading['save'] = false;
                this.addNewUserForm.enable();
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    /**
     * On backdrop clicked
     */
    onBackdropClicked(): void {
        // Go back to the list
        this._router.navigate(['./'], { relativeTo: this._activatedRoute });

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }    

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.personId || index;
    }

    openModal(): void {
        this.dialog.open(this.userModal, {
            panelClass: ['md:w-160', 'w-full']
        });
    }

}
