<form name="'form-{{appointment.appointmentId}}'">
    <li class="relative flex py-7">
      <div class="absolute -top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600"></div>
      <!-- add cdkDrag to element below for drag&drop -->
      <div class="relative flex flex-auto w-full">
        <div class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-accent-100"
          [ngClass]="appointment.treatmentId ? 'bg-accent-200' : '' ">
          <!-- icon on the left -->
          <mat-icon role="img" class="mat-icon notranslate  text-white icon-size-5 mat-icon-no-color"
            aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="star"
            data-mat-icon-namespace="heroicons_solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true"
              fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
              <path fill-rule="evenodd"
                d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                clip-rule="evenodd"></path>
            </svg>
          </mat-icon>
        </div>

        <div class="flex flex-row items-start w-full">
          <mat-expansion-panel displayMode="flat" class="shadow-none bg-transparent p-0 w-full">
            <mat-expansion-panel-header class="no-hover flex flex-row">
              <mat-panel-title class="flex-shrink-0 w-20">
                <mat-select id="treatment-dropdown-{{appointment.appointmentId}}"
                  [(value)]="appointment.treatmentId" placeholder="{{translateStrings.beautyPath.treatment}}"
                  class="w-full min-h-6 p-1 py-2 pl-4 pr-2 rounded-full bg-hover white-selected text-white"
                  [ngClass]="appointment.treatmentId ? 'bg-accent-200' : '' " (click)="$event.stopPropagation()"
                  (selectionChange)="changeAppointment($event)">
                  <mat-option *ngFor="let treatment of treatments"
                    [value]="treatment.uuid">{{treatment.name}}</mat-option>
                </mat-select>
              </mat-panel-title>
              <mat-panel-description>
                <div class="flex flex-col sm:flex-row sm:items-center sm:space-x-1 text-md leading-5 w-20">
                  <div class="left text-gray-700">
                    <date-picker [value]="appointment.date"
                      placeHolder="{{translateStrings.beautyPath.notPlanned}}"
                      (onSelectedDate)="changeDate($event)"></date-picker>
                  </div>
                  <button mat-icon-button color="accent" (click)="createComment()">
                    <mat-icon class="icon-size-4" svgIcon="mat_outline:comment"></mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="$event.stopPropagation(); $event.preventDefault(); deleteAppointment()" >
                    <mat-icon class="icon-size-4" svgIcon="delete"></mat-icon>
                  </button>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <!-- <div class="ng-star-inserted font-medium ml-5">{{session.name ?? translateStrings.beautyPath.singular }}</div> -->
            <!-- <div class="hidden sm:block ng-star-inserted">•</div><a class="cursor-pointer text-primary ng-star-inserted" href="/apps/mailbox">Test Link</a> -->

            <textarea matInput class="w-5/6 mt-4 px-3 py-2 rounded-lg bg-gray-100 dark:bg-gray-800"
              [value]="appointment.description ?? ''"></textarea>
          </mat-expansion-panel>
        </div>
      </div>

    </li>
  </form>
