<div class=" inset-0 flex flex-col min-w-0 overflow-hidden">
    <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent" (backdropClick)="onBackdropClicked()">

        <!-- Drawer -->
        <mat-drawer class="w-full md:w-160 dark:bg-gray-900" [mode]="drawerMode" [opened]="false" [position]="'end'"
            [disableClose]="true" #matDrawer>
            <router-outlet></router-outlet>
        </mat-drawer>

        <mat-drawer-content class="flex flex-col">

            <!-- Main -->
            <div class="flex-auto">
                <!-- Header -->
                <div class="flex flex-col sm:flex-row md:flex-col flex-auto justify-between py-8 px-6 md:px-8 border-b">

                    <!-- Title -->
                    <div>
                        <div class="text-4xl font-extrabold tracking-tight leading-none">
                            {{translateStrings.element.plural}}</div>
                        <div class="ml-0.5 font-medium text-secondary">
                            <ng-container *ngIf="filteredElements?.length">
                                {{filteredElements.length}}
                            </ng-container>
                            {{filteredElements?.length | i18nPlural: {
                            '=0' : translateStrings.element.no,
                            '=1' : translateStrings.element.singular,
                            'other': translateStrings.element.plural
                            } }}
                        </div>
                        <!-- Loader -->
                        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
                            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                        </div>

                    </div>

                    <!-- Main actions -->
                    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
                        <!-- Search -->
                        <div class="flex-auto">
                            <mat-form-field
                                class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                                <input matInput [formControl]="searchInputControl" [autocomplete]="'off'"
                                    [placeholder]="translateStrings.element.search">
                            </mat-form-field>
                        </div>
                        <!-- Add element button -->
                        <button class="ml-4" mat-flat-button [color]="'primary'"
                            (click)="this.selectedOwner = undefined; openModal()">
                            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            <span class="ml-2 mr-1">{{ translateStrings.element.addNew }}</span>
                        </button>
                    </div>
                </div>

                <!-- list -->
                <div class="relative">
                    <ng-container *ngIf="(isLoading || !isLoadingError); else loadingerror">
                        <ng-container *ngIf="filteredElements?.length; else noElements">
                            <ng-container *ngFor="let element of filteredElements; let i = index; trackBy: trackByFn">
                                <!-- element -->
                                <a class="z-20 flex items-center px-6 py-4 md:px-8 cursor-pointer border-b justify-between hover:bg-accent hover:bg-opacity-10 dark:hover:bg-hover"
                                    (click)="selectedOwner = element; openModal()">
                                    <!-- [routerLink]="['../detail', element.personId]"> -->
                                    <div class="flex items-center">
                                        <div
                                            class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                            <ng-container *ngIf="element.avatar">
                                                <img class="object-cover w-full h-full" [src]="element.avatar"
                                                    alt="element avatar" />
                                            </ng-container>
                                            <ng-container *ngIf="!element.avatar">
                                                <div
                                                    class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-accent bg-opacity-70 text-white dark:bg-gray-700 dark:text-gray-200">
                                                    {{element?.firstName?.charAt(0)}}
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="min-w-0 ml-4 ">
                                            <div class="font-medium leading-5 truncate">{{element.firstName}}
                                                {{element.lastName}}
                                                <!-- <span
                                                    *ngIf="element.roles[0]?.hasRole[0]?.name=='Beautycians-Stores-Owner'"
                                                    class="bg-red-100 text-red-800 text-xs font-medium mx-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">Owner</span> -->
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        (click)="$event.stopPropagation();  $event.preventDefault(); selectedOwner = element;"
                                        mat-icon-button [matMenuTriggerFor]="menu" aria-label="Toggle menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <!-- <button mat-menu-item (click)="openModal()" [routerLink]="['../detail', element.personId]">
                                        <mat-icon>edit</mat-icon>
                                        <span>Edit</span>
                                    </button> -->
                                        <button mat-menu-item (click)="openModal()">
                                            <mat-icon>edit</mat-icon>
                                            <span>Edit</span>
                                        </button>
                                        <button mat-menu-item (click)="sendWelcomeHandler()">
                                            <mat-icon>email</mat-icon>
                                            <span>Send Welcome</span>
                                        </button>
                                        <button mat-menu-item class="text-red-400" (click)="deleteContact(element)">
                                            <mat-icon class="text-red-400">delete</mat-icon>
                                            <span>{{translateStrings.generic.delete}}</span>
                                        </button>
                                    </mat-menu>
                                </a>
                            </ng-container>

                            <!-- Load more -->
                            <div *ngIf="!reachedLimitSubject.value" class="mx-auto my-12 items-center w-64">
                                <button *ngIf="!isSpecificLoading['loadMore']" class="w-64" mat-flat-button
                                    color="accent" [disabled]="isSpecificLoading['loadMore']" #loadMoreButton>
                                    <span *ngIf="!isSpecificLoading['loadMore']" class="ml-2 mr-1">{{
                                        translateStrings.generic.loadMore }}</span>
                                </button>
                                <div *ngIf="isSpecificLoading['loadMore']" class="w-1 mx-auto">
                                    <mat-progress-spinner [diameter]="24"
                                        [mode]="'indeterminate'"></mat-progress-spinner>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <!-- No Elements -->
                    <ng-template #noElements>
                        <div *ngIf="!isLoading"
                            class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">
                            {{translateStrings.element.noSearchResults}}</div>
                    </ng-template>

                </div>
            </div>

        </mat-drawer-content>
    </mat-drawer-container>
</div>


<!-- Main modal -->
<ng-template #accountModal>
    <h3 mat-dialog-title>{{ selectedElement ? translateStrings.element.editAdmin :
        translateStrings.element.addNew }}</h3>
    <mat-dialog-content>
        <form [formGroup]="accountFormGroup" (ngSubmit)="accountFormSubmit($event.submitter.id)" #accountForm="ngForm">
            <div class="flex flex-col gt-xs:flex-row p-7">

                <mat-form-field class="flex-auto gt-xs:pr-3">
                    <mat-label>{{translateStrings.user.firstName}}</mat-label>
                    <input matInput formControlName="firstName">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.lastName}}</mat-label>
                    <input matInput formControlName="lastName">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.email}}</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pr-3">
                    <mat-label>{{translateStrings.generic.defaultpassword}}</mat-label>
                    <input matInput type="password" formControlName="defaultpassword" #defaultpassword>
                    <button mat-icon-button type="button"
                        (click)="defaultpassword.type === 'password' ? defaultpassword.type = 'text' : defaultpassword.type = 'password'"
                        matSuffix>
                        <mat-icon class="icon-size-5" *ngIf="defaultpassword.type === 'password'"
                            [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                        <mat-icon class="icon-size-5" *ngIf="defaultpassword.type === 'text'"
                            [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                    </button>
                </mat-form-field>

            </div>

            <!-- Modal footer -->
            <div
                class="flex justify-between items-center py-6 mx-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <div>
                    <button mat-button mat-dialog-close type="button"
                        class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{{translateStrings.generic.close}}</button>
                </div>

                <div class="flex space-x-2">
                    <button id="save" type="submit" mat-flat-button [color]="'secondary'" class="rounded-lg"
                        [disabled]="!accountFormGroup.valid">
                        <span *ngIf="!isSpecificLoading['save']">
                            {{translateStrings.generic.save}}
                        </span>
                        <mat-progress-spinner *ngIf="isSpecificLoading['save']" [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>
                    <button id="saveAndSendWelcome" type="submit" mat-flat-button [color]="'primary'" class="rounded-lg"
                        [disabled]="!accountFormGroup.valid">
                        <span *ngIf="!isSpecificLoading['saveAndSendWelcome']">
                            {{translateStrings.generic.saveAndSendWelcome}}
                        </span>
                        <mat-progress-spinner *ngIf="isSpecificLoading['saveAndSendWelcome']" [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>
                </div>
            </div>

        </form>
    </mat-dialog-content>
</ng-template>

<ng-template #loadingerror>
    <img src="/assets/images/ui/beautycians/error_503.jpg" alt="Error" class="w-2/6 h-1/6 mx-auto">
    <div class="text-2xl font-semibold tracking-tight text-center w-4/6 sm:w-full mx-auto">
        {{translateStrings.generic.error503}}</div>
</ng-template>