import {Component, ViewEncapsulation} from '@angular/core';
import {BaseElement} from '@gruuls-core/elements/misc/base-element/base-element';
import {GruulsAngularTranslateService} from '@gruuls-fe/services/gruuls-angular-translate.service';
import {GruulsAngularNumberFormatterService} from '@gruuls-fe/services/gruuls-angular-number-formatter.service';
import {ContextElement} from '@gruuls-core/elements/misc/context-element/context-element';
import { BaseElementStatus } from '@gruuls-core/elements/misc/base-element/base-element-config';
import { installPatch } from '@gruuls-fe/patches/nested-drag-drop-patch';
import {map} from 'rxjs/operators';
import {GruulsAngularRoutingService} from '@gruuls-fe/services/gruuls-angular-routing.service';
import {GruulsAngularHttpProxyService} from "../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service";

@Component({
    selector: 'example-next',
    templateUrl: './example-next.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ExampleNextComponent {

    data = {
        name: 'Marco'
    };

    context: ContextElement;

    bes = BaseElementStatus;
    constructor(
        translateService: GruulsAngularTranslateService,
        numberFormatterService: GruulsAngularNumberFormatterService,
        routingService: GruulsAngularRoutingService,
        httpService: GruulsAngularHttpProxyService
    ) {

        installPatch();

        const pageConfig = {
            name: 'MainPage',
            type: 'DynamicFlexContainer',
            layout: 'col',
            logging: false,
            width: 'full',
            height: 'full',
            containerBackgroundImage: 'url(https://console.next14.com/images/white-rabbit/login.jpg)',
            containerBackgroundRepeat: 'no-repeat',
            containerBackgroundPosition: 'center',
            containerBackgroundSize: 'cover',
            elements: [
                {
                    name: 'Top',
                    type: 'DynamicFlexContainer',
                    containerHeight: '1/6'
                },
                {
                    name: 'Middle',
                    type: 'DynamicFlexContainer',
                    containerHeight: '3/6',
                    //itemFillSpace: 'initial',
                    containerItemDisposition: 'middle-middle',
                    itemDisposition: 'middle-middle',

                    elements: [
                        // {
                        //     name: 'MiddleLeft',
                        //     type: 'DynamicFlexContainer',
                        //     containerWidth: '2/6',
                        // },
                        {
                            name: 'MiddleMiddle',
                            type: 'DynamicFlexContainer',
                            width: '90',
                            itemFillSpace: 'auto',
                            //containerItemDisposition: 'middle-middle',
                            itemDisposition: 'middle-middle',
                            backgroundColor: 'rgba(255, 255, 255, 0.75)',
                            layout: 'col',
                            elements: [
                                {
                                    name: 'logo',
                                    marginTop: '15',
                                    height: '30',
                                    width: '30',
                                    //containerHeight: '30',
                                    //containerWidth: '40',
                                    itemDisposition: 'middle-middle',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundImage: 'url(https://console.next14.com/images/white-rabbit/placeholder.png)'
                                },
                                {
                                    name: 'username',
                                    type: 'InputElement',
                                    containerMarginLeft: 'xxx',
                                    containerMarginRight: 'xxx',
                                    marginLeft: '5',
                                    marginRight: '5',
                                    containerItemDisposition: 'top-middle',
                                    containerItemFillSpace : 'initial',
                                    width: 'full',
                                    label: 'email'
                                },
                                {
                                    name: 'pass',
                                    type: 'InputElement',
                                    containerMarginLeft: 'xxx',
                                    containerMarginRight: 'xxx',
                                    marginLeft: '5',
                                    marginRight: '5',
                                    containerItemDisposition: 'top-middle',
                                    containerItemFillSpace : 'initial',
                                    width: 'full',
                                    label: 'password'
                                },
                                {
                                    name: 'loginButton',
                                    type: 'ButtonElement',
                                    containerItemsDisposition: 'zzz',
                                    itemsDisposition: 'top-middle',
                                    containerItemFillSpace : 'auto',
                                    containerWidth: 'full',
                                    width: 'full',
                                    paddingBottom: '5',
                                    buttonType: 'flat',
                                    label: 'LOGIN',
                                },
                                {
                                    name: 'passReset',
                                    type: 'BaseElement',
                                    containerItemsDisposition: 'zzz',
                                    itemsDisposition: 'top-middle',
                                    chainElementConfigs: ['Reset your password'],
                                    textColor: '#8A9BA6',
                                    fontSize: 'sm',
                                    paddingBottom: '5',
                                },
                                {
                                    name: 'passReset',
                                    type: 'BaseElement',
                                    containerItemsDisposition: 'zzz',
                                    itemsDisposition: 'top-middle',
                                    chainElementConfigs: ['or login with'],
                                    fontWeight: 'semibold',
                                    textColor: '#8A9BA6',
                                    fontSize: 'sm',
                                    paddingBottom: '5',
                                },
                                {
                                    name: 'ButtonElementToggle',
                                    type: 'ButtonElement',
                                    containerItemsDisposition: 'zzz',
                                    itemsDisposition: 'top-middle',
                                    buttonType: 'toggle',
                                    label: 'custom domain',
                                    paddingBottom: '15',
                                }
                            ]
                        },
                        // {
                        //     name: 'MiddleRight',
                        //     type: 'DynamicFlexContainer',
                        //     containerWidth: '2/6',
                        // },
                    ]
                },
                {
                    name: 'Bottom',
                    type: 'DynamicFlexContainer',
                    containerHeight: '2/6',
                    elements: [
                        {
                            name: 'copy',
                            chainElementConfigs: ['2018© All rights reserved...'],
                            itemsDisposition: 'bottom-left',
                            containerItemFillSpace : 'initial',
                            textColor: 'white',
                            paddingBottom: '5',
                            paddingLeft: '10',
                            fontSize: 'sm',
                        },
                        {
                            name: 'space',
                            type: 'BaseElement',
                            width: '80%'
                        },
                        {
                            name: 'facebook',
                            type: 'ButtonElement',
                            itemsDisposition: 'bottom-right',
                            containerItemFillSpace : 'initial',
                            buttonType: 'miniFab',
                            icon: 'facebook',
                            paddingBottom: '5',
                            paddingRight: '40',
                        },
                    ]
                }
            ]
        };

        this.context = new ContextElement({
            name: 'Context',
            editMode: false,
            logging: false,
            containerWidth: 'screen',
            containerHeight: 'screen',
            containerOverflow: 'hidden',
            width: 'screen',
            height: 'screen',
            overflow: 'hidden',
            elements: [pageConfig]
        }, translateService, numberFormatterService, routingService, httpService);

        this.context.init()
            .pipe(
                map((el) => {
                    el.processStream().subscribe();
                    return el;
                }),
                // mergeMap(el => interval(10000).pipe(map(i => el))),
                // mergeMap(el => el.processStream({
                //     value: Math.round(Math.random() * 100)
                // }))
            ).subscribe(
            (res) => {
                console.log(res);
            }
        );

    }
}
