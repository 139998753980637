import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, HostBinding,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {GruulsContainerFlexUiBuilderComponent} from '../../ui-builders/container-flex/gruuls-container-flex-ui-builder.component';
import {ContextElement} from '@gruuls-core/elements/misc/context-element/context-element';
import {map, mergeWith, takeUntil, tap} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SandboxedContext} from '../../../../@gruuls-core/elements/misc/context-element/sandboxed-context';
import {BaseElement} from '../../../../@gruuls-core/elements/misc/base-element/base-element';
import {GruulsModalElementComponent} from '../../modal-element/gruuls-modal-element.component';
import {ModalElement} from '../../../../@gruuls-core/elements/ui/modal-element/modal-element';

@Component({
    selector: '[gruuls-context-element]',
    templateUrl: '../../ui-builders/container-flex/gruuls-container-flex-ui-builder.component.html',
    styleUrls: ['../../ui-builders/container-flex/gruuls-container-flex-ui-builder.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class GruulsContextElementComponent extends GruulsContainerFlexUiBuilderComponent implements OnInit{

    @Input() element: ContextElement;

    dialogRef: MatDialogRef<GruulsModalElementComponent>;

    constructor(
        protected cdr: ChangeDetectorRef,
        public dialog: MatDialog
    ) {
        super(cdr);

    }


    ngOnInit(): void {
        super.ngOnInit();
        this.enableDragDrop = this.element.config.enableDragDrop;

        this.element.registerCommand('openModal', (body: any, context: SandboxedContext, thisElement: BaseElement): any=> {
            // body must be a ModalElementConfig
            this.dialogRef = this.dialog.open(GruulsModalElementComponent, {
                height: body.height || '400px',
                width: body.width || '600px',
                data: {
                    element: new ModalElement(body)
                }
            });
            return this.dialogRef.afterClosed().pipe(map(el => ({result: el})));
        });
    }
}
