<!-- cdkDropListOrientation="horizontal" -->
<!--{{element.runtimeId}} -({{element.elementList.length}})- {{allConnectedIds}}-->
<!--({{element.elementList.length}}-{{element.config1.elements.length}})-->
<div class="flex flex-col flex-auto md:w-160 md:min-w-160 -m-6">
    <h1 mat-dialog-title>Hi {{element.config.name}}</h1>
    <div mat-dialog-content>
        <p>What's your favorite animal?</p>
        <mat-form-field appearance="fill">
            <mat-label>Favorite Animal</mat-label>
            <input matInput>
        </mat-form-field>
        <ng-container *ngIf="(element.config.status !== 'initializing') && element.config.status !== 'toInitialize'">
            <ng-container *ngIf="!enableDragDrop">
                <div [ngClass]="classes()" class="box-content" style="min-width: 10px;">
                    <ng-container *ngFor="let child of element?.elementList">
                        <div *ngIf="!child.config.isBackgroundElement"
                             gruuls-element-factory
                             [element]="child"
                             [allConnectedIds]="allConnectedIds"
                        > </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="enableDragDrop">
                <div [ngClass]="classes()" class="box-content" style="min-width: 10px;"
                     cdkDropList
                     [id]="element.runtimeId"
                     [cdkDropListConnectedTo]="allConnectedIds"
                     [cdkDropListData]="element"
                     [cdkDropListOrientation] = "dropListOrientation"
                     [cdkDropListSortingDisabled]="!element.config._dropSortEnabled"
                     (cdkDropListEntered)="entered($event)"
                     (cdkDropListExited)="exited($event)">
                    <!--     (cdkDropListDropped)="drop($event)"-->
                    <!--    my: {{id}} - others: {{allConnectedIds}}-->
                    <div cdkDrag
                         [id]="element.runtimeId"
                         (cdkDragDropped)="drop($event)"
                         [cdkDragDisabled] = "!element.config._dragEnabled"

                         gruuls-element-factory
                         *ngFor="let child of element?.elementList"
                         [element]="child"
                         [allConnectedIds]="allConnectedIds">
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div mat-dialog-actions>
        <button mat-button>No Thanks</button>
        <button mat-button [mat-dialog-close]="element" cdkFocusInitial>Ok</button>
    </div>
</div>
