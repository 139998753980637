import { GruulsAngularHttpProxyService } from "@gruuls-fe/services/gruuls-angular-http-proxy.service";
import { GruulsConstants } from "app/mock-api/gruuls/gruuls-constants";
import { Observable, catchError, map, of, mergeMap, EMPTY } from "rxjs";
import moment_ from 'moment';
import { ComplexFilters, Utils } from "@gruuls-core/utils/Utils";
import { ACCOUNT_ASSEMBLE, ACCOUNT_ASSEMBLE_SM, APPOINTMENT_ASSEMBLE, CLIENT_ANAMNESI_ASSEMBLE, CLIENT_MD_ASSEMBLE, CLIENT_SM_ASSEMBLE, CLIENT_XL_ASSEMBLE, FULL_ANAMNESI_ASSEMBLE, ORDER_ASSEMBLE, ORDER_LIST_ASSEMBLE, PRODUCT_ASSEMBLE, STORE_ASSEMBLE } from "./assemble";
import { Order, OrderInfo } from "../modules/orders/order.types";
import { Product } from "../modules/products/product.types";
import { BeautyciansUtils } from "./utils";
import { Appointment, MedicalHistory, Person } from "../modules/clients/client.types";
import { EmailNotificationTemplate, Pagination, Store } from "./dataTypes";
import { GruulsAuthService } from "@gruuls-fe/services/gruuls-auth.service";
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";
import { Organization } from "../modules/centri/centri.types";
import { domain } from "process";

export class ApiCaller {

    constructor(
        private _httpClient: GruulsAngularHttpProxyService,
        private _authService: GruulsAuthService
    ) { }

    sendOrderExecutionEmail(order: Order, receiver: string = 'amministrazione@beautycians.it', baseEmail: EmailNotificationTemplate = null): Observable<any> {
        let emailData: EmailNotificationTemplate;

        emailData = {
            receiver: receiver,
            subject: "Esecuzione ordine: " + order.name,
            title: order.name,
            subtitle: "Riferimento ordine piattaforma B#" + order.id.toString(),
            CTA: "Visualizza Ordine",
            CTA_LINK: window.location.origin + "/beauty/order/supplierdetail/" + order.orderId
        }
        if (baseEmail) {
            emailData = {
                ...baseEmail,
                ...emailData
            }
        }
        return this.sendEmail(emailData);


    }

    sendOrderEmail(order: Order, receiver: string = 'fboano@gmail.com', baseEmail: EmailNotificationTemplate = null): Observable<any> {
        let emailData: EmailNotificationTemplate;

        // TODO: add the correct receiver
        emailData = {
            receiver: receiver,
            subject: "Nuovo ordine: " + order.name,
            title: order.name,
            subtitle: "ordine #" + order.id.toString(),
            CTA: "Visualizza Ordine",
            CTA_LINK: window.location.origin + "/beauty/order/supplierdetail/" + order.orderId
        }
        if (baseEmail) {
            emailData = {
                ...baseEmail,
                ...emailData
            }
        }
        return this.sendEmail(emailData);
    }

    sendEmail(emailData: EmailNotificationTemplate): Observable<any> {

        const command = {
            contextName: 'Core',
            domainName: 'Email',
            commandName: 'SEND_EMAIL',
            commandType: 'NORMAL_COMMAND',
            commandId: uuidv4(),
            body: {
                aggregate: emailData
            }
        }

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => res.hits[0])
        );
    }

    getEmptySurvey(): Observable<any> {
        // return newSurvey if available
        const query = {
            contextName: 'Beautycians',
            domainName: 'MedicalHistory',
            queryName: 'GET_SURVEY',
        };
        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => {
                return res.hits[0]?.hits;
            }),
        );
    }

    /**
     * Get Medical History Scores
     * @param {string} medicalHistoryId - The id of the medical history
     * @returns {Observable<any>} - The saved Anamnesi scores
     * 
     **/
    getMedicalHistoryScores(medicalHistoryId: string): Observable<any> {
        const contextName = "Beautycians";
        const domainName = "MedicalHistory";
        const queryName = "GET_SURVEY_SCORE";

        const query = {
            contextName: contextName,
            domainName: domainName,
            queryName: queryName,
            where: {
                MEDICAL_HISTORY_ID: medicalHistoryId
            },
            assembleAs: {
                ...FULL_ANAMNESI_ASSEMBLE
            }
        };

        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => {
                // TODO: remove in the future
                // res.hits[0]['IBD_presenz_cellu_diff_tipologia'] = 2;
                // res.hits[0]['IBD_presenz_cellu_loc_tipologia'] = 2;
                return res.hits[0];
            }),
        )
    }

    /**
     * Get the appointements of an Organization
     * @description
     * This method is used to return all the appointments, and highlight the one of a client
     * @param {string} activePersonId - The id of the person to highlight
     * @returns {Observable<any>} - A parsed list of FullCalendar event objects
     * 
     **/
    getAppointments(activePersonId: string = null, filters: ComplexFilters[] = []): Observable<Appointment[]> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'Appointments',
            queryName: 'FIND_ALL',
            assembleAs: {
                ...APPOINTMENT_ASSEMBLE
            }
        };
        let where: any = Utils.buildWhereExpression(filters);
        if (Object.keys(where).length > 0) {
            query['where'] = where
        }
        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => res.hits)
        );
    }

    getAppointmentCalendarEvents(activePersonId: string = null, filters: ComplexFilters[] = []): Observable<any> {
        return this.getAppointments(activePersonId, filters).pipe(
            map((appointments) => {
                // transform in FullCalendar event object
                return appointments.map((a) => BeautyciansUtils.appointmentToEvent(a));
            })
        );
    }

    createAppointment(appointment: Appointment): Observable<any> {
        delete appointment.appointmentId;

        const aggregate = {
            ...appointment
        };

        let body: any = {
            aggregate,
            assembleAs: {
                ...APPOINTMENT_ASSEMBLE
            }
        };

        const command = {
            contextName: 'Beautycians',
            domainName: 'Appointments',
            commandName: 'CREATE',
            commandType: 'CREATE_AGGREGATE_COMMAND',
            body
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => res.hits[0])
        )
    }

    createAppointmentCE(appointment: Appointment): Observable<any> {
        return this.createAppointment(appointment).pipe(
            map((appointment) => {
                return BeautyciansUtils.appointmentToEvent(appointment);
            })
        )
    }

    updateAppointment(appointment: Appointment): Observable<any> {
        if (!appointment.appointmentId)
            return;

        let body: any = {
            aggregate: {
                ...appointment
            },
            where: {
                appointmentId: appointment.appointmentId
            },
            assembleAs: {
                ...APPOINTMENT_ASSEMBLE
            }
        };

        const command = {
            contextName: 'Beautycians',
            domainName: 'Appointments',
            commandName: 'UPDATE',
            commandType: 'UPDATE_AGGREGATE_COMMAND',
            body
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => res.hits[0])
        )
    }

    updateAppointmentCE(uuid: string, appointment: Appointment): Observable<any> {
        return this.updateAppointment(appointment).pipe(
            map((appointment) => {
                return BeautyciansUtils.appointmentToEvent(appointment);
            })
        )
    }

    deleteAppointment(uuid: string): Observable<boolean> {
        if (!uuid)
            return;

        let body: any = {
            where: {
                appointmentId: uuid
            },
            assembleAs: {
                ...APPOINTMENT_ASSEMBLE
            }
        };

        const command = {
            contextName: 'Beautycians',
            domainName: 'Appointments',
            commandName: 'LOGICAL_DELETE',
            commandType: 'NORMAL_COMMAND',
            body
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                const deletedElement = res.hits[0];
                if (deletedElement && deletedElement.appointmentId === uuid)
                    return true;
                else return false;
            })
        )
    }

    /**
     * Get All Beauty Path
     * @description
     * This method is used to return all the available beauty paths
     * @param {string} personId - The id of the person
     * @returns {Observable<any>} - A parsed list of beauty paths
     **/
    getBeautyPaths(personId: string): Observable<any> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'BeautyPath',
            queryName: 'FIND_ALL',
            where: {
                personId: personId
            },
            assembleAs: {
                _creationTime: true,
            }
        };

        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => {
                return res.hits;
            })
        );
    }

    createStore(store: Store): Observable<any> {
        const aggregate = {
            ...store,
        };

        let body: any = {
            aggregate,
            assembleAs: {
                ...STORE_ASSEMBLE
            },
        };

        const command = {
            contextName: 'Beautycians',
            domainName: 'Store',
            commandName: 'CREATE',
            commandType: 'CREATE_AGGREGATE_COMMAND',
            body
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
        )
    }

    updateStore(store: Store): Observable<any> {
        if (!store.storeId)
            return;

        const aggregate = {
            ...store
        };

        let body: any = {
            aggregate,
            assembleAs: {
                ...STORE_ASSEMBLE
            },
            where: {
                storeId: store.storeId
            }
        };

        const command = {
            contextName: 'Beautycians',
            domainName: 'Store',
            commandName: 'UPDATE',
            commandType: 'UPDATE_AGGREGATE_COMMAND',
            body
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
        )
    }

    deleteStore(uuid: string): Observable<boolean> {
        if (!uuid)
            return;

        let body: any = {
            where: {
                storeId: uuid
            },
            assembleAs: {
                storeId: true,
            }
        };

        const command = {
            contextName: 'Beautycians',
            domainName: 'Store',
            commandName: 'LOGICAL_DELETE',
            commandType: 'NORMAL_COMMAND',
            body
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                if (res && res.length > 0 && res[0].storeId === uuid)
                    return true;
                else return false;
            })
        )
    }

    /**
 * Get All Stores
 * @description
 * This method is used to return all the available stores
 * @param {string} organizationId - The id of the organization
 * @returns {Observable<any>} - A parsed list of stores
 *  
 **/
    getStores(organizationId: string = null): Observable<any[]> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'Store',
            queryName: 'FIND_ALL',
            assembleAs: {
                ...STORE_ASSEMBLE
            }
        };
        if (organizationId)
            query['where'] = Utils.exactMatchExpression('organization', organizationId);

        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => {
                return res.hits;
            })
        );
    }


    /**
     * Get a single store
     * @description
     * This method is used to return all the available stores
     * @param {string} uuid - The id of the store
     * @returns {Observable<any>} - A parsed list of stores
     *  
     **/
    getStore(uuid: string): Observable<Store> {
        if (!uuid) return of(null);

        const query = {
            contextName: 'Beautycians',
            domainName: 'Store',
            queryName: 'FIND_ALL',
            assembleAs: {
                ...STORE_ASSEMBLE
            }
        };

        query['where'] = Utils.exactMatchExpression('storeId', uuid);

        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
        );
    }

    getOrganizations(filters: {} = {}): Observable<any> {

        const query = {
            contextName: 'Core',
            domainName: 'Organization',
            queryName: 'FIND_ALL',
            assembleAs: {
                organizationId: true,
                name: true,
                vatNumber: true,
                extra: true,
            },
        };

        const where = {};
        Object.entries(filters).forEach(([key, value]) => {
            where[key.toString()] = value;
            //Utils.exactMatchExpression(key, value.toString());
        });
        if (Object.keys(where).length > 0)
            query['where'] = where

        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => {
                return res.hits;
            })
        );

    }

    createOrganization(organization: Organization, parentOrganizationId: string): Observable<any> {
        const aggregate = {
            ...organization,
            parent: {
                organizationId: parentOrganizationId,
            },
        };

        let body: any = {
            aggregate,
            assembleAs: {
                name: true,
                extra: true,
            }
        };

        const command = {
            contextName: 'Core',
            domainName: 'Organization',
            commandName: 'CREATE',
            commandType: 'CREATE_AGGREGATE_COMMAND',
            commandId: uuidv4(),
            body: body
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
        );

    }

    updateOrganization(organization: Organization): Observable<any> {

        let body: any = {
            aggregate: {
                ...organization
            },
            assembleAs: {
                name: true,
                extra: true,
            },
            where: {
                organizationId: organization.organizationId
            }
        };

        const command = {
            contextName: 'Core',
            domainName: 'Organization',
            commandName: 'UPDATE',
            commandType: 'UPDATE_AGGREGATE_COMMAND',
            commandId: uuidv4(),
            body: body
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
        );

    }

    deleteOrganization(uuid: string): Observable<boolean> {
        if (!uuid)
            return;

        let body: any = {
            where: {
                organizationId: uuid
            },
            assembleAs: {
                organizationId: true,
            }
        };

        const command = {
            contextName: 'Core',
            domainName: 'Organization',
            commandName: 'LOGICAL_DELETE',
            commandType: 'NORMAL_COMMAND',
            body
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                if (res && res.length > 0 && res[0].organizationId === uuid)
                    return true;
                else return false;
            })
        )
    }

    relatePersonOrganization(personId: string, _referenceOrganizationId: string): Observable<any> {
        const command = {
            contextName: 'Core',
            domainName: 'Person',
            commandName: 'CHANGE_REFERENCE_ORGANIZATION_ID',
            body: {
                aggregate: {
                    _referenceOrganizationId: _referenceOrganizationId,
                    personId: personId
                },
                assembleAs: {
                    personId: true
                }
            }
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
        );
    }

    relatePersonToStore(personId: string, storeId: string): Observable<any> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'Store',
            commandName: 'RELATE_CLIENT_PERSON',
            body: {
                store: {
                    storeId: storeId,
                    personId: personId
                },
                assembleAs: {
                    active: true,
                    address: true,
                    zip: true,
                    name: true
                }
            }
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: query
        });
    }


    createAccount(organizationId: string, details: {}, email: string, roleTemplateId: string, defaultPwd: string = null): Observable<any> {
        let pwd: any = {};
        if (defaultPwd && defaultPwd != '') {
            pwd = {
                password: defaultPwd,
                passwordVerify: defaultPwd
            }
        }

        const aggregate = {
            ...details,
            account: {
                username: email,
                email: email,
                ...pwd
            },
            organizationId: organizationId,
            roles: {
                inOrganization: {
                    organizationId: organizationId
                },
                hasRole: [{
                    roleTemplateId: roleTemplateId
                }]
            }
        };

        let body: any = {
            aggregate,
            assembleAs: {
                ...ACCOUNT_ASSEMBLE,
            }
        };

        const command = {
            contextName: 'Core',
            domainName: 'Person',
            commandName: 'CREATE',
            commandType: 'CREATE_AGGREGATE_COMMAND',
            commandId: uuidv4(),
            body
        };
        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => res.hits[0]),
            mergeMap((resPerson) => {
                if (!resPerson._referenceOrganizationId || resPerson._referenceOrganizationId != organizationId) {
                    return this.relatePersonOrganization(resPerson.personId, organizationId).
                        pipe(map((res) => resPerson));
                } else {
                    return of(resPerson);
                }
            })
        );
    }

    updateAccount(accountDeatails: Person, organizationId: string, roleTemplateId: string = null): Observable<any> {
        let roleUpdate = {};
        
        if (!accountDeatails.personId)
            return;

        if (roleTemplateId && organizationId) {
            roleUpdate = {
                roles: {
                    inOrganization: {
                        organizationId: organizationId
                    },
                    hasRole: [{
                        roleTemplateId: roleTemplateId
                    }]
                }
            }
        }

        const command = {
            contextName: 'Core',
            domainName: 'Person',
            commandName: 'UPDATE',
            commandType: 'UPDATE_AGGREGATE_COMMAND',
            commandId: uuidv4(),
            body: {
                aggregate: {
                    ...accountDeatails,
                    ...roleUpdate
                },
                where: {
                    personId: accountDeatails.personId
                },
                assembleAs: {
                    ...ACCOUNT_ASSEMBLE,
                }
            },
        };
        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => res.hits[0])
        )
    }

    impersonate(username: string): Observable<any> {
        const command = {
            contextName: 'Core',
            domainName: 'Person',
            commandName: 'IMPERSONATE',
            commandType: 'NORMAL_COMMAND',
            commandId: uuidv4(),
            body: {
                username
            },
        };
        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => res.hits[0])
        )
    }

    createClient(client: Person, organizationId: string, storeId: string = null): Observable<any> {
        const command = {
            contextName: 'Core',
            domainName: 'Person',
            commandName: 'CREATE',
            commandType: 'CREATE_AGGREGATE_COMMAND',
            commandId: uuidv4(),
            body: {
                aggregate: {
                    ...client,
                    roles: {
                        inOrganization: {
                            organizationId: organizationId
                        },
                        hasRole: [{
                            roleTemplateId: '6d3a71b1-2750-4bbe-868e-946fdaf09e77'
                        }]
                    }
                },
                assembleAs: {
                    ...CLIENT_XL_ASSEMBLE,
                }
            },
        };
        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => res.hits[0]),
            mergeMap((client) => {
                if (storeId) {
                    return this.relatePersonToStore(client.personId, storeId).pipe(
                        map((res) => client)
                    )
                } else return of(client);
            })
        )
    }

    updateClient(uuid: string, client: {}): Observable<any> {
        const command = {
            contextName: 'Core',
            domainName: 'Person',
            commandName: 'UPDATE',
            commandType: 'UPDATE_AGGREGATE_COMMAND',
            commandId: uuidv4(),
            body: {
                aggregate: {
                    ...client
                },
                where: {
                    personId: uuid
                },
                assembleAs: {
                    ...CLIENT_XL_ASSEMBLE,
                }
            },
        };
        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => res.hits[0])
        )
    }

    getClients(filters: {} = {}, assembleAs: {} = CLIENT_SM_ASSEMBLE, sort: string[] | {}[] = [], page: number = null): Observable<any> {
        // return this.getPersons(filters, assembleAs, ["firstName"], page).pipe(
        //     map((persons) => {
        //         return persons.filter((person) => Utils.hasRole(person.roles, 'Client'));
        //     }),
        return this.getPersonsByRoleTemplateName(['Client'], filters, assembleAs, page).pipe(
            map((clients) => {
                return clients.hits.map((client) => {
                    client.age = client.birthdate ? moment_().diff(moment_(client.birthdate, "YYYY-MM-DD"), 'years') : null;
                    return client;
                })
            })
        )
    }

    getClient(uuid: string): Observable<any> {
        return this.getPersons({ 'personId': uuid }, CLIENT_SM_ASSEMBLE).pipe(
            map((personResponse) => personResponse[0])
        );
    }

    getClientInfo(vat: string): Observable<any> {
        const query = {
            contextName: "Beautycians",
            domainName: "Mexal",
            queryName: "INFO_CLIENTE",
            where: {
                partita_iva: vat
            },
        };

        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => res.hits[0])
        );
    }
    /**
     * Get Persons
     * @description
     * This method is used to return all the available roles
     * @param {} filters - Filters to be applied to the query
     * @returns {Observable<any>} - A parsed list of treatments
     * 
     **/
    getPersons(filters: {} = {}, assembleAs: {} = ACCOUNT_ASSEMBLE, sort: string[] | {}[] = [], page: number = null): Observable<any> {
        const query = {
            contextName: 'Core',
            domainName: 'Person',
            queryName: 'FIND_ALL',
            assembleAs,
            sort
        };
        if (page != null) {
            query['pagination'] = this.paginationObj(page);
        }

        const where = {};
        Object.entries(filters).forEach(([key, value]) => {
            where[key.toString()] = value;
            //Utils.exactMatchExpression(key, value.toString());
        });
        if (Object.keys(where).length > 0) {
            query['where'] = where
        }
        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => res.hits),
            map((persons) => {
                return persons.map((person) => {
                    person.age = person.birthdate ? moment_().diff(moment_(person.birthdate, "YYYY-MM-DD"), 'years') : null;
                    return person;
                })
            })

        );
    }

    getPerson(uuid: string): Observable<any> {
        return this.getPersons({ 'personId': uuid }).pipe(
            map((personResponse) => personResponse[0])
        );
    }

    getPersonsByRoleTemplateName(roleTemplateNames: string[], filters: {} = {}, assembleAs: {} = CLIENT_MD_ASSEMBLE, page: number = null): Observable<{ hits, hasNextPage }> {
        const sort = [];
        let f: ComplexFilters[] = [];

        let where = {};
        where['roleTemplateName'] = roleTemplateNames;
        Object.entries(filters).forEach(([key, value]) => {
            // if (key.toString().startsWith('not_'))
            //     Utils.notExactMatchExpression(key, value.toString()); // need to handle the introduction in the query
            // else
            //     where[key.toString()] = value;
            const field = key.toString().startsWith('not_') ? key.toString().substring(4) : key.toString();
            f.push({ field, value: value.toString(), operator: 'match_phrase', affirmative: !key.toString().startsWith('not_') });
        });
        where = _.merge(where, Utils.buildWhereExpression(f));
        if (Object.keys(filters).length === 0) {
            console.warn("No filters provided for GET_BY_ROLE query while looking for templates: " + roleTemplateNames.toString());
        }

        const query = {
            contextName: 'Core',
            domainName: 'Person',
            queryName: 'GET_BY_ROLE',
            assembleAs,
            where,
            // sort
        };

        if (page != null) {
            query['pagination'] = this.paginationObj(page);
        }

        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => {
                return {
                    hits: res.hits,
                    hasNextPage: res.next_page
                }
            }),
            catchError((err) => {
                console.log(err);
                return of({ hits: [], hasNextPage: false, error: true });
            }
            ));
    }

    getBeautyciansAccounts(organizationId: string, assembleAs: {} = ACCOUNT_ASSEMBLE_SM, page: number = null): Observable<any> {
        return this.getPersonsByRoleTemplateName(['Beautycians-HQ'], { _referenceOrganizationId: organizationId }, assembleAs, page);
    }

    deletePerson(uuid: string): Observable<boolean> {
        if (!uuid)
            return;

        let body: any = {
            where: {
                personId: uuid
            },
            assembleAs: {
                ...CLIENT_SM_ASSEMBLE
            }
        };

        const command = {
            contextName: 'Core',
            domainName: 'Person',
            commandName: 'LOGICAL_DELETE',
            commandType: 'NORMAL_COMMAND',
            body,
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                if (res && res.hits.length > 0 && res.hits[0].personId === uuid)
                    return true;
                else return false;
            })
        )
    }

    getRole(uuid: string): Observable<any> {
        if (!uuid)
            return of(null);

        return this.getRoles().pipe(
            map((rolesResponse) => rolesResponse[0])
        );
    }

    /**
     * Get Available Roles
     * @description
     * This method is used to return all the available roles
     * @returns {Observable<any>} - A parsed list of treatments
     * 
     **/
    getRoles(uuid: string = null): Observable<any> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'RoleTemplates',
            queryName: 'FIND_ALL',
            assembleAs: {
                uuid: true,
                name: true,
                permissions: true
            }
        };

        if (uuid)
            query['where'] = Utils.exactMatchExpression('uuid', uuid);

        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => res.hits)
        );
    }

    getInvoices(folderId: string = null): Observable<any> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'Invoices',
            queryName: 'FIND_ALL',
            assembleAs: {
                date: true,
                name: true,
            }
        };

        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
        );

    }

    getHealthPlans(): Observable<any> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'BeautyHealthPriority',
            queryName: 'FIND_ALL',
            assembleAs: {
                ...FULL_ANAMNESI_ASSEMBLE
            }
        };
        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => res.hits)
        );
    }

    /**
     * Get Available Treatments
     * @description
     * This method is used to return all the available treatments
     * @returns {Observable<any>} - A parsed list of treatments
     * 
     **/
    getTreatments(): Observable<any> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'TreatmentCatalog',
            queryName: 'FIND_ALL',
            assembleAs: {
                code: true,
                description: true,
                advisedProducts: true,
                target: true
            }
        };

        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => {
                return res.hits;
            })
        );
    }

    getPriorities(): Observable<any> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'BeautyPriority',
            queryName: 'FIND_ALL',
            assembleAs: {
                ...FULL_ANAMNESI_ASSEMBLE
            }
        };
        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => res.hits[0])
        );
    }

    getProducts(): Observable<any> {
        const q = {
            contextName: 'Beautycians',
            domainName: 'Products',
            queryName: 'GET_BEAUTYCIANS_PRODUCTS',
            assembleAs: {
                ...PRODUCT_ASSEMBLE
            }
        };
        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: q
        }).pipe(
            map((res) => {
                return res.hits;
            })
        )
    }

    createProduct(product: Product): Observable<any> {
        const command = {
            contextName: 'Beautycians',
            domainName: 'Products',
            commandName: 'CREATE',
            commandType: 'CREATE_AGGREGATE_COMMAND',
            commandId: uuidv4(),
            body: {
                aggregate: {
                    ...product
                },
                assembleAs: {
                    ...PRODUCT_ASSEMBLE
                }
            },
        };
        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
        )
    }

    updateProduct(uuid: string, product: Product): Observable<any> {
        if ('price' in product && product.price.toString() == "")
            product.price = null;

        const command = {
            contextName: 'Beautycians',
            domainName: 'Products',
            commandName: 'UPDATE',
            commandType: 'UPDATE_AGGREGATE_COMMAND',
            commandId: uuidv4(),
            body: {
                aggregate: {
                    ...product
                },
                where: {
                    productId: uuid
                },
                assembleAs: {
                    ...PRODUCT_ASSEMBLE
                }
            },
        };
        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
        )
    }

    deleteProduct(uuid: string): Observable<boolean> {
        if (!uuid)
            return;

        let body: any = {
            where: {
                productId: uuid
            },
            assembleAs: {
                ...PRODUCT_ASSEMBLE
            }
        };

        const command = {
            contextName: 'Beautycians',
            domainName: 'Products',
            commandName: 'LOGICAL_DELETE',
            commandType: 'NORMAL_COMMAND',
            body,
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                if (res && res.length > 0 && res[0].id === uuid)
                    return true;
                else return false;
            })
        )
    }

    createOrder(order: Order): Observable<any> {
        delete order.total; // will be provided by BE
        // if (order.items) {
        //     order.items.forEach((item) => {
        //         delete item.product;
        //     });
        // }
        order.info = JSON.stringify(order.infoObject);

        const command = {
            contextName: 'Beautycians',
            domainName: 'Orders',
            commandName: 'CREATE',
            commandType: 'CREATE_AGGREGATE_COMMAND',
            commandId: uuidv4(),
            body: {
                aggregate: {
                    ...order
                },
                assembleAs: {
                    ...ORDER_ASSEMBLE
                }
            },
        };
        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
        )
    }

    addOrderComment(order: Order, comment: OrderInfo): Observable<any> {
        comment.uuid = uuidv4();
        order.infoObject.push(comment);
        return this.updateOrder(order);
    }

    deleteOrderComment(order: Order, uuid: string): Observable<any> {
        order.infoObject = order.infoObject.filter(comment => comment.uuid != uuid)
        return this.updateOrder(order);
    }

    updateOrder(order: Order): Observable<any> {
        if (!order.orderId) return;

        delete order.total; // will be provided by BE
        if (order.infoObject)
            order.info = JSON.stringify(order.infoObject);

        const command = {
            contextName: 'Beautycians',
            domainName: 'Orders',
            commandName: 'UPDATE',
            commandType: 'UPDATE_AGGREGATE_COMMAND',
            commandId: uuidv4(),
            body: {
                where: Utils.exactMatchExpression('orderId', order.orderId),
                aggregate: {
                    ...order
                },
                assembleAs: {
                    ...ORDER_ASSEMBLE
                }
            },
        };
        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                res.hits.forEach((order) => {
                    order.infoObject = (order.info != null) ? JSON.parse(order.info) : [];
                    return order;
                });
                return res.hits;
            }),
            map((modifiedRes) => {
                return modifiedRes[0];
            })
        )
    }

    getOrder(uuid: string): Observable<any> {
        if (!uuid) return;
        return this.getOrders(uuid, ORDER_ASSEMBLE).pipe(
            map((order) => order[0]),
        );
    }

    getOrders(uuid: string = null, assembleAs: any = ORDER_LIST_ASSEMBLE): Observable<any> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'Orders',
            queryName: 'FIND_ALL',
            assembleAs
        };
        if (uuid)
            query['where'] = Utils.exactMatchExpression('orderId', uuid);

        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => res.hits),
            map((orderResponse) => {
                orderResponse.forEach((order) => {
                    order.infoObject = (order.info != null) ? JSON.parse(order.info) : [];
                    return order;
                });
                return orderResponse;
            })
        )
    }

    sendOrderToMexal(orderId: string): Observable<any> {
        const currentYear = new Date().getFullYear();
        const command = {
            contextName: 'Beautycians',
            domainName: 'Mexal',
            commandName: 'SEND_TO_MEXAL',
            body: {
                where: {
                    order_id: orderId,
                    year: `${currentYear}`
                }
            }
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
            // TODO: should change order status in case of success (best to have it done by BE)
        )
    }

    getLastMedicalHistoryWithScores(clientId: string): Observable<any> {
        // TODO: check
        return this.getAnamnesi({ clientId: clientId }, FULL_ANAMNESI_ASSEMBLE, [{ "_creationTime": "desc" }], { skip: 0, limit: 1 }).pipe(
            mergeMap((medicalHistoryArray: any) => {
                if (medicalHistoryArray.length == 0)
                    return of();
                // TODO: (with ABI) this is causing some error in forkjoin understand why
                const medicalHistory = medicalHistoryArray[0];
                return this.getMedicalHistoryScores(medicalHistory.MEDICAL_HISTORY_ID).pipe(
                    map((scores) => {
                        let aggregateObj = {};
                        Object.keys(medicalHistory).forEach((k) => { aggregateObj[k] = {}; aggregateObj[k].value = medicalHistory[k] });
                        Object.keys(scores).forEach((k) => { if (k in aggregateObj) aggregateObj[k].score = scores[k] });

                        return aggregateObj;
                    })
                )
            })
        );
    }

    getMedicalHistoryWithScores(medicalHistoryId: string): Observable<any> {
        return this.getAnamnesi({ id: medicalHistoryId }, FULL_ANAMNESI_ASSEMBLE).pipe(
            mergeMap((medicalHistory: any) => {
                return this.getMedicalHistoryScores(medicalHistory.MEDICAL_HISTORY_ID).pipe(
                    map((scores) => {
                        let aggregateObj = {};
                        Object.keys(medicalHistory).forEach((k) => { aggregateObj[k] = {}; aggregateObj[k].value = medicalHistory[k] });
                        Object.keys(scores).forEach((k) => { if (k in aggregateObj) aggregateObj[k].score = scores[k] });

                        return aggregateObj;
                    })
                )
            })
        );
    }

    getMedicalHistoryScoresAndMerge(medicalHistory: any): Observable<any> {
        return this.getMedicalHistoryScores(medicalHistory.MEDICAL_HISTORY_ID).pipe(
            map((scores) => {
                let aggregateObj = {};
                Object.keys(medicalHistory).forEach((k) => { aggregateObj[k] = {}; aggregateObj[k].value = medicalHistory[k] });
                Object.keys(scores).forEach((k) => { if (k in aggregateObj) aggregateObj[k].score = scores[k] });

                return aggregateObj;
            })
        );
    }

    /**
     * Get Available Anamnesi
     * @param {string} clientId - The id of the client
     * @returns {Observable<any>} - The saved Anamnesi
     * 
     **/
    getAnamnesi(filters: any, assembleAs: {} = FULL_ANAMNESI_ASSEMBLE, sort: string[] | {}[] = [], pagination: Pagination = null): Observable<any> {
        const query = {
            contextName: 'Beautycians',
            domainName: 'MedicalHistory',
            queryName: 'FIND_ALL',
            assembleAs,
            sort
        };
        query['where'] = {
            _referenceOrganizationId: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId
        }

        if (pagination != null || ('id' in filters)) {
            query['pagination'] = pagination;
            if ('id' in filters)
                query['where']['MEDICAL_HISTORY_ID'] = filters.id;
            if ('clientId' in filters)
                query['where']['CLIENT'] = filters.clientId;
        } else {
            query['queryName'] = 'GET_BY_PERSONID'
            query['where'] = {
                personId: filters.clientId,
            }
        }

        return this._httpClient.doPost({
            url: GruulsConstants.QUERY_API_URL,
            body: query
        }).pipe(
            map((res) => {
                return res.hits;
            })
        )
    }

    deleteMedicalHistory(uuid: string): Observable<boolean> {
        if (!uuid)
            return;

        let body: any = {
            where: {
                MEDICAL_HISTORY_ID: uuid
            },
            assembleAs: {
                ...FULL_ANAMNESI_ASSEMBLE
            }
        };

        const command = {
            contextName: 'Beautycians',
            domainName: 'MedicalHistory',
            commandName: 'LOGICAL_DELETE',
            commandType: 'NORMAL_COMMAND',
            body,
        };

        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                if (res && res.length > 0 && res[0].MEDICAL_HISTORY_ID === uuid)
                    return true;
                else return false;
            })
        )
    }

    /**
     * Get Available Anamnesi Count
     * @param {string} clientId - The id of the client
     * @returns {Observable<any>} - The saved Anamnesi
     * 
     **/
    getAnamnesiCount(clientId: string): Observable<any> {
        return this.getAnamnesi({ clientId: clientId }, {}).pipe(
            map((res) => {
                return res.length;
            })
        )
    }

    /**
     * Either Create or Update an Anamnesi
     * @param {any[]} answers - The list of answers to save
     * @param {string} clientId - The id of the client
     * @param {string} medicalHistoryId - The id of the medical history to update (optional)
     * @returns {Observable<any>} - The saved Anamnesi
     * 
     **/
    saveAnamnesi(answers: {}, clientId: string, medicalHistoryId: string = null, assembleAs: any = FULL_ANAMNESI_ASSEMBLE): Observable<any> {

        const aggregate = {
            ...answers,
            CLIENT: { personId: clientId }
        };

        let body: any = {
            aggregate,
            assembleAs
        };
        const where = medicalHistoryId ? Utils.exactMatchExpression('MEDICAL_HISTORY_ID', medicalHistoryId) : '';
        body = where ? { ...body, where } : body;

        const command = {
            contextName: 'Beautycians',
            domainName: 'MedicalHistory',
            commandName: medicalHistoryId ? 'UPDATE' : 'CREATE',
            commandType: medicalHistoryId ? 'UPDATE_AGGREGATE_COMMAND' : 'CREATE_AGGREGATE_COMMAND',
            commandId: uuidv4(),
            body
        };
        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
        );
    }

    saveWelcomeAnamnesi(answers: {}, clientId: string): Observable<any> {
        const aggregate = {
            ...answers,
            CLIENT: { personId: clientId }
        };

        let body: any = {
            aggregate,
            assembleAs: {
                ...CLIENT_ANAMNESI_ASSEMBLE
            }
        };

        const command = {
            contextName: 'Beautycians',
            domainName: 'MedicalHistory',
            commandName: 'CREATE_WELCOME_SURVEY',
            commandType: 'SKIP_AUTH_COMMAND',
            commandId: uuidv4(),
            body
        };
        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        }).pipe(
            map((res) => {
                return res.hits[0];
            })
        );
    }

    sendMedicalHistoryEmail(personId: string): Observable<any> {
        const command = {
            contextName: 'Beautycians',
            domainName: 'MedicalHistory',
            commandName: 'SEND_MEDICAL_HISTORY',
            commandType: 'SEND_MEDICAL_HISTORY',
            commandId: uuidv4(),
            body: {
                url: window.location.origin,
                personId: personId
            }
        };
        return this._httpClient.doPost({
            url: GruulsConstants.COMMAND_API_URL,
            body: command
        });
    }

    paginationObj(page: number): {} {
        const pageSize = 50;

        return {
            skip: pageSize * page,
            limit: pageSize
        }
    }

}