<div class=" inset-0 flex flex-col min-w-0 overflow-hidden">
    <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent" (backdropClick)="onBackdropClicked()">

        <!-- Drawer -->
        <mat-drawer class="w-full md:w-160 dark:bg-gray-900" [mode]="drawerMode" [opened]="false" [position]="'end'"
            [disableClose]="true" #matDrawer>
            <router-outlet></router-outlet>
        </mat-drawer>

        <mat-drawer-content class="flex flex-col">

            <!-- Main -->
            <div class="flex-auto">
                <!-- Header -->
                <div class="flex flex-col sm:flex-row md:flex-col flex-auto justify-between py-8 px-6 md:px-8 border-b">

                    <!-- Title -->
                    <div>
                        <div class="text-4xl font-extrabold tracking-tight leading-none">
                            {{translateStrings.operator.plural}}</div>
                        <div class="ml-0.5 font-medium text-secondary">
                            <ng-container *ngIf="filteredContacts?.length">
                                {{filteredContacts.length}}
                            </ng-container>
                            {{filteredContacts?.length | i18nPlural: {
                            '=0' : translateStrings.operator.no,
                            '=1' : translateStrings.operator.singular,
                            'other': translateStrings.operator.plural
                            } }}
                        </div>
                    </div>
                    <!-- Loader -->
                    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
                        <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
                    </div>

                    <!-- Main actions -->
                    <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
                        <!-- Search -->
                        <div class="flex-auto">
                            <mat-form-field
                                class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-full min-w-50">
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
                                <input matInput [formControl]="searchInputControl" [autocomplete]="'off'"
                                    [placeholder]="translateStrings.operator.search">
                            </mat-form-field>
                        </div>
                        <!-- Add contact button -->
                        <button class="ml-4" mat-flat-button [color]="'primary'"
                            (click)="this.selectedOperator = undefined; openModal()">
                            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            <span class="ml-2 mr-1">{{ translateStrings.operator.addNew }}</span>
                        </button>
                    </div>
                </div>

                <!-- Contacts list -->
                <div class="relative">
                    <ng-container *ngIf="filteredContacts?.length; else noContacts">
                        <ng-container *ngFor="let contact of filteredContacts; let i = index; trackBy: trackByFn">
                            <!-- Group -->
                            <ng-container
                                *ngIf="i === 0 || contact?.firstName?.charAt(0) !== filteredContacts[i - 1]?.firstName?.charAt(0)">
                                <div
                                    class="z-10 sticky top-0 -mt-px px-6 py-1 md:px-8 border-t border-b font-medium uppercase text-white bg-accent bg-opacity-50 dark:bg-gray-900">
                                    {{contact?.firstName?.charAt(0)}}
                                </div>
                            </ng-container>
                            <!-- Contact -->
                            <a class="z-20 flex items-center px-6 py-4 md:px-8 cursor-pointer border-b justify-between hover:bg-accent hover:bg-opacity-10 dark:hover:bg-hover"
                            (click)="selectedOperator = contact; openModal()"> <!-- [routerLink]="['../detail', contact.personId]"> -->
                                <div class="flex items-center">
                                    <div
                                        class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                        <ng-container *ngIf="contact.avatar">
                                            <img class="object-cover w-full h-full" [src]="contact.avatar"
                                                alt="Contact avatar" />
                                        </ng-container>
                                        <ng-container *ngIf="!contact.avatar">
                                            <div
                                                class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-accent bg-opacity-70 text-white dark:bg-gray-700 dark:text-gray-200">
                                                {{contact?.firstName?.charAt(0)}}
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="min-w-0 ml-4">
                                        <div class="font-medium leading-5 truncate">{{contact.firstName}}
                                            {{contact.lastName}} <span
                                            *ngIf="hasRole(contact, 'Beautycians-HQ')" 
                                            class="bg-primary-100 text-primary-800 text-xs font-medium mx-2 px-2.5 py-0.5 rounded-full dark:bg-primary-900 dark:text-primary-300">HQ</span>
                                            <span
                                                *ngIf="hasRole(contact, 'Beautycians-Stores-Owner')" 
                                                class="bg-red-100 text-red-800 text-xs font-medium mx-2 px-2.5 py-0.5 rounded-full dark:bg-primary-900 dark:text-red-300">Owner</span>
                                        </div>
                                        <div class="leading-5 truncate text-secondary"></div>
                                    </div>
                                </div>
                                <button
                                    (click)="$event.stopPropagation();  $event.preventDefault(); selectedOperator = contact;"
                                    mat-icon-button [matMenuTriggerFor]="menu" aria-label="Toggle menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <!-- <button mat-menu-item [routerLink]="['../detail', contact.personId]">
                                        <mat-icon>edit</mat-icon>
                                        <span>Edit</span>
                                    </button> -->
                                    <button mat-menu-item (click)="openModal()">
                                        <mat-icon>edit</mat-icon>
                                        <span>Edit</span>
                                    </button>
                                    <button mat-menu-item (click)="sendWelcomeHandler()">
                                        <mat-icon>email</mat-icon>
                                        <span>Send Welcome</span>
                                    </button>
                                </mat-menu>
                                <!-- <mat-icon class="pl-3 icon-size-8" [svgIcon]="'heroicons_solid:pencil'" (click)="$event.stopPropagation(); $event.preventDefault(); selectedOperator = contact; openModal();"></mat-icon> -->
                            </a>
                        </ng-container>
                    </ng-container>

                    <!-- No contacts -->
                    <ng-template #noContacts>
                        <div *ngIf="!isLoading"
                            class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">
                            {{translateStrings.operator.noSearchResults}}</div>
                    </ng-template>

                </div>
            </div>

        </mat-drawer-content>
    </mat-drawer-container>
</div>


<!-- Main modal -->
<ng-template #userModal>
    <h3 mat-dialog-title>{{ translateStrings.operator.addNew }}</h3>
    <mat-dialog-content>
        <form [formGroup]="addNewUserForm" (ngSubmit)="userFormSubmit()" #newUserForm="ngForm">
            <div class="flex flex-col gt-xs:flex-row p-7">

                <mat-form-field class="flex-auto gt-xs:pr-3">
                    <mat-label>{{translateStrings.user.firstName}}</mat-label>
                    <input matInput formControlName="firstName">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.lastName}}</mat-label>
                    <input matInput formControlName="lastName">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.email}}</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.user.sex}}</mat-label>
                    <mat-select formControlName="sex">
                        <mat-option value="M">{{translateStrings.user.male}}</mat-option>
                        <mat-option value="F">{{translateStrings.user.female}}</mat-option>
                        <mat-option value="ND">{{translateStrings.user.ND}}</mat-option>
                    </mat-select>
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </mat-form-field>

                <mat-form-field class="flex-auto gt-xs:pl-3">
                    <mat-label>{{translateStrings.operator.grantAdmin}}</mat-label>
                    <mat-select formControlName="grantAdmin">
                        <mat-option value=0>{{translateStrings.generic.no}}</mat-option>
                        <mat-option value=1>{{translateStrings.generic.yes}}</mat-option>
                        <mat-option *ngIf="isHQ" value=2>{{translateStrings.operator.hq}}</mat-option>
                    </mat-select>
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:star'"></mat-icon>
                </mat-form-field>

                <!-- <mat-form-field
                    class="flex-auto">
                    <mat-label>{{translateStrings.user.birthdate}}</mat-label>
                    <input
                        matInput
                        formControlName="birthdate"
                        [matDatepicker]="picker1">
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field> -->

                <mat-form-field class="flex-auto gt-xs:pr-3">
                    <mat-label>{{translateStrings.user.defaultpassword}}</mat-label>
                    <input matInput type="password" formControlName="defaultpassword" #defaultpassword>
                    <button mat-icon-button type="button"
                        (click)="defaultpassword.type === 'password' ? defaultpassword.type = 'text' : defaultpassword.type = 'password'"
                        matSuffix>
                        <mat-icon class="icon-size-5" *ngIf="defaultpassword.type === 'password'"
                            [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                        <mat-icon class="icon-size-5" *ngIf="defaultpassword.type === 'text'"
                            [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                    </button>

                </mat-form-field>


                <mat-form-field *ngIf="!selectedOperator" class="flex-auto gt-xs:pl-3">
                    <mat-label>Punto vendita</mat-label>
                    <mat-select formControlName="store">
                        <mat-option>---</mat-option>
                        <ng-container *ngFor="let store of storeList">
                            <mat-option [value]="store.storeId">{{store.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-icon class="icon-size-5" matSuffix [svgIcon]="'heroicons_solid:shopping-bag'"></mat-icon>
                </mat-form-field>

            </div>

            <!-- Modal footer -->
            <div
                class="flex justify-between items-center py-6 mx-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <div>
                    <button mat-button mat-dialog-close type="button"
                        class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{{translateStrings.generic.close}}</button>
                </div>
                <div class="flex space-x-2">
                    <button id="save" type="submit" mat-flat-button [color]="'secondary'" class="rounded-lg"
                        [disabled]="!addNewUserForm.valid">{{translateStrings.generic.saveAndSendWelcome}}</button>
                    <button id="saveAndSendWelcome" type="submit" mat-flat-button [color]="'primary'" class="rounded-lg"
                        [disabled]="!addNewUserForm.valid">{{translateStrings.generic.save}}</button>
                </div>
            </div>

        </form>
    </mat-dialog-content>
</ng-template>