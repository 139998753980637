<!-- Products list -->
<div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
  <ng-container *ngIf="order.cart.products?.length > 0; else noProducts">
    <div class="grid">
      <!-- Header -->
      <div
        class="inventory-grid items-center z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md text-center font-semibold text-white bg-accent-200 dark:bg-black dark:bg-opacity-5">
        <div></div>
        <div class="text-left">{{translateStrings.product.name}}</div>
        <!-- <div class="hidden md:block">{{translateStrings.product.category}}</div> -->
        <div class="hidden md:block">{{translateStrings.product.dimension}}</div>
        <div>{{translateStrings.product.quantity}}</div>
        <div class="hidden md:block">{{translateStrings.product.unitPrice}}</div>
        <div>{{translateStrings.product.price}}</div>
        <div>{{translateStrings.product.discount}}</div>
      </div>

      <!-- Rows -->
      <ng-container *ngFor="let orderItem of order.cart.products; trackBy: trackByFn">
        <div *ngIf="orderItem.quantity>0 || orderItem.isGift; else productRemoved">
          <div class="inventory-grid grid items-center gap-4 py-3 px-6 md:px-8 border-b">

            <!-- Image -->
            <div class="flex items-center">
              <div class="items-start w-full">
                <img class="object-cover h-full w-8" [alt]="'No thumbnail'"
                  [src]="'/assets/images/ui/beautycians/cream.png'">
                <ng-template #noThumbnail>
                </ng-template>
              </div>
            </div>

            <div class="truncate">
              <ng-container *ngIf="orderItem.recentlyAdded && canChange; else confirmedProduct">
                <mat-select [(ngModel)]="orderItem.productId" (selectionChange)="updateOrder(orderItem)">
                  <mat-option *ngFor="let product of products" [value]="product.productId">{{product.name}} <small>{{product.dimension}}</small></mat-option>
                </mat-select>
              </ng-container>
              <ng-template #confirmedProduct>
                <div class="font-semibold">{{keyValueProducts[orderItem.productId]?.name ?? translateStrings['product']['generic']}}</div>
              </ng-template>
              <ng-container *ngIf="order.delivered && order.delivered[keyValueProducts[orderItem.productId]?.code]">
                <ng-container *ngFor="let delivery of order.delivered[keyValueProducts[orderItem.productId]?.code]">
                  <p class="text-gray-500 text-sm">{{delivery.quantita}} processati il {{delivery.data | date}}</p>
                </ng-container>
              </ng-container>
            </div>
            <!-- <div class="truncate hidden md:block">{{keyValueProducts[orderItem.productId]?.category}}</div> -->
            <div class="truncate hidden md:block">{{keyValueProducts[orderItem.productId]?.dimension}}</div>
            <div class="mx-auto" *ngIf="canChange; else cannotChange">
              <td>
                <select class="rounded-lg bg-gray-100 p-1" [(ngModel)]="orderItem.quantity"
                  (change)="updateOrder(orderItem)">
                  <option ngValue="0" class="text-right pl-2">0 (remove)</option>
                  <option *ngFor="let num of quantityOptions" [ngValue]="num" class="text-right pl-2">{{num}}</option>
                </select>
              </td>
            </div>
            <ng-template #cannotChange>
              <div class="truncate hidden md:block text-right">{{orderItem.quantity}}</div>
            </ng-template>
            <ng-container *ngIf="!orderItem.isGift">
              <div class="truncate hidden md:block text-right">{{keyValueProducts[orderItem.productId]?.price | currency:'EUR'}}
              </div>
              <div class="truncate text-right">{{orderItem.quantity * keyValueProducts[orderItem.productId]?.price |
                currency:'EUR'}}</div>
            </ng-container>
            <ng-container *ngIf="isAdmin">
              <div class="truncate text-right">
                <mat-select [(ngModel)]="orderItem.discount" (selectionChange)="updateOrder(orderItem)" [disabled]="!canChange"> <!---->
                  <mat-option class="text-right" *ngFor="let discount of discounts" [value]="discount">{{(discount==0) ? '--' : discount | percent}}</mat-option>
                </mat-select>
              </div>
              <!-- <div [ngClass]="{'col-span-3': orderItem.isGift}">
                <mat-slide-toggle [(ngModel)]="orderItem.isGift" (change)="onIsGiftChange($event, orderItem)">
                  <span>{{orderItem.isGift ? 'Omaggio' : ''}}</span>
                </mat-slide-toggle>
              </div> -->
            </ng-container>
          </div>

          <div *ngIf="orderItem.discount && orderItem.discount>0"
            class="inventory-grid grid items-center gap-4 py-1 px-6 md:px-8 border-b text-gray-700">
            <div class="flex items-center m-auto">{{orderItem.discount | percent}}</div>
            <div class="truncate">{{translateStrings.product.discount}}</div>
            <!-- <div class="truncate hidden md:block"></div> -->
            <div class="truncate hidden md:block"></div>
            <div class="truncate text-right"></div>
            <div class="truncate hidden md:block text-right"></div>
            <div class="truncate text-right">{{orderItem.quantity * keyValueProducts[orderItem.productId]?.price *
              orderItem.discount * (-1) | currency:'EUR'}}</div>
              <div class="truncate">
              </div>
            </div>

        </div>

        <ng-template #productRemoved>
          <div class="items-center gap-4 py-3 px-6 md:px-8 border-b"><a [routerLink]="['../../product/list']"
              class="text-primary">{{keyValueProducts[orderItem.productId]?.name ?? translateStrings['product']['generic']}}</a>
            {{translateStrings.orderDetail.removedFromCart}} <a (click)="orderItem.quantity=1; updateOrder(orderItem);"
              class="text-primary cursor-pointer">{{translateStrings.order.reintroduce}}</a></div>
        </ng-template>
      </ng-container>

      <div *ngIf="canChange && order.orderId" class="items-center gap-4 py-3 px-6 md:px-8 border-b">
        <a (click)="addProductAtTheEnd()"
        class="text-primary cursor-pointer">{{translateStrings.order.addNewProduct}}</a></div>

      <!-- Footer -->
      <div
        class="inventory-grid grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-white bg-accent-200 dark:bg-black dark:bg-opacity-5">
        <div></div>
        <div class="text-left">{{translateStrings.order.total}}</div>
        <!-- <div class="hidden md:block"></div> -->
        <div class="hidden md:block"></div>
        <div></div>
        <div class="hidden md:block"></div>
        <div class="text-right">{{order.total ? (order.total | currency:'EUR') : 'N/A'}}</div>
      </div>
    </div>
  </ng-container>

  <ng-template #noProducts>
    <div *ngIf="!isLoading" class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">
      {{translateStrings.orderDetail.no}}, <a [routerLink]="['../../product/list']"
        class="text-primary">{{translateStrings.orderDetail.gotobuy}}</a>.</div>
  </ng-template>
</div>

<table id="excel-table" class="hidden">
  <tr>
    <th><strong>{{translateStrings.product.name}}</strong></th>
    <th><strong>{{translateStrings.product.dimension}}</strong></th>
    <th><strong>{{translateStrings.product.quantity}}</strong></th>
    <th><strong>{{translateStrings.product.unitPrice}}</strong></th>
    <th><strong>{{translateStrings.product.discount}}</strong></th>
    <th><strong>{{translateStrings.product.price}}</strong></th>
  </tr>
  <tr *ngFor="let orderItem of order.cart.products;">
    <td>{{keyValueProducts[orderItem.productId]?.name ?? translateStrings['product']['generic']}}</td>
    <td>{{keyValueProducts[orderItem.productId]?.dimension}}</td>
    <td>{{orderItem.quantity}}</td>
    <td>{{keyValueProducts[orderItem.productId]?.price * 100 / 100}}</td>
    <td>{{orderItem.discount | percent}}</td>
    <td>{{orderItem.quantity * keyValueProducts[orderItem.productId]?.price * (1 - orderItem.discount)}}</td>
  </tr>
</table>