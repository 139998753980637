import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Moment } from 'moment';
import { Calendar, CalendarEvent, CalendarEventEditMode, CalendarSettings, CalendarWeekday } from './mycalendar.types';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAngularHttpProxyService } from '@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { BeautyciansUtils } from 'app/beautycians/utils/utils';
import { GruulsAngularTranslateService } from '@gruuls-fe/services/gruuls-angular-translate.service';
import { Person } from '../clients/client.types';
import { CLIENT_SM_ASSEMBLE } from 'app/beautycians/utils/assemble';
import { GruulsAngularAppointmentsService } from '@gruuls-fe/services/gruuls-angular-appointments-service';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    // Private
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
    private _calendars: BehaviorSubject<Calendar[] | null> = new BehaviorSubject(null);
    private _events: BehaviorSubject<CalendarEvent[] | null> = new BehaviorSubject(null);
    private _clients: BehaviorSubject<Person[] | null> = new BehaviorSubject(null);
    private _loadedEventsRange: { start: Moment | null; end: Moment | null } = {
        start: null,
        end: null
    };
    private readonly _numberOfDaysToPrefetch = 45;
    private readonly _numberOfDaysToCheckDuringPrefetch = 21;
    private _settings: BehaviorSubject<CalendarSettings | null> = new BehaviorSubject(null);
    private _weekdays: BehaviorSubject<CalendarWeekday[] | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: GruulsAngularHttpProxyService,
        private _translate: GruulsAngularTranslateService,
        private _appointmentsService: GruulsAngularAppointmentsService,
        private _authService: GruulsAuthService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for calendars
     */
    get calendars$(): Observable<Calendar[]> {
        return this._calendars.asObservable();
    }

    get clients$(): Observable<Person[]> {
        return this._clients.asObservable();
    }

    get events$(): Observable<CalendarEvent[]> {
        // return this._events.asObservable();
        return this._appointmentsService.appointments$.pipe(
            map((appointments) => appointments.map((appointment) => BeautyciansUtils.appointmentToEvent(appointment))),
        )
    }

    /**
     * Getter for settings
     */
    get settings$(): Observable<CalendarSettings> {
        // return this._settings.asObservable();
        return of(null);
    }

    /**
     * Getter for weekdays
     */
    get weekdays$(): Observable<CalendarWeekday[]> {
        return this._weekdays.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get calendars
     */
    getCalendars(PersonIdFocus: string = null): Observable<Calendar[]> {
        return this._apiCaller.getTreatments().pipe(
            map(treatments => {
                return treatments.map(treatment => Object({
                    id: treatment.uuid,
                    title: this._translate.translate('treatments.' + treatment.code),
                    color: treatment.color,
                    visible: true,
                }))
            }),
            tap((response: Calendar[]) => {
                this._calendars.next(response);
            })
        );

        // return this._httpClient.get<Calendar[]>('api/apps/calendar/calendars').pipe(
        //     tap((response) => {
        //         this._calendars.next(response);
        //     })
        // );
    }

    getClients(): Observable<Person[]> {
        const referenceOrganizationId = this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId;
        return this._apiCaller.getClients({ '_referenceOrganizationId': referenceOrganizationId }, CLIENT_SM_ASSEMBLE).pipe(
            tap((response: Person[]) => {
                this._clients.next(response);
            })
        );
    }

    /**
     * Get events
     *
     * @param start
     * @param end
     * @param replace
     */
    getEvents(start: Moment, end: Moment, replace = false, organizationId: string): Observable<CalendarEvent[]> {
        // Set the new start date for loaded events
        ;
        if (replace || !this._loadedEventsRange.start || start.subtract(this._numberOfDaysToCheckDuringPrefetch, 'day').isBefore(this._loadedEventsRange.start)) {
            this._loadedEventsRange.start = start.subtract(this._numberOfDaysToPrefetch, 'day');
        }

        // Set the new end date for loaded events
        if (replace || !this._loadedEventsRange.end || end.add(this._numberOfDaysToCheckDuringPrefetch, 'day').isAfter(this._loadedEventsRange.end)) {
            this._loadedEventsRange.end = end.add(this._numberOfDaysToPrefetch, 'day');
        }

        return this._appointmentsService.refreshAppointments(this._loadedEventsRange.start.valueOf(), this._loadedEventsRange.end.valueOf()).pipe(
            map((appointments) => appointments.map((appointment) => BeautyciansUtils.appointmentToEvent(appointment))),
        ).pipe(
            switchMap(response => this._events.pipe(
                take(1),
                map((events) => {

                    // If replace...
                    if (replace) {
                        // Execute the observable with the response replacing the events object
                        this._events.next(response);
                    } else {
                        // If events is null, replace it with an empty array
                        events = events || [];

                        // Execute the observable by appending the response to the current events
                        this._events.next([...events, ...response]);
                    }

                    // Return the response
                    return response;
                })
            ))
        );
    }

    /**
     * Reload events using the loaded events range
     */
    reloadEvents(organizationId: string): Observable<CalendarEvent[]> {
        // Get the events
        return this._appointmentsService.refreshAppointments().pipe(
            map((appointments) => appointments.map((appointment) => BeautyciansUtils.appointmentToEvent(appointment))),
            map((response: CalendarEvent[]) => {

                // Execute the observable with the response replacing the events object
                this._events.next(response);

                // Return the response
                return response;
            })
        );
    }

    /**
     * Prefetch future events
     *
     * @param end
     */
    prefetchFutureEvents(end: Moment, organizationId: string): Observable<CalendarEvent[]> {
        // Calculate the remaining prefetched days
        const remainingDays = this._loadedEventsRange.end.diff(end, 'days');

        // Return if remaining days is bigger than the number
        // of days to prefetch. This means we were already been
        // there and fetched the events data so no need for doing
        // it again.
        if (remainingDays >= this._numberOfDaysToCheckDuringPrefetch) {
            return of([]);
        }

        // Figure out the start and end dates
        const start = this._loadedEventsRange.end.clone().add(1, 'day');
        end = this._loadedEventsRange.end.clone().add(this._numberOfDaysToPrefetch - remainingDays, 'days');

        // Prefetch the events
        return this.getEvents(start, end, false, organizationId);
    }

    /**
     * Prefetch past events
     *
     * @param start
     */
    prefetchPastEvents(start: Moment, organizationId: string): Observable<CalendarEvent[]> {
        // Calculate the remaining prefetched days
        const remainingDays = start.diff(this._loadedEventsRange.start, 'days');

        // Return if remaining days is bigger than the number
        // of days to prefetch. This means we were already been
        // there and fetched the events data so no need for doing
        // it again.
        if (remainingDays >= this._numberOfDaysToCheckDuringPrefetch) {
            return of([]);
        }

        // Figure out the start and end dates
        start = this._loadedEventsRange.start.clone().subtract(this._numberOfDaysToPrefetch - remainingDays, 'days');
        const end = this._loadedEventsRange.start.clone().subtract(1, 'day');

        // Prefetch the events
        return this.getEvents(start, end, false, organizationId);
    }

    /**
     * Add event
     *
     * @param event
     */
    addEvent(event: CalendarEvent): Observable<CalendarEvent> {
        return this.events$.pipe(
            take(1),
            switchMap(events => this._appointmentsService.createAppointment(BeautyciansUtils.eventToAppointment(event)).pipe(
                map((addedEvent) => BeautyciansUtils.appointmentToEvent(addedEvent)),
                map((addedEvent) => {

                    // Update the events
                    this._events.next([addedEvent, ...events]);

                    // Return the added event
                    return addedEvent;
                })
            ))
        );
    }

    /**
     * Update event
     *
     * @param id
     * @param event
     */
    updateEvent(event): Observable<CalendarEvent> {
        return this.events$.pipe(
            take(1),
            switchMap(events => this._appointmentsService.updateAppointment(BeautyciansUtils.eventToAppointment(event)).pipe(
                map((updatedEvent) => BeautyciansUtils.appointmentToEvent(updatedEvent)),
                map((updatedEvent) => {

                    // Find the index of the updated event
                    const index = events.findIndex(item => item.id === event.id);

                    // Update the event
                    events[index] = updatedEvent;

                    // Update the events
                    this._events.next(events);

                    // Return the updated event
                    return updatedEvent;
                })
            ))
        );
    }

    /**
     * Update recurring event
     *
     * @param event
     * @param originalEvent
     * @param mode
     */
    updateRecurringEvent(event, originalEvent, mode: CalendarEventEditMode): Observable<boolean> {
        // return this._httpClient.patch<boolean>('api/apps/calendar/recurring-event', {
        //     event,
        //     originalEvent,
        //     mode
        // });
        return of(false);
    }

    /**
     * Delete event
     *
     * @param id
     */
    deleteEvent(id: string): Observable<boolean> {
        return this.events$.pipe(
            take(1),
            switchMap(events => this._appointmentsService.deleteAppointment(id).pipe(
                map((isDeleted) => {
                    if (isDeleted) {
                        // Find the index of the deleted event
                        const index = events.findIndex(item => item.id === id);

                        // Delete the event
                        events.splice(index, 1);

                        // Update the events
                        this._events.next(events);
                    }
                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }

    /**
     * Delete recurring event
     *
     * @param event
     * @param mode
     */
    deleteRecurringEvent(event, mode: CalendarEventEditMode): Observable<boolean> {
        // return this._httpClient.delete<boolean>('api/apps/calendar/recurring-event', {
        //     params: {
        //         event: JSON.stringify(event),
        //         mode
        //     }
        // });
        return of(false);
    }

}
