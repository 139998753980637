import {HttpClient, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of, skip} from 'rxjs';
import {map} from 'rxjs/operators';
import {GruulsLogin} from '../../../@gruuls-core/login/gruuls-login';
import lodash from 'lodash';

const _ = lodash;
export interface GruulsMockHttpRequest {
    request: HttpRequest<any>;
    urlParams: { [key: string]: string };
}

export class GruulsMockDomain {

    protected queries: {
        [queryName: string]: (data: GruulsMockHttpRequest) => ([number, string | any]) | Observable<any>;
    } = {};

    protected commands: {
        [commandName: string]: (data: GruulsMockHttpRequest) => ([number, string | any]) | Observable<any>;
    } = {};

    protected entities: any[] = [];

    constructor(protected httpClient: HttpClient) {

        this.queries.FIND_ALL = (requestData: GruulsMockHttpRequest): Observable<any> => {
            if (requestData.request.body.where) {
                return of([
                    200,
                    {
                        hits: this.entities.filter((el) => {
                            let found = false;
                            for (const w of Object.keys(requestData.request.body.where)) {
                                if (Array.isArray(requestData.request.body.where[w]) && requestData.request.body.where[w].some(f => f === el[w])) {
                                    found = true;
                                } else if (requestData.request.body.where[w] === el[w]) {
                                    found = true;
                                }
                            }
                            return found;
                        })
                    }
                ]);
            } else {
                return of([
                    200,
                    {
                        hits: this.entities
                    }
                ]);
            }
        };

        this.queries.FIND_BY_ID = this.queries.FIND_ALL;
    }


    executeQuery(data: { request: HttpRequest<any>; urlParams: { [p: string]: string } }): [number, any] | Observable<any> {
        const queryName = data.request.body.queryName;
        const exe = this.queries[queryName];
        if (exe){
            return exe(data);
        }

        // add 'skip' header to allow mockApiInterceptor to skip che mock and reach real endpoint
        // const h: any = _.clone(GruulsLogin.httpHeaders);
        // h.skip = 'true';
        // return this.httpClient.post('api/executor/query',{
        //         'contextName': 'Core', 'domainName': 'Person',
        //         'queryName': 'GET_MYSELF',
        //         'queryId': '1234',
        //         'where': {},
        //         'assembleAs': GruulsLogin.assembleAs
        //     }, {headers: h})
        return this.httpClient.request(
                data.request.clone({
                headers: data.request.headers.set('skip', 'true')
            }))
        //
        // return this.httpClient.request(
        //         new HttpRequest(data.request.method,data.request.url, data.request.body, {
        //         headers: data.request.headers.set('skip', 'true'),
        //         context: data.request.context,
        //         params: data.request.params,
        //         responseType: data.request.responseType
        //     } )
        // )
            .pipe(
                skip(1),
                map((res: HttpResponse<any>) => {
                    const t = this;
                    return [
                        res.status,
                        res.body
                    ];
                })
            );

        // GruulsDomainNotFoundMockDomain.QUERY_NOT_FOUND(data);
    }

}
