<div class="inset-0 flex flex-col min-w-0 overflow-hidden">

    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Loader -->
        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>

        <!-- Title -->
        <div>
            <div class="text-4xl font-extrabold tracking-tight">{{order.name ?? translateStrings.order.cart}}</div>
            <div *ngIf="order.date" class="ml-0.5 font-medium text-secondary">
                {{translateStrings.order.singular}} <strong>B{{order.id}}</strong> del {{order.date | date: 'dd/MM/yyyy HH:mm'}}
            </div>
        </div>

        <!-- Actions -->
        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">

            <!-- Badge -->
            <div *ngIf="order.status"
                class="ml-auto flex flex-row items-center py-0.5 px-3 rounded-full text-sm font-semibold dark:text-{{getStatusColor(order.status)}}-50 dark:bg-{{getStatusColor(order.status)}}-500 text-{{getStatusColor(order.status)}}-700 bg-{{getStatusColor(order.status)}}-100">
                <mat-icon *ngIf="getStatusIcon(order.status)" class="icon-size-4 mr-2" svgIcon="{{getStatusIcon(order.status)}}"></mat-icon>
                <div class="text-base">{{order.status | lowercase}}</div>
            </div>

            <!-- Send order button -->
            <button class="ml-4" mat-flat-button *ngIf="!order.status && order.cart.products?.length > 0"
                (click)="proceedWithOrder()" [color]="'primary'">
                <span class="ml-2 mr-1">{{translateStrings.orderDetail.proceed}}</span>
            </button>
        </div>
    </div>

    <div *ngIf="order.store" class="flex sm:items-center py-8 px-6 md:px-8 border-b bg-gray-100">
        <mat-icon>place</mat-icon>
        <div class="ml-6 leading-6">
            <div class="font-medium">{{order.store?.name}}</div>
            <p *ngIf="order.store?.phone">Tel.:{{order.store?.phone}}</p>
            <p>{{order.store?.address}}</p>
            <p>{{order.store?.zip}} {{order.store?.city}} ({{order.store?.province}})</p>
        </div>
    </div>

    <div *ngIf="order.orderId" class="p-6">
        <div class="py-3 leading-6">
            <ng-container *ngFor="let comment of order.infoObject">
                <div><span
                        class="text-secondary">{{comment.date | date:'dd-MMM HH:mm'}}</span>&nbsp;<span
                        [ngClass]="(comment.isPublic ? 'font-medium' : 'text-secondary') + ' ' + (comment.type=='note' ? 'text-green-500' : '')">{{comment.comment}}
                    </span></div>
            </ng-container>
        </div>
    </div>

    <!-- Main -->
    <div *ngIf="!isLoading" class="flex flex-auto overflow-hidden w-full">
        <order-table [order]="order" (onOrderChange)="updateOrder($event)" [canChange]="!order.orderId" [isAdmin]="false" class="w-full"></order-table>
    </div>
</div>