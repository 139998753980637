import { NgModule } from '@angular/core';
import {Page404Component} from "./e404.component";

@NgModule({
    declarations: [
        Page404Component
    ],
    imports     : [
    ]
})
export class Page404Module
{
}
