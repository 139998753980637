import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { GruulsAngularAppointmentsService } from '@gruuls-fe/services/gruuls-angular-appointments-service';
// import { any } from '@amcharts/amcharts5/.internal/core/util/Array';
import { GruulsAngularHttpProxyService } from '@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAngularTranslateService } from '@gruuls-fe/services/gruuls-angular-translate.service';
import { Appointment, Treatment } from '../../client.types';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'treatment-box',
    templateUrl: './treatment-box.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreatmentBoxComponent implements OnInit, OnChanges {

    @Input() appointment: Appointment;
    @Input() treatments: Treatment[] = [];

    translateStrings: any = { beautyPath: {}, generic: {}, appointment: {} };
    isSpecificLoading: boolean[] = [];

    /**
     * Constructor
     */
    constructor(
        private _translate: GruulsAngularTranslateService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _appointmentsService: GruulsAngularAppointmentsService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _snackBar: MatSnackBar
    ) { }

    @HostBinding('class') get classList(): any {
        return {
            'h-full': true
        };
    }

    ngOnInit(): void {
        const beautyPathTranslations = ['singular', 'plural', 'notPlanned', 'addSession', 'treatment', 'advisedSessions'];
        beautyPathTranslations.forEach((translation) => {
            this.translateStrings['beautyPath'][translation] = this._translate.translate('beautyPath.' + translation);
        });

        const appointmentTranslations = ['singular', 'notPlanned', 'note', 'reference', 'add', 'deleteWarning', 'deleteWarningMessage']
        appointmentTranslations.forEach((translation) => {
            this.translateStrings['appointment'][translation] = this._translate.translate('appointment.' + translation);
        });

    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.appointment.firstChange) {
            console.log(changes.appointment.currentValue);
        }
    }

    createComment(): void {
        if (this.appointment.description == null || this.appointment.description == '')
            this.appointment.description = 'Inserisci un commento';
    }

    changeDate($event): void {
        this.appointment.date = $event.getTime();
        this._appointmentsService.updateAppointment(this.appointment).subscribe();
    }

    changeAppointment($event): void {
        this.appointment.treatmentId = $event.value;
        if (this.appointment.durationMin === null)
            this.appointment.durationMin = this.treatments.find(t => t.uuid === this.appointment.treatmentId).defaultDurationInMin ?? 30;
        this._appointmentsService.updateAppointment(this.appointment).subscribe();
    }

    deleteAppointment(): void {
        const confirmation = this._fuseConfirmationService.open({
            title: this.translateStrings.appointment.deleteWarning,
            message: this.translateStrings.appointment.deleteWarningMessage,
            actions: {
                confirm: {
                    label: this.translateStrings.generic.delete
                }
            }
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) => {

            // If the confirm button pressed...
            if (result === 'confirmed') {
                // Update the event on the server
                this._appointmentsService.deleteAppointment(this.appointment.appointmentId).subscribe({
                    next: () => {
                        // Show the success message
                        this._changeDetectorRef.markForCheck();
                        this._snackBar.open('Appointment deleted', 'OK', {
                            horizontalPosition: "left",
                            verticalPosition: "bottom",
                            duration: 2000
                        });
                    },
                    error: (err) => {
                        console.error("Error while deleting appointment: " + err);
                    }
                });
            }
        });
    }

}
