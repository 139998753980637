import {DataSchema} from '../data-schema/data-schema';
import {QuerySchemaRepository} from '../repository/query-schema-repository';
import {CommandSchemaRepository} from '../repository/command-schema-repository';
import {Action, TemplatesConfig} from './templates-config';
import {Observable, of} from 'rxjs';
import {Fields} from '../data-schema/fields';
import {Field} from '../data-schema/field';
import {map} from 'rxjs/operators';
import {GruulsHttpProxyInterface} from '../../service-interfaces/gruuls-http-proxy-interface';
import {GruulsAuthServiceInterface} from '../../service-interfaces/gruuls-auth-service-interface';

export class Domain {

    query: QuerySchemaRepository;
    command: CommandSchemaRepository;

    constructor(
        public contextName: string,
        public domainName: string,
        public schema: DataSchema,
        public http: GruulsHttpProxyInterface,
        public authService: GruulsAuthServiceInterface,
        // public listTemplate?:any,
        // public formTemplate?:any,
        // public newEntityTemplate?:any,
        // public entityFormTemplate?:any,
        public templatesConfig?: TemplatesConfig,
        public actions?: { [actionName: string]: Action },
        queryExecutorUrl?: string,
        commandExecutorUrl?: string,
    ) {
        if (!contextName || !domainName) {
            throw new Error('It is mandatory to add a contextName and a domainName in order to build a DataSchema. (contextName:\'' + contextName + '\' domainName:\'' + domainName + '\')');
        }

        this.query = new QuerySchemaRepository(http, this, authService, queryExecutorUrl);
        this.command = new CommandSchemaRepository(http, this, authService, commandExecutorUrl);

        if (schema) {
            this.setSchema(schema);
        }

    }

    public static ofConfig(
        config: {
            contextName: string;
            domainName: string;
            schema?: DataSchema;
            templatesConfig?: TemplatesConfig;
            actions?: { [actionName: string]: Action };
            http: GruulsHttpProxyInterface;
            auth: GruulsAuthServiceInterface;
            queryExecutorUrl?: string;
            commandExecutorUrl?: string;
        }
    ): Domain {
        return new Domain(config.contextName, config.domainName,
            config.schema, config.http, config.auth,
            config.templatesConfig, config.actions,
            config.queryExecutorUrl,
            config.commandExecutorUrl);
    }

    public static ofRemoteConfig(contextName: string, domainName: string, httpClient: GruulsHttpProxyInterface, auth: GruulsAuthServiceInterface): Observable<Domain> {
        return Domain.ofConfig({
            contextName: contextName,
            domainName: domainName,
            http: httpClient,
            auth: auth
        }).loadFromRemote();
    }

    setSchema(schema: DataSchema): void {
        this.schema = schema;
        this.schema.setContextName(this.contextName);
        this.schema.setDomainName(this.domainName);
    }

    loadFromRemote(): Observable<Domain> {

        return this.getQueryRepository().getDomainConfig()
            .pipe(
                map((config: any) => {
                    // load fields
                    const fields: Field[] = [];
                    // let extendedSchema: any = config.schema; // config.schema.extends
                    //
                    // while (extendedSchema) {
                    //     for (const f of extendedSchema.fields){
                    //         const field: Field = Fields.ofJsonConfig(f);
                    //         if (field) {
                    //             fields.push(field);
                    //         }
                    //     }
                    //     extendedSchema = extendedSchema.extends;
                    // }

                    for (const f of config.fields) {
                        const field: Field = Fields.ofJsonConfig(f);
                        if (field) {
                            fields.push(field);
                        }
                    }

                    const schema: DataSchema = DataSchema.of(fields, config.defaultAssembleAs);
                    this.setSchema(schema);
                    return this;
                })
            );


        // load queries

        // load commands

    }

    getQueryRepository(): QuerySchemaRepository {
        return this.query;
    }

    getCommandRepository(): CommandSchemaRepository {
        return this.command;
    }

    getContextName(): string {
        return this.contextName;
    }

    getDomainName(): string {
        return this.domainName;
    }

    getFqdn(): string {
        return this.getContextName() + '.' + this.getDomainName();
    }

    getSchema(): DataSchema {
        return this.schema;
    }

    getTemplatesConfig(): TemplatesConfig {
        return this.templatesConfig;
    }

    logInfo(message: string, params?: any): void {
        console.log('[' + this.getContextName() + '.' + this.getDomainName() + '] - ' + message, params);
    }

    logWarn(message: string, params?: any): void {
        console.warn('[' + this.getContextName() + '.' + this.getDomainName() + '] - ' + message, params);
    }

    logError(message: string, exception?: any, params?: any): void {
        console.error('[' + this.getContextName() + '.' + this.getDomainName() + '] - ' + message, params);
        if (exception) {
            console.error(exception);
        }
    }
}
