import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { GruulsAuthService } from '../../../../../@gruuls-fe/services/gruuls-auth.service';
import { CurrentUser } from '../../../../../@gruuls-core/models/user/current-user';
import { GruulsAngularTranslateService } from '@gruuls-fe/services/gruuls-angular-translate.service';
import { FuseConfigService } from '@fuse/services/config';
import { AccountService } from 'app/_services';

@Component({
    selector: 'auth-sign-in',
    styles: [
        `
            .btn-facebook,
            .btn-facebook:hover,
            .btn-facebook:first-child:active {
                background: #3B5998;
                border-color: #3B5998;
                color: #fff;
            }

            .btn-facebook:hover {
                opacity: 0.9;
            }

            .btn-facebook:first-child:active {
                opacity: 0.8;
            }
        `
    ],
    templateUrl: './sign-in.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AuthSignInComponent implements OnInit {
    @ViewChild('signInNgForm') signInNgForm: NgForm;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: ''
    };
    signInForm: FormGroup;
    showAlert: boolean = false;
    translateStrings: any = { generic: {} };
    logoImageSrc: string;
    title: string;


    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _authService: GruulsAuthService,
        private _formBuilder: FormBuilder,
        private _translate: GruulsAngularTranslateService,
        private _fuseConfigService: FuseConfigService,
        private _router: Router
    ) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form
        this.signInForm = this._formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', Validators.required],
            rememberMe: ['']
        });

        this.logoImageSrc = this._fuseConfigService.config.logoImgSrc;
        this.title = this._fuseConfigService.config.name;

        const genericTranslations = ['wrongPassword', 'error503', 'signIn', 'emailAddress', 'forgotPassword', 'rememberMe', 'emailRequired', 'passwordRequired', 'emailInvalid'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     */
    signIn(): void {
        // Return if the form is invalid
        if (this.signInForm.invalid) {
            return;
        }

        // Disable the form
        this.signInForm.disable();

        // Hide the alert
        this.showAlert = false;

        // Sign in
        this._authService.login(this.signInForm.value.email, this.signInForm.value.password)
            .subscribe({
                next: (currentUser: CurrentUser) => {

                    // Set the redirect url.
                    // The '/signed-in-redirect' is a dummy url to catch the request and redirect the user
                    // to the correct page after a successful sign in. This way, that url can be set via
                    // routing file and we don't have to touch here.
                    const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';

                    // Navigate to the redirect url
                    this._router.navigateByUrl(redirectURL);

                },
                error: (error) => {

                    // Re-enable the form
                    this.signInForm.enable();

                    // Reset the form
                    this.signInNgForm.resetForm();

                    // Set the alert
                    if ('status' in error && error.status > 500) {
                        this.alert = {
                            type: 'error',
                            message: this.translateStrings.generic.error503
                        };
                    } else {
                        this.alert = {
                            type: 'error',
                            message: this.translateStrings.generic.wrongPassword
                        };
                    }
                    // Show the alert
                    this.showAlert = true;
                }
            }
            );
    }
}
