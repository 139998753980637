import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from 'app/shared/shared.module';
import { DraggableTreeComponent } from './draggable-tree.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';


// More info at: https://stackblitz.com/edit/angular-draggable-mat-tree
@NgModule({
    declarations: [
      DraggableTreeComponent
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        SharedModule,
        DragDropModule,
        MatTreeModule,
        MatCheckboxModule
    ],
    exports     : [
        DraggableTreeComponent
    ]
})
export class DraggableTreeModule
{
}
