<div class="flex flex-row items-center">
  <a mat-button matSuffix [matMenuTriggerFor]="appMenu" class="p-3" (click)="$event.stopPropagation()">
    <p class="mr-3 left">{{(date | date: 'd MMMM YYYY HH:mm') || defaultText}}</p>
    <input matInput [(ngModel)]="date" hidden>
    <mat-icon class="icon-size-3">calendar_today</mat-icon>
  </a>
</div>

<mat-menu #appMenu="matMenu" class="drop-calendar">
  <div class="flex flex-row" (click)="$event.stopPropagation()">
    <mat-calendar #calendar [minDate]="todayDate" (selectedChange)="changeHandler($event)" [selected]="date"></mat-calendar>
    <div class="grid grid-rows-4 grid-cols-3 grid-flow-row-dense gap-2 mx-2">
      <ngcontainer *ngFor="let hourBlock of hourBlocks">
        <button mat-icon-button (click)="setHour(hourBlock)" class="w-10" [ngClass]="{'mat-calendar-body-selected': hourBlock == getSelectedhourBlock()}">
          {{hourBlock}}
          <div class="text-xs">00</div>
        </button>
      </ngcontainer>
    </div>
  </div>
</mat-menu>