// THANKS TO:
// https://github.com/angular/components/issues/16671

import {coerceElement} from '@angular/cdk/coercion';
import {DragRef, DropListRef} from '@angular/cdk/drag-drop';

// A few lines of code used for debugging (saved to avoid having to re-write them)
// let reflistToString = (list: DropListRef[]) => JSON.stringify(list.map(ref => coerceElement(ref.element).id));
// console.log("Targets: " + reflistToString(targets));

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function installPatch(): any {
    DropListRef.prototype._getSiblingContainerFromPosition = function(item: DragRef, x: number, y: number): DropListRef | undefined {
        // Hack to access private member DropListRef._siblings
        const this__siblings = (this as any)._siblings as DropListRef[];

        // Possible targets include siblings and this
        const targets = [this, ...this__siblings];

        // Only consider targets where the drag postition is within the client rect
        // (this avoids calling enterPredicate on each possible target)
        const matchingTargets = targets.filter((ref) => {
            // Hack to access private member DropListRef._clientRect
            const ref__clientRect = (ref as any)._clientRect as ClientRect | undefined;
            return ref__clientRect && isInsideClientRect(ref__clientRect, x, y);
        });

        // Stop if no targets match the coordinates
        if (matchingTargets.length === 0) {
            return undefined;
        }

        // Order candidates by DOM hierarchy and z-index
        const orderedMatchingTargets = _orderByHierarchy(matchingTargets);

        // The drop target is the last matching target in the list
        const matchingTarget = orderedMatchingTargets[orderedMatchingTargets.length - 1];

        // Return the target if it is a sibling and can recieve the item
        // return matchingTarget !== this && matchingTarget._canReceive(item, x, y) ? matchingTarget : undefined;
        // Only return matching target if it is a sibling
        if (matchingTarget === this) {
            return undefined;
        }

        // Can the matching target receive the item?
        if (!matchingTarget._canReceive(item, x, y)) {
            return undefined;
        }

        // Return matching target
        return matchingTarget;
    };
}

/**
 * Sort a list of DropListRef in such that forevery nested pair drop containers, the
 * outer drop container appear before the inner drop container.
 *
 * @param refs List of DropListRefs.
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function _orderByHierarchy(refs: DropListRef[]): DropListRef[] {
    // Build a map from HTMLElement to DropListRef
    const refsByElement: Map<HTMLElement, DropListRef> = new Map();
    refs.forEach((ref) => {
        refsByElement.set(coerceElement(ref.element), ref);
    });

    // Function to identify the closest ancestor among th DropListRefs
    const findAncestor = (ref: DropListRef): any => {
        let ancestor = coerceElement(ref.element).parentElement;

        while (ancestor) {
            if (refsByElement.has(ancestor)) {
                return refsByElement.get(ancestor);
            }
            ancestor = ancestor.parentElement;
        }

        return undefined;
    };

    // Node type for tree structure
    type NodeType = {ref: DropListRef; parent?: NodeType; children: NodeType[]};

    // Add all refs as nodes to the tree
    const tree: Map<DropListRef, NodeType> = new Map();
    refs.forEach((ref) => {
        tree.set(ref, {ref: ref, children: []});
    });

    // Build parent-child links in tree
    refs.forEach((ref) => {
        const parent = findAncestor(ref);

        if (parent) {
            const node = tree.get(ref);
            const parentNode = tree.get(parent);

            if (node){
                node.parent = parentNode;
                if (parentNode){
                    parentNode.children.push(node);
                }

            }

        }
    });

    // Find tree roots
    const roots = Array.from(tree.values()).filter(node => !node.parent);

    // Function to recursively build ordered list from roots and down
    const buildOrderedList = (nodes: NodeType[], list: DropListRef[]): any => {
        list.push(... nodes.map(node => node.ref));
        nodes.forEach((node) => {buildOrderedList(node.children, list); });
    };

    // Build and return the ordered list
    const ordered: DropListRef[] = [];
    buildOrderedList(roots, ordered);
    return ordered;
}

// Not possible to improt isInsideClientRect from @angular/cdk/drag-drop/client-rect
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function isInsideClientRect(clientRect: ClientRect, x: number, y: number): any {
    const {top, bottom, left, right} = clientRect;
    return y >= top && y <= bottom && x >= left && x <= right;
}

// Order a list of DropListRef so that for nested pairs, the outer DropListRef
// is preceding the inner DropListRef. Should probably be ammended to also
// sort by Z-level.
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function orderByHierarchy(refs: DropListRef[]): any {
    // Build a map from HTMLElement to DropListRef
    const refsByElement: Map<HTMLElement, DropListRef> = new Map();
    refs.forEach((ref) => {
        refsByElement.set(coerceElement(ref.element), ref);
    });

    // Function to identify the closest ancestor among th DropListRefs
    const findAncestor = (ref: DropListRef): any => {
        let ancestor = coerceElement(ref.element).parentElement;

        while (ancestor) {
            if (refsByElement.has(ancestor)) {
                return refsByElement.get(ancestor);
            }

            ancestor = ancestor.parentElement;
        }

        return undefined;
    };

    // Node type for tree structure
    type NodeType = { ref: DropListRef; parent?: NodeType; children: NodeType[] };

    // Add all refs as nodes to the tree
    const tree: Map<DropListRef, NodeType> = new Map();
    refs.forEach((ref) => {
        tree.set(ref, {ref: ref, children: []});
    });

    // Build parent-child links in tree
    refs.forEach((ref) => {
        const parent = findAncestor(ref);

        if (parent) {
            const node = tree.get(ref);
            const parentNode = tree.get(parent);

            if (node) {
                node.parent = parentNode;
                if (parentNode) {
                    parentNode.children.push(node);
                }
            }


        }
    });

    // Find tree roots
    const roots = [...tree.values()].filter(node => !node.parent);

    // Function to recursively build ordered list from roots and down
    const buildOrderedList = (nodes: NodeType[], list: DropListRef[]): any => {
        list.push(...nodes.map(node => node.ref));
        nodes.forEach((node) => {
            buildOrderedList(node.children, list);
        });
    };

    // Build and return the ordered list
    const ordered: DropListRef[] = [];
    buildOrderedList(roots, ordered);
    return ordered;
}
