<!--<ng-container cdkDropListGroup>-->
<!--    <div cdkDropList [cdkDropListData]="availableElements" class="flex flex-auto flex-col">-->
<!--        <button class="mat-button flex flex-0" cdkDrag *ngFor="let el of availableElements" [cdkDragData]="el">{{el.name}}</button>-->
<!--    </div>-->
    <div gruuls-context-element [element]="context" *ngIf="context && context.config.status !== bes.toInitialize && context.config.status !== bes.initializing">

    </div>
<!--</ng-container>-->
<!--<ng-container *ngIf="drawer">-->
<!--    <div class="flex flex-col flex-auto min-w-0">-->
<!--        <div class="flex-auto p-6 sm:p-10">-->
<!--            name: {{context?.data?.clickedElement?.config?.name}}-->
<!--            <div gruuls-element-factory [element]="context"-->
<!--                 class=" h-400 min-h-400 max-h-400 border-2 border-dashed border-gray-300 rounded-2xl">-->
<!--&lt;!&ndash;                <div gruuls-element-factory *ngIf="element" [element]="element"></div>&ndash;&gt;-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--    <gruuls-drawer-element [element]="drawer"></gruuls-drawer-element>-->
<!--</ng-container>-->
