import _lodash from 'lodash';
import {Field} from '../../field';
import {FieldType} from '../../field-type';
import {CollectionType} from '../../collection-type';
import {Fields} from '../../fields';
import {FormElementsAngularUtils} from '../../../../../@gruuls-fe/utils/form-elements-angular-utils';
import {InverseManyToOneFieldConfig} from './inverse-many-to-one-field-config';

const _ = _lodash;


export class InverseManyToOneField extends Field {

    public constructor(
        name: string,
        subFields: Field[],
        type: FieldType,
        collectionType: CollectionType,
        fieldConfig: InverseManyToOneFieldConfig,
        modelType: string,
        public otherDomain: string
    ) {
        super(name, subFields, undefined, type, collectionType, fieldConfig, modelType);

    }

    public static ofConfig(config: {
                               name: string;
                               subFields?: Field[];
                               type?: FieldType;
        collectionType?: CollectionType;
                               otherDomain?: string;
                               fieldConfig?: InverseManyToOneFieldConfig;
                               modelType?: string;
                           }
    ): Field {
        Fields.counter = Fields.counter++;
        config = _.defaultsDeep(
            {
                type: FieldType.oneToMany
            },
            config,
            {
                fieldConfig: {
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.oneToManyFormControlElement
                }
            });

        return new InverseManyToOneField(config.name, config.subFields, config.type, config.collectionType, config.fieldConfig, config.modelType, config.otherDomain);
    }

}
