<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div class="flex flex-col shadow bg-card">

        <!-- Cover image -->
        <div>
            <img
                class="h-40 lg:h-80 object-cover"
                src="{{contact?.background ? contact.background : 'assets/images/ui/beautycians/stones.jpg'}}"
                alt="Cover image">
        </div>

        <!-- User info -->
        <div class="flex flex-col flex-0 lg:flex-row items-center max-w-5xl w-full mx-auto px-8 lg:h-18 bg-card p-4">

            <!-- Avatar -->
            <div class="-mt-26 lg:-mt-22 rounded-full">
                <ng-container [ngSwitch]="!!contact?.avatar">
                    <img *ngSwitchCase="true"
                         class="w-32 h-32 rounded-full ring-4 ring-bg-card"
                         src="assets/images/avatars/male-04.jpg"
                         alt="User avatar">

                    <div class="w-32 h-32 rounded-full ring-4 ring-bg-card bg-white flex justify-center items-center text-4xl font-semibold border-4 border-accent" *ngSwitchDefault>
                        {{contact?.firstName?.charAt(0)?.toUpperCase()}}
                    </div>
                </ng-container>

            </div>

            <!-- Details -->
            <div class="flex flex-col items-center md:items-start mt-4 md:mt-0 md:ml-8">
                <div class="text-lg font-bold leading-none">{{contact?.firstName}} {{contact?.lastName}}</div>
                <div class="text-sm text-gray-600">{{contact?.account?.email}}</div>
            </div>

        </div>

    </div>

    <!-- Main -->
    <div class="grid md:grid-cols-3 gap-4 justify-center w-full max-w-5xl mx-auto p-6 sm:p-8">

        <!-- Organizations -->
        <ng-container *ngFor="let role of contact?.roles; trackBy: trackByFn" class="grid grid-cols-4 max-w-80 w-full gap-4">
            <fuse-card class="flex flex-col max-w-80 w-full p-6">
                <div class="flex flex-row">
                    <mat-icon class="icon-size-7 mr-3"
                    [svgIcon]="'heroicons_solid:home'"></mat-icon>
                    <div class="flex flex-col">
                        <div class="flex items-center text-xl font-semibold ">{{role.inOrganization?.name}}</div>
                        <div class="leading-tight pb-4">{{contact.roles[0]?.hasRole[0]?.name.split("-")[2]}}</div>
                    </div>
                </div>

            </fuse-card>
        </ng-container>
    </div>

    <!-- Column -->
    <div class="flex-auto pt-6 sm:px-4 sm:pb-4">

    </div>


</div>
