import { FormElementsAngularUtils } from '@gruuls-fe/utils/form-elements-angular-utils';
import _lodash from 'lodash';
import { CollectionType } from '../../collection-type';
import { Field } from '../../field';
import { FieldType } from '../../field-type';
import { Fields } from '../../fields';
import { OneToManyFieldConfig } from './one-to-many-field-config';

const _ = _lodash;


export class OneToManyField extends Field {

    public constructor(
        name: string,
        subFields: Field[],
        type: FieldType,
        collectionType: CollectionType,
        fieldConfig: OneToManyFieldConfig,
        modelType: string,
        public otherDomain: string,
        public otherDomainInverseFieldName: string
    ) {
        super(name, subFields, undefined, type, collectionType, fieldConfig, modelType);

    }

    public static ofConfig(config: {
                               name: string;
                               subFields?: Field[];
                               type?: FieldType;
        collectionType?: CollectionType;
                               otherDomain?: string;
                               otherDomainInverseFieldName?: string;
                               fieldConfig?: OneToManyFieldConfig;
                               modelType?: string;
                           }
    ): Field {
        Fields.counter = Fields.counter++;
        config = _.defaultsDeep(
            {
                type: FieldType.oneToMany
            },
            config,
            {
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.oneToManyFormControlElement
                }
            });

        return new OneToManyField(config.name, config.subFields, config.type, config.collectionType, config.fieldConfig, config.modelType, config.otherDomain, config.otherDomainInverseFieldName);
    }

}
