import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit, TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, NgForm, Validators } from '@angular/forms';
import { BehaviorSubject, fromEvent, merge, mergeMap, Observable, of, Subject, Subscription } from 'rxjs';
import { delay, exhaustMap, filter, map, takeUntil, tap } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { GruulsConstants } from '../../../../mock-api/gruuls/gruuls-constants';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { BeautyciansUtils } from '../../../utils/utils';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import moment from 'moment/moment';
import { GruulsModalElementComponent } from '../../../../../@gruuls-fe/elements/modal-element/gruuls-modal-element.component';
import { ModalElement } from '../../../../../@gruuls-core/elements/ui/modal-element/modal-element';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalElementConfig } from '../../../../../@gruuls-core/elements/ui/modal-element/modal-element-config';
import { ContextElement } from '../../../../../@gruuls-core/elements/misc/context-element/context-element';
import { GruulsAuthService } from '../../../../../@gruuls-fe/services/gruuls-auth.service';
import { Utils } from '@gruuls-core/utils/Utils';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { ACCOUNT_ASSEMBLE } from 'app/beautycians/utils/assemble';
import { View } from '@fullcalendar/core';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Organization } from '../../centri/centri.types';

@Component({
    selector: 'student-list',
    templateUrl: './student-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentListComponent implements OnInit, OnDestroy {
    @ViewChild('accountForm') accountForm: NgForm;
    @ViewChild('accountModal') accountModal: TemplateRef<any>;
    @ViewChild('loadMoreButton', { static: true }) loadMoreButton: ElementRef;

    drawerMode: 'side' | 'over';
    searchInputControl: FormControl = new FormControl();
    filteredElements: any[] = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private _elements: any[] = [];
    private orderArray = ['firstName', 'lastName'];

    private page: number = 0;
    reachedLimitSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    entities: any[];
    isLoading: boolean = false;
    isSpecificLoading: boolean[] = [];
    isLoadingError: boolean = false;

    translateStrings: any = { element: {}, user: {}, store: {}, generic: {} };

    selectedOwner: any;

    storeList: any[];

    dialogRef: MatDialogRef<GruulsModalElementComponent>;
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
    private academyOrg: Organization;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseConfirmationService: FuseConfirmationService,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _httpClient: GruulsAngularHttpProxyService,
        private _translate: GruulsAngularTranslateService,
        private _fb: FormBuilder,
        private dialog: MatDialog,
        private _authService: GruulsAuthService
    ) {

    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    accountFormGroup = this._fb.group({
        firstName: ['', Validators.required],
        lastName: [''],
        email: ['', Validators.required],
        defaultpassword: [''],
    });

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isLoading = true;

        this._apiCaller.getOrganizations({ name: 'Academy' }).pipe(
            tap((organizations: Organization[]) => {
                if (organizations && organizations.length > 0)
                    this.academyOrg = organizations[0];
            })
        ).subscribe();

        this.loadContent(null).subscribe({
            next: (personsResponse) => {
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                if (err.status >= 500)
                    this.isLoadingError = true;
                console.error(err);
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            }
        });

        // Subscribe to search input field value changes
        this.searchInputControl.valueChanges.pipe(
            takeUntil(this._unsubscribeAll),
            map(query => BeautyciansUtils.searchStringByKeys(this._elements, query, ['firstName', 'lastName'])),
            map(queryResult => BeautyciansUtils.sortByKeys(queryResult, this.orderArray)),
            tap(filteredElements => this.filteredElements = filteredElements)
        ).subscribe(() => this._changeDetectorRef.markForCheck());

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Set the drawerMode if the given breakpoint is active
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                } else {
                    this.drawerMode = 'over';
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this.dialog.afterOpened.pipe(
            takeUntil(this._unsubscribeAll),
            delay(0)
        ).subscribe(() => {
            if (this.selectedOwner) {
                this.accountFormGroup.controls['firstName'].setValue(this.selectedOwner.firstName);
                this.accountFormGroup.controls['lastName'].setValue(this.selectedOwner.lastName);
                this.accountFormGroup.controls['email'].setValue(this.selectedOwner.account?.email);
                this.accountFormGroup.controls['email'].disable();
                this.accountFormGroup.controls.defaultpassword.setValue("**********");
                this.accountFormGroup.controls.defaultpassword.disable();
            }
        });

        // Translations
        // TODO: Chiedere a Marco per quale motivo ritorna una stringa e non un Observable, anche se il tipo richiesto è l'Obs
        const elementTranslations = ['plural', 'singular', 'no', 'search', 'defaultpassword', 'addNew', 'editAdmin', 'noSearchResults', 'accountName', 'accountAdminData'];
        elementTranslations.forEach((translation) => {
            this.translateStrings['element'][translation] = this._translate.translate('students.' + translation);
        });

        const userTranslations = ['firstName', 'lastName', 'address', 'email', 'sex', 'male', 'female', 'ND', 'birthdate', 'pictureUrl', 'roles', 'deleteWarning', 'deleteWarningMessage'];
        userTranslations.forEach((translation) => {
            this.translateStrings['user'][translation] = this._translate.translate('users.' + translation);
        });

        const genericTranslations = ['send', 'close', 'save', 'delete','defaultpassword', 'loadMore', 'saveAndSendWelcome'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });
    }

    /**
     * 
     * After view init
     */
    afterContentLoadedInit(): void {
        const scrollEvent = fromEvent(window, 'scroll')
        const loadMoreButtonEvent = fromEvent(this.loadMoreButton.nativeElement, 'click');

        merge(
            scrollEvent, loadMoreButtonEvent
        ).pipe(
            takeUntil(this._unsubscribeAll),
            takeUntil(this.reachedLimitSubject),
            map(() => window.scrollY),
            filter((offset) => {
                return offset >= document.body.offsetHeight - window.innerHeight - 200;
            }),
            exhaustMap(() => {
                return this.loadContent(this.page);
            }),
            tap(() => {
                this.page++;
            })
        ).subscribe();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    loadContent(page: number = null): Observable<any> {
        if (page == null) {
            this.reachedLimitSubject.next(true);
            this.reachedLimitSubject.complete();
        }
        this.isSpecificLoading['loadMore'] = true;
        this._changeDetectorRef.markForCheck();
        return this._apiCaller.getPersonsByRoleTemplateName(['Beautycians-Student'], {}, ACCOUNT_ASSEMBLE, page).pipe(
            takeUntil(this._unsubscribeAll),
            tap((responsePersons) => {
                if (!responsePersons || responsePersons.hasNextPage == false) {
                    this.reachedLimitSubject.next(true);
                    this.reachedLimitSubject.complete();
                }
                if (responsePersons && responsePersons.hits.length > 0) {
                    this._elements.push(...responsePersons.hits);
                    this.filteredElements = BeautyciansUtils.sortByKeys(this._elements, this.orderArray);
                }
                this.isSpecificLoading['loadMore'] = false;
                this._changeDetectorRef.markForCheck();
            }),
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    sendWelcomeHandler(): void {
        if (!this.selectedOwner) {
            return;
        }

        this._authService.sendWelcomeEmail(this.selectedOwner.account.email).subscribe({
            next: (res) => {
                this._changeDetectorRef.markForCheck();
                this.dialog.closeAll();
            },
            error: (err) => {
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    accountFormSubmit(submitId): void {
        this.isSpecificLoading[submitId] = true;

        if (this.accountFormGroup.invalid)
            return;

        if (this.selectedOwner)
            return;

        this.accountFormGroup.disable();
        const roleTemplate = {
            'student': 'c379f678-6150-4ffb-b1d6-5a1087cba729',
        }

        this._apiCaller.createAccount(
            this.academyOrg ? this.academyOrg.organizationId : this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId,
            this.accountFormGroup.value,
            this.accountFormGroup.value["email"],
            roleTemplate['student'],
            this.accountFormGroup.value["defaultpassword"],
        ).pipe(
            mergeMap((resMap) => {
                if (submitId == 'saveAndSendWelcome') {
                    return this._authService.sendWelcomeEmail(this.accountFormGroup.value["email"]);
                } else {
                    return of(resMap);
                }

            })
        ).subscribe({
            next: (res) => {
                this.isSpecificLoading[submitId] = false;
                this.accountForm.resetForm();
                this.accountFormGroup.enable();
                this._changeDetectorRef.markForCheck();
                window.location.reload();
            },
            error: (err) => {
                this._fuseConfirmationService.open(
                    BeautyciansUtils.getErrorDialogConfig('Something went wrong:' + err, 'Error', 'OK')
                );
                console.log(err);
                this.isSpecificLoading[submitId] = false;
                this.accountFormGroup.enable();
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    /**
     * On backdrop clicked
     */
    onBackdropClicked(): void {
        // Go back to the list
        this._router.navigate(['./'], { relativeTo: this._activatedRoute });

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }


    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    openModal(): void {
        // if (this.selectedOwner) this.accountFormBuilder.controls['email'].disable();
        // else this.accountFormBuilder.controls['email'].enable();
        this.dialog.open(this.accountModal, {
            panelClass: ['md:w-160', 'w-full']
        });
    }

    deleteContact(contact: any): void {
        // TODO: could be interesting to add anamnesi count using getAnamnesiCount
        const confirmation = this._fuseConfirmationService.open({
            title: this.translateStrings.user.deleteWarning + ": " + contact.firstName + " " + contact.lastName,
            message: this.translateStrings.user.deleteWarningMessage,
            actions: {
                confirm: {
                    label: this.translateStrings.generic.delete
                }
            }
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) => {

            if (result === 'confirmed') {
                this._apiCaller.deletePerson(contact.personId).subscribe({
                    next: (res) => {
                        this._elements = this._elements.filter((el) => el.personId != contact.personId);
                        this.filteredElements = BeautyciansUtils.sortByKeys(this._elements, this.orderArray);
                        this._changeDetectorRef.markForCheck();
                    },
                    error: (err) => {
                        console.error(err);
                    }
                });
            }
        })
    }


}