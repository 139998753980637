import { GruulsMockDomain, GruulsMockHttpRequest } from '../../gruuls-mock-domain';
import { HttpClient } from '@angular/common/http';

export class BeautyPriorityMock extends GruulsMockDomain {

  constructor(httpClient: HttpClient) {
    super(httpClient);

    this.entities = this.GET_PRIORITY;
  }

  GET_PRIORITY =
    [{
      IBD_color_cutn: {
        advisedTreatment: 'IBD_color_cutn',
        priorityScore: { 2: 20, 1: 25, }, homePriorityScore: { 2: 36, 1: 37 }
      },
      IBD_cond_tess_conn: {
        advisedTreatment: 'atonia',
        priorityScore: { 3: 15, 2: 60, }, homePriorityScore: { 3: 38, 2: 48 }
      },
      IBD_condz_tess_musc: {
        advisedTreatment: 'atonia',
        priorityScore: { 3: 30, 2: 75, }, homePriorityScore: { 3: 39, 2: 49 }
      },
      IBD_edema_arti_inf: {
        advisedTreatment: 'IBD_presenz_edema',
        priorityScore: { 2: 45, },
        homePriorityScore: { 2: 17 }
      },
      IBD_elast: {
        advisedTreatment: 'IBD_elast',
        priorityScore: { 3: 55, 2: 60, }, homePriorityScore: { 3: 38, 2: 39 }
      },
      IBD_fovea: {
        advisedTreatment: 'IBD_fovea',
        priorityScore: { 3: 55, 2: 90, },
      },
      IBD_idratz: {
        advisedTreatment: 'IBD_idratz',
        priorityScore: { 3: 5, 2: 5, }, homePriorityScore: { 3: 15, 2: 25 }
      },
      IBD_inest_cont_occhi: {
        advisedTreatment: '',
        priorityScore: { 1: 70 },
      },
      IBD_presenz_adp_diff: {
        advisedTreatment: 'IBD_presenz_adp_diff',
        priorityScore: { 2: 5, }, homePriorityScore: { 2: 15 }
      },
      // IBD_presenz_cellu_diff: {
      //   advisedTreatment: '',
      //   priorityScore: { 2: 20, }, homePriorityScore: { 2: 16, },
      // },
      // IBD_presenz_cellu_loc: {
      //   advisedTreatment: '',
      //   priorityScore: { 0: 20, }
      // },
      IBD_presenz_cuper: {
        advisedTreatment: 'IBD_presenz_cuper',
        priorityScore: { 2: 35, }, homePriorityScore: { 2: 16 }
      },
      IBD_presenz_edema: {
        advisedTreatment: 'IBD_presenz_edema',
        priorityScore: { 2: 45, }
      },
      IBD_presenz_edema_artSX: {
        advisedTreatment: 'IBD_presenz_edema',
        priorityScore: { 2: 10, },
      },
      IBD_presenz_eritm: {
        advisedTreatment: '',
        priorityScore: { 2: 80, },
        homePriorityScore: { 2: 17 }
      },
      IBD_presenz_pann_adp: {
        advisedTreatment: 'IBD_presenz_pann_adp',
        priorityScore: { 2: 50, },
        homePriorityScore: { 2: 27 }
      },
      IBD_presenza_pori_dil: {
        advisedTreatment: 'IBD_presenza_pori_dil',
        priorityScore: { 1: 90, },
        homePriorityScore: { 1: 19 }
      },
      IBD_presez_acne: {
        advisedTreatment: 'IBD_presez_acne',
        priorityScore: { 2: 70, }
      },
      IBD_presez_impur: {
        advisedTreatment: 'IBD_presez_impur',
        priorityScore: { 1: 50, },
      },
      IBD_presz_crom: {
        advisedTreatment: '',
        priorityScore: { 2: 60, }, homePriorityScore: { 2: 28 }
      },
      IBD_presz_infmz: {
        advisedTreatment: 'IBD_presz_infmz',
        priorityScore: { 3: 20, }, homePriorityScore: { 3: 27 }
      },
      IBD_prof_rughe: {
        specifPriorityForResponse: {
          'DEEP': {
            advisedTreatment: 'IBD_rughe_profonde',
            priorityScore: { 3: 10, 1: 85, },
            homePriorityScore: { 3: 18, 1: 29, },
          },
          'SUPERFICIAL': {
            advisedTreatment: 'IBD_rughe_superficiali',
            priorityScore: { 3: 10, 1: 85, },
            homePriorityScore: { 3: 18, 1: 29, },
          }
        }
      },
      IBD_sensibilita: {
        advisedTreatment: 'IBD_sensibilita',
        priorityScore: { 2: 65, }, homePriorityScore: { 2: 26, },
      },
      IBD_senz_gonf_add: {
        advisedTreatment: 'IBD_senz_gonf_add',
        priorityScore: { 2: 70, },
      },
      IBD_senz_pesant_art_inf: {
        advisedTreatment: 'IBD_senz_pesant_art_inf',
        priorityScore: { 2: 45, }, homePriorityScore: { 2: 37, },
      },
      IBD_presenz_cellu_diff_tipologia: {
        specifPriorityForResponse: {
          'FIBROSA': {
            advisedTreatment: 'IBD_presenz_cellu_fibr',
            priorityScore: { 2: 25 },
            homePriorityScore: { 2: 18 },
          },
          'EDEMATOSA': {
            advisedTreatment: 'IBD_presenz_cell_edem',
            priorityScore: { 2: 25 },
            homePriorityScore: { 2: 19 },
          }
        }
      },
      IBD_presenz_cellu_loc_tipologia: {
        specifPriorityForResponse: {
          'ADIPOSA': {
            advisedTreatment: 'IBD_presenz_cell_adip',
            priorityScore: { 2: 35 },
            homePriorityScore: { 2: 26 },
          },
          'FIBROSA': {
            advisedTreatment: 'IBD_presenz_cellu_fibr',
            priorityScore: { 2: 40 },
            homePriorityScore: { 2: 28 },
          },
          'EDEMATOSA': {
            advisedTreatment: 'IBD_presenz_cell_edem',
            priorityScore: { 2: 40 },
            homePriorityScore: { 2: 29 },
          },
        }
      }
    }];
}
