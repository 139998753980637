import {Role} from './role';
import {UserGroupSettings} from './user-group-settings';

// @dynamic
export class UserGroup {

  constructor(
    public groupId: string,
    public groupName: string,
    public groupFlatName: string,
    public roles: Role[],
    public settings: UserGroupSettings
  ){

  }

  public static of(userGroup: any): UserGroup{
    return new UserGroup(
      userGroup.groupId,
      userGroup.groupName,
      userGroup.froupFlatName,
      userGroup.roles.map((el: any) => Role.of(el)),
      userGroup.settings ? userGroup.settings.map : undefined
    );
  }

  public getHomePageUrl(): string|undefined{
    if (this.settings && this.settings.home &&  this.settings.home.route){
      return this.settings.home.route;
    }else{
      let route;
      for (const u of this.roles){
        const h = u.getHomePageUrl();
        if (h){
          route = h;
          break;
        }
      }
      return route ? route : undefined;
    }
  }

  public isFavourite(): boolean{
    for (const r in this.roles){
      if (this.roles[r].isFavourite()){
        return true;
      }
    }
    return false;
  }
}
