import { ChangeDetectorRef, Component, EventEmitter, Inject, Injectable, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { FuseConfirmationConfig, FuseConfirmationService } from "@fuse/services/confirmation";
import { GruulsAngularCartService } from "@gruuls-fe/services/gruuls-angular-cart.service";
import { GruulsAngularHttpProxyService } from "@gruuls-fe/services/gruuls-angular-http-proxy.service";
import { GruulsAngularTranslateService } from "@gruuls-fe/services/gruuls-angular-translate.service";
import { GruulsAuthService } from "@gruuls-fe/services/gruuls-auth.service";
import { ApiCaller } from "app/beautycians/utils/apiCaller";
import { Subscription, filter, mergeMap, switchMap, map, tap, of, forkJoin, take } from "rxjs";
import { ORDERSTATUS, Order, OrderInfo } from "../order.types";
import { Organization } from "../../centri/centri.types";
import { GruulsAngularStoreService } from "@gruuls-fe/services/gruuls-angular-stores-service";
import { Utils } from "@gruuls-core/utils/Utils";
import { Store } from "app/beautycians/utils/dataTypes";
import { BeautyciansUtils } from "app/beautycians/utils/utils";


@Component({
  selector: 'order-confirmation-dialog',
  templateUrl: './order-confirmation-dialog.component.html',
  styles: [
    /* language=SCSS */
    `a {
          cursor: pointer;
      }
    
      .custom-confirmation-dialog-panel {
          @screen md {
              @apply w-128;
          }

          .mat-dialog-container {
              padding: 0 !important;
          }
      }
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class OrderConfirmationDialogComponent implements OnInit {
  @Output() shippingTo: EventEmitter<any> = new EventEmitter<any>();

  isEditShipping: boolean = false;
  translateStrings: any = { order: {}, generic: {} };
  selectedShippingStore: any;
  isSpecificLoading: any = {};
  stores: Store[] = [];
  message: string;
  _routerSubscription: Subscription;
  totalQuantity: number = 0;
  totalPrice: number = 0;
  receiver: string = 'fboano@gmail.com';
  allInfo: boolean = false;
  legalAddress: Store;
  private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FuseConfirmationConfig,
    @Inject(MAT_DIALOG_DATA) public order: Order,
    private _authService: GruulsAuthService,
    private _httpClient: GruulsAngularHttpProxyService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseConfirmationService: FuseConfirmationService,
    private _cartService: GruulsAngularCartService,
    private _storeService: GruulsAngularStoreService,
    private _router: Router,
    private _translate: GruulsAngularTranslateService,
    public matDialogRef: MatDialogRef<OrderConfirmationDialogComponent>
  ) { }

  ngOnInit(): void {
    this.isSpecificLoading['stores'] = true;

    const orderTranslations = ['plural', 'singular', 'no', 'search', 'addNew', 'copyToNew', 'noSearchResults', 'total', 'shippingTo', 'addCustomAddress', 'notePlaceholder'];
    orderTranslations.forEach((translation) => {
      this.translateStrings['order'][translation] = this._translate.translate('orders.' + translation);
    });
    const genericTranslations = ['warning', 'cancel', 'confirm', 'close', 'send', 'edit'];
    genericTranslations.forEach((translation) => {
      this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
    });

    forkJoin({
      org: this._apiCaller.getOrganizations({ organizationId: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId }).pipe(
        switchMap((organization: Organization[]) => {
          if (organization[0]?.vatNumber) {
            return this._apiCaller.getClientInfo(organization[0]?.vatNumber).pipe(
              tap((clientInfo) => {
                this.legalAddress = BeautyciansUtils.legalAddressFromClientInfo(clientInfo);
                this._changeDetectorRef.markForCheck();
              })
            );
          } else {
            return of([]);
          }
        }),
        take(1)
      ),
      stores: this._storeService.stores$.pipe(
        tap((stores) => {
          this.stores = stores;
        }),
        take(1)
      )
    }).subscribe({
      next: (res) => {
        if (this.legalAddress)
          this.stores.unshift(this.legalAddress);
        if (this.stores && this.stores.length > 0) {
          this.selectedShippingStore = this.stores[0];
          this.changeShippingStoreHandler(this.selectedShippingStore);
        }
        this.isSpecificLoading['stores'] = false;
        this._changeDetectorRef.markForCheck();
      },
      error: (err) => {
        console.log('Error while getting organization info: ', err);
      }
    });

    this._storeService.getStores().subscribe();

    this.totalQuantity = this.order.cart.products.reduce((acc, curr) => acc + curr.quantity, 0);

    this._routerSubscription = this._router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        filter(() => !!this.matDialogRef)
      ).subscribe(() => {
        this.matDialogRef.close({ message: 'addShippingAddress' });
      });

    // TODO: improve by placing in getSelectedOrganization's extra field
    this._apiCaller.getOrganizations({ 'organizationId': this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId }).subscribe((orgs) => {
      if (orgs[0].extra?.referentMail && orgs[0].extra?.referentMail != '')
        this.receiver = orgs[0].extra?.referentMail;
    });

  }

  public changeShippingStoreHandler(store: any): void {
    this.allInfo = (Utils.isValidString(this.selectedShippingStore.name) && Utils.isValidString(this.selectedShippingStore.address) && Utils.isValidString(this.selectedShippingStore.city) && Utils.isValidString(this.selectedShippingStore.zip) && Utils.isValidString(this.selectedShippingStore.province));
  }

  public editShipping(): void {
    this.isEditShipping = true;
  }

  addShippingAddress(): void {
    this.matDialogRef.close({ message: 'addShippingAddress' });
  }

  sendOrder(): void {
    if (this.isEditShipping) {
      this.isEditShipping = false;
      return;
    }
    const name = this._authService.getCurrentLoggedUser().getSelectedOrganization().name; // + "_" + this.datepipe.transform(new Date(), 'dd_MM_yyyy');
    let note: OrderInfo | null = null;
    if (this.order._note) {
      note = {
        personId: this._authService.getCurrentLoggedUser().personId,
        organizationId: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId,
        date: new Date().getTime(),
        comment: this.order._note,
        isPublic: true,
        type: 'note'
      }
    };
    this.order = {
      ...this.order,
      name: name,
      date: new Date().getTime(),
      externalPaymentConditionsId: 0,
      organizationId: {
        organizationId: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId
      },
      storeId: this.selectedShippingStore?.storeId,
      status: ORDERSTATUS.WAITING,
      infoObject: note ? [note] : [],
    };
    if (this.order.orderId)
      return;

    this.isSpecificLoading['save'] = true;
    this._apiCaller.createOrder(this.order).pipe(
      mergeMap((order) => {
        return this._apiCaller.sendOrderEmail(order, this.receiver).pipe(
          map((mailSent) => {
            return order
          })
        );
      }
      )).
      subscribe({
        next: (res) => {
          this._cartService.resetCart();
          this.isSpecificLoading['save'] = false;
          this._router.navigate(['/beauty/order/list']);
        },
        error: (err) => {
          this.isSpecificLoading['save'] = false;
          const dialogRef = this._fuseConfirmationService.open({
            "title": this.translateStrings['generic']['error'],
            "message": this.translateStrings['order']['errorWhilePlacing'] + ": " + err.error,
            "icon": {
              "show": true,
              "name": "triangle",
              "color": "error"
            },
            "actions": {
              "confirm": {
                "show": true,
                "label": this.translateStrings['generic']['close'],
                "color": "primary"
              },
              "cancel": {
                "show": false,
                "label": this.translateStrings['generic']['cancel']
              }
            },
            "dismissible": true
          });
          console.log('Error while sending order: ', err);
        }
      });
  }

}