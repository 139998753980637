import { DraggableTreeModule } from "../common/draggable-tree/draggable-tree.module";
import { NgModule } from "@angular/core";
import { SettingsComponent } from "./settings.component";


@NgModule({
  declarations: [
      SettingsComponent,
  ],
  imports     : [
      DraggableTreeModule
  ],
  providers   : []
})
export class SettingsModule
{
}
