import {FieldType} from './field-type';
import {CollectionType} from './collection-type';
import {DataSchema} from './data-schema';
import {FieldModelTypes} from './field-model-types';
import {FieldConfig} from '../domain/templates-config';

export class Field {
    public _pathName: string[] = [];
    dataSchema: DataSchema;


    public constructor(
        public name: string,
        public subFields: Field[],
        public systemSubFields: Field[],
        public type: FieldType,
        public collectionType: CollectionType,
        public fieldConfig: FieldConfig,
        public modelType: string,
    ) {
        this.fieldConfig = this.fieldConfig || {};
        if (!this.fieldConfig.templateName) {
            this.fieldConfig.templateName = 'default';
        }
        if (!this.modelType) {
            this.modelType = FieldModelTypes.flat;
        }
        if (!this.collectionType) {
            this.collectionType = CollectionType.NONE;
        }
        if (!this.fieldConfig.syncValidators) {
            this.fieldConfig.syncValidators = [];
        }
        if (!this.fieldConfig.asyncValidators) {
            this.fieldConfig.asyncValidators = [];
        }
        if (!this.fieldConfig.listSyncValidators) {
            this.fieldConfig.listSyncValidators = [];
        }
        if (!this.fieldConfig.listAsyncValidators) {
            this.fieldConfig.listAsyncValidators = [];
        }
        if (!this.fieldConfig.groupSyncValidators) {
            this.fieldConfig.groupSyncValidators = [];
        }
        if (!this.fieldConfig.groupAsyncValidators) {
            this.fieldConfig.groupAsyncValidators = [];
        }
        if (!this.subFields) {
            this.subFields = [];
        }
        if (!this.systemSubFields) {
            this.systemSubFields = [];
        }
        if (!this.fieldConfig.uiFrameworkFormElementBuilder) {
            throw new Error('field ' + this.name + ' has no uiFrameworkFormElementBuilder');
        }

    }

    public static of(
        name: string,
        subFields: Field[],
        systemSubFields: Field[],
        type: FieldType,
        collectionType: CollectionType,
        fieldConfig: FieldConfig,
        modelType: string
    ): Field {
        return new Field(name, subFields, systemSubFields, type, collectionType, fieldConfig, modelType);
    }

    public static ofConfig(config: {
                               [key: string]: any;
                               name: string;
                               subFields?: Field[];
                               systemSubFields?: Field[];
                               type: FieldType;
                               collectionType?: CollectionType;
                               modelType?: string;
                               fieldConfig?: FieldConfig;
                           }
    ): Field {
        return new Field(config.name, config.subFields, config.systemSubFields, config.type, config.collectionType, config.fieldConfig, config.modelType);
    }

    public getName(): string {
        return this.name;
    }

    public setPathName(parentPathName: string[]): void{
        this._pathName = parentPathName.concat([this.name]);
        if (this.subFields) {
            for (const f of this.subFields) {
                f.setPathName(this._pathName);
            }
        }
    }

    public setSchema(dataSchema: DataSchema): void {
        this.dataSchema = dataSchema;
        if (this.subFields && this.subFields.length) {
            for (const subF of this.subFields) {
                subF.setSchema(dataSchema);
            }
        }
    }

    // public getUIFrameworkFormElement(schema:DataSchema, data:any, considerAsSingle:boolean):FormGroup | FormArray | FormControl | FieldsForParentControls{
    //   return this.fieldConfig.editFormConfig.uiFrameworkFormElementBuilder.build(schema, this, data, considerAsSingle);
    // }


    public getI18nCode(): string[] {
        return [this.dataSchema.getContextName(), this.dataSchema.getDomainName(), 'fields'].concat(this._pathName);
    }

    public getFieldConfig(): FieldConfig {
        return this.fieldConfig;
    }

    public populateAssembleAs(assembleAs: any, subFieldsAssembly?: any): void {
        assembleAs[this.name] = true;
    }

}
