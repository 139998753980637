import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {UserMenuComponent} from './user-menu.component';
import {QrnerSharedModule} from '../../../qrner-shared-module/qrner-shared.module';

@NgModule({
    declarations: [
        UserMenuComponent
    ],
    imports     : [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        QrnerSharedModule
    ],
    exports     : [
        UserMenuComponent
    ]
})
export class UserMenuModule
{
}
