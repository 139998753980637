import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit, TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, NgForm, Validators, AbstractControlOptions } from '@angular/forms';
import { BehaviorSubject, forkJoin, fromEvent, merge, mergeMap, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, delay, exhaustMap, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { GruulsConstants } from '../../../../mock-api/gruuls/gruuls-constants';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { BeautyciansUtils } from '../../../utils/utils';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import moment from 'moment/moment';
import { GruulsModalElementComponent } from '../../../../../@gruuls-fe/elements/modal-element/gruuls-modal-element.component';
import { ModalElement } from '../../../../../@gruuls-core/elements/ui/modal-element/modal-element';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalElementConfig } from '../../../../../@gruuls-core/elements/ui/modal-element/modal-element-config';
import { ContextElement } from '../../../../../@gruuls-core/elements/misc/context-element/context-element';
import { GruulsAuthService } from '../../../../../@gruuls-fe/services/gruuls-auth.service';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { ACCOUNT_ASSEMBLE } from 'app/beautycians/utils/assemble';
import { atLeastOne } from 'app/beautycians/utils/customValidators';
import { Person } from '../../clients/client.types';
import { Utils } from '@gruuls-core/utils/Utils';
import { AccountsService } from '../allowner.service';
import { debounce } from 'lodash';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Organization } from '../../centri/centri.types';
import { OWNERSHIP_ROLES } from 'app/beautycians/utils/constants';

@Component({
    selector: 'owner-list',
    templateUrl: './owner-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwnerListComponent implements OnInit, OnDestroy {
    @ViewChild('accountForm') accountForm: NgForm;
    @ViewChild('accountModal') accountModal: TemplateRef<any>;
    @ViewChild('loadMoreButton') loadMoreButton: ElementRef;

    // selectedAdminTab = new FormControl(0);
    drawerMode: 'side' | 'over';
    searchInputControl: FormControl = new FormControl();
    filteredContacts: any[];

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _contacts: Person[];
    private _organizations: Organization[];

    page: number = 0;
    reachedLimitSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    entities: any[];
    isPartialLoading: boolean[] = [];
    isSpecificLoading: boolean[] = [];
    isLoadingError: boolean = false;

    translateStrings: any = { owner: {}, user: {}, store: {}, generic: {}, organization: {} };

    selectedOwner: any;

    orgList: Organization[];
    accounts: Person[];
    orgAccountMatrix: { organization: Organization, accounts: Person[] }[];
    filteredOrgAccountMatrix: { organization: Organization, accounts: Person[] }[];

    dialogRef: MatDialogRef<GruulsModalElementComponent>;
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _snackBar: MatSnackBar,
        private _httpClient: GruulsAngularHttpProxyService,
        private _translate: GruulsAngularTranslateService,
        private _fb: FormBuilder,
        private dialog: MatDialog,
        private _authService: GruulsAuthService,
        private _accountService: AccountsService
    ) {

    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    accountFormGroup = this._fb.group({
        firstName: ['', Validators.required],
        lastName: [''],
        email: ['', Validators.required],
        defaultpassword: [''],
        accountName: [''],
        accountList: [''],
        accountOrganizationId: [''],
        accountAddress: ['']
        // vatNumber: ['', Validators.pattern('^[a-zA-Z]{2,3}[0-9]*$')],
        // referent: [''],
        // licence: ['']    
    }, { validator: atLeastOne(Validators.required, ['accountList', 'accountName']) } as AbstractControlOptions);

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isPartialLoading['organizations'] = true;
        this.isPartialLoading['accounts'] = true;
        this._changeDetectorRef.markForCheck();

        this.loadContent(null).subscribe({
            next: (accounts) => {
                // this.orgAccountMatrix = this._organizations.map((org: Organization) => {
                //     return {
                //         organization: org,
                //         accounts: accounts?.filter((account: Person) => Utils.hasRole(account.roles, [], org.organizationId))
                //     }
                // })
                // this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                if (err.status >= 500)
                    this.isLoadingError = true;
                console.error(err);
                this._changeDetectorRef.markForCheck();
            }
        });

        this._accountService.isCompleted$.pipe(
            tap((isCompleted) => {
                if (isCompleted) {
                    this.isPartialLoading['accounts'] = false;
                    this._changeDetectorRef.markForCheck();
                }
            })
        ).subscribe();

        forkJoin({
            org: this._apiCaller.getOrganizations().pipe(
                filter((org) => org.organizationId != this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId),
                tap((organizations) => {
                    this.isPartialLoading['organizations'] = false;
                    this.orgList = [{ name: 'None', organizationId: null }];
                    // TODO: Remove this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId
                    const orderedOrgs = organizations.sort((a, b) => a.name.localeCompare(b.name));
                    this._organizations = orderedOrgs
                    this.orgList.push(...orderedOrgs);
                }),
                tap((organizations) => {
                    this.orgAccountMatrix = this._organizations.map((org: Organization) => ({
                        organization: org,
                        accounts: this.accounts?.filter((account: Person) => Utils.hasRole(account.roles, [], org.organizationId))
                    }))
                    if (this.searchInputControl.value)
                        this.filteredOrgAccountMatrix = BeautyciansUtils.searchStringByKeys(this.orgAccountMatrix, this.searchInputControl.value, [['organization', 'name'], ['accounts', '*', 'firstName'], ['accounts', '*', 'lastName']]);
                    else
                        this.filteredOrgAccountMatrix = this.orgAccountMatrix;
                    this._changeDetectorRef.markForCheck();
                })
            ),
            accounts: this._accountService.accounts$.pipe(
                takeUntil(this._unsubscribeAll),
                tap((accounts) => {
                    // this.filteredContacts = BeautyciansUtils.sortByKeys(accounts, [['roles', '0', 'inOrganization', 'name'], 'firstName', 'lastName']);
                    this.accounts = accounts;
                    if (this._organizations) {
                        this.orgAccountMatrix = this._organizations.map((org: Organization) => ({
                            organization: org,
                            accounts: accounts.filter((account: Person) => Utils.hasRole(account.roles, [], org.organizationId))
                        }))
                        if (this.searchInputControl.value)
                            this.filteredOrgAccountMatrix = BeautyciansUtils.searchStringByKeys(this.orgAccountMatrix, this.searchInputControl.value, [['organization', 'name'], ['accounts', '*', 'firstName'], ['accounts', '*', 'lastName']]);
                        else
                            this.filteredOrgAccountMatrix = this.orgAccountMatrix;
                    }
                    this._changeDetectorRef.markForCheck();
                })
            )
        }).subscribe({
            error: (err) => {
                if (err.status >= 500)
                    this.isLoadingError = true;
                console.error(err);
                this.isPartialLoading['organizations'] = false;
                // this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            },
        });


        // Subscribe to search input field value changes
        this.searchInputControl.valueChanges.pipe(
            takeUntil(this._unsubscribeAll),
            debounceTime(300),
            // switchMap(query => this._accountService.filterAccounts(query)),
            map(query => BeautyciansUtils.searchStringByKeys(this.orgAccountMatrix, query, [['organization', 'name'], ['accounts', '*', 'firstName'], ['accounts', '*', 'lastName']])),
            tap((filteredMatrix) => {
                this.filteredOrgAccountMatrix = filteredMatrix;
                this._changeDetectorRef.markForCheck();
            })
        ).subscribe();

        this.dialog.afterOpened.pipe(
            takeUntil(this._unsubscribeAll),
            delay(0)
        ).subscribe(() => {
            if (this.selectedOwner) {
                const suitableRoles = this.selectedOwner.roles.filter((role) => role.hasRole.filter((role) => role.name in OWNERSHIP_ROLES));
                let suitableRole;
                if (suitableRoles.length > 0)
                    suitableRole = suitableRoles[0].inOrganization;
                else
                    suitableRole = null;
                this.accountFormGroup.controls['accountName'].setValue(this.selectedOwner.account?.name);
                this.accountFormGroup.controls['firstName'].setValue(this.selectedOwner.firstName);
                this.accountFormGroup.controls['lastName'].setValue(this.selectedOwner.lastName);
                this.accountFormGroup.controls['email'].setValue(this.selectedOwner.account?.email);
                this.accountFormGroup.controls['email'].disable();
                this.accountFormGroup.controls['accountName'].disable();
                this.accountFormGroup.controls['accountList'].setValue(suitableRole.organizationId);
                this.accountFormGroup.controls.defaultpassword.setValue("**********");
                this.accountFormGroup.controls.defaultpassword.disable();

            } else {
                this.accountFormGroup.controls.accountName.enable();
                this.accountFormGroup.controls.email.enable();
                this.accountFormGroup.controls.defaultpassword.enable();
            }
        });

        // Translations
        // TODO: Chiedere a Marco per quale motivo ritorna una stringa e non un Observable, anche se il tipo richiesto è l'Obs
        const ownerTranslations = ['plural', 'singular', 'no', 'search', 'defaultpassword', 'addNew', 'editAdmin', 'noSearchResults', 'accountName', 'accountAdminData', 'accountManagerData', 'orgNew', 'orgExisting', 'deleteWarning', 'deleteWarningMessage', 'errorWhileImpersonating', 'accountData', 'orgName'];
        ownerTranslations.forEach((translation) => {
            this.translateStrings['owner'][translation] = this._translate.translate('owners.' + translation);
        });

        const organizationTranslations = ['name', 'plural', 'singular', 'no', 'search', 'addNew', 'edit', 'noSearchResults', 'accountName', 'accountAdminData', 'deleteWarning', 'deleteWarningMessage', 'referent', 'licence', 'vatNumber'];
        organizationTranslations.forEach((translation) => {
            this.translateStrings['organization'][translation] = this._translate.translate('organizations.' + translation);
        });

        const userTranslations = ['firstName', 'lastName', 'address', 'email', 'sex', 'male', 'female', 'ND', 'birthdate', 'pictureUrl', 'roles'];
        userTranslations.forEach((translation) => {
            this.translateStrings['user'][translation] = this._translate.translate('users.' + translation);
        });

        const storeTranslations = ['address', 'zip', 'name', 'description', 'type', 'singular', 'plural', 'noStores', 'search', 'noSearchResults', 'addNew'];
        storeTranslations.forEach((translation) => {
            this.translateStrings['store'][translation] = this._translate.translate('stores.' + translation);
        });

        const genericTranslations = ['send', 'close', 'save', 'loadMore', 'saveAndSendWelcome', 'edit', 'delete', 'sendWelcome', 'mailSent', 'impersonate', 'error'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    loadContent(page: number = null): Observable<any> {
        if (page == null) {
            this.reachedLimitSubject.next(true);
            this.reachedLimitSubject.complete();
        }
        this.isSpecificLoading['loadMore'] = true;
        this._changeDetectorRef.markForCheck();

        return this._accountService.refreshAccounts(page).pipe(
            tap((responsePersons) => {
                if (responsePersons && responsePersons.hits && responsePersons.hits.length > 0) {
                    if (page == null) {
                        this._contacts = responsePersons.hits;
                    } else {
                        this._contacts.push(...responsePersons.hits);
                    }
                    // this.filteredContacts = BeautyciansUtils.sortByKeys(this._contacts, [['roles', '0', 'inOrganization', 'name'], 'firstName', 'lastName']);
                }
                this.isSpecificLoading['loadMore'] = false;
                this._changeDetectorRef.markForCheck();
            })
        );
    }

    isLoading(): boolean {
        const state = Object.values(this.isPartialLoading).reduce((acc, curr) => acc || curr, false);
        return state;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    addAdminToOrganization(organization: Organization): void {
        this.accountFormGroup.reset();
        this.accountFormGroup.controls.accountOrganizationId.setValue(organization.organizationId);
        this.accountFormGroup.controls.accountName.setValue(organization.name);
        this.accountFormGroup.controls.accountName.disable();
        this.accountFormGroup.controls.address.setValue(organization.address);
        this.accountFormGroup.controls.address.disable();

    }

    sendWelcomeHandler(): void {
        if (!this.selectedOwner) {
            return;
        }

        this._authService.sendWelcomeEmail(this.selectedOwner.account.email).subscribe({
            next: (res) => {
                this._snackBar.open(this.translateStrings['generic']['mailSent'], null, {
                    duration: 3000,
                    horizontalPosition: "left",
                    verticalPosition: "bottom"
                });
            },
            error: (err) => {
            }
        });
    }

    accountFormSubmit(submitId): void {

        const storeOwnerRoleTemplateId = 'a2d854a1-c1c1-4343-a8c4-91f8bc7ba1a1'; // Stores Owner
        const storeManagerRoleTemplateId = 'f5457ad1-a575-4147-8a9e-386b8b24f208'; // Stores Manager
        let organization$: Observable<Organization>;
        let roleTemplateId: string;

        // Originally designed to directly create also the Organization
        // if (this.selectedAdminTab.value == 0) {
        //     if (this.accountFormGroup.controls.accountName.value == '')
        //         return;
        //     organization$ = this._apiCaller.createOrganization(
        //         this.accountFormGroup.value["accountName"],
        //         this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId
        //     );
        //     roleTemplateId = storeOwnerRoleTemplateId;
        // } else {
        if (this.accountFormGroup.controls.accountList.value == null)
            return;
        organization$ = of(this.orgList.find((org) => org.organizationId === this.accountFormGroup.controls.accountList.value));
        roleTemplateId = storeManagerRoleTemplateId;
        // }

        if (this.accountFormGroup.invalid)
            return;

        if (this.selectedOwner)
            return;

        this.isSpecificLoading[submitId] = true;
        this.accountFormGroup.disable();

        let pwd = {};
        if (this.accountFormGroup.value["defaultpassword"] && this.accountFormGroup.value["defaultpassword"] != '') {
            pwd = {
                password: this.accountFormGroup.value["defaultpassword"],
                passwordVerify: this.accountFormGroup.value["defaultpassword"]
            }
        }
        const accountDetails: Person = {
            firstName: this.accountFormGroup.value["firstName"],
            lastName: this.accountFormGroup.value["lastName"],
            account: {
                email: this.accountFormGroup.value["email"],
                username: this.accountFormGroup.value["email"],
                ...pwd
            }
        };

        organization$.pipe(
            mergeMap((org) => {
                let account$: Observable<Person>;

                if (this.selectedOwner)
                    account$ = this._accountService.updateAccount(accountDetails)
                else
                    account$ = this._accountService.createAccount(accountDetails, org, roleTemplateId)

                return account$.pipe(
                    map((account) => ({
                        resOrganization: org,
                        resOwner: account
                    }))
                )
            }),
            mergeMap((resMap) => {
                if (submitId == 'saveAndSendWelcome') {
                    // 4. Send Welcome Mail
                    return this._authService.sendWelcomeEmail(this.accountFormGroup.value["email"]);
                } else {
                    return of(resMap);
                }
            }),
        ).subscribe({
            next: (res) => {
                this.isSpecificLoading[submitId] = false;
                this.accountForm.resetForm();
                this.accountFormGroup.enable();
                this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                this._fuseConfirmationService.open(
                    BeautyciansUtils.getErrorDialogConfig('Something went wrong:' + err, 'Error', 'OK')
                );
                console.log(err);
                this.isSpecificLoading[submitId] = false;
                this.accountFormGroup.enable();
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    deleteHandler(contact: Person): void {
        if (!this.selectedOwner) {
            return;
        }

        const confirmation = this._fuseConfirmationService.open({
            title: this.translateStrings.owner.deleteWarning + " " + contact.firstName + " " + contact.lastName,
            message: this.translateStrings.owner.deleteWarningMessage,
            actions: {
                confirm: {
                    label: this.translateStrings.generic.delete
                }
            }
        });

        confirmation.afterClosed().subscribe((result) => {

            if (result === 'confirmed') {
                this._accountService.deleteAccount(contact.personId).subscribe();
            }
        });
    }

    /**
     * On backdrop clicked
     */
    onBackdropClicked(): void {
        // Go back to the list
        this._router.navigate(['./'], { relativeTo: this._activatedRoute });

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }


    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    openModal(): void {
        // if (this.selectedOwner) this.accountFormBuilder.controls['email'].disable();
        // else this.accountFormBuilder.controls['email'].enable();
        this.accountFormGroup.reset();
        this.dialog.open(this.accountModal, {
            panelClass: ['md:w-160', 'w-full']
        });
    }

    /**
     * opens a modal to add a new element
     */
    addElement(): void {

        const modalElementConfig = {
            type: ModalElementConfig.type,
            height: 'full',
            width: 'full',
            elements: [
                {
                    chainElementConfigs: [
                        'HELLO'
                    ]
                }
            ]
        };

        const context = new ContextElement({}, undefined, undefined, undefined, undefined);

        const modalElementInstance = new ModalElement(modalElementConfig);

        modalElementInstance.init(context)
            .pipe(
                tap((el) => {
                    this.dialogRef = this.dialog.open(GruulsModalElementComponent, {
                        height: '400px',
                        width: '600px',
                        data: {
                            element: modalElementInstance
                        }
                    });
                    this.dialogRef.afterClosed().pipe(map(el1 => ({ result: el1 })));
                })
            )
            .subscribe();
    }

    hasRole(person: Person, role: string, inOrganization: Organization): boolean {
        return Utils.hasRole(person.roles, [role], inOrganization.organizationId);
    }

    impersonate() {
        this._authService.impersonate(this.selectedOwner.account.username).subscribe({
            next: (res) => {
                this._router.navigate(['/'], { relativeTo: this._activatedRoute }).then(() => {
                    window.location.reload();
                });
            },
            error: (err) => {
                this._fuseConfirmationService.open(
                    BeautyciansUtils.getErrorDialogConfig(this.translateStrings['owner']['errorWhileImpersonating'], this.translateStrings['generic']['error'], "error")
                );
            }
        });
    }

}


