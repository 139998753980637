import {
    BaseElement
} from '../base-element/base-element';
import {ElementsFactory} from '../../elements-factory';
import {combineLatest, forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {filter, map, startWith, tap} from 'rxjs/operators';
import {StreamChannelMessage} from '../base-element/stream-channel-message';
import {mergeMap} from 'rxjs/operators';
import {BaseElementConfig, BaseElementConfigInterface, BaseElementStatus} from '../base-element/base-element-config';
import {GroupElementConfig, GroupElementConfigInterface} from './group-element-config';

import jsonpath_ from 'jsonpath';
const jp = jsonpath_;

// è un raggruppamento di elementi
export class GroupElement extends BaseElement {
    //
    // elements: {
    //     [elementName: string]: BaseElement;
    // };
    elementList: BaseElement[] = [];

    // groupInStreamChannel: Subject<StreamChannelMessage>;
    // groupOutStreamChannel: Subject<StreamChannelMessage>;
    // groupOutStreamChannelSubscription: Subscription;
    // groupOutStreamChannelObs: Observable<StreamChannelMessage>;

    constructor(config: GroupElementConfigInterface) {
        super(config);
        this.config = new GroupElementConfig(config, this);

        this.chainElements.push({
            order: 0,
            value: (msg, context, thisElement) => {
                let toRet: Observable<StreamChannelMessage>;
                if (this.config.status === BaseElementStatus.sleeping) {
                    this.config.status = BaseElementStatus.toInitialize;
                    toRet = this.init(this.context);
                }else{
                    toRet = of({});
                }

                return toRet.pipe(
                    mergeMap((res) => {
                        if (this.elementList.length){
                            return combineLatest(this.elementList
                                .filter(el => el.isInitialElement())
                                .map((el) => {
                                    const childMsg = {
                                        value: msg?.value ? msg.value[el.config.name]: undefined
                                    };
                                    this.log('messageValue: ' + el.config.name, msg);
                                    this.log('passing to child: ' + el.config.name, childMsg);
                                    return el.processStream(childMsg)
                                        .pipe(
                                            startWith({ topic: el.config.name }),
                                            tap(msg1 => this.log('received from child: ' + el.config.name, msg1))
                                        );
                                }))
                                .pipe(
                                    map(els => els.reduce((acc, value) => {
                                        if (value && value.topic) {
                                            acc[value.topic] = value ? (value as any).value: undefined;
                                        }
                                        return acc;
                                    }, {})),
                                    map(els => ({value: els}))
                                );
                        }else{
                            return of({});
                        }
                    })
                );
            }
        });
    }

    moveChildElementPosition(previousIndex: number, currentIndex: number): void {
        this.elementList.splice(currentIndex, 0, this.elementList.splice(previousIndex, 1)[0]);
        this.config.elements.splice(currentIndex, 0, this.config.elements.splice(previousIndex, 1)[0]);
    }

    removeChildElementAndReboot(previousIndex: number): Observable<BaseElementConfig> {
        const elements = (this.config as any).elements;
        const el = elements.splice(previousIndex, 1)[0];
        const elementsToNotToInit = elements.map(el1 => el1.name);
        return this.reboot({elementsToNotToInit: elementsToNotToInit}).pipe(map(thiz => el));
    }

    addChildElementAndReboot(elementToAdd: BaseElementConfigInterface, position?: number): Observable<any> {
        const elements = (this.config as GroupElementConfig).elements;
        position = position === undefined ? elements.length - 1 : position;
        const elementsToNotToInit = elements.map(el => el.name);
        (this.config as any).elements.splice(position, 0, elementToAdd);
        return this.reboot({elementsToNotToInit: elementsToNotToInit, order: {[elementToAdd.name]: position}});
    }

    replaceAllChildrenElementsAndReboot(elementsToAdd: BaseElementConfigInterface[]): Observable<any>{
        const els = (this.config as any).elements;
        els.splice(0, els.length, ...elementsToAdd);
        this.config.delegate.elements = els;
        return this.reboot();
    }

    protected _init(options?: any): Observable<BaseElement> {
        if (this.elementList && this.elementList.length) {
            const toRemove = [];
            for (const e of this.elementList) {
                if (!(options?.elementsToNotToInit || []).find(id => id === e.config.name)) {
                    delete this[e.config.name];
                    toRemove.push(e.config.name);
                }
            }
            for (const r of toRemove) {
                for(let i = 0; i < this.elementList.length; i++) {
                    if (r === this.elementList[i].config.name) {
                        const el: BaseElement = this.elementList.splice(i, 1)[0];
                        el.removeInStreamRuntimeChannel(el.getOutStreamChannel());
                        break;
                    }
                }
            }
        }

        return super._init(options)
            .pipe(
                mergeMap((res) => {
                    const elems = (this.config as GroupElementConfig).elements;
                    if (elems.length) {
                        const l: Observable<BaseElement>[] = elems
                            .filter(el => !(options?.elementsToNotToInit || []).find(id => id === el.name))
                            .map(el => new ElementsFactory().create(el));
                        if (l.length) {
                            return forkJoin(l)
                                .pipe(
                                    map((els) => {
                                        for (const el of els) {
                                            if (options?.order && (options?.order[el.config.name] !== undefined)) {
                                                this.elementList.splice(options.order[el.config.name], 0, el);
                                            } else {
                                                this.elementList.push(el);
                                            }
                                        }
                                        for (const e of els) {
                                            this[e.config.name] = e;
                                        }
                                        // this.elements = els.reduce((acc, value) => {
                                        //     acc[value.config.name] = value;
                                        //     return acc;
                                        // }, {});
                                        return res;
                                    })
                                );
                        } else {
                            return of(res);
                        }
                    }
                    return of(res);
                }),
                map((res) => {
                    if (this.elementList.length) {
                        this.elementList
                            .filter(el => el.isInitialElement())
                            .map((el) => {
                                const i = options?.elementsToNotToInit?.indexOf(options?.elementsToNotToInit?.filter(e => e === el.config.name)[0]);
                                if (i >= 0){
                                    options.elementsToNotToInit.splice(i,1);
                                }
                                return el;
                            });
                    }
                    return res;
                }),
                mergeMap((res) => {
                    if (this.elementList.length)  {
                        const l = this.elementList.filter(el => !(options?.elementsToNotToInit || []).find(id => id === el.config.name));
                        if (l && l.length){
                            return forkJoin(l.map((el) => {
                                el.parent = this;
                                return el.init(this.context);
                            }));
                        }else{
                            return of(res);
                        }
                    }else{
                        return of(res);
                    }
                }),
                map(res => this)
            );
    }

    getElement(elementName: string): BaseElement {
        const dot = elementName.charAt(0) === '[' ? '': '.';
        const res = jp.query(this, '$' + dot + elementName);
        return res[0];
        // return this.elementList.length ? this.elementList.find(el => el.config.name === elementName) : undefined;
    }

    destroy(): void {
        for (const el of this.elementList) {
            el.destroy();
        }
        super.destroy();
    }

    protected _deInit(): Observable<BaseElement> {
        // if (this.groupInStreamChannel) {
        //     this.groupInStreamChannel.unsubscribe();
        // }
        // if (this.groupOutStreamChannel) {
        //     this.groupOutStreamChannel.unsubscribe();
        //     this.groupOutStreamChannelSubscription.unsubscribe();
        // }
        // this.groupInStreamChannel = undefined;
        // this.groupOutStreamChannel = undefined;
        // this.groupOutStreamChannelObs = undefined;
        // this.groupOutStreamChannelSubscription = undefined;
        for (const e of this.elementList){
            e.destroy();
        }
        this.elementList.splice(0, this.elementList.length);
        return super._deInit();
    }


}
