import _lodash from 'lodash';
import { CollectionType } from '../collection-type';
import { Field } from '../field';
import { FieldType } from '../field-type';
import {FieldConfig} from '../../domain/templates-config';
import {Fields} from '../fields';
import {FieldModelTypes} from '../field-model-types';
import { FormElementsAngularUtils } from '@gruuls-fe/utils/form-elements-angular-utils';

const _ = _lodash;


export class AddressField extends Field {

    // public static latFieldNameDefaultName = "lat";
    // public static lonFieldNameDefaultName = "lon";

    public constructor(
        name: string,
        subFields: Field[],
        type: FieldType,
        collectionType: CollectionType,
        fieldConfig: FieldConfig,
        modelType: string,
        public latFieldName?: string,
        public lonFieldName?: string,
        public zipFieldName?: string,
        public cityFieldName?: string,
        public countryFieldName?: string,
        public streetFieldName?: string,
        public civicNumberFieldName?: string,
        public textualAddressFieldName?: string
    ) {
        super(name, subFields, [], type, collectionType, fieldConfig, modelType);
    }

    public static ofConfig(
        config: {
            name: string;
            subFields?: Field[];
            type?: FieldType;
            collectionType?: CollectionType;
            modelType?: string;
            fieldConfig?: FieldConfig;
            latFieldName?: string; // for geoPoint and address
            lonFieldName?: string; // for geoPoint and address
            zipFieldName?: string; // for address
            cityFieldName?: string;
            countryFieldName?: string;
            streetFieldName?: string;
            civicNumberFieldName?: string;
            textualAddressFieldName?: string;
        }
    ): Field {
        Fields.counter = Fields.counter++;


        const subFields: Field[] = [];

        if (config.textualAddressFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.textualAddressFieldName
                })
            );
        }

        if (config.latFieldName) {
            subFields.push(
                Fields.long({
                    name: config.latFieldName
                })
            );
        }

        if (config.lonFieldName) {
            subFields.push(
                Fields.long({
                    name: config.lonFieldName
                })
            );
        }

        if (config.streetFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.streetFieldName
                })
            );
        }

        if (config.civicNumberFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.civicNumberFieldName
                })
            );
        }

        if (config.zipFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.zipFieldName
                })
            );
        }

        if (config.cityFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.cityFieldName
                })
            );
        }

        if (config.countryFieldName) {
            subFields.push(
                Fields.string_({
                    name: config.countryFieldName
                })
            );
        }


        config = _.defaultsDeep(
            {
                type: FieldType.address,
                subFields: subFields
            },
            config,
            {
                collectionType: CollectionType.NONE,
                storeLatLon: false,
                modelType: FieldModelTypes.flat.toString(),
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.maybeFlatValueObjectFormControlElement
                }
            });

        return new AddressField(config.name, config.subFields, config.type, config.collectionType, config.fieldConfig, config.modelType, config.latFieldName, config.lonFieldName,
            config.zipFieldName, config.cityFieldName, config.countryFieldName, config.streetFieldName, config.civicNumberFieldName, config.textualAddressFieldName);
    }

    public populateAssembleAs(assembleAs: any, subFieldsAssembly?: any): void {
        if (this.latFieldName) {
            assembleAs[this.latFieldName] = true;
        }
        if (this.lonFieldName) {
            assembleAs[this.lonFieldName] = true;
        }
        if (this.zipFieldName) {
            assembleAs[this.zipFieldName] = true;
        }
        if (this.cityFieldName) {
            assembleAs[this.cityFieldName] = true;
        }
        if (this.countryFieldName) {
            assembleAs[this.countryFieldName] = true;
        }
        if (this.streetFieldName) {
            assembleAs[this.streetFieldName] = true;
        }
        if (this.civicNumberFieldName) {
            assembleAs[this.civicNumberFieldName] = true;
        }
        if (this.textualAddressFieldName) {
            assembleAs[this.textualAddressFieldName] = true;
        }
    }
}
