import { NgModule } from '@angular/core';
import { BarComponent } from './bar/bar.component';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';



@NgModule({
    declarations: [
        BarComponent,
        DatePickerComponent
    ],
    imports     : [
        CommonModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatMenuModule,
        MatExpansionModule,
    ],
    providers   : [],
    exports     : [
        BarComponent,
        DatePickerComponent

    ]
})
export class ElementsModule
{
}
