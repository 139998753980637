import { ChangeDetectorRef, Component, EventEmitter, Inject, Injectable, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FuseConfirmationConfig, FuseConfirmationService } from "@fuse/services/confirmation";
import { GruulsAngularCartService } from "@gruuls-fe/services/gruuls-angular-cart.service";
import { GruulsAngularHttpProxyService } from "@gruuls-fe/services/gruuls-angular-http-proxy.service";
import { GruulsAngularTranslateService } from "@gruuls-fe/services/gruuls-angular-translate.service";
import { GruulsAuthService } from "@gruuls-fe/services/gruuls-auth.service";
import { takeUntil, Subject, Observable, forkJoin, switchMap, map, tap, catchError, of } from "rxjs";
import { Product } from "app/beautycians/modules/products/product.types";
import { ProductsService } from "app/beautycians/modules/products/product.service";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { ApiCaller } from "app/beautycians/utils/apiCaller";
import { MedicalHistory } from "../../client.types";
import { FuseConfigService } from "@fuse/services/config";
import _ from "lodash";


@Component({
    selector: 'product-selection-dialog',
    templateUrl: './product-selection-dialog.component.html',
    styles: [
        /* language=SCSS */
        `a {
          cursor: pointer;
      }
    
      .product-selection-dialog-panel {
          @screen md {
              @apply w-3/5;
          }

          .mat-dialog-container {
              padding: 0 !important;
          }
      }
    `
    ],
    encapsulation: ViewEncapsulation.None
})
export class ProductSelectionDialogComponent implements OnInit {
    // @Output() shippingTo: EventEmitter<any> = new EventEmitter<any>();

    advisedProducts: Product[] = [];
    suggestedHealthPlan: String;
    translateStrings: any = { order: {}, generic: {}, product: {}, healthPlan: {} };
    isSpecificLoading: any = {};
    totalQuantity: number = 0;
    totalPrice: number = 0;
    message: string = '';
    productsSelectionGroup: FormGroup;
    products$: Observable<Product[]>;
    selectedProcuts: any;
    isLoadingError: boolean = false;
    WA_ENABLED: boolean = false;
    healthPlans: any[] = [];

    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
    private _unsubscribeAll: Subject<any> = new Subject<any>();


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: FuseConfirmationConfig,
        @Inject(MAT_DIALOG_DATA) public lastAnamnesi: MedicalHistory,
        @Inject(MAT_DIALOG_DATA) public clientId: string,
        private _authService: GruulsAuthService,
        private _httpClient: GruulsAngularHttpProxyService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _fuseConfigService: FuseConfigService,
        private _cartService: GruulsAngularCartService,
        private _router: Router,
        private _productsService: ProductsService,
        private _translate: GruulsAngularTranslateService,
        public matDialogRef: MatDialogRef<ProductSelectionDialogComponent>
    ) { }

    ngOnInit(): void {
        this.WA_ENABLED = this._fuseConfigService.config?.features.indexOf('whatsapp')>-1;

        const genericTranslations = ['warning', 'cancel', 'confirm', 'close', 'send', 'edit', 'affinity', 'low', 'medium', 'high'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });
        
        const healthPlanTranslations = ['advise'];
        healthPlanTranslations.forEach((translation) => {
            this.translateStrings['healthPlan'][translation] = this._translate.translate('healthPlan.' + translation);
        });

        const productsTranslations = ['advise', 'search', 'adviseError'];
        productsTranslations.forEach((translation) => {
            this.translateStrings['product'][translation] = this._translate.translate('products.' + translation);
        });

        this.productsSelectionGroup = this._formBuilder.group({
            productSelection: this._formBuilder.array([]), 
        });

        this.isSpecificLoading['products'] = true;
        this.isSpecificLoading['suggestedProducts'] = true;
        this.products$ = this._productsService.products$;

        this._productsService.products$.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((products: Product[]) => {
            // Mark for check
            if (products != null) {
                this.productSelection().clear();
                products.filter((product) => product.usage_home).forEach((product) => this.productSelection().push(this.newProductSelection(product)));
                this._changeDetectorRef.markForCheck();
            }
        });

        forkJoin({
            products: this._productsService.refresh().pipe(catchError(() => of(null))),
            priorities: this._apiCaller.getPriorities().pipe(catchError(() => of(null))),
            healthPlans: this._apiCaller.getHealthPlans().pipe(catchError(() => of(null))),
            lastAnamnesiWithScores: this._apiCaller.getLastMedicalHistoryWithScores(this.clientId).pipe(catchError(() => of(null))),
            treatments: this._apiCaller.getTreatments().pipe(catchError(() => of(null))),
        }).pipe(
            tap((res) => {
                console.log("Time at the beginning of the call: " + new Date().getTime());
                this.isSpecificLoading['suggestedProducts'] = false;
                this._changeDetectorRef.markForCheck();
                this.healthPlans = this.calculateHealthPlanReccomendation(res.lastAnamnesiWithScores, res.healthPlans);
                const highestAffinityPlan = this.healthPlans.reduce((prev: any, current: any) => (prev.affinityPercentage > current.affinityPercentage) ? prev : current);
                this.suggestedHealthPlan = highestAffinityPlan ? highestAffinityPlan.code : '';                
            }),
            tap((res) => {
                console.log("Time at the beginning of the call: " + new Date().getTime());
                const advisedTreatments = this.calculateHomeReccomendation(res.lastAnamnesiWithScores , res.priorities);
                let advisedProducts = [];
                advisedTreatments.forEach((advisedTreatment) => {
                    const t = res.treatments.find((treatment) => treatment.code === advisedTreatment);
                    if (t && t.advisedProducts)
                        advisedProducts = advisedProducts.concat(t.advisedProducts);
                });
                const uniqueAdvisedProducts = [...new Set(advisedProducts)].map((p) => p.toLowerCase());

                // Build the form
                this.productSelection().clear();
                res.products.sort().filter((product) => product.usage_home).forEach((product) => this.productSelection().push(this.newProductSelection(product, uniqueAdvisedProducts.includes(product.name.toLowerCase()))));
            })
        ).subscribe({
            next: (result) => {
                console.log("Time at the beginning of the call: " + new Date().getTime());
                this.isSpecificLoading['suggestedProducts'] = false;
                this._changeDetectorRef.markForCheck();
            },
            error: (err) => {
                console.error("Error while loading information for Beauty Paths: " + err);
                this.isSpecificLoading['suggestedProducts'] = false;
                this.isLoadingError = true;
                this._changeDetectorRef.markForCheck();
            }
        });

    }

    calculateHomeReccomendation(lastAnamnesiWithScores: [], priorities: []): string[] {
        let advisedHomeTreatmentsPerPriority = [];

        for (const [key, value] of Object.entries(lastAnamnesiWithScores)) {
            const v = value as any;
            if ((key in priorities) && ('score' in v) && ('homePriorityScore' in priorities[key]) && v.score in priorities[key]['homePriorityScore']) {
                const priority = priorities[key]['priorityScore'][v.score];
                if (priorities[key]['advisedTreatment'] && priorities[key]['advisedTreatment'] != '') {
                    if (!(priority in advisedHomeTreatmentsPerPriority))
                        advisedHomeTreatmentsPerPriority[priority] = [];
                    advisedHomeTreatmentsPerPriority[priority].push(priorities[key]['advisedTreatment']);
                }
            }
        }
        const keyTreatmentsPerPriority = Object.keys(advisedHomeTreatmentsPerPriority).map(a => Number(a)).sort((a, b) => a - b);

        let counter = 0;
        let proposedTreatments: string[] = [];
        while (proposedTreatments.length < 2 && counter < keyTreatmentsPerPriority.length) {
            if (!(advisedHomeTreatmentsPerPriority[keyTreatmentsPerPriority[counter]] in proposedTreatments)) {
                proposedTreatments = proposedTreatments.concat(advisedHomeTreatmentsPerPriority[keyTreatmentsPerPriority[counter]]);
                counter++;
            }
        }
        return proposedTreatments;
    }

    calculateHealthPlanReccomendation(lastAnamnesiWithScores: [], healthPlans: []): string[] {
        healthPlans.forEach((plan: any) => {
            let planPoints: number = 0;
            (plan['factors'] as string[]).forEach((factor) => {
                if (factor in lastAnamnesiWithScores) {
                    planPoints += Number(lastAnamnesiWithScores[factor]['score'])
                }
            });
            plan['affinityPercentage'] = planPoints / Number(plan['max']);
            plan['affinityPercentageText'] = this.getAffinityText(plan['affinityPercentage']);
        })

        return healthPlans;
    }


    productSelection(): FormArray {
        return this.productsSelectionGroup.get('productSelection') as FormArray;
    }

    newProductSelection(product: Product, selected: boolean = false): FormGroup {
        return this._formBuilder.group({
            name: product.name,
            dimension: product.dimension,
            selected
        })
    }

    onSubmit(outputType: string | null = null): void {
        outputType = outputType || 'url';
        const suggestedProducts = this.productsSelectionGroup.value.productSelection.filter((product) => product.selected).map((productSelection) => productSelection.name);
        const suggestedHealthPlan = this.suggestedHealthPlan;
        this.matDialogRef.close({ message: 'confirm', suggestedProducts, suggestedHealthPlan, outputType });
    }

    // Function to determine affinity text based on affinity percentage
    private getAffinityText(affinityPercentage: number): string {
        if (affinityPercentage >= 0.4) {
            return 'high';
        } else if (affinityPercentage >= 0.20 && affinityPercentage < 0.4) {
            return 'medium';
        } else {
            return 'low';
        }
    }

    public trackByFn(index: number, item: any): any {
        return item.uuid || index;
    }

}