export const CLIENT_ANAMNESI_ASSEMBLE = {
  _creationTime: true,
  MEDICAL_HISTORY_ID: true,
  REFERENCE_NAME: true,
}
export const FULL_ANAMNESI_ASSEMBLE_NO_INDEXES = {
  _creationTime: true,
  MEDICAL_HISTORY_ID: true,
  REFERENCE_NAME: true,
  // CLIENT: true,
  GENDER: true,

  IBF_peso: true,
  IBF_altez: true,
  IBF_mas_schl: true,
  IBF_mas_muscl: true,
  IBF_mas_grassa: true,
  IBF_BMI: true,
  IBF_DCI: true,
  IBF_acq_tot: true,
  IBF_circ_vita: true,
  IBF_circ_vita_height: true,
  IBF_fianchi: true,
  IBF_fianchi_height: true,
  IBF_coscia: true,
  IBF_coscia_height: true,
  IBF_ginocchio: true,
  IBF_ginocchio_height: true,
  IBF_polpaccio: true,
  IBF_polpaccio_height: true,
  IBF_caviglia: true,
  IBF_caviglia_height: true,
  IBF_braccio: true,
  IBF_braccio_height: true,
  IBF_diff_disteso: true,
  IBF_aum_kg: true,
  IBF_alim_note: true,
  IBF_fumo: true,
  IBF_fumo_Description: true,
  IBF_situaz_alvo: true,
  IBF_ciclo_mestr: true,
  IBF_alcolici: true,
  IBF_alcolici_Description: true,
  IBF_qnt_acq: true,
  IBF_patologie_attuali: true,
  IBF_patologie_attuali_note: true,
  IBF_patologie_passate: true,
  IBF_patologie_passate_note: true,
  IBF_allg_farm: true,
  IBF_farma: true,
  IBF_allg_farm_Description: true,
  IBF_allg_poll: true,
  IBF_allg_poll_Description: true,
  IBF_allg_altre: true,
  IBF_allg_altre_Description: true,
  IBF_allg_alimen: true,
  IBF_allg_alimen_Description: true,
  IBF_allg_metal: true,
  IBF_allg_metal_Description: true,
  IBF_celiaco: true,
  IBF_freq_inflz: true,
  IBF_diff_respirat: true,
  IBF_ritenz_idr: true,
  IBF_cav_gonf: true,
  IBF_gamb_pst: true,
  IBF_crampi_muscl_nott: true,
  IBF_stanchez_min_sforzo: true,
  IBF_ipert_art: true,
  IBF_probl_resp_nott: true,
  IBF_gastr_refl: true,
  IBF_cibi_malessere: true,
  IBF_cibi_malessere_Description: true,
  IBF_diff_prendere_peso: true,
  IBF_ipersen_freddo: true,
  IBF_diff_aumt_peso: true,
  IBF_mani_piedi_freddi: true,
  IBF_risv_nott_fame: true,
  IBF_bsgn_caffe: true,
  IBF_calcoli_renali: true,
  IBF_costipaz: true,
  IBF_dolori_add: true,
  IBF_meteorisimo: true,
  IBF_dipenz_antiacidi: true,
  IBD_idratz: true,
  IBD_idratz_note: true,
  IBD_elast: true,
  IBD_elast_note: true,
  IBD_color_cutn: true,
  IBD_inest_cont_occhi: true,
  IBD_rughe: true,
  IBD_prof_rughe: true,
  IBD_localiz_rughe: true,
  IBD_presz_crom: true,
  IBD_presz_crom_note: true,
  IBD_presez_impur: true,
  IBD_presez_impur_note: true,
  IBD_presenza_pori_dil: true,
  IBD_presez_acne: true,
  IBD_presz_infmz: true,
  IBD_presenz_eritm: true,
  IBD_presenz_cuper: true,
  IBD_sensaz_cliente: true,
  IBD_sensibilita: true,
  IBD_sensaz_cliente_bruciore: true,
  IBD_stat_circ_gen: true,
  IBD_stat_circ_capillari: true,
  IBD_presenz_cellu_loc: true,
  IBD_presenz_cellu_loc_tipologia: true,
  IBD_presenz_cellu_loc_note: true,
  IBD_presenz_cellu_diff: true,
  IBD_presenz_pann_adp: true,
  IBD_presenz_pann_adp_note: true,
  IBD_presenz_adp_diff: true,
  IBD_condz_tess_musc: true,
  IBD_cond_tess_conn: true,
  IBD_presenz_edema_artSX: true,
  IBD_presenz_edema_note: true,
  IBD_edema_arti_inf: true,
  IBD_senz_pesant_art_inf: true,
  IBD_senz_gonf_add: true,
  IBD_fovea: true,
  IBP_stato_civile: true,
  IBP_figli: true,
  IBP_figli_num: true,
  IBP_lavora: true,
  IBP_lavoro_sedentario: true,
  IBP_tipo_lavoro: true,
  IBP_tipo_lavoro_Description: true,
  IBP_tipo_lavoro_hours: true,
  IBP_stato_ben: true,
  IBP_att_fisica: true,
  IBP_att_fisica_description: true,
  IBP_att_fisica_hours: true,
  IBP_ansia: true,
  IBP_sodd_lavoro: true,
  IBP_sodd_fam: true,
  IBP_sodd_coppia: true,
  IBP_stress_impegni: true,
  IBP_hobby: true,
  IBP_hobby_Description: true,
  IBP_control_situaz: true,
  IBP_gest_prob_fam: true,
  IBP_ctrl_irritab: true,
  IBP_senzaz_non_ctrl: true,
  IBP_ottimismo: true,
  IBP_positiv_corporea: true,
  IBP_corpo_camb_note: true,
  IBP_psicosomatica: true,
  IBP_psicof: true,
  IBP_sonno: true,
  IBP_sonno_addorm: true,
  IBP_sonno_quantific: true,
  IBP_sonno_disturbi: true,
  IBP_sonno_disturbi_note: true,
  IBP_sonno_farmaci: true,
  IBP_sonno_farmaci_note: true,
  IBP_sonno_integratori: true,
  IBP_sonno_integratori_note: true,
  POC_urine_leu: true,
  POC_urine_nitr: true,
  POC_urine_urobil: true,
  POC_urine_prot: true,
  POC_urine_ph: true,
  POC_urine_sng: true,
  POC_urine_dens: true,
  POC_urine_corp: true,
  POC_urine_bilru: true,
  POC_urine_glu: true,
  POC_fumo: true,
  POC_Car_Ox: true,
};

export const FULL_ANAMNESI_ASSEMBLE = {
  ...FULL_ANAMNESI_ASSEMBLE_NO_INDEXES,

  INDEX_PHYSIOLOGICAL_WELLNESS: true,
  INDEX_PSYCHOPHYSICAL_WELLNESS: true,
  INDEX_DERMOESTHETIC_WELLNESS: true,
  INDEX_uC: true,
  INDEX_ReD: true,
  INDEX_AB: true,
  INDEX_PHYSIOLOGICAL_WELLNESS_RATIO: true,
  INDEX_PSYCHOPHYSICAL_WELLNESS_RATIO: true,
  INDEX_DERMOESTHETIC_WELLNESS_RATIO: true,
  INDEX_uC_RATIO: true,
  INDEX_ReD_RATIO: true,
  INDEX_AB_RATIO: true
};

export const CLIENT_SM_ASSEMBLE = {
  firstName: true,
  lastName: true,
  address: true,
  sex: true,
  birthdate: true
}

export const CLIENT_MD_ASSEMBLE = {
  _creationTime: true,
  firstName: true,
  lastName: true,
  address: true,
  sex: true,
  birthdate: true,
  pictureUrl: true,
  roles: true
}
export const CLIENT_XL_ASSEMBLE = {
  firstName: true,
  lastName: true,
  address: true,
  sex: true,
  birthdate: true,
  pictureUrl: true,
  roles: true
}

export const ACCOUNT_ASSEMBLE_SM = {
  firstName: true,
  lastName: true,
}



export const ACCOUNT_ASSEMBLE = {
  firstName: true,
  lastName: true,
  _referenceOrganizationId: true,
  birthdate: true,
  account: true,
  address: true,
  sex: true,
  pictureUrl: true,
  roles: true
}

export const ORDER_LIST_ASSEMBLE = {
  id: true,
  orderId: true,
  uuid: true,
  name: true,
  date: true,
  status: true,
  cart: true,
  total: true
}

export const ORDER_ASSEMBLE = {
  id: true,
  orderId: true,
  uuid: true,
  name: true,
  date: true,
  total: true,
  storeId: true,
  info: true,
  cart: true,
  delivered: true,
  status: true,
  externalPaymentConditionsId: true,
  organizationId: true,
  _referenceOrganizationId: true,
}


export const PRODUCT_ASSEMBLE = {
  productId: true,
  name: true,
  code: true,
  long_description: true,
  short_description: true,
  inci: true,
  usage: true,
  usage_home: true,
  target: true,
  dimension: true,
  ingredients: true,
  sustainability: true,
  img_url: true,
  price: true,
  discount: true,
  out_of_stock: true,
  isPublic: true,
  website_ref: true,
  description: true
}

export const APPOINTMENT_ASSEMBLE = {
  "uuid": true,
  "name": true,
  "description": true,
  "client": true,
  "storeId": true,
  "date": true,
  "durationMin": true,
  "treatmentId": true
}

export const STORE_ASSEMBLE = {
  storeId: true,
  name: true,
  description: true,
  organization: true,
  address: true,
  city: true,
  province: true,
  zip: true
}