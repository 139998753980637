import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormBuilder, NgForm, Validators} from '@angular/forms';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FuseConfirmationService} from '@fuse/services/confirmation';
import {Contact, Tag} from './operator-details.types';
import {GruulsConstants} from '../../../../mock-api/gruuls/gruuls-constants';
import {GruulsAngularHttpProxyService} from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import moment_ from 'moment';
import {GruulsAngularTranslateService} from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';

@Component({
    selector: 'operator-details',
    templateUrl: './operator-details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperatorDetailsComponent implements OnInit, OnDestroy {

    @ViewChild('avatarFileInput') private _avatarFileInput: ElementRef;
    @ViewChild('tagsPanel') private _tagsPanel: TemplateRef<any>;
    @ViewChild('tagsPanelOrigin') private _tagsPanelOrigin: ElementRef;

    editMode: boolean = false;
    tags: Tag[];
    contact: any;
    contacts: Contact[];
    newSurvey: any = [];
    surveyList: any[] = [];
    private _tagsPanelOverlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private personId: string;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _renderer2: Renderer2,
        private _router: Router,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _httpClient: GruulsAngularHttpProxyService,
        private _fb: FormBuilder,
        private _translate: GruulsAngularTranslateService
    ) {
    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this._activatedRoute.params.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((params) => {
            this.personId = params.id;
            // Get the contact
            const q = {
                contextName: 'Core',
                domainName: 'Person',
                queryName: 'FIND_ALL',
                where: {
                    bool: {
                        must: [
                            {
                                match_phrase: {
                                    personId: {
                                        query: this.personId
                                    }
                                }
                            }
                        ]
                    }
                },
                assembleAs: {
                    firstName: true,
                    lastName: true,
                    address: true,
                    sex: true,
                    birthdate: true,
                    pictureUrl: true
                }
            };
            this._httpClient.doPost({
                url: GruulsConstants.QUERY_API_URL,
                body: q
            }).subscribe({
                next: (res) => {
                    this.contact = res.hits[0];
                    this.contact.birthdate = this.contact.birthdate ? moment_(this.contact.birthdate).format('MM/DD/YYYY') : undefined;
                    this._changeDetectorRef.markForCheck();
                }
            });

        });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();

        // Dispose the overlays if they are still on the DOM
        if (this._tagsPanelOverlayRef) {
            this._tagsPanelOverlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }


}
