import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit, TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, NgForm, Validators } from '@angular/forms';
import { BehaviorSubject, fromEvent, mergeMap, Observable, of, Subject, Subscription } from 'rxjs';
import { delay, exhaustMap, filter, map, takeUntil, tap } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { BeautyciansUtils } from '../../../utils/utils';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import { GruulsModalElementComponent } from '../../../../../@gruuls-fe/elements/modal-element/gruuls-modal-element.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GruulsAuthService } from '../../../../../@gruuls-fe/services/gruuls-auth.service';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Person } from '../../clients/client.types';
import { Organization } from '../centri.types';
import { GruulsAngularCollaboratorService } from '@gruuls-fe/services/gruuls-angular-collaborator-service';
import { Utils } from '@gruuls-core/utils/Utils';

@Component({
  selector: 'centri-list',
  templateUrl: './centri-list.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CentriListComponent implements OnInit, OnDestroy {
  @ViewChild('organizationForm') organizationForm: NgForm;
  @ViewChild('organizationModal') organizationModal: TemplateRef<any>;

  drawerMode: 'side' | 'over';
  searchInputControl: FormControl = new FormControl();
  filteredElements: any[];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private _elements: any[];
  private _apiCallerSubscription: Subscription;

  page: number = 0;
  private reachedLimitSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  entities: any[];
  referents: Person[] = [];
  isLoading: boolean = false;
  isSpecificLoading: boolean[] = [];
  isLoadingError: boolean = false;

  translateStrings: any = { organization: {}, user: {}, store: {}, generic: {}, licence: {} };

  selectedElement: Organization;

  storeList: any[];

  dialogRef: MatDialogRef<GruulsModalElementComponent>;
  private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: any,
    private _router: Router,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _httpClient: GruulsAngularHttpProxyService,
    private _translate: GruulsAngularTranslateService,
    private _fb: FormBuilder,
    private dialog: MatDialog,
    private _fuseConfirmationService: FuseConfirmationService,
    private _authService: GruulsAuthService,
    private _collaboratorService: GruulsAngularCollaboratorService,
  ) {

  }

  @HostBinding('class') get classList(): any {
    return {
      'w-full': true
    };
  }

  organizationFormGroup = this._fb.group({
    name: ['', Validators.required],
    organizationId: [''],
    vatNumber: ['', Validators.pattern('^[a-zA-Z]{2,3}[0-9]*$')],
    referent: [''],
    licence: ['']
  });

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.isLoading = true;

    // this.apiCaller.getPersons().pipe(
    //     map((res) => res.filter((person) => (Object.keys(person).indexOf('roles')>=0 && person.roles[0].hasRole[0].name.startsWith('Beautycians-Store')))),
    this.loadContent().subscribe({
      next: (elementsResponse) => {
        this.isLoading = false;
        this._elements = BeautyciansUtils.sortByKeys(elementsResponse, ['name']);
        this.filteredElements = this._elements;
        this._changeDetectorRef.markForCheck();
      },
      error: (err) => {
        if (err.status >= 500)
          this.isLoadingError = true;
        console.error(err);
        this.isLoading = false;
        this._changeDetectorRef.markForCheck();
      }
    });

    // Subscribe to search input field value changes
    this.searchInputControl.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
      map(query => BeautyciansUtils.searchStringByKeys(this._elements, query, ['name'])),
      map(queryResult => BeautyciansUtils.sortByKeys(queryResult, ['name'])),
      tap(filteredElements => this.filteredElements = filteredElements)
    ).subscribe(() => this._changeDetectorRef.markForCheck());

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {

        // Set the drawerMode if the given breakpoint is active
        if (matchingAliases.includes('lg')) {
          this.drawerMode = 'side';
        } else {
          this.drawerMode = 'over';
        }

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Listen for shortcuts
    fromEvent(this._document, 'keydown')
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter<KeyboardEvent>(event =>
          (event.ctrlKey === true || event.metaKey) // Ctrl or Cmd
          && (event.key === '/') // '/'
        )
      )
      .subscribe(() => {
      });

    this.dialog.afterOpened.pipe(
      takeUntil(this._unsubscribeAll),
      delay(0)
    ).subscribe(() => {
      if (this.selectedElement) {
        const referentPersonId = this.selectedElement.extra?.referent;
        this.organizationFormGroup.controls['name'].setValue(this.selectedElement.name);
        this.organizationFormGroup.controls['vatNumber'].setValue(this.selectedElement.vatNumber);
        this.organizationFormGroup.controls['licence'].setValue(this.selectedElement.extra?.licence);
        this.organizationFormGroup.controls['referent'].setValue(this.referents?.find((referent) => referent.personId == referentPersonId));
      } else {
        this.organizationFormGroup.reset();
      }
    });

    this._collaboratorService.accounts$.pipe(
      takeUntil(this._unsubscribeAll),
      tap((accounts) => {
        this.referents = accounts?.filter((account) => Utils.hasRole(account.roles,['Beautycians-HQ'], this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId));
        this._changeDetectorRef.markForCheck();
      })).subscribe();

    this._collaboratorService.refreshAccounts().subscribe();

    // Listener to scroll that create an infinite scroll effect by calling loadMore if the user reach the end of the page
    // fromEvent(window, 'scroll').pipe(
    //     takeUntil(this._unsubscribeAll),
    //     takeUntil(this.reachedLimitSubject),
    //     map(() => window.scrollY),
    //     filter((offset) => {                
    //         return offset >= document.body.offsetHeight - window.innerHeight - 200;
    //     }),
    //     exhaustMap(() => {
    //         return of(this.loadMore());
    //     })
    // ).subscribe();

    // Translations
    // TODO: Chiedere a Marco per quale motivo ritorna una stringa e non un Observable, anche se il tipo richiesto è l'Obs
    const organizationTranslations = ['name', 'plural', 'singular', 'no', 'search', 'addNew', 'edit', 'noSearchResults', 'accountName', 'accountAdminData', 'deleteWarning', 'deleteWarningMessage', 'referent', 'licence', 'vatNumber'];
    organizationTranslations.forEach((translation) => {
      this.translateStrings['organization'][translation] = this._translate.translate('organizations.' + translation);
    });

    const userTranslations = ['firstName', 'lastName', 'address', 'email', 'sex', 'male', 'female', 'ND', 'birthdate', 'pictureUrl', 'roles'];
    userTranslations.forEach((translation) => {
      this.translateStrings['user'][translation] = this._translate.translate('users.' + translation);
    });

    const storeTranslations = ['address', 'zip', 'name', 'description', 'type', 'singular', 'plural', 'noStores', 'search', 'noSearchResults', 'addNew'];
    storeTranslations.forEach((translation) => {
      this.translateStrings['store'][translation] = this._translate.translate('stores.' + translation);
    });

    const genericTranslations = ['send', 'close', 'save', 'defaultpassword', 'loadMore', 'saveAndSendWelcome', 'edit', 'delete', 'validatorVatNumber'];
    genericTranslations.forEach((translation) => {
      this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
    });

    const licenceTranslations = ['LEVEL_1', 'LEVEL_2', 'LEVEL_3', 'LEVEL_4'];
    licenceTranslations.forEach((translation) => {
      this.translateStrings['licence'][translation] = this._translate.translate('licence.' + translation);
    });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  loadContent(page: number = null): Observable<any> {
    if (page == null) {
      this.reachedLimitSubject$.next(true);
      this.reachedLimitSubject$.complete();
    }
    this.isSpecificLoading['loadMore'] = true;
    this._changeDetectorRef.markForCheck();
    return this._apiCaller.getOrganizations().pipe(
      takeUntil(this._unsubscribeAll),
      tap((responseClients) => {
        this.isSpecificLoading['loadMore'] = false;
        this._changeDetectorRef.markForCheck();
      })
    );

  }



  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  addAdminToOrganization(organization: Organization): void {
    this.organizationFormGroup.reset();
    this.organizationFormGroup.controls.organizationId.setValue(organization.organizationId);
    this.organizationFormGroup.controls.name.setValue(organization.name);
  }

  organizationFormSubmit(submitId): void {
    this.isSpecificLoading[submitId] = true;

    if (this.organizationFormGroup.invalid)
      return;

    this.organizationFormGroup.disable();
    const extra = {
      referent: this.organizationFormGroup.controls.referent.value?.personId,
      referentMail: this.organizationFormGroup.controls.referent.value?.account?.email,
      licence: this.organizationFormGroup.controls.licence.value
    }

    let tmpOrganization: Organization = {
      name: this.organizationFormGroup.controls.name.value,
      vatNumber: this.organizationFormGroup.controls.vatNumber.value,
      extra: extra
    };

    if (this.selectedElement) {
      tmpOrganization['organizationId'] = this.selectedElement.organizationId;
      this._apiCaller.updateOrganization(tmpOrganization).subscribe({
        next: (res) => {
          this.isSpecificLoading[submitId] = false;
          this.organizationForm.resetForm();
          this.organizationFormGroup.enable();
          this.dialog.closeAll();
          this._elements[this._elements.indexOf(this.selectedElement)] = tmpOrganization; //TODO: check
          this.filteredElements = BeautyciansUtils.sortByKeys(this._elements, ['name']);
          this._changeDetectorRef.markForCheck();
        },
        error: (err) => {
          BeautyciansUtils.getErrorDialogConfig('Something went wrong:' + err, 'Error', 'OK');
          console.log(err);
          this.isSpecificLoading[submitId] = false;
          this.organizationFormGroup.enable();
          this._changeDetectorRef.markForCheck();
        }
      });
    } else {
      this._apiCaller.createOrganization(
        tmpOrganization,
        this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId
      ).subscribe({
        next: (organization) => {
          this.isSpecificLoading[submitId] = false;
          this.organizationForm.resetForm();
          this.organizationFormGroup.enable();
          this.dialog.closeAll();
          this._elements.push(organization);
          this.filteredElements = BeautyciansUtils.sortByKeys(this._elements, ['name']);
          this._changeDetectorRef.markForCheck();
        },
        error: (err) => {
          this._fuseConfirmationService.open(
            BeautyciansUtils.getErrorDialogConfig('Something went wrong:' + err, 'Error', 'OK')
          );
          console.log(err);
          this.isSpecificLoading[submitId] = false;
          this.organizationFormGroup.enable();
          this._changeDetectorRef.markForCheck();
        }
      });
    }
  }


  deleteOrganization(organization: Organization): void {
    // TODO: could be interesting to add anamnesi count using getAnamnesiCount
    const confirmation = this._fuseConfirmationService.open({
      title: this.translateStrings.organization.deleteWarning + " " + organization.name,
      message: this.translateStrings.organization.deleteWarningMessage,
      actions: {
        confirm: {
          label: this.translateStrings.generic.delete
        }
      }
    });

    // Subscribe to the confirmation dialog closed action
    confirmation.afterClosed().subscribe((result) => {

      if (result === 'confirmed') {
        this._apiCaller.deleteOrganization(organization.organizationId).subscribe({
          next: (res) => {
            this._elements.splice(this._elements.indexOf(organization), 1);
            this.filteredElements = BeautyciansUtils.sortByKeys(this._elements, ['name']);
            this._changeDetectorRef.markForCheck();
          },
          error: (err) => {
            console.error(err);
            this._fuseConfirmationService.open(
              BeautyciansUtils.getErrorDialogConfig('Something went wrong:' + err.error, 'Error', 'OK')
            );
          }
        });
      }
    })
  }


  /**
   * On backdrop clicked
   */
  onBackdropClicked(): void {
    // Go back to the list
    this._router.navigate(['./'], { relativeTo: this._activatedRoute });

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }


  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  openModal(): void {
    // if (this.selectedOwner) this.organizationFormBuilder.controls['email'].disable();
    // else this.organizationFormBuilder.controls['email'].enable();
    this.dialog.open(this.organizationModal, {
      panelClass: ['md:w-160', 'w-full']
    });
  }

}


