import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GruulsAuthService} from '../services/gruuls-auth.service';
import {GruulsAuthUtils} from './gruuls-auth.utils';
import { environment } from 'environments/environment';

@Injectable()
export class GruulsAuthInterceptor implements HttpInterceptor {
    private endpoint: string = '';
    /**
     * Constructor
     */
    constructor(private _authService: GruulsAuthService) {
        this.endpoint = environment.API_ENDPOINT;
        // SET API ENDPOINT FROM ENVIRONMENT
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Change endpoint for API requests
        // let redirectEndpoint = '';
        // if (req.url.startsWith('api')) {
        //     redirectEndpoint = this.endpoint;
        // }
        // let newReq = req.clone({
        //     url: redirectEndpoint + req.url
        // });
        let newReq = req.clone({
        });

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        const jwt = this._authService.getAccessToken();
        if (jwt && !GruulsAuthUtils.isTokenExpired(jwt)) {
            newReq = newReq.clone({
                headers: newReq.headers.set('Authorization', 'Bearer ' + jwt),
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {

                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // check if request was coming from login page
                    // if from login page, it is expected that there is a special
                    // header "onLogin" == true
                    const onLogin = req.headers.get('onLogin');
                    if (!onLogin) {
                        // Sign out
                        this._authService.logout().subscribe(); // .signOut();
                        // Reload the app
                        location.reload();
                    }
                }

                return throwError(error);
            })
        );
    }
}
