import { FieldConfig } from '@gruuls-core/domain/domain/templates-config';
import _lodash from 'lodash';
import { CollectionType } from '../collection-type';
import { Field } from '../field';
import { FieldModelTypes } from '../field-model-types';
import { FieldType } from '../field-type';
import { Fields } from '../fields';
import {FormElementsAngularUtils} from '../../../../@gruuls-fe/utils/form-elements-angular-utils';

const _ = _lodash;


export class DateRangeField extends Field {

    public constructor(
        name: string,
        subFields: Field[],
        type: FieldType,
        collectionType: CollectionType,
        fieldConfig: FieldConfig,
        modelType: string,
        public startFieldName?: string,
        public endFieldName?: string
    ) {
        super(name, subFields, undefined, type, collectionType, fieldConfig, modelType);
    }

    public static ofConfig(
        config: {
            name: string;
            subFields?: Field[];
            type?: FieldType;
            collectionType?: CollectionType;
            fieldConfig?: FieldConfig;
            modelType?: string;
            startFieldName?: string; // for geoPoint and address
            endFieldName?: string; // for geoPoint and address
        }
    ): Field {

        Fields.counter = Fields.counter++;

        if (config.modelType === FieldModelTypes.flat && (config.collectionType === 'LIST' || config.collectionType === 'SET')) {
            throw new Error('rangedatetime field (name: ' + config.name + ') has a flat modelType. However it cannot be a collectionType (collectionType: ' + config.collectionType + '). If you want a collectionType you should select anotherModel type (eg. valueObject)');
        }

        config.startFieldName = config.startFieldName ? config.startFieldName : 'start';
        config.endFieldName = config.endFieldName ? config.endFieldName : 'end';

        config = _.defaultsDeep(
            {
                type: FieldType.dateRange,
                subFields: [
                    Fields.date({
                        name: config.startFieldName
                    }),
                    Fields.date({
                        name: config.endFieldName
                    })
                ]
            },
            config,
            {
                modelType: FieldModelTypes.flat,
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.maybeFlatValueObjectFormControlElement,
                    groupSyncValidators: [
                        {name: 'group_lt', param: [config.startFieldName, config.endFieldName]}
                    ]
                }
            });

        return new DateRangeField(config.name, config.subFields, config.type, config.collectionType, config.fieldConfig, config.modelType, config.startFieldName, config.endFieldName);
    }

}
