import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { ClientsService as ClientService } from 'app/beautycians/services/clientService';
import { GruulsAngularHttpProxyService } from './gruuls-angular-http-proxy.service';
import { GruulsAuthService } from './gruuls-auth.service';
import { Person } from 'app/beautycians/modules/clients/client.types';

@Injectable()
export class GruulsAngularClientService{
  clientService: ClientService;

  public constructor(
    private _httpClient: GruulsAngularHttpProxyService,
    private _authService: GruulsAuthService
  ){
    this.clientService = new ClientService(this._httpClient,this._authService);
  }

  get clients$(): Observable<Person[]> {
    return this.clientService.clients$;
  }

  public getClients(page: number = null): Observable<Person[]> {
    return this.clientService.getClients(page);
  }

  public refreshClients(page: number = null): Observable<Person[]> {
    return this.clientService.refreshClients(page);
  }

  public filterClients(filter: string): Observable<Person[]> {
    return this.clientService.filterClients(filter);
  }

  // public getClient(clientId: string): Observable<Client> {
  //   return this.clientService.getClient(clientId);
  // }

  /**
   * Updates a client.
   * @param client The client object to update.
   * @returns An Observable that emits the updated client.
   */
  public updateClient(client: Person): Observable<Person> {
    return this.clientService.updateClient(client);
  }

  /**
   * Creates a client.
   * 
   * @param client The client object to create.
   * @param storeId The ID of the store.
   * @returns An Observable that emits the created client.
   */
  public createClient(client: Person, storeId: string = null): Observable<Person> {
    return this.clientService.createClient(client, storeId);
  }

  /**
   * Deletes a client.
   * @param clientId - The ID of the client to delete.
   * @returns An Observable that emits the result of the delete operation.
   */
  public deleteClient(clientId: string): Observable<any> {
    return this.clientService.deleteClient(clientId);
  }


}