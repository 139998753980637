import { GruulsLocalStorageInterface } from '@gruuls-core/service-interfaces/gruuls-local-storage-interface';

export class GruulsLocalStorageService implements GruulsLocalStorageInterface{

  getItem(key: any): any {
    return localStorage.getItem(key);
  }

  removeItem(key: any): any {
    return localStorage.removeItem(key);
  }

  setItem(key: any, value: any): any {
    return localStorage.setItem(key, value);
  }

}
