import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BarComponent  {
  @Input() value: number;
  @Input() ratio: number;
  ratioInPixel: number = 0;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(): void {
    if (this.ratio > 100) {
      this.ratio = 100;
    }
    if (this.ratio < 0) {
      this.ratio = 0;
    }

    this.ratioInPixel = Math.round(this.ratio/100 * 400);
    this._changeDetectorRef.markForCheck();  
    
  }

}