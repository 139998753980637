import { Injectable } from "@angular/core";
import { CollaboratorService } from "app/beautycians/services/collaboratorService";
import { GruulsAngularHttpProxyService } from "./gruuls-angular-http-proxy.service";
import { GruulsAuthService } from "./gruuls-auth.service";
import { Person } from "app/beautycians/modules/clients/client.types";
import { Observable } from "rxjs";

@Injectable()
export class GruulsAngularCollaboratorService {
  collaboratorService: CollaboratorService;

  public constructor(
    private _httpClient: GruulsAngularHttpProxyService,
    private _authService: GruulsAuthService
  ) {
    this.collaboratorService = new CollaboratorService(this._httpClient, this._authService);
  }
  
  get accounts$(): Observable<Person[]> {
    return this.collaboratorService.accounts$;
  }

  public getAccounts(page: number = null): Observable<Person[]> {
    return this.collaboratorService.getAccounts(page);
  }

  public refreshAccounts(page: number = null): Observable<Person[]> {
    return this.collaboratorService.refreshAccounts(page);
  }

  public createAccount(account: Person, roleTemplateId: string): Observable<Person> {
    return this.collaboratorService.createAccount(account, roleTemplateId);
  }

  public updateAccount(account: Person, roleTemplateId: string): Observable<Person> {
    return this.collaboratorService.updateAccount(account, roleTemplateId);
  }

  public deleteAccount(account: Person): Observable<boolean> {
    return this.collaboratorService.deleteAccount(account);
  }

}

