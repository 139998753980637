import {Observable, of} from 'rxjs';
import {DataSchema} from './data-schema';
import {Field} from './field';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {delay} from 'rxjs/operators';

export interface AsyncValidator {

    errorMessage: string;
    name: string;

    validate(fieldValue: any): Observable<any>;
}

export interface AsyncValidatorJson {
    name: string;
    param?: any;
}

export interface AsyncValidatorFactory {
    rehydrate(config: AsyncValidatorJson, schema: DataSchema, fieldDefinition: Field): any;
}

export class AngularAsyncValidatorFactory implements AsyncValidatorFactory {

    public rehydrate(config: AsyncValidatorJson, schema: DataSchema, fieldDefinition: Field | DataSchema): any {

        switch (config.name) {
            case 'random_unique':
                return {
                    validate(ctrl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
                        // get detail from fieldDefinition and perform http call
                        const r = Math.random();
                        if (r > 0.5) {
                            return of(null)
                                .pipe(
                                    delay(1000)
                                );
                        } else {
                            const message = {};
                            message[config.name] = true;
                            return of(message)
                                .pipe(
                                    delay(1000)
                                );
                        }

                        // let err = {};
                        // err[config.name] = true;
                        // return of(err);
                    }
                };
            case 'random_equals':
                return {
                    validate(ctrl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
                        // get detail from fieldDefinition and perform http call
                        const r = Math.random();
                        if (r > 0.5) {
                            return of(null)
                                .pipe(
                                    delay(1000)
                                );
                        } else {
                            const message = {};
                            message[config.name] = true;
                            return of(message)
                                .pipe(
                                    delay(1000)
                                );
                        }
                    }
                };
            default:
                console.warn('the requested async validator (' + config.name + ') has not been found');
                return {
                    validate(ctrl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
                        // null validator
                        return of(null);
                    }
                };
        }
    }


}
