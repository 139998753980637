<div class="flex flex-col flex-auto min-w-0">

    <!-- Header -->
    <div class="flex flex-col shadow bg-card">

        <!-- Cover image -->
        <div>
            <img
                class="h-40 lg:h-80 object-cover"
                src="{{contact?.background ? contact.background : 'assets/images/ui/beautycians/stones.jpg'}}"
                alt="Cover image">
        </div>

        <!-- User info -->
        <div class="flex flex-col flex-0 lg:flex-row items-center max-w-5xl w-full mx-auto px-8 lg:h-18 bg-card">

            <!-- Avatar -->
            <div class="-mt-26 lg:-mt-22 rounded-full">
                <ng-container [ngSwitch]="!!contact?.avatar">
                    <img *ngSwitchCase="true"
                         class="w-32 h-32 rounded-full ring-4 ring-bg-card"
                         src="assets/images/avatars/male-04.jpg"
                         alt="User avatar">

                    <div class="w-32 h-32 rounded-full ring-4 ring-bg-card bg-white flex justify-center items-center text-4xl font-semibold border-4 border-accent" *ngSwitchDefault>
                        {{contact?.firstName?.charAt(0)?.toUpperCase()}}
                    </div>
                </ng-container>

            </div>

            <!-- Details -->
            <div class="flex flex-col items-center lg:items-start mt-4 lg:mt-0 lg:ml-8">
                <div class="text-lg font-bold leading-none">{{contact?.firstName}} {{contact?.lastName}}</div>
            </div>

        </div>

    </div>

    <!-- Main -->
    <div class="flex flex-auto justify-center w-full max-w-5xl mx-auto p-6 sm:p-8">

        <!-- Column -->
        <div class="hidden lg:flex flex-col items-start mr-8">

            <!-- About me -->
            <fuse-card class="flex flex-col max-w-80 w-full p-8" *ngIf="contact?.address || contact?.birthdate">
                <div class="text-2xl font-semibold leading-tight pb-4">Informazioni</div>
                <div class="flex flex-col">
                    <div class="flex items-center" *ngIf="contact?.address">
                        <mat-icon
                            class="icon-size-5 mr-3"
                            [svgIcon]="'heroicons_solid:location-marker'"></mat-icon>
                        <span class="leading-none">{{contact.address}}</span>
                    </div>
                    <div class="flex items-center mt-4" *ngIf="contact?.birthdate">
                        <mat-icon
                            class="icon-size-5 mr-3"
                            [svgIcon]="'heroicons_solid:cake'"></mat-icon>
                        <span class="leading-none">{{contact.birthdate}}</span>
                    </div>
                </div>
            </fuse-card>

        </div>

        <!-- Column -->
        <div class="flex-auto pt-6 sm:px-4 sm:pb-4">

        </div>
    </div>


</div>
