import {Utils} from '../../../utils/Utils';
import {StreamChannelMessage} from './stream-channel-message';
import {UserFunctionInput} from '../../logic/user-function-element';
import {BaseElement} from './base-element';
import {SandboxedContext} from '../context-element/sandboxed-context';


export enum BaseElementStatus {
    online = 'online',
    offline = 'offline',
    sleeping = 'sleeping',
    paused = 'paused',
    loading = 'loading',
    toInitialize = 'toInitialize',
    error = 'error',
    initializing = 'initializing'
}

export interface ElementStructuredCommandEventHook {
    elementName: string;
    commandName: string;
    body: any;
}

export interface ElementsEventHook {
    [eventName: string]: string
        | { type: 'function'; value: string }
        | ((value: any, context: SandboxedContext, thiz: BaseElement) => any)
        | ElementStructuredCommandEventHook;
}

export interface ElementsEventHookConfig {
    [eventName: string]: string
        | { type: 'function'; value: string }
        | ElementStructuredCommandEventHook;
}



export interface BaseElementConfigInterface{
    [key: string]: any;

    isBackgroundElement?: boolean; //if true, should not be rendered
    // doNotProcessIncomingMessage?: boolean; // if true, incoming message will not be processed
    order?: number;
    type?: string; // unique id that refers to this type of element
    category?: string;
    name?: string;  // Name is optional, if not provided, an UUID will be placed
    otherNames?: string[];
    status?: BaseElementStatus;
    description?: string;
    inStreamElementIds?: string[]; // list of elements' ids that are used ad input streams. All this streams are "merged" together (as per rxjs "merge()" function)
    defaultValue?: { [key: string]: any } | string | boolean | number | StreamChannelMessage | UserFunctionInput;
    debounceIn?: number;// debounce milliseconds of incoming stream
    debounceOut?: number;// debounce milliseconds of outgoing stream
    autoUpdateMs?: number;// if > 0, it will push the last message received into the stream
    forwardErrorMessage?: boolean;// if true and if an error in this element occurs, it forwards it to the next element
    pauseAutoUpdate?: boolean;// if true, autoUpdate will pause (if > 0)
    // view?: BaseElementViewConfig;
    useLastInValueWhenAutoUpdate?: boolean;
    useIncomingFormattedValue?: boolean;
    chainElementConfigs?: (string | number | any | BaseElementConfigInterface | UserFunctionInput)[];
    //inputChainElementConfigs?: BaseElementConfigInterface[];
    //outputChainElementConfigs?: BaseElementConfigInterface[];
    logging?: boolean;
    eventsHook?: ElementsEventHookConfig;
    elementConfigurationPanel?: BaseElementConfigInterface[];
    editMode?: boolean;
    selectValue?: string;

    customClasses?: string;

    //view
    disableFlex?: boolean;
    itemFillSpace?: string;
    flexWrap?: string;
    flexBasis?: string;
    flexOrder?: string;
    flexGrow?: boolean;
    flexShrink?: boolean;
    zIndex?: string;
    itemDisposition?: 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string; // 'around' | 'between' | 'center' | 'end' | 'start' | 'evenly';
    itemsDisposition?: 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string; // 'around' | 'between' | 'center' | 'end' | 'start' | 'evenly';
    placeContent?: 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly' | 'stretch';

    textColor?: string; // ??? maybe only on parent container?
    fontSize?: string;// ??? maybe only on parent container?
    fontWeight?: string;// ??? maybe only on parent container?
    letterSpacing?: string;
    lineHeight?: string;
    textDecoration?: string;
    textTransform?: string;
    textOverflow?: string;
    textIndent?: string;
    whitespace?: string;
    wordBreak?: string;

    minWidth?: string;
    maxWidth?: string;
    width?: string;
    minHeight?: string;
    maxHeight?: string;
    height?: string;
    overflow?: string;
    backgroundColor?: string;
    paddingLeft?: string;
    paddingRight?: string;
    paddingTop?: string;
    paddingBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    marginTop?: string;
    marginBottom?: string;
    backgroundImage?: string;
    backgroundAttachment?: string;
    backgroundClip?: string;
    backgroundOrigin?: string;
    backgroundPosition?: string;
    backgroundRepeat?: string;
    backgroundSize?: string;
    ringWidth?: string;
    ringColor?: string;
    ringOffsetWidth?: string;
    ringOffsetColor?: string;
    boxShadow?: string;
    boxShadowColor?: string;
    opacity?: string;
    dropShadow?: string;
    hidden?: boolean;
    borderRadiusTopLeft?: string;
    borderRadiusTopRight?: string;
    borderRadiusBottomLeft?: string;
    borderRadiusBottomRight?: string;
    borderWidthTop?: string;
    borderWidthRight?: string;
    borderWidthLeft?: string;
    borderWidthBottom?: string;
    borderColor?: string;
    borderStyle?: string;
    outlineWidth?: string;
    outlineColor?: string;
    outlineStyle?: string;
    blur?: string;
    brightness?: string;
    contrast?: string;
    hueRotate?: string;
    invert?: boolean;
    saturate?: string;
    sepia?: boolean;
    backdropBlur?: string;
    backdropBrightness?: string;
    backdropContrast?: string;
    backdropHueRotate?: string;
    backdropInvert?: boolean;
    backdropOpacity?: string;
    backdropSaturate?: string;
    backdropSepia?: boolean;



    // for item into a container
    containerFlexBasis?: string;
    containerFlexOrder?: string;
    containerItemDisposition?: 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string; // 'around' | 'between' | 'center' | 'end' | 'start' | 'evenly';
    containerItemsDisposition?: 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string; // 'around' | 'between' | 'center' | 'end' | 'start' | 'evenly';
    containerItemFillSpace?: '1' | 'auto' | 'none' | 'initial';
    containerPlaceContent?: 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly' | 'stretch';
    containerDisableFlex?: boolean;
    containerFlexWrap?: string;
    containerTextColor?: string;
    containerFontSize?: string;
    containerFontWeight?: string;
    containerFontFamily?: string;
    containerLetterSpacing?: string;
    containerLineHeight?: string;
    containerTextDecoration?: string;
    containerTextTransform?: string;
    containerTextOverflow?: string;
    containerTextIndent?: string;
    containerWhitespace?: string;
    containerWordBreak?: string;
    containerDivideWidthTopLeft?: string;
    containerDivideWidthTopRight?: string;
    containerDivideWidthBottomLeft?: string;
    containerDivideWidthBottomRight?: string;
    containerDivideColorTopLeft?: string;
    containerDivideColorTopRight?: string;
    containerDivideColorBottomLeft?: string;
    containerDivideColorBottomRight?: string;
    containerDivideStyleTopLeft?: string;
    containerDivideStyleTopRight?: string;
    containerDivideStyleBottomLeft?: string;
    containerDivideStyleBottomRight?: string;
    containerMinWidth?: string;
    containerMaxWidth?: string;
    containerWidth?: string;
    containerMinHeight?: string;
    containerMaxHeight?: string;
    containerHeight?: string;
    containerOverflow?: string;
    containerBackgroundColor?: string;
    containerPaddingLeft?: string;
    containerPaddingRight?: string;
    containerPaddingTop?: string;
    containerPaddingBottom?: string;
    containerMarginLeft?: string;
    containerMarginRight?: string;
    containerMarginTop?: string;
    containerMarginBottom?: string;
    containerBackgroundImage?: string;
    containerBackgroundAttachment?: string;
    containerBackgroundClip?: string;
    containerBackgroundOrigin?: string;
    containerBackgroundPosition?: string;
    containerBackgroundRepeat?: string;
    containerBackgroundSize?: string;
    containerRingWidth?: string;
    containerRingColor?: string;
    containerRingOffsetWidth?: string;
    containerRingOffsetColor?: string;
    containerBoxShadow?: string;
    containerBoxShadowColor?: string;
    containerOpacity?: string;
    containerDropShadow?: string;
    containerHidden?: boolean;
    containerBorderRadiusTopLeft?: string;
    containerBorderRadiusTopRight?: string;
    containerBorderRadiusBottomLeft?: string;
    containerBorderRadiusBottomRight?: string;
    containerBorderWidthTop?: string;
    containerBorderWidthRight?: string;
    containerBorderWidthLeft?: string;
    containerBorderWidthBottom?: string;
    containerBorderColor?: string;
    containerBorderStyle?: string;
    containerOutlineWidth?: string;
    containerOutlineColor?: string;
    containerOutlineStyle?: string;
    containerBlur?: string;
    containerBrightness?: string;
    containerContrast?: string;
    containerHueRotate?: string;
    containerInvert?: boolean;
    containerSaturate?: string;
    containerSepia?: boolean;
    containerBackdropBlur?: string;
    containerBackdropBrightness?: string;
    containerBackdropContrast?: string;
    containerBackdropHueRotate?: string;
    containerBackdropInvert?: boolean;
    containerBackdropOpacity?: string;
    containerBackdropSaturate?: string;
    containerBackdropSepia?: boolean;

}

export class BaseElementConfig implements BaseElementConfigInterface{
    static category: string = 'Misc';
    static type: string = 'BaseElement';

    delegate?: BaseElementConfigInterface;
    element: BaseElement;

    [key: string]: any;

    type?: string; // unique id that refers to this type of element
    category?: string;

    // _doNotProcessIncomingMessage?: boolean;
    // set doNotProcessIncomingMessage(newValue: boolean){
    //     this.internalSetValue('doNotProcessIncomingMessage', newValue);
    // }
    // get doNotProcessIncomingMessage(): boolean{
    //     return this._doNotProcessIncomingMessage;
    // }

    _isBackgroundElement?: boolean;
    set isBackgroundElement(newValue: boolean){
        this.internalSetValue('isBackgroundElement', newValue);
    }
    get isBackgroundElement(): boolean{
        return this._isBackgroundElement;
    }

    _customClasses?: string;
    set customClasses(newValue: string){
        this.internalSetValue('customClasses', newValue);
    }
    get customClasses(): string{
        return this._customClasses;
    }

    _order?: number;
    set order(newValue: number){
        this.internalSetValue('order', newValue);
    }
    get order(): number{
        return this._order;
    }

    _name?: string;
    set name(newValue: string){
        this.internalSetValue('name', newValue);
    }

    get name(): string{
        return this._name;
    }

    _otherNames?: string[];
    set otherNames(newValue: string[]){
        this.internalSetValue('otherNames', newValue);
    }

    get otherNames(): string[]{
        return this._otherNames;
    }

    _status?: BaseElementStatus;
    set status(newValue: BaseElementStatus){
        if (newValue === BaseElementStatus.sleeping){
            this.element.sleep()
                .subscribe({
                    next: res => this.internalSetValue('status', newValue)
                });
        }else if (newValue === BaseElementStatus.online && this.element.config.status === BaseElementStatus.sleeping) {
            this.element.wakeUp()
                .subscribe({
                    next: res => this.internalSetValue('status', newValue)
                });
        }else{
            this.internalSetValue('status', newValue);
        }
    }
    get status(): BaseElementStatus{
        return this._status;
    }

    _description?: string;
    set description(newValue: string){
        this.internalSetValue('description', newValue);
    }
    get description(): string{
        return this._description;
    }

    _paused?: boolean;
    set paused(newValue: boolean){
        this.internalSetValue('paused', newValue);
    }
    get paused(): boolean{
        return this._paused;
    }

    _inStreamElementIds?: string[]; // list of elements' ids that are used ad input streams. All this streams are "merged" together (as per rxjs "merge()" function)
    set inStreamElementIds(newValue: string[]){
        this.internalSetValue('inStreamElementIds', newValue);
    }
    get inStreamElementIds(): string[]{
        return this._inStreamElementIds;
    }

    _defaultValue?: { [key: string]: any } | string | boolean | number | StreamChannelMessage | UserFunctionInput;
    set defaultValue(newValue: { [key: string]: any } | string | boolean | number | StreamChannelMessage | UserFunctionInput){
        this.internalSetValue('defaultValue', newValue);
    }
    get defaultValue(): { [key: string]: any } | string | boolean | number | StreamChannelMessage | UserFunctionInput{
        return this._defaultValue;
    }

    _debounceIn?: number;// debounce milliseconds of incoming stream
    set debounceIn(newValue: number){
        this.internalSetValue('debounceIn', newValue);
    }
    get debounceIn(): number{
        return this._debounceIn;
    }

    _debounceOut?: number;// debounce milliseconds of outgoing stream
    set debounceOut(newValue: number){
        this.internalSetValue('debounceOut', newValue);
    }
    get debounceOut(): number{
        return this._debounceOut;
    }

    _autoUpdateMs?: number;// if > 0, it will push the last message received into the stream
    set autoUpdateMs(newValue: number){
        this.internalSetValue('autoUpdateMs', newValue);
    }
    get autoUpdateMs(): number{
        return this._autoUpdateMs;
    }

    _forwardErrorMessage?: boolean;// if true and if an error in this element occurs, it forwards it to the next element
    set forwardErrorMessage(newValue: boolean){
        this.internalSetValue('forwardErrorMessage', newValue);
    }
    get forwardErrorMessage(): boolean{
        return this._forwardErrorMessage;
    }

    _pauseAutoUpdate?: boolean;// if true, autoUpdate will pause (if > 0)
    set pauseAutoUpdate(newValue: boolean){
        this.internalSetValue('pauseAutoUpdate', newValue);
    }
    get pauseAutoUpdate(): boolean{
        return this._pauseAutoUpdate;
    }

    _useLastInValueWhenAutoUpdate?: boolean;
    set useLastInValueWhenAutoUpdate(newValue: boolean){
        this.internalSetValue('useLastInValueWhenAutoUpdate', newValue);
    }
    get useLastInValueWhenAutoUpdate(): boolean{
        return this._useLastInValueWhenAutoUpdate;
    }

    _useIncomingFormattedValue?: boolean;
    set useIncomingFormattedValue(newValue: boolean){
        this.internalSetValue('useIncomingFormattedValue', newValue);
    }
    get useIncomingFormattedValue(): boolean{
        return this._useIncomingFormattedValue;
    }

    _chainElementConfigs?: (string | number | any | BaseElementConfigInterface | UserFunctionInput)[];
    set chainElementConfigs(newValue: (string | number | any | BaseElementConfigInterface | UserFunctionInput)[]){
        this.internalSetValue('chainElementConfigs', newValue);
    }
    get chainElementConfigs(): (string | number | any | BaseElementConfigInterface | UserFunctionInput)[]{
        return this._chainElementConfigs;
    }

    // _inputChainElementConfigs?: BaseElementConfigInterface[];
    // set inputChainElementConfigs(newValue: BaseElementConfigInterface[]){
    //     this.internalSetValue('inputChainElementConfigs', newValue);
    // }
    // get inputChainElementConfigs(): BaseElementConfigInterface[]{
    //     return this._inputChainElementConfigs;
    // }
    //
    // _outputChainElementConfigs?: BaseElementConfigInterface[];
    // set outputChainElementConfigs(newValue: BaseElementConfigInterface[]){
    //     this.internalSetValue('outputChainElementConfigs', newValue);
    // }
    // get outputChainElementConfigs(): BaseElementConfigInterface[]{
    //     return this._outputChainElementConfigs;
    // }

    _logging?: boolean;
    set logging(newValue: boolean){
        this.internalSetValue('logging', newValue);
    }
    get logging(): boolean{
        return this._logging;
    }

    _eventsHook?: ElementsEventHookConfig;
    set eventsHook(newValue: ElementsEventHookConfig){
        this.internalSetValue('eventsHook', newValue);
    }
    get eventsHook(): ElementsEventHookConfig{
        return this._eventsHook;
    }

    _elementConfigurationPanel?: BaseElementConfigInterface[];
    set elementConfigurationPanel(newValue: BaseElementConfigInterface[]){
        this.internalSetValue('elementConfigurationPanel', newValue);
    }
    get elementConfigurationPanel(): BaseElementConfigInterface[]{
        return this._elementConfigurationPanel;
    }

    _editMode?: boolean;
    set editMode(newValue: boolean){
        this.internalSetValue('editMode', newValue);
    }
    get editMode(): boolean{
        return this._editMode;
    }

    _selectValue?: string;
    set selectValue(newValue: string){
        this.internalSetValue('selectValue', newValue);
    }
    get selectValue(): string{
        return this._selectValue;
    }

    //view
    _disableFlex?: boolean;
    set disableFlex(newValue: boolean){
        this.internalSetValue('disableFlex', newValue);
    }
    get disableFlex(): boolean{
        return this._disableFlex;
    }

    _itemFillSpace: string;
    set itemFillSpace(newValue: string){
        this.internalSetValue('itemFillSpace', newValue);
    }
    get itemFillSpace(): string{
        return this._itemFillSpace;
    }

    _flexWrap: string;
    set flexWrap(newValue: string){
        this.internalSetValue('flexWrap', newValue);
    }
    get flexWrap(): string{
        return this._flexWrap;
    }

    _flexOrder: string;
    set flexOrder(newValue: string){
        this.internalSetValue('flexOrder', newValue);
    }
    get flexOrder(): string{
        return this._flexOrder;
    }

    _flexBasis: string;
    set flexBasis(newValue: string){
        this.internalSetValue('flexBasis', newValue);
    }
    get flexBasis(): string{
        return this._flexBasis;
    }

    _flexGrow: boolean;
    set flexGrow(newValue: boolean){
        this.internalSetValue('flexGrow', newValue);
    }
    get flexGrow(): boolean{
        return this._flexGrow;
    }

    _flexShrink: boolean;
    set flexShrink(newValue: boolean){
        this.internalSetValue('flexShrink', newValue);
    }
    get flexShrink(): boolean{
        return this._flexShrink;
    }

    _zIndex: string;
    set zIndex(newValue: string){
        this.internalSetValue('zIndex', newValue);
    }
    get zIndex(): string{
        return this._zIndex;
    }

    _itemsDisposition?: 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string; // 'around' | 'between' | 'center' | 'end' | 'start' | 'evenly';
    set itemsDisposition(newValue: 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string){
        this.internalSetValue('itemsDisposition', newValue);
    }
    get itemsDisposition(): 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string{
        return this._itemsDisposition;
    }

    _itemDisposition?: 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string; // 'around' | 'between' | 'center' | 'end' | 'start' | 'evenly';
    set itemDisposition(newValue: 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string){
        this.internalSetValue('itemDisposition', newValue);
    }
    get itemDisposition(): 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string{
        return this._itemDisposition;
    }

    _placeContent?: 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly' | 'stretch';
    set placeContent(newValue: 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly' | 'stretch'){
        this.internalSetValue('placeContent', newValue);
    }
    get placeContent(): 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly' | 'stretch'{
        return this._placeContent;
    }

    _minWidth?: string;
    set minWidth(newValue: string){
        this.internalSetValue('minWidth', newValue);
    }
    get minWidth(): string{
        return this._minWidth;
    }

    _maxWidth?: string;
    set maxWidth(newValue: string){
        this.internalSetValue('maxWidth', newValue);
    }
    get maxWidth(): string{
        return this._maxWidth;
    }

    _width?: string;
    set width(newValue: string){
        this.internalSetValue('width', newValue);
    }
    get width(): string{
        return this._width;
    }

    _minHeight?: string;
    set minHeight(newValue: string){
        this.internalSetValue('minHeight', newValue);
    }
    get minHeight(): string{
        return this._minHeight;
    }

    _maxHeight?: string;
    set maxHeight(newValue: string){
        this.internalSetValue('maxHeight', newValue);
    }
    get maxHeight(): string{
        return this._maxHeight;
    }

    _height?: string;
    set height(newValue: string){
        this.internalSetValue('height', newValue);
    }
    get height(): string{
        return this._height;
    }

    _overflow?: string;
    set overflow(newValue: string){
        this.internalSetValue('overflow', newValue);
    }
    get overflow(): string{
        return this._overflow;
    }

    _backgroundColor?: string;
    set backgroundColor(newValue: string){
        this.internalSetValue('backgroundColor', newValue);
    }
    get backgroundColor(): string{
        return this._backgroundColor;
    }

    _textColor?: string;
    set textColor(newValue: string){
        this.internalSetValue('textColor', newValue);
    }
    get textColor(): string{
        return this._textColor;
    }

    _paddingLeft: string;
    set paddingLeft(newValue: string){
        this.internalSetValue('paddingLeft', newValue);
    }
    get paddingLeft(): string{
        return this._paddingLeft;
    }

    _paddingRight: string;
    set paddingRight(newValue: string){
        this.internalSetValue('paddingRight', newValue);
    }
    get paddingRight(): string{
        return this._paddingRight;
    }

    _paddingTop: string;
    set paddingTop(newValue: string){
        this.internalSetValue('paddingTop', newValue);
    }
    get paddingTop(): string{
        return this._paddingTop;
    }

    _paddingBottom: string;
    set paddingBottom(newValue: string){
        this.internalSetValue('paddingBottom', newValue);
    }
    get paddingBottom(): string{
        return this._paddingBottom;
    }

    _marginLeft: string;
    set marginLeft(newValue: string){
        this.internalSetValue('marginLeft', newValue);
    }
    get marginLeft(): string{
        return this._marginLeft;
    }

    _marginRight: string;
    set marginRight(newValue: string){
        this.internalSetValue('marginRight', newValue);
    }
    get marginRight(): string{
        return this._marginRight;
    }

    _marginTop: string;
    set marginTop(newValue: string){
        this.internalSetValue('marginTop', newValue);
    }
    get marginTop(): string{
        return this._marginTop;
    }

    _marginBottom: string;
    set marginBottom(newValue: string){
        this.internalSetValue('marginBottom', newValue);
    }
    get marginBottom(): string{
        return this._marginBottom;
    }

    _fontSize: string;
    set fontSize(newValue: string){
        this.internalSetValue('fontSize', newValue);
    }
    get fontSize(): string{
        return this._fontSize;
    }

    _fontWeight: string;
    set fontWeight(newValue: string){
        this.internalSetValue('fontWeight', newValue);
    }
    get fontWeight(): string{
        return this._fontWeight;
    }

    _letterSpacing: string;
    set letterSpacing(newValue: string){
        this.internalSetValue('letterSpacing', newValue);
    }
    get letterSpacing(): string{
        return this._letterSpacing;
    }

    _lineHeight?: string;
    set lineHeight(newValue: string){
        this.internalSetValue('lineHeight', newValue);
    }
    get lineHeight(): string{
        return this._lineHeight;
    }

    _textDecoration?: string;
    set textDecoration(newValue: string){
        this.internalSetValue('textDecoration', newValue);
    }
    get textDecoration(): string{
        return this._textDecoration;
    }

    _textTransform?: string;
    set textTransform(newValue: string){
        this.internalSetValue('textTransform', newValue);
    }
    get textTransform(): string{
        return this._textTransform;
    }

    _textOverflow?: string;
    set textOverflow(newValue: string){
        this.internalSetValue('textOverflow', newValue);
    }
    get textOverflow(): string{
        return this._textOverflow;
    }

    _textIndent?: string;
    set textIndent(newValue: string){
        this.internalSetValue('textIndent', newValue);
    }
    get textIndent(): string{
        return this._textIndent;
    }

    _whitespace?: string;
    set whitespace(newValue: string){
        this.internalSetValue('whitespace', newValue);
    }
    get whitespace(): string{
        return this._whitespace;
    }

    _wordBreak?: string;
    set wordBreak(newValue: string){
        this.internalSetValue('wordBreak', newValue);
    }
    get wordBreak(): string{
        return this._wordBreak;
    }

    _backgroundImage?: string;
    set backgroundImage(newValue: string){
        this.internalSetValue('backgroundImage', newValue);
    }
    get backgroundImage(): string{
        return this._backgroundImage;
    }

    _backgroundAttachment?: string;
    set backgroundAttachment(newValue: string){
        this.internalSetValue('backgroundAttachment', newValue);
    }
    get backgroundAttachment(): string{
        return this._backgroundAttachment;
    }

    _backgroundClip?: string;
    set backgroundClip(newValue: string){
        this.internalSetValue('backgroundClip', newValue);
    }
    get backgroundClip(): string{
        return this._backgroundClip;
    }

    _backgroundOrigin?: string;
    set backgroundOrigin(newValue: string){
        this.internalSetValue('backgroundOrigin', newValue);
    }
    get backgroundOrigin(): string{
        return this._backgroundOrigin;
    }

    _backgroundPosition?: string;
    set backgroundPosition(newValue: string){
        this.internalSetValue('backgroundPosition', newValue);
    }
    get backgroundPosition(): string{
        return this._backgroundPosition;
    }

    _backgroundRepeat?: string;
    set backgroundRepeat(newValue: string){
        this.internalSetValue('backgroundRepeat', newValue);
    }
    get backgroundRepeat(): string{
        return this._backgroundRepeat;
    }

    _backgroundSize?: string;
    set backgroundSize(newValue: string){
        this.internalSetValue('backgroundSize', newValue);
    }
    get backgroundSize(): string{
        return this._backgroundSize;
    }

    _ringWidth?: string;
    set ringWidth(newValue: string){
        this.internalSetValue('ringWidth', newValue);
    }
    get ringWidth(): string{
        return this._ringWidth;
    }

    _ringColor?: string;
    set ringColor(newValue: string){
        this.internalSetValue('ringColor', newValue);
    }
    get ringColor(): string{
        return this._ringColor;
    }

    _ringOffsetWidth?: string;
    set ringOffsetWidth(newValue: string){
        this.internalSetValue('ringOffsetWidth', newValue);
    }
    get ringOffsetWidth(): string{
        return this._ringOffsetWidth;
    }

    _ringOffsetColor?: string;
    set ringOffsetColor(newValue: string){
        this.internalSetValue('ringOffsetColor', newValue);
    }
    get ringOffsetColor(): string{
        return this._ringOffsetColor;
    }

    _boxShadow?: string;
    set boxShadow(newValue: string){
        this.internalSetValue('boxShadow', newValue);
    }
    get boxShadow(): string{
        return this._boxShadow;
    }

    _boxShadowColor?: string;
    set boxShadowColor(newValue: string){
        this.internalSetValue('boxShadowColor', newValue);
    }
    get boxShadowColor(): string{
        return this._boxShadowColor;
    }

    _opacity?: string;
    set opacity(newValue: string){
        this.internalSetValue('opacity', newValue);
    }
    get opacity(): string{
        return this._opacity;
    }

    _dropShadow?: string;
    set dropShadow(newValue: string){
        this.internalSetValue('dropShadow', newValue);
    }
    get dropShadow(): string{
        return this._dropShadow;
    }

    _hidden?: boolean;
    set hidden(newValue: boolean){
        this.internalSetValue('hidden', newValue);
    }
    get hidden(): boolean{
        return this._hidden;
    }

    _borderRadiusTopLeft?: string;
    set borderRadiusTopLeft(newValue: string){
        this.internalSetValue('borderRadiusTopLeft', newValue);
    }
    get borderRadiusTopLeft(): string{
        return this._borderRadiusTopLeft;
    }

    _borderRadiusTopRight?: string;
    set borderRadiusTopRight(newValue: string){
        this.internalSetValue('borderRadiusTopRight', newValue);
    }
    get borderRadiusTopRight(): string{
        return this._borderRadiusTopRight;
    }

    _borderRadiusBottomLeft?: string;
    set borderRadiusBottomLeft(newValue: string){
        this.internalSetValue('borderRadiusBottomLeft', newValue);
    }
    get borderRadiusBottomLeft(): string{
        return this._borderRadiusBottomLeft;
    }

    _borderRadiusBottomRight?: string;
    set borderRadiusBottomRight(newValue: string){
        this.internalSetValue('borderRadiusBottomRight', newValue);
    }
    get borderRadiusBottomRight(): string{
        return this._borderRadiusBottomRight;
    }

    _borderWidthTop?: string;
    set borderWidthTop(newValue: string){
        this.internalSetValue('borderWidthTop', newValue);
    }
    get borderWidthTop(): string{
        return this._borderWidthTop;
    }

    _borderWidthRight?: string;
    set borderWidthRight(newValue: string){
        this.internalSetValue('borderWidthRight', newValue);
    }
    get borderWidthRight(): string{
        return this._borderWidthRight;
    }

    _borderWidthLeft?: string;
    set borderWidthLeft(newValue: string){
        this.internalSetValue('borderWidthLeft', newValue);
    }
    get borderWidthLeft(): string{
        return this._borderWidthLeft;
    }

    _borderWidthBottom?: string;
    set borderWidthBottom(newValue: string){
        this.internalSetValue('borderWidthBottom', newValue);
    }
    get borderWidthBottom(): string{
        return this._borderWidthBottom;
    }

    _borderColor?: string;
    set borderColor(newValue: string){
        this.internalSetValue('borderColor', newValue);
    }
    get borderColor(): string{
        return this._borderColor;
    }

    _borderStyle?: string;
    set borderStyle(newValue: string){
        this.internalSetValue('borderStyle', newValue);
    }
    get borderStyle(): string{
        return this._borderStyle;
    }

    _outlineWidth?: string;
    set outlineWidth(newValue: string){
        this.internalSetValue('outlineWidth', newValue);
    }
    get outlineWidth(): string{
        return this._outlineWidth;
    }

    _outlineColor?: string;
    set outlineColor(newValue: string){
        this.internalSetValue('outlineColor', newValue);
    }
    get outlineColor(): string{
        return this._outlineColor;
    }

    _outlineStyle?: string;
    set outlineStyle(newValue: string){
        this.internalSetValue('outlineStyle', newValue);
    }
    get outlineStyle(): string{
        return this._outlineStyle;
    }

    _blur?: string;
    set blur(newValue: string){
        this.internalSetValue('blur', newValue);
    }
    get blur(): string{
        return this._blur;
    }

    _brightness?: string;
    set brightness(newValue: string){
        this.internalSetValue('brightness', newValue);
    }
    get brightness(): string{
        return this._brightness;
    }

    _contrast?: string;
    set contrast(newValue: string){
        this.internalSetValue('contrast', newValue);
    }
    get contrast(): string{
        return this._contrast;
    }

    _hueRotate?: string;
    set hueRotate(newValue: string){
        this.internalSetValue('hueRotate', newValue);
    }
    get hueRotate(): string{
        return this._hueRotate;
    }

    _invert?: boolean;
    set invert(newValue: boolean){
        this.internalSetValue('invert', newValue);
    }
    get invert(): boolean{
        return this._invert;
    }

    _saturate?: string;
    set saturate(newValue: string){
        this.internalSetValue('saturate', newValue);
    }
    get saturate(): string{
        return this._saturate;
    }

    _sepia?: boolean;
    set sepia(newValue: boolean){
        this.internalSetValue('sepia', newValue);
    }
    get sepia(): boolean{
        return this._sepia;
    }

    _backdropBlur?: string;
    set backdropBlur(newValue: string){
        this.internalSetValue('backdropBlur', newValue);
    }
    get backdropBlur(): string{
        return this._backdropBlur;
    }

    _backdropBrightness?: string;
    set backdropBrightness(newValue: string){
        this.internalSetValue('backdropBrightness', newValue);
    }
    get backdropBrightness(): string{
        return this._backdropBrightness;
    }

    _backdropContrast?: string;
    set backdropContrast(newValue: string){
        this.internalSetValue('backdropContrast', newValue);
    }
    get backdropContrast(): string{
        return this._backdropContrast;
    }

    _backdropHueRotate?: string;
    set backdropHueRotate(newValue: string){
        this.internalSetValue('backdropHueRotate', newValue);
    }
    get backdropHueRotate(): string{
        return this._backdropHueRotate;
    }

    _backdropInvert?: boolean;
    set backdropInvert(newValue: boolean){
        this.internalSetValue('backdropInvert', newValue);
    }
    get backdropInvert(): boolean{
        return this._backdropInvert;
    }

    _backdropOpacity?: string;
    set backdropOpacity(newValue: string){
        this.internalSetValue('backdropOpacity', newValue);
    }
    get backdropOpacity(): string{
        return this._backdropOpacity;
    }

    _backdropSaturate?: string;
    set backdropSaturate(newValue: string){
        this.internalSetValue('backdropSaturate', newValue);
    }
    get backdropSaturate(): string{
        return this._backdropSaturate;
    }

    _backdropSepia?: boolean;
    set backdropSepia(newValue: boolean){
        this.internalSetValue('backdropSepia', newValue);
    }
    get backdropSepia(): boolean{
        return this._backdropSepia;
    }



    // for item into a container
    _containerFlexOrder: string;
    set containerFlexOrder(newValue: string){
        this.internalSetValue('containerFlexOrder', newValue);
    }
    get containerFlexOrder(): string{
        return this._containerFlexOrder;
    }

    _containerFlexBasis: string;
    set containerFlexBasis(newValue: string){
        this.internalSetValue('containerFlexBasis', newValue);
    }
    get containerFlexBasis(): string{
        return this._containerFlexBasis;
    }

    _containerFlexWrap: string;
    set containerFlexWrap(newValue: string){
        this.internalSetValue('containerFlexWrap', newValue);
    }
    get containerFlexWrap(): string{
        return this._containerFlexWrap;
    }

    _containerItemsDisposition?: 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string; // 'around' | 'between' | 'center' | 'end' | 'start' | 'evenly';
    set containerItemsDisposition(newValue: 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string){
        this.internalSetValue('containerItemsDisposition', newValue);
    }
    get containerItemsDisposition(): 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string{
        return this._containerItemsDisposition;
    }

    _containerItemDisposition?: 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string; // 'around' | 'between' | 'center' | 'end' | 'start' | 'evenly';
    set containerItemDisposition(newValue: 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string){
        this.internalSetValue('containerItemDisposition', newValue);
    }
    get containerItemDisposition(): 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle' | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right' | string{
        return this._containerItemDisposition;
    }

    _containerItemFillSpace?: '1' | 'auto' | 'none' | 'initial';
    set containerItemFillSpace(newValue: '1' | 'auto' | 'none' | 'initial'){
        this.internalSetValue('containerItemFillSpace', newValue);
    }
    get containerItemFillSpace(): '1' | 'auto' | 'none' | 'initial'{
        return this._containerItemFillSpace;
    }

    _containerPlaceContent?: 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly' | 'stretch';
    set containerPlaceContent(newValue: 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly' | 'stretch'){
        this.internalSetValue('containerPlaceContent', newValue);
    }
    get containerPlaceContent(): 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly' | 'stretch'{
        return this._containerPlaceContent;
    }

    _containerDisableFlex?: boolean;
    set containerDisableFlex(newValue: boolean){
        this.internalSetValue('containerDisableFlex', newValue);
    }
    get containerDisableFlex(): boolean{
        return this._containerDisableFlex;
    }

    _containerTextColor?: string;
    set containerTextColor(newValue: string){
        this.internalSetValue('containerTextColor', newValue);
    }
    get containerTextColor(): string{
        return this._containerTextColor;
    }

    _containerFontSize?: string;
    set containerFontSize(newValue: string){
        this.internalSetValue('containerFontSize', newValue);
    }
    get containerFontSize(): string{
        return this._containerFontSize;
    }

    _containerFontWeight?: string;
    set containerFontWeight(newValue: string){
        this.internalSetValue('containerFontWeight', newValue);
    }
    get containerFontWeight(): string{
        return this._containerFontWeight;
    }

    _containerFontFamily?: string;
    set containerFontFamily(newValue: string){
        this.internalSetValue('containerFontFamily', newValue);
    }
    get containerFontFamily(): string{
        return this._containerFontFamily;
    }

    _containerLetterSpacing?: string;
    set containerLetterSpacing(newValue: string){
        this.internalSetValue('containerLetterSpacing', newValue);
    }
    get containerLetterSpacing(): string{
        return this._containerLetterSpacing;
    }

    _containerLineHeight?: string;
    set containerLineHeight(newValue: string){
        this.internalSetValue('containerLineHeight', newValue);
    }
    get containerLineHeight(): string{
        return this._containerLineHeight;
    }

    _containerTextDecoration?: string;
    set containerTextDecoration(newValue: string){
        this.internalSetValue('containerTextDecoration', newValue);
    }
    get containerTextDecoration(): string{
        return this._containerTextDecoration;
    }

    _containerTextTransform?: string;
    set containerTextTransform(newValue: string){
        this.internalSetValue('containerTextTransform', newValue);
    }
    get containerTextTransform(): string{
        return this._containerTextTransform;
    }

    _containerTextOverflow?: string;
    set containerTextOverflow(newValue: string){
        this.internalSetValue('containerTextOverflow', newValue);
    }
    get containerTextOverflow(): string{
        return this._containerTextOverflow;
    }

    _containerTextIndent?: string;
    set containerTextIndent(newValue: string){
        this.internalSetValue('containerTextIndent', newValue);
    }
    get containerTextIndent(): string{
        return this._containerTextIndent;
    }

    _containerWhitespace?: string;
    set containerWhitespace(newValue: string){
        this.internalSetValue('containerWhitespace', newValue);
    }
    get containerWhitespace(): string{
        return this._containerWhitespace;
    }

    _containerWordBreak?: string;
    set containerWordBreak(newValue: string){
        this.internalSetValue('containerWordBreak', newValue);
    }
    get containerWordBreak(): string{
        return this._containerWordBreak;
    }


    _containerDivideWidthTopLeft?: string;
    set containerDivideWidthTopLeft(newValue: string){
        this.internalSetValue('containerDivideWidthTopLeft', newValue);
    }
    get containerDivideWidthTopLeft(): string{
        return this._containerDivideWidthTopLeft;
    }

    _containerDivideWidthTopRight?: string;
    set containerDivideWidthTopRight(newValue: string){
        this.internalSetValue('containerDivideWidthTopRight', newValue);
    }
    get containerDivideWidthTopRight(): string{
        return this._containerDivideWidthTopRight;
    }

    _containerDivideWidthBottomLeft?: string;
    set containerDivideWidthBottomLeft(newValue: string){
        this.internalSetValue('containerDivideWidthBottomLeft', newValue);
    }
    get containerDivideWidthBottomLeft(): string{
        return this._containerDivideWidthBottomLeft;
    }

    _containerDivideWidthBottomRight?: string;
    set containerDivideWidthBottomRight(newValue: string){
        this.internalSetValue('containerDivideWidthBottomRight', newValue);
    }
    get containerDivideWidthBottomRight(): string{
        return this._containerDivideWidthBottomRight;
    }

    _containerDivideColorTopLeft?: string;
    set containerDivideColorTopLeft(newValue: string){
        this.internalSetValue('containerDivideColorTopLeft', newValue);
    }
    get containerDivideColorTopLeft(): string{
        return this._containerDivideColorTopLeft;
    }

    _containerDivideColorTopRight?: string;
    set containerDivideColorTopRight(newValue: string){
        this.internalSetValue('containerDivideColorTopRight', newValue);
    }
    get containerDivideColorTopRight(): string{
        return this._containerDivideColorTopRight;
    }

    _containerDivideColorBottomLeft?: string;
    set containerDivideColorBottomLeft(newValue: string){
        this.internalSetValue('containerDivideColorBottomLeft', newValue);
    }
    get containerDivideColorBottomLeft(): string{
        return this._containerDivideColorBottomLeft;
    }

    _containerDivideColorBottomRight?: string;
    set containerDivideColorBottomRight(newValue: string){
        this.internalSetValue('containerDivideColorBottomRight', newValue);
    }
    get containerDivideColorBottomRight(): string{
        return this._containerDivideColorBottomRight;
    }

    _containerDivideStyleTopLeft?: string;
    set containerDivideStyleTopLeft(newValue: string){
        this.internalSetValue('containerDivideStyleTopLeft', newValue);
    }
    get containerDivideStyleTopLeft(): string{
        return this._containerDivideStyleTopLeft;
    }

    _containerDivideStyleTopRight?: string;
    set containerDivideStyleTopRight(newValue: string){
        this.internalSetValue('containerDivideStyleTopRight', newValue);
    }
    get containerDivideStyleTopRight(): string{
        return this._containerDivideStyleTopRight;
    }

    _containerDivideStyleBottomLeft?: string;
    set containerDivideStyleBottomLeft(newValue: string){
        this.internalSetValue('containerDivideStyleBottomLeft', newValue);
    }
    get containerDivideStyleBottomLeft(): string{
        return this._containerDivideStyleBottomLeft;
    }

    _containerDivideStyleBottomRight?: string;
    set containerDivideStyleBottomRight(newValue: string){
        this.internalSetValue('containerDivideStyleBottomRight', newValue);
    }
    get containerDivideStyleBottomRight(): string{
        return this._containerDivideStyleBottomRight;
    }

    _containerMinWidth?: string;
    set containerMinWidth(newValue: string){
        this.internalSetValue('containerMinWidth', newValue);
    }
    get containerMinWidth(): string{
        return this._containerMinWidth;
    }

    _containerMaxWidth?: string;
    set containerMaxWidth(newValue: string){
        this.internalSetValue('containerMaxWidth', newValue);
    }
    get containerMaxWidth(): string{
        return this._containerMaxWidth;
    }

    _containerWidth?: string;
    set containerWidth(newValue: string){
        this.internalSetValue('containerWidth', newValue);
    }
    get containerWidth(): string{
        return this._containerWidth;
    }

    _containerMinHeight?: string;
    set containerMinHeight(newValue: string){
        this.internalSetValue('containerMinHeight', newValue);
    }
    get containerMinHeight(): string{
        return this._containerMinHeight;
    }

    _containerMaxHeight?: string;
    set containerMaxHeight(newValue: string){
        this.internalSetValue('containerMaxHeight', newValue);
    }
    get containerMaxHeight(): string{
        return this._containerMaxHeight;
    }

    _containerHeight?: string;
    set containerHeight(newValue: string){
        this.internalSetValue('containerHeight', newValue);
    }
    get containerHeight(): string{
        return this._containerHeight;
    }

    _containerOverflow?: string;
    set containerOverflow(newValue: string){
        this.internalSetValue('containerOverflow', newValue);
    }
    get containerOverflow(): string{
        return this._containerOverflow;
    }

    _containerBackgroundColor?: string;
    set containerBackgroundColor(newValue: string){
        this.internalSetValue('containerBackgroundColor', newValue);
    }
    get containerBackgroundColor(): string{
        return this._containerBackgroundColor;
    }

    _containerPaddingLeft?: string;
    set containerPaddingLeft(newValue: string){
        this.internalSetValue('containerPaddingLeft', newValue);
    }
    get containerPaddingLeft(): string{
        return this._containerPaddingLeft;
    }

    _containerPaddingRight?: string;
    set containerPaddingRight(newValue: string){
        this.internalSetValue('containerPaddingRight', newValue);
    }
    get containerPaddingRight(): string{
        return this._containerPaddingRight;
    }

    _containerPaddingTop?: string;
    set containerPaddingTop(newValue: string){
        this.internalSetValue('containerPaddingTop', newValue);
    }
    get containerPaddingTop(): string{
        return this._containerPaddingTop;
    }

    _containerPaddingBottom?: string;
    set containerPaddingBottom(newValue: string){
        this.internalSetValue('containerPaddingBottom', newValue);
    }
    get containerPaddingBottom(): string{
        return this._containerPaddingBottom;
    }

    _containerMarginLeft?: string;
    set containerMarginLeft(newValue: string){
        this.internalSetValue('containerMarginLeft', newValue);
    }
    get containerMarginLeft(): string{
        return this._containerMarginLeft;
    }

    _containerMarginRight?: string;
    set containerMarginRight(newValue: string){
        this.internalSetValue('containerMarginRight', newValue);
    }
    get containerMarginRight(): string{
        return this._containerMarginRight;
    }

    _containerMarginTop?: string;
    set containerMarginTop(newValue: string){
        this.internalSetValue('containerMarginTop', newValue);
    }
    get containerMarginTop(): string{
        return this._containerMarginTop;
    }

    _containerMarginBottom?: string;
    set containerMarginBottom(newValue: string){
        this.internalSetValue('containerMarginBottom', newValue);
    }
    get containerMarginBottom(): string{
        return this._containerMarginBottom;
    }

    _containerBackgroundImage?: string;
    set containerBackgroundImage(newValue: string){
        this.internalSetValue('containerBackgroundImage', newValue);
    }
    get containerBackgroundImage(): string{
        return this._containerBackgroundImage;
    }

    _containerBackgroundAttachment?: string;
    set containerBackgroundAttachment(newValue: string){
        this.internalSetValue('containerBackgroundAttachment', newValue);
    }
    get containerBackgroundAttachment(): string{
        return this._containerBackgroundAttachment;
    }

    _containerBackgroundClip?: string;
    set containerBackgroundClip(newValue: string){
        this.internalSetValue('containerBackgroundClip', newValue);
    }
    get containerBackgroundClip(): string{
        return this._containerBackgroundClip;
    }

    _containerBackgroundOrigin?: string;
    set containerBackgroundOrigin(newValue: string){
        this.internalSetValue('containerBackgroundOrigin', newValue);
    }
    get containerBackgroundOrigin(): string{
        return this._containerBackgroundOrigin;
    }

    _containerBackgroundPosition?: string;
    set containerBackgroundPosition(newValue: string){
        this.internalSetValue('containerBackgroundPosition', newValue);
    }
    get containerBackgroundPosition(): string{
        return this._containerBackgroundPosition;
    }

    _containerBackgroundRepeat?: string;
    set containerBackgroundRepeat(newValue: string){
        this.internalSetValue('containerBackgroundRepeat', newValue);
    }
    get containerBackgroundRepeat(): string{
        return this._containerBackgroundRepeat;
    }

    _containerBackgroundSize?: string;
    set containerBackgroundSize(newValue: string){
        this.internalSetValue('containerBackgroundSize', newValue);
    }
    get containerBackgroundSize(): string{
        return this._containerBackgroundSize;
    }

    _containerRingWidth?: string;
    set containerRingWidth(newValue: string){
        this.internalSetValue('containerRingWidth', newValue);
    }
    get containerRingWidth(): string{
        return this._containerRingWidth;
    }

    _containerRingColor?: string;
    set containerRingColor(newValue: string){
        this.internalSetValue('containerRingColor', newValue);
    }
    get containerRingColor(): string{
        return this._containerRingColor;
    }

    _containerRingOffsetWidth?: string;
    set containerRingOffsetWidth(newValue: string){
        this.internalSetValue('containerRingOffsetWidth', newValue);
    }
    get containerRingOffsetWidth(): string{
        return this._containerRingOffsetWidth;
    }

    _containerRingOffsetColor?: string;
    set containerRingOffsetColor(newValue: string){
        this.internalSetValue('containerRingOffsetColor', newValue);
    }
    get containerRingOffsetColor(): string{
        return this._containerRingOffsetColor;
    }

    _containerBoxShadow?: string;
    set containerBoxShadow(newValue: string){
        this.internalSetValue('containerBoxShadow', newValue);
    }
    get containerBoxShadow(): string{
        return this._containerBoxShadow;
    }

    _containerBoxShadowColor?: string;
    set containerBoxShadowColor(newValue: string){
        this.internalSetValue('containerBoxShadowColor', newValue);
    }
    get containerBoxShadowColor(): string{
        return this._containerBoxShadowColor;
    }

    _containerOpacity?: string;
    set containerOpacity(newValue: string){
        this.internalSetValue('containerOpacity', newValue);
    }
    get containerOpacity(): string{
        return this._containerOpacity;
    }

    _containerDropShadow?: string;
    set containerDropShadow(newValue: string){
        this.internalSetValue('containerDropShadow', newValue);
    }
    get containerDropShadow(): string{
        return this._containerDropShadow;
    }

    _containerHidden?: boolean;
    set containerHidden(newValue: boolean){
        this.internalSetValue('containerHidden', newValue);
    }
    get containerHidden(): boolean{
        return this._containerHidden;
    }

    _containerBorderRadiusTopLeft?: string;
    set containerBorderRadiusTopLeft(newValue: string){
        this.internalSetValue('containerBorderRadiusTopLeft', newValue);
    }
    get containerBorderRadiusTopLeft(): string{
        return this._containerBorderRadiusTopLeft;
    }

    _containerBorderRadiusTopRight?: string;
    set containerBorderRadiusTopRight(newValue: string){
        this.internalSetValue('containerBorderRadiusTopRight', newValue);
    }
    get containerBorderRadiusTopRight(): string{
        return this._containerBorderRadiusTopRight;
    }

    _containerBorderRadiusBottomLeft?: string;
    set containerBorderRadiusBottomLeft(newValue: string){
        this.internalSetValue('containerBorderRadiusBottomLeft', newValue);
    }
    get containerBorderRadiusBottomLeft(): string{
        return this._containerBorderRadiusBottomLeft;
    }

    _containerBorderRadiusBottomRight?: string;
    set containerBorderRadiusBottomRight(newValue: string){
        this.internalSetValue('containerBorderRadiusBottomRight', newValue);
    }
    get containerBorderRadiusBottomRight(): string{
        return this._containerBorderRadiusBottomRight;
    }

    _containerBorderWidthTop?: string;
    set containerBorderWidthTop(newValue: string){
        this.internalSetValue('containerBorderWidthTop', newValue);
    }
    get containerBorderWidthTop(): string{
        return this._containerBorderWidthTop;
    }

    _containerBorderWidthRight?: string;
    set containerBorderWidthRight(newValue: string){
        this.internalSetValue('containerBorderWidthRight', newValue);
    }
    get containerBorderWidthRight(): string{
        return this._containerBorderWidthRight;
    }

    _containerBorderWidthLeft?: string;
    set containerBorderWidthLeft(newValue: string){
        this.internalSetValue('containerBorderWidthLeft', newValue);
    }
    get containerBorderWidthLeft(): string{
        return this._containerBorderWidthLeft;
    }

    _containerBorderWidthBottom?: string;
    set containerBorderWidthBottom(newValue: string){
        this.internalSetValue('containerBorderWidthBottom', newValue);
    }
    get containerBorderWidthBottom(): string{
        return this._containerBorderWidthBottom;
    }

    _containerBorderColor?: string;
    set containerBorderColor(newValue: string){
        this.internalSetValue('containerBorderColor', newValue);
    }
    get containerBorderColor(): string{
        return this._containerBorderColor;
    }

    _containerBorderStyle?: string;
    set containerBorderStyle(newValue: string){
        this.internalSetValue('containerBorderStyle', newValue);
    }
    get containerBorderStyle(): string{
        return this._containerBorderStyle;
    }

    _containerOutlineWidth?: string;
    set containerOutlineWidth(newValue: string){
        this.internalSetValue('containerOutlineWidth', newValue);
    }
    get containerOutlineWidth(): string{
        return this._containerOutlineWidth;
    }

    _containerOutlineColor?: string;
    set containerOutlineColor(newValue: string){
        this.internalSetValue('containerOutlineColor', newValue);
    }
    get containerOutlineColor(): string{
        return this._containerOutlineColor;
    }

    _containerOutlineStyle?: string;
    set containerOutlineStyle(newValue: string){
        this.internalSetValue('containerOutlineStyle', newValue);
    }
    get containerOutlineStyle(): string{
        return this._containerOutlineStyle;
    }

    _containerBlur?: string;
    set containerBlur(newValue: string){
        this.internalSetValue('containerBlur', newValue);
    }
    get containerBlur(): string{
        return this._containerBlur;
    }

    _containerBrightness?: string;
    set containerBrightness(newValue: string){
        this.internalSetValue('containerBrightness', newValue);
    }
    get containerBrightness(): string{
        return this._containerBrightness;
    }

    _containerContrast?: string;
    set containerContrast(newValue: string){
        this.internalSetValue('containerContrast', newValue);
    }
    get containerContrast(): string{
        return this._containerContrast;
    }

    _containerHueRotate?: string;
    set containerHueRotate(newValue: string){
        this.internalSetValue('containerHueRotate', newValue);
    }
    get containerHueRotate(): string{
        return this._containerHueRotate;
    }

    _containerInvert?: boolean;
    set containerInvert(newValue: boolean){
        this.internalSetValue('containerInvert', newValue);
    }
    get containerInvert(): boolean{
        return this._containerInvert;
    }

    _containerSaturate?: string;
    set containerSaturate(newValue: string){
        this.internalSetValue('containerSaturate', newValue);
    }
    get containerSaturate(): string{
        return this._containerSaturate;
    }

    _containerSepia?: boolean;
    set containerSepia(newValue: boolean){
        this.internalSetValue('containerSepia', newValue);
    }
    get containerSepia(): boolean{
        return this._containerSepia;
    }

    _containerBackdropBlur?: string;
    set containerBackdropBlur(newValue: string){
        this.internalSetValue('containerBackdropBlur', newValue);
    }
    get containerBackdropBlur(): string{
        return this._containerBackdropBlur;
    }

    _containerBackdropBrightness?: string;
    set containerBackdropBrightness(newValue: string){
        this.internalSetValue('containerBackdropBrightness', newValue);
    }
    get containerBackdropBrightness(): string{
        return this._containerBackdropBrightness;
    }

    _containerBackdropContrast?: string;
    set containerBackdropContrast(newValue: string){
        this.internalSetValue('containerBackdropContrast', newValue);
    }
    get containerBackdropContrast(): string{
        return this._containerBackdropContrast;
    }

    _containerBackdropHueRotate?: string;
    set containerBackdropHueRotate(newValue: string){
        this.internalSetValue('containerBackdropHueRotate', newValue);
    }
    get containerBackdropHueRotate(): string{
        return this._containerBackdropHueRotate;
    }

    _containerBackdropInvert?: boolean;
    set containerBackdropInvert(newValue: boolean){
        this.internalSetValue('containerBackdropInvert', newValue);
    }
    get containerBackdropInvert(): boolean{
        return this._containerBackdropInvert;
    }

    _containerBackdropOpacity?: string;
    set containerBackdropOpacity(newValue: string){
        this.internalSetValue('containerBackdropOpacity', newValue);
    }
    get containerBackdropOpacity(): string{
        return this._containerBackdropOpacity;
    }

    _containerBackdropSaturate?: string;
    set containerBackdropSaturate(newValue: string){
        this.internalSetValue('containerBackdropSaturate', newValue);
    }
    get containerBackdropSaturate(): string{
        return this._containerBackdropSaturate;
    }

    _containerBackdropSepia?: boolean;
    set containerBackdropSepia(newValue: boolean){
        this.internalSetValue('containerBackdropSepia', newValue);
    }
    get containerBackdropSepia(): boolean{
        return this._containerBackdropSepia;
    }



    constructor(config: BaseElementConfigInterface, element: BaseElement) {
        const randomId = Utils.guid();
        config.name = config.name || randomId;
        this._name = config.name;

        this._order = config.order;
        this._otherNames = config.otherNames || [];
        this._description = config.description || '';
        this._inStreamElementIds = config.inStreamElementIds || [];
        this._debounceIn = config.debounceIn || 0;
        this._debounceOut = config.debounceOut || 0;
        this._paused = config.paused || false;
        this._autoUpdateMs = config.autoUpdateMs || 0;
        this._forwardErrorMessage = config.forwardErrorMessage || true;
        this._defaultValue = config.defaultValue || undefined;
        this._pauseAutoUpdate = config.pauseAutoUpdate || false;
        this._useLastInValueWhenAutoUpdate = config.useLastInValueWhenAutoUpdate !== undefined ? config.useLastInValueWhenAutoUpdate: true;
        this._useIncomingFormattedValue = config.useIncomingFormattedValue || false;
        this._chainElementConfigs = config.chainElementConfigs || [];
        // this._inputChainElementConfigs = config.inputChainElementConfigs || [];
        // this._outputChainElementConfigs = config.outputChainElementConfigs || [];
        this._editMode = config.editMode || false;
        this._selectValue = config.selectValue;

        this._elementConfigurationPanel = BaseElementConfig.elementConfigurationPanel;

        this._status = config.status;
        this._description = config.description;

        this._logging = config.logging;
        this._eventsHook = config.eventsHook || {};

        this._customClasses = config.customClasses;

        //view
        this._disableFlex = config.disableFlex;
        this._itemFillSpace = config.itemFillSpace;
        this._placeContent = config.placeContent;
        this._flexWrap = config.flexWrap;
        this._flexBasis = config.flexBasis;
        this._flexOrder = config.flexOrder;
        this._flexGrow = config.flexGrow;
        this._flexShrink = config.flexShrink;
        this._containerFlexOrder = config.containerFlexOrder;
        this._zIndex = config.zIndex;
        this._itemDisposition = config.itemDisposition;
        this._itemsDisposition = config.itemsDisposition;
        this._minWidth = config.minWidth;
        this._maxWidth = config.maxWidth;
        this._width = config.width;
        this._height = config.height;
        this._minHeight = config.minHeight;
        this._maxHeight = config.maxHeight;
        this._overflow = config.overflow;
        this._backgroundColor = config.backgroundColor;
        this._textColor = config.textColor;
        this._paddingLeft = config.paddingLeft;
        this._paddingRight = config.paddingRight;
        this._paddingTop = config.paddingTop;
        this._paddingBottom = config.paddingBottom;
        this._marginLeft = config.marginLeft;
        this._marginRight = config.marginRight;
        this._marginTop = config.marginTop;
        this._marginBottom = config.marginBottom;
        this._fontSize = config.fontSize;
        this._fontWeight = config.fontWeight;
        this._letterSpacing = config.letterSpacing;
        this._lineHeight = config.lineHeight;
        this._textDecoration = config.textDecoration;
        this._textTransform = config.textTransform;
        this._textOverflow = config.textOverflow;
        this._textIndent = config.textIndent;
        this._whitespace = config.whitespace;
        this._wordBreak = config.wordBreak;
        this._backgroundImage = config.backgroundImage;
        this._backgroundAttachment = config.backgroundAttachment;
        this._backgroundImage = config.backgroundImage;
        this._backgroundClip = config.backgroundClip;
        this._backgroundOrigin = config.backgroundOrigin;
        this._backgroundPosition = config.backgroundPosition;
        this._backgroundRepeat = config.backgroundRepeat;
        this._backgroundSize = config.backgroundSize;
        this._ringWidth = config.ringWidth;
        this._ringColor = config.ringColor;
        this._ringOffsetWidth = config.ringOffsetWidth;
        this._ringOffsetColor = config.ringOffsetColor;
        this._boxShadow = config.boxShadow;
        this._boxShadowColor = config.boxShadowColor;
        this._opacity = config.opacity;
        this._dropShadow = config.dropShadow;
        this._hidden = config.hidden;

        this._borderRadiusTopLeft = config.borderRadiusTopLeft;
        this._borderRadiusTopRight = config.borderRadiusTopRight;
        this._borderRadiusBottomLeft = config.borderRadiusBottomLeft;
        this._borderRadiusBottomRight = config.borderRadiusBottomRight;
        this._borderWidthTop = config.borderWidthTop;
        this._borderWidthRight = config.borderWidthRight;
        this._borderWidthLeft = config.borderWidthLeft;
        this._borderWidthBottom = config.borderWidthBottom;
        this._borderColor = config.borderColor;
        this._borderStyle = config.borderStyle;
        this._outlineWidth = config.outlineWidth;
        this._outlineColor = config.outlineColor;
        this._outlineStyle = config.outlineStyle;
        this._blur = config.blur;
        this._brightness = config.brightness;
        this._contrast = config.contrast;
        this._hueRotate = config.hueRotate;
        this._invert = config.invert;
        this._saturate = config.saturate;
        this._sepia = config.sepia;
        this._backdropBlur = config.backdropBlur;
        this._backdropBrightness = config.backdropBrightness;
        this._backdropContrast = config.backdropContrast;
        this._backdropHueRotate = config.backdropHueRotate;
        this._backdropInvert = config.backdropInvert;
        this._backdropOpacity = config.backdropOpacity;
        this._backdropSaturate = config.backdropSaturate;
        this._backdropSepia = config.backdropSepia;

        // for item into a container
        this._containerFlexBasis = config.containerFlexBasis;
        this._containerFlexWrap = config.containerFlexWrap;
        this._containerItemDisposition = config.containerItemDisposition;
        this._containerItemsDisposition = config.containerItemsDisposition;
        this._containerItemFillSpace = config.containerItemFillSpace;
        this._containerPlaceContent = config.containerPlaceContent;
        this._containerDisableFlex = config.containerDisableFlex;
        this._containerTextColor = config.containerTextColor;
        this._containerFontSize = config.containerFontSize;
        this._containerFontWeight = config.containerFontWeight;
        this._containerFontFamily = config.containerFontFamily;
        this._containerLetterSpacing = config.containerLetterSpacing;
        this._containerLineHeight = config.containerLineHeight;
        this._containerTextDecoration = config.containerTextDecoration;
        this._containerTextTransform = config.containerTextTransform;
        this._containerTextOverflow = config.containerTextOverflow;
        this._containerTextIndent = config.containerTextIndent;
        this._containerWhitespace = config.containerWhitespace;
        this._containerWordBreak = config.containerWordBreak;
        this._containerDivideWidthTopLeft = config.containerDivideWidthTopLeft;
        this._containerDivideWidthTopRight = config.containerDivideWidthTopRight;
        this._containerDivideWidthBottomLeft = config.containerDivideWidthBottomLeft;
        this._containerDivideWidthBottomRight = config.containerDivideWidthBottomRight;
        this._containerDivideColorTopLeft = config.containerDivideColorTopLeft;
        this._containerDivideColorTopRight = config.containerDivideColorTopRight;
        this._containerDivideColorBottomLeft = config.containerDivideColorBottomLeft;
        this._containerDivideColorBottomRight = config.containerDivideColorBottomRight;
        this._containerDivideStyleTopLeft = config.containerDivideStyleTopLeft;
        this._containerDivideStyleTopRight = config.containerDivideStyleTopRight;
        this._containerDivideStyleBottomLeft = config.containerDivideStyleBottomLeft;
        this._containerDivideStyleBottomRight = config.containerDivideStyleBottomRight;
        this._containerMinWidth = config.containerMinWidth;
        this._containerMaxWidth = config.containerMaxWidth;
        this._containerWidth = config.containerWidth;
        this._containerMinHeight = config.containerMinHeight;
        this._containerMaxHeight = config.containerMaxHeight;
        this._containerHeight = config.containerHeight;
        this._containerOverflow = config.containerOverflow;
        this._containerBackgroundColor = config.containerBackgroundColor;
        this._containerPaddingLeft = config.containerPaddingLeft;
        this._containerPaddingRight = config.containerPaddingRight;
        this._containerPaddingTop = config.containerPaddingTop;
        this._containerPaddingBottom = config.containerPaddingBottom;
        this._containerMarginLeft = config.containerMarginLeft;
        this._containerMarginRight = config.containerMarginRight;
        this._containerMarginTop = config.containerMarginTop;
        this._containerMarginBottom = config.containerMarginBottom;
        this._containerBackgroundImage = config.containerBackgroundImage;
        this._containerBackgroundAttachment = config.containerBackgroundAttachment;
        this._containerBackgroundClip = config.containerBackgroundClip;
        this._containerBackgroundOrigin = config.containerBackgroundOrigin;
        this._containerBackgroundPosition = config.containerBackgroundPosition;
        this._containerBackgroundRepeat = config.containerBackgroundRepeat;
        this._containerBackgroundSize = config.containerBackgroundSize;
        this._containerRingWidth = config.containerRingWidth;
        this._containerRingColor = config.containerRingColor;
        this._containerRingOffsetWidth = config.containerRingOffsetWidth;
        this._containerRingOffsetColor = config.containerRingOffsetColor;
        this._containerBoxShadow = config.containerBoxShadow;
        this._containerBoxShadowColor = config.containerBoxShadowColor;
        this._containerOpacity = config.containerOpacity;
        this._containerDropShadow = config.containerDropShadow;
        this._containerHidden = config.containerHidden;
        this._containerBorderRadiusTopLeft = config.containerBorderRadiusTopLeft;
        this._containerBorderRadiusTopRight = config.containerBorderRadiusTopRight;
        this._containerBorderRadiusBottomLeft = config.containerBorderRadiusBottomLeft;
        this._containerBorderRadiusBottomRight = config.containerBorderRadiusBottomRight;
        this._containerBorderWidthTop = config.containerBorderWidthTop;
        this._containerBorderWidthRight = config.containerBorderWidthRight;
        this._containerBorderWidthLeft = config.containerBorderWidthLeft;
        this._containerBorderWidthBottom = config.containerBorderWidthBottom;
        this._containerBorderColor = config.containerBorderColor;
        this._containerBorderStyle = config.containerBorderStyle;
        this._containerOutlineWidth = config.containerOutlineWidth;
        this._containerOutlineColor = config.containerOutlineColor;
        this._containerOutlineStyle = config.containerOutlineStyle;
        this._containerBlur = config.containerBlur;
        this._containerBrightness = config.containerBrightness;
        this._containerContrast = config.containerContrast;
        this._containerHueRotate = config.containerHueRotate;
        this._containerInvert = config.containerInvert;
        this._containerSaturate = config.containerSaturate;
        this._containerSepia = config.containerSepia;
        this._containerBackdropBlur = config.containerBackdropBlur;
        this._containerBackdropBrightness = config.containerBackdropBrightness;
        this._containerBackdropContrast = config.containerBackdropContrast;
        this._containerBackdropHueRotate = config.containerBackdropHueRotate;
        this._containerBackdropInvert = config.containerBackdropInvert;
        this._containerBackdropOpacity = config.containerBackdropOpacity;
        this._containerBackdropSaturate = config.containerBackdropSaturate;
        this._containerBackdropSepia = config.containerBackdropSepia;

        config.type = BaseElementConfig.type;
        config.category = BaseElementConfig.category;
        this.type = config.type;
        this.category = config.category;
        this._status = (config.status === BaseElementStatus.offline || config.status === BaseElementStatus.paused || config.status === BaseElementStatus.sleeping) ? config.status : BaseElementStatus.toInitialize;

        this.delegate = config;
        this.element = element;
    }

    protected internalSetValue(paramName: string, value: any): void{
        const oldValue = this['_' + paramName];
        this['_' + paramName] = value;
        this.notifyConfigChanged(paramName, oldValue, value);
    }

    public setValue(paramName: string, value: any): void{
        const oldValue = this[paramName];
        this[paramName] = value;
        this.notifyConfigChanged(paramName, oldValue, value);
    }

    public getValue(paramName: string): any{
        return this[paramName];
    }

    protected notifyConfigChanged(paramName: string, oldValue: any, newValue: any): void{
        // TODO: check also if value is complex object
        if (oldValue !== newValue) {
            this.delegate[paramName] = newValue;
            this.element.configChangedSubject.next({
                config: this,
                oldValue: oldValue,
                newValue: newValue
            });
        }
    }

    static elementConfigurationPanel = [
        {
            name: 'BaseElementConfiguration',
            type: 'DynamicFlexContainer',
            layout: 'col',
            elements: [
                {
                    name: 'DrawerTitle',
                    chainElementConfigs: [{
                         type: 'function',
                         value: 'return { value: \'Element: \' + context.data.clickedElement.config.name };'
                    }],
                    logging: false,
                    itemDisposition: 'top-middle',
                    itemFillSpace: 'none',
                    // contentDisposition: 'middle', // top-left, top-middle, top-right, middle-left, middle, middle-right, bottom-left, bottom-middle, bottom-right
                },
                {
                    name: 'BaseElementConfigTabs',
                    type: 'ElementTab',
                    containerWidth: '80',
                    width: '80',
                    elements: [
                        {
                            name: 'General',
                            type: 'DynamicFlexContainer',
                            itemFillSpace : 'auto',
                            width: 'full',
                            layout: 'col',
                            elements: [
                                {
                                    name: 'ElementStatus',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    width: 'full',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.status',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Status',
                                    // END - input-specific config
                                    values: [
                                        'online', 'offline', 'sleeping', 'paused',
                                        {
                                            value: 'loading',
                                            disabled: true
                                        },
                                        {
                                            value: 'toInitialize',
                                            disabled: true
                                        },
                                        {
                                            value: 'error',
                                            disabled: true
                                        },
                                        {
                                            value: 'initializing',
                                            disabled: true
                                        }]
                                },
                                {
                                    name: 'ElementName',
                                    type: 'InputElement',
                                    logging: false,
                                    itemDisposition: 'top-middle',
                                    itemFillSpace : 'initial',
                                    width: 'full',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.name',
                                    label: 'Name'
                                    // END - twoWayBind-specific config
                                },
                                {
                                    name: 'DefaultValue',
                                    type: 'TextareaElement',
                                    itemDisposition: 'top-middle',
                                    itemFillSpace : 'initial',
                                    width: 'full',
                                    logging: false,
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.defaultValue',
                                    label: 'defaultValue',
                                    // END - twoWayBind-specific config
                                },
                                {
                                    name: 'DebounceIn',
                                    type: 'InputNumberElement',
                                    itemDisposition: 'top-middle',
                                    itemFillSpace : 'initial',
                                    width: 'full',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.debounceIn',
                                    label: 'debounceIn'
                                    // END - twoWayBind-specific config
                                },
                                {
                                    name: 'DebounceOut',
                                    type: 'InputNumberElement',
                                    itemDisposition: 'top-middle',
                                    itemFillSpace : 'initial',
                                    width: 'full',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.debounceOut',
                                    label: 'debounceOut',
                                    // END - twoWayBind-specific config
                                },
                                {
                                    name: 'Description',
                                    type: 'TextareaElement',
                                    itemDisposition: 'top-middle',
                                    itemFillSpace : 'initial',
                                    width: 'full',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.description',
                                    label: 'description',
                                    // END - twoWayBind-specific config
                                },
                                {
                                    name: 'Divider',
                                    type: 'DividerElement',
                                    itemDisposition: 'top-middle',
                                    itemFillSpace : 'initial',
                                    width: 'full'
                                },
                                {
                                    name: 'useLastInValueWhenAutoUpdate',
                                    type: 'ToggleElement',
                                    itemDisposition: 'top-left',
                                    itemFillSpace : 'initial',
                                    //width: 'full',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.useLastInValueWhenAutoUpdate',
                                    label: 'Use Last In Value When AutoUpdate',
                                    // END - twoWayBind-specific config
                                },
                                {
                                    name: 'PauseAutoUpdate',
                                    type: 'ToggleElement',
                                    itemDisposition: 'top-left',
                                    itemFillSpace : 'initial',
                                    //width: 'full',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.pauseAutoUpdate',
                                    label: 'Pause AutoUpdate',
                                    // END - twoWayBind-specific config
                                },
                                {
                                    name: 'AutoUpdateMs',
                                    type: 'InputNumberElement',
                                    itemDisposition: 'top-middle',
                                    itemFillSpace : 'initial',
                                    width: 'full',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.autoUpdateMs',
                                    label: 'AutoUpdate(Ms)',
                                    // END - twoWayBind-specific config
                                },
                                {
                                    name: 'ForwardErrorMessage',
                                    type: 'ToggleElement',
                                    itemDisposition: 'top-left',
                                    itemFillSpace : 'initial',
                                    //width: 'full',
                                    label: 'Forward Error Message',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.forwardErrorMessage',
                                    // END - twoWayBind-specific config
                                },
                                {
                                    name: 'useIncomingFormattedValue',
                                    type: 'ToggleElement',
                                    itemDisposition: 'top-left',
                                    itemFillSpace : 'initial',
                                    //width: 'full',
                                    label: 'Use Incoming Formatted Value',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.useIncomingFormattedValue',
                                    // END - twoWayBind-specific config
                                },
                                {
                                    name: 'logging',
                                    type: 'ToggleElement',
                                    itemDisposition: 'top-left',
                                    itemFillSpace : 'initial',
                                    //width: 'full',
                                    label: 'Activate logging',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.logging',
                                    // END - twoWayBind-specific config
                                },
                            ]
                        },
                        {
                            name: 'Layout',
                            type: 'DynamicFlexContainer',
                            itemFillSpace : 'auto',
                            width: 'full',
                            layout: 'col',
                            elements: [
                                //aspectRatio //TODO
                                //columns //TODO
                                //objectFit //TODO
                                //objectPosition //TODO
                                {
                                    name: 'Overflow',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.overflow',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Overflow',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: ['auto', 'hidden', 'clip', 'visible', 'scroll', 'x-auto', 'y-auto', 'x-hidden', 'y-hidden', 'x-clip', 'y-clip', 'x-visible', 'y-visible', 'x-scroll', 'y-scroll']
                                    // END - select-specific config
                                },
                                // overscrollBehavior //TODO
                                // position //TODO
                                // top right bottom left //TODO
                                {
                                    name: 'Hidden',
                                    type: 'ToggleElement',
                                    itemDisposition: 'top-left',
                                    itemFillSpace : 'initial',
                                    //width: 'full',
                                    label: 'Hidden',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.hidden',
                                    // END - twoWayBind-specific config
                                },
                                // zIndex //TODO
                            ]
                        },
                        {
                            name: 'FlexboxAndGrid',
                            type: 'DynamicFlexContainer',
                            itemFillSpace : 'auto',
                            width: 'full',
                            layout: 'col',
                            elements: [
                                // flexBasis //TODO
                                {
                                    //flex
                                    name: 'itemFillSpace',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.itemFillSpace',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Item fill Space',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: [1, 'auto', 'none', 'initial']
                                    // END - select-specific config
                                },
                                // flexGrow //TODO
                                // flexShrink //TODO
                                // order //TODO
                                {
                                    name: 'HorizontalAlignment',
                                    type: 'RadioElement',
                                    itemDisposition: 'top-middle',
                                    itemFillSpace : 'initial',
                                    //logging: true,
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.itemDisposition',
                                    // END - twoWayBind-specific config
                                    chainElementConfigs: [
                                        {
                                            order: 0,
                                            type:  'function',
                                            value: 'thisElement._value = msg.value ? msg.value.split("-")[1] : ""; return msg;'
                                        },
                                        {
                                            order: 1,
                                            name: 'HorizontalAlignment_mergeValue',
                                            type: BaseElementConfig.type,
                                            forwardErrorMessage: true,
                                            chainElementConfigs: [{
                                                type: 'function',
                                                value: 'msg.value = (context.elements.MainPage.ElementConfigPanel.BaseElementConfiguration.BaseElementConfigTabs.FlexboxAndGrid.VerticalAlignment.value || "top") + "-" + (msg.value || "left"); return msg;'
                                            }]
                                        },
                                        {
                                            order: 200,
                                            name: 'HorizontalAlignment_splitValue',
                                            type: BaseElementConfig.type,
                                            forwardErrorMessage: true,
                                            chainElementConfigs: [{
                                                type: 'function',
                                                value: 'msg.value = msg.value ? msg.value.split("-")[1] : ""; return msg;'
                                            }]
                                        }
                                    ],
                                    // START - radioButton-specific config
                                    radioType: 'toggleButtonIcon',
                                    radioValues: [
                                        {
                                            icon: 'format_align_left',
                                            value: 'left'
                                        },
                                        {
                                            icon: 'format_align_center',
                                            value: 'middle'
                                        },
                                        {
                                            icon: 'format_align_right',
                                            value: 'right'
                                        }
                                    ],
                                    // END - radioButton-specific config
                                },
                                {
                                    name: 'VerticalAlignment',
                                    type: 'RadioElement',
                                    itemDisposition: 'top-middle',
                                    itemFillSpace : 'initial',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.itemDisposition',
                                    // END - twoWayBind-specific config
                                    chainElementConfigs: [
                                        {
                                            order: 0,
                                            type:  'function',
                                            value: 'thisElement._value = msg.value? msg.value.split("-")[0] : ""; return msg;'
                                        },
                                        {
                                            order: 100,
                                            name: 'VerticalAlignment_mergeValue',
                                            type: BaseElementConfig.type,
                                            forwardErrorMessage: true,
                                            chainElementConfigs: [{
                                                type: 'function',
                                                value: 'msg.value = (msg.value || "top") + "-" + (context.elements.MainPage.ElementConfigPanel.BaseElementConfiguration.BaseElementConfigTabs.FlexboxAndGrid.HorizontalAlignment.value || "left"); return msg;'
                                            }]
                                        },
                                        {
                                            order: 200,
                                            name: 'VerticalAlignment_splitValue',
                                            type: BaseElementConfig.type,
                                            forwardErrorMessage: true,
                                            chainElementConfigs: [{
                                                type: 'function',
                                                value: 'msg.value = msg.value? msg.value.split("-")[0] : ""; return msg;'
                                            }]
                                        }
                                    ],
                                    // START - radioButton-specific config
                                    radioType: 'toggleButtonIcon',
                                    radioValues: [
                                        {
                                            icon: 'vertical_align_top',
                                            value: 'top'
                                        },
                                        {
                                            icon: 'vertical_align_center',
                                            value: 'middle'
                                        },
                                        {
                                            icon: 'vertical_align_bottom',
                                            value: 'bottom'
                                        }
                                    ],
                                    // END - radioButton-specific config
                                },
                            ]
                        },
                        {
                            name: 'Spacing',
                            type: 'DynamicFlexContainer',
                            itemFillSpace : 'auto',
                            width: 'full',
                            layout: 'col',
                            elements: [
                                {
                                    name: 'MarginTop',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.marginTop',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Margin Top',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: [0, 'px', 0.5, 1, 1.5, 2, 2.5, 3, 3.5].concat( new Array(99-4+1).fill(1,0,99).map((_, idx) => (4 + idx)))
                                    // END - select-specific config
                                },
                                {
                                    name: 'MarginBottom',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.marginBottom',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Margin Bottom',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: [0, 'px', 0.5, 1, 1.5, 2, 2.5, 3, 3.5].concat( new Array(99-4+1).fill(1,0,99).map((_, idx) => (4 + idx)))
                                    // END - select-specific config
                                },
                                {
                                    name: 'MarginLeft',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.marginLeft',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Margin Left',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: [0, 'px', 0.5, 1, 1.5, 2, 2.5, 3, 3.5].concat( new Array(99-4+1).fill(1,0,99).map((_, idx) => (4 + idx)))
                                    // END - select-specific config
                                },
                                {
                                    name: 'MarginRight',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.marginRight',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Margin Right',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: [0, 'px', 0.5, 1, 1.5, 2, 2.5, 3, 3.5].concat( new Array(99-4+1).fill(1,0,99).map((_, idx) => (4 + idx)))
                                    // END - select-specific config
                                },
                                {
                                    name: 'PaddingTop',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.paddingTop',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Padding Top',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: [0, 'px', 0.5, 1, 1.5, 2, 2.5, 3, 3.5].concat( new Array(99-4+1).fill(1,0,99).map((_, idx) => (4 + idx)))
                                    // END - select-specific config
                                },
                                {
                                    name: 'PaddingBottom',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.paddingBottom',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Padding Bottom',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: [0, 'px', 0.5, 1, 1.5, 2, 2.5, 3, 3.5].concat( new Array(99-4+1).fill(1,0,99).map((_, idx) => (4 + idx)))
                                    // END - select-specific config
                                },
                                {
                                    name: 'PaddingLeft',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.paddingLeft',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Padding Left',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: [0, 'px', 0.5, 1, 1.5, 2, 2.5, 3, 3.5].concat( new Array(99-4+1).fill(1,0,99).map((_, idx) => (4 + idx)))
                                    // END - select-specific config
                                },
                                {
                                    name: 'PaddingRight',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.paddingRight',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Padding Right',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: [0, 'px', 0.5, 1, 1.5, 2, 2.5, 3, 3.5].concat( new Array(99-4+1).fill(1,0,99).map((_, idx) => (4 + idx)))
                                    // END - select-specific config
                                },
                                // space between //TODO
                            ]
                        },
                        {
                            name: 'Sizing',
                            type: 'DynamicFlexContainer',
                            itemFillSpace : 'auto',
                            width: 'full',
                            layout: 'col',
                            elements: [
                                {
                                    name: 'Height',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.height',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Height',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: ['full', 'screen', 0, 'px', 0.5, 1, 1.5, 2, 2.5, 3, 3.5].concat( new Array(99-4+1).fill(1,0,99).map((_, idx) => (4 + idx)))
                                    // END - select-specific config
                                },
                                {
                                    name: 'MinHeight',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.minHeight',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Min Height',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: [0, 'full', 'min', 'max', 'fit']
                                    // END - select-specific config
                                },
                                {
                                    name: 'MaxHeight',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.maxHeight',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Max Height',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: ['full', 'screen', 'min', 'max', 'fit', 0, 'px', 0.5, 1, 1.5, 2, 2.5, 3, 3.5].concat( new Array(96-4+1).fill(1,0,96).map((_, idx) => (4 + idx)))
                                    // END - select-specific config
                                },
                                {
                                    name: 'Width',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.width',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Width',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: ['auto', 'full', 'screen', 0, 'px', 0.5, 1, 1.5, 2, 2.5, 3, 3.5].concat( new Array(99-4+1).fill(1,0,99).map((_, idx) => (4 + idx)))
                                    // END - select-specific config
                                },
                                {
                                    name: 'MinWidth',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.minWidth',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Min Width',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: [0, 'full', 'min', 'max', 'fit']
                                    // END - select-specific config
                                },
                                {
                                    name: 'MaxWidth',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.maxWidth',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Max Width',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: [0, 'none', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', 'full', 'min', 'max', 'fit', 'prose', 'screen-sm', 'screen-md', 'screen-lg', 'screen-xl', 'screen-2xl'  ]
                                    // END - select-specific config
                                },
                            ]
                        },
                        {
                            name: 'Typografy',
                            type: 'DynamicFlexContainer',
                            itemFillSpace : 'auto',
                            width: 'full',
                            layout: 'col',
                            elements: [
                                {
                                    name: 'FontFamily',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.fontFamily',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Font Family',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: ['sans', 'serif', 'mono']
                                    // END - select-specific config
                                },
                                {
                                    name: 'FontSize',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.fontSize',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Font Size',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: ['xs', 'sm','base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', '8xl', '9xl']
                                    // END - select-specific config
                                },
                                // fontSmoothing //TODO
                                // fontStyle //TODO
                                {
                                    name: 'FontWeight',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.fontWeight',
                                    // END - twoWayBind-specific config
                                    // START - input-specific config
                                    label: 'Font Weight',
                                    // END - input-specific config
                                    // START - select-specific config
                                    values: ['thin', 'extralight','light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black']
                                    // END - select-specific config
                                },
                                {
                                    name: 'LetterSpacing',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.letterSpacing',
                                    label: 'Letter Spacing',
                                    values: ['tighter', 'tight', 'normal', 'wide', 'wider', 'widest']
                                },
                                {
                                    name: 'LineHeight',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.lineHeight',
                                    label: 'Line Height',
                                    values: ['none', 'tight', 'snug', 'normal', 'relaxed', 'loose', 2, 3, 4, 5, 6, 7, 8, 9, 10]
                                },
                                {
                                    name: 'TextColor',
                                    type: 'ColorPickerElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.textColor',
                                    // END - twoWayBind-specific config
                                },
                                {
                                    name: 'TextDecoration',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.textDecoration',
                                    label: 'Text Decoration',
                                    values: ['underline', 'overline', 'line-through', 'no-underline'],
                                },
                                {
                                    name: 'Text Transform',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.textTransform',
                                    label: 'Text Transform',
                                    values: ['uppercase', 'lowercase', 'capitalize', 'normal-case'],
                                },
                                {
                                    name: 'Text Overflow',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.textOverflow',
                                    label: 'Text Overflow',
                                    values: ['truncate', 'text-ellipsis', 'text-clip'],
                                },
                                {
                                    name: 'Text Indent',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.textIndent',
                                    label: 'Text Indent',
                                    values: [0, 'px', 0.5, 1, 1.5, 2, 2.5, 3, 3.5].concat( new Array(96-4+1).fill(1,0,96).map((_, idx) => (4 + idx)))
                                },
                                {
                                    name: 'Whitespace',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.whitespace',
                                    label: 'Whitespace',
                                    values: ['normal', 'nowrap', 'pre', 'pre-line', 'pre-wrap'],
                                },
                                {
                                    name: 'WordBreak',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.wordBreak',
                                    label: 'Word Break',
                                    values: ['normal', 'words', 'all'],
                                }
                            ]
                        },
                        {
                            name: 'Background',
                            type: 'DynamicFlexContainer',
                            itemFillSpace : 'auto',
                            width: 'full',
                            layout: 'col',
                            elements: [
                                {
                                    name: 'BackgroundAttachment',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.backgroundAttachment',
                                    label: 'Background Attachment',
                                    values: ['fixed', 'local','scroll']
                                },
                                {
                                    name: 'BackgroundClip',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.backgroundClip',
                                    label: 'Background Clip',
                                    values: ['border', 'padding', 'content']
                                },
                                {
                                    name: 'BackgroundColor',
                                    type: 'ColorPickerElement',
                                    itemDisposition: 'top-middle',
                                    // START - twoWayBind-specific config
                                    bindTo: 'context.data.clickedElement.config.backgroundColor',
                                    // END - twoWayBind-specific config
                                },
                                {
                                    name: 'BackgroundOrigin',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.backgroundOrigin',
                                    label: 'Background Origin',
                                    values: ['border', 'padding', 'content']
                                },
                                {
                                    name: 'BackgroundPosition',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.backgroundPosition',
                                    label: 'Background Position',
                                    values: ['bottom', 'center', 'left', 'left-bottom', 'left-top', 'right', 'right-bottom', 'right-top', 'top']
                                },
                                {
                                    name: 'BackgroundRepeat',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.backgroundRepeat',
                                    label: 'Background Repeat',
                                    values: ['repeat', 'no-repeat', 'repeat-x', 'repeat-y', 'repeat-round', 'repeat-space']
                                },
                                {
                                    name: 'BackgroundSize',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.backgroundSize',
                                    label: 'Background Size',
                                    values: ['auto', 'cover', 'contain']
                                }
                            ]
                        },
                        {
                            name: 'Borders',
                            type: 'DynamicFlexContainer',
                            itemFillSpace : 'auto',
                            width: 'full',
                            layout: 'col',
                            elements: [
                                {
                                    name: 'BorderRadiusTopLeft',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.borderRadiusTopLeft',
                                    label: 'Border Radius Top Left',
                                    values: ['none', 'sm','normal', 'md', 'lg', 'xl', '2xl', '3xl', 'full']
                                },
                                {
                                    name: 'BorderRadiusTopRight',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.borderRadiusTopRight',
                                    label: 'Border Radius Top Right',
                                    values: ['none', 'sm','normal', 'md', 'lg', 'xl', '2xl', '3xl', 'full']
                                },
                                {
                                    name: 'BorderRadiusBottomLeft',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.borderRadiusBottomLeft',
                                    label: 'Border Radius Bottom Left',
                                    values: ['none', 'sm','normal', 'md', 'lg', 'xl', '2xl', '3xl', 'full']
                                },
                                {
                                    name: 'BorderRadiusBottomRight',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.borderRadiusBottomRight',
                                    label: 'Border Radius Bottom Right',
                                    values: ['none', 'sm','normal', 'md', 'lg', 'xl', '2xl', '3xl', 'full']
                                },
                                {
                                    name: 'BorderWidthTop',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.borderWidthTop',
                                    label: 'Border Width Top Left',
                                    values: [0, 2, 4, 8, 'normal']
                                },
                                {
                                    name: 'BorderWidthRight',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.borderWidthRight',
                                    label: 'Border Width Top Right',
                                    values: [0, 2, 4, 8, 'normal']
                                },
                                {
                                    name: 'BorderWidthLeft',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.borderWidthLeft',
                                    label: 'Border Width Bottom Left',
                                    values: [0, 2, 4, 8, 'normal']
                                },
                                {
                                    name: 'BorderWidthBottom',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.borderWidthBottom',
                                    label: 'Border Width Bottom Right',
                                    values: [0, 2, 4, 8, 'normal']
                                },

                                // borderColorTopLeft?: string;
                                // borderColorTopRight?: string;
                                // borderColorBottomLeft?: string;
                                // borderColorBottomRight?: string;
                                {
                                    name: 'BorderStyle',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.borderStyle',
                                    label: 'Border Style',
                                    values: ['solid', 'dashed', 'dotted', 'double', 'hidden', 'none']
                                },
                                {
                                    name: 'OutlineWidth',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.outlineWidth',
                                    label: 'Outline Width Top Left',
                                    values: [0, 1, 2, 4, 8]
                                },
                                // outlineColor?: string;
                                {
                                    name: 'OutlineStyle',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.outlineStyle',
                                    label: 'Outline Style',
                                    values: ['none', 'normal', 'dashed', 'dotted', 'double', 'hidden']
                                },
                                {
                                    name: 'RingWidth',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.ringWidth',
                                    label: 'Ring Width',
                                    values: [0, 1, 2, 'normal', 4, 8, 'inset']
                                },
                                // ringColor?: string;
                                {
                                    name: 'RingOffsetWidth',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.ringOffsetWidth',
                                    label: 'Ring Offset Width',
                                    values: [0, 1, 2, 4, 8]
                                },
                                // ringOffsetColor?: string;
                            ]
                        },
                        {
                            name: 'Effects',
                            type: 'DynamicFlexContainer',
                            itemFillSpace : 'auto',
                            width: 'full',
                            layout: 'col',
                            elements: [
                                {
                                    name: 'BoxShadow',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.boxShadow',
                                    label: 'Box Shadow',
                                    values: ['sm', 'normal', 'md', 'lg', 'xl', '2xl', 'inner', 'none']
                                },
                                // boxShadowColor?: string;
                                {
                                    name: 'Opacity',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.opacity',
                                    label: 'Opacity',
                                    values: [0, 5, 10, 20, 25, 30, 40, 50, 60, 70, 80, 90, 95, 100]
                                }
                            ]
                        },
                        {
                            name: 'Filters',
                            type: 'DynamicFlexContainer',
                            itemFillSpace : 'auto',
                            width: 'full',
                            layout: 'col',
                            elements: [
                                {
                                    name: 'Blur',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.blur',
                                    label: 'Blur',
                                    values: ['none', 'sm','normal', 'md', 'lg', 'xl', '2xl', '3xl']
                                },
                                {
                                    name: 'Brightness',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.brightness',
                                    label: 'Brightness',
                                    values: [0, 50, 75, 90, 95, 100, 105, 110, 125, 150, 200]
                                },
                                {
                                    name: 'Contrast',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.contrast',
                                    label: 'Contrast',
                                    values: [0, 50, 75, 100, 125, 150, 200]
                                },
                                {
                                    name: 'DropShadow',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.dropShadow',
                                    label: 'Drop Shadow',
                                    values: ['sm','normal', 'md', 'lg', 'xl', '2xl', 'none']
                                },
                                {
                                    name: 'HueRotate',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.hueRotate',
                                    label: 'Hue Rotate',
                                    values: [0, 15, 30, 60, 90, 100]
                                },
                                {
                                    name: 'invert',
                                    type: 'ToggleElement',
                                    itemDisposition: 'top-left',
                                    itemFillSpace : 'initial',
                                    bindTo: 'context.data.clickedElement.config.invert',
                                    label: 'Invert',
                                },
                                {
                                    name: 'Saturate',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.saturate',
                                    label: 'Saturate',
                                    values: [0, 50, 100, 150, 200]
                                },
                                {
                                    name: 'Sepia',
                                    type: 'ToggleElement',
                                    itemDisposition: 'top-left',
                                    itemFillSpace : 'initial',
                                    bindTo: 'context.data.clickedElement.config.sepia',
                                    label: 'Sepia',
                                },
                                {
                                    name: 'BackdropBlur',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.backdropBlur',
                                    label: 'Backdrop Blur',
                                    values: ['none', 'sm','normal', 'md', 'lg', 'xl', '2xl', '3xl']
                                },
                                {
                                    name: 'BackdropBrightness',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.backdropBrightness',
                                    label: 'Backdrop Brightness',
                                    values: [0, 50, 75, 90, 95, 100, 105, 110, 125, 150, 200]
                                },
                                {
                                    name: 'BackdropContrast',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.backdropContrast',
                                    label: 'Backdrop Contrast',
                                    values: [0, 50, 75, 100, 125, 150, 200]
                                },
                                {
                                    name: 'BackdropHueRotate',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.backdropHueRotate',
                                    label: 'Backdrop Hue Rotate',
                                    values: [0, 15, 30, 60, 90, 100]
                                },
                                {
                                    name: 'BackdropInvert',
                                    type: 'ToggleElement',
                                    itemDisposition: 'top-left',
                                    itemFillSpace : 'initial',
                                    bindTo: 'context.data.clickedElement.config.backdropInvert',
                                    label: 'Backdrop Invert',
                                },
                                {
                                    name: 'BackdropSaturate',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.backdropSaturate',
                                    label: 'Backdrop Saturate',
                                    values: [0, 50, 100, 150, 200]
                                },
                                {
                                    name: 'BackdropSepia',
                                    type: 'ToggleElement',
                                    itemDisposition: 'top-left',
                                    itemFillSpace : 'initial',
                                    bindTo: 'context.data.clickedElement.config.backdropSepia',
                                    label: 'Backdrop Sepia',
                                },
                                {
                                    name: 'BackdropOpacity',
                                    type: 'SelectElement',
                                    itemDisposition: 'top-middle',
                                    bindTo: 'context.data.clickedElement.config.backdropOpacity',
                                    label: 'Backdrop Opacity',
                                    values: [0, 5, 10, 20, 25, 30, 40, 50, 60, 70, 80, 90, 95, 100]
                                }
                            ]
                        },
                        {
                            name: 'LogList',
                            type: 'ListElement',
                            itemDisposition: 'top-middle',
                            itemFillSpace : 'auto',
                            width: 'full',
                            layout: 'col',
                            appendElements: true,
                            elements: {
                                name: 'internalItemGroupConfig',
                                type: 'DynamicFlexContainer',
                                itemFillSpace : 'initial',
                                elements: [{
                                    name: 'timestamp',
                                    type: BaseElementConfig.type,
                                    // doAction: '${msg.value.timestamp}'
                                }]
                            },
                            // doAction:[
                            //     {
                            //         type: 'function',
                            //         value: 'return context.data.clickedElement.logHistoryObservable'
                            //     },
                            //     {
                            //         type: 'function',
                            //         value: 'return { value : msg };'
                            //     }
                            // ]
                        }
                    ]
                }
            ]
        }
    ];
}
