import {Field} from '../field';
import {FieldConfig} from '../../domain/templates-config';
import {FieldType} from '../field-type';
import {CollectionType} from '../collection-type';

export class ReferenceOrganizationField extends Field {

    public constructor(
        name: string,
        subFields: Field[],
        systemSubFields: Field[],
        fieldConfig: FieldConfig,
        modelType: string
    ) {
        super(name, subFields, systemSubFields, FieldType.referenceOrganization, CollectionType.NONE, fieldConfig, modelType);
    }

    public static ofReferenceOrganization(
        name: string,
        subFields: Field[],
        systemSubFields: Field[],
        fieldConfig: FieldConfig,
        modelType: string
    ): Field {
        return new ReferenceOrganizationField(name, subFields, systemSubFields, fieldConfig, modelType);
    }

    public static ofConfig(config: {
                               [key: string]: any;
                               name: string;
                               subFields?: Field[];
                               systemSubFields?: Field[];
                               modelType?: string;
                               fieldConfig?: FieldConfig;
                           }
    ): Field {
        return new ReferenceOrganizationField(config.name, config.subFields, config.systemSubFields, config.fieldConfig, config.modelType);
    }

    public getI18nCode(): string[] {
        return ['common', 'fields'].concat(this._pathName);
    }

    public populateAssembleAs(assembleAs: any, subFieldsAssembly?: any): void {
        assembleAs[this.name] = {
            organizationId: true,
            name: true
        };
    }
}
