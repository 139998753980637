import { BehaviorSubject, Observable, tap, map, of, take, switchMap, filter } from "rxjs";
import { Appointment } from "../modules/clients/client.types";
import { GruulsAngularHttpProxyService } from "@gruuls-fe/services/gruuls-angular-http-proxy.service";
import { ApiCaller } from "../utils/apiCaller";
import { GruulsAuthService } from "@gruuls-fe/services/gruuls-auth.service";

export class AppointmentsService {
  // decided to duplicate this as an Angular service in order to use it as an Injectable

  _appointments$: BehaviorSubject<Appointment[]> = new BehaviorSubject<Appointment[]>([]);
  _clientAppointments$: BehaviorSubject<Appointment[]> = new BehaviorSubject<Appointment[]>([]);
  updates: BehaviorSubject<Appointment[]> = new BehaviorSubject<Appointment[]>([]);

  private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
  private readonly _updateDelta = 15 * 60 * 1000;
  private _loadedStartRage: number = undefined;
  private _loadedEndRage: number = undefined;
  private _lastAppointmentsUpdate: number = null;

  constructor(
    private _httpClient: GruulsAngularHttpProxyService,
    private _authService: GruulsAuthService
  ) {
    // this.updates.next(this.appointments);
  }

  get appointments$(): Observable<Appointment[]> {
    return this._appointments$.asObservable();
  }

  get clientAppointments$(): Observable<Appointment[]> {
    return this._clientAppointments$.asObservable();
  }

  getAppointments(start: number = null, end: number = null): Observable<Appointment[]> {
    let filters = [];
    if (start)
      filters.push({ field: 'date', operator: 'gt', value: start });
    if (end)
      filters.push({ field: 'date', operator: 'lt', value: end });
    filters.push({field: '_referenceOrganizationId', operator: 'match_phrase', value: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId});

    return this._apiCaller.getAppointments(null, filters).pipe(
      tap((appointments) => {
        this._lastAppointmentsUpdate = Date.now();
        this._loadedStartRage = start;
        this._loadedEndRage = end;
        this._appointments$.next(appointments);
      })
    );
  }

  refreshAppointments(start: number = null, end: number = null): Observable<Appointment[]> {
    const now = Date.now();

    if (this._lastAppointmentsUpdate == null || (now - this._lastAppointmentsUpdate) > this._updateDelta) {
      this._lastAppointmentsUpdate = Date.now();
      return this.getAppointments(start, end);
    } else if (start && end && ((this._loadedStartRage !== null && start < this._loadedStartRage) || (this._loadedEndRage!==null && end > this._loadedEndRage))) {
      const newStart = start < this._loadedStartRage ? start : this._loadedStartRage;
      const newEnd = end > this._loadedEndRage ? end : this._loadedEndRage;
      return this.getAppointments(newStart, newEnd);
    } else {
      return of(this._appointments$.getValue());
    }
  }

  /**
   * Get appointments
   */
  getClientAppointments(clientId: string): Observable<Appointment[]> {
    if (!clientId)
      return of([]);
    return this.refreshAppointments().pipe(
      map((appointments: Appointment[]) => appointments.filter((appointment: Appointment) => (appointment.client && appointment.client.personId === clientId))),
      // return this._apiCaller.getAppointments(null, { client: clientId, _referenceOrganizationId: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId }).pipe(
      tap((appointments: Appointment[]) => {
        this._clientAppointments$.next(appointments);
      })
    );
  }

  /**
   * Update appointment
   */
  updateAppointment(appointment: Appointment): Observable<Appointment> {
    return this.appointments$.pipe(
      take(1),
      switchMap(appointments => this._apiCaller.updateAppointment(appointment).pipe(
        map((updatedAppointment: Appointment) => {

          // Find the index of the updated contact
          const index = appointments.findIndex(item => item.appointmentId === appointment.appointmentId);

          // Update the contact
          appointments[index] = updatedAppointment;

          // Update the contacts
          this._appointments$.next(appointments);
          // this._appointments.next(appointments);

          // Return the updated contact
          return updatedAppointment;
        }),
        switchMap(updatedAppointment => this.clientAppointments$.pipe(
          take(1),
          map((clientAppointments: Appointment[]) => {
            // Find the index of the updated contact
            const index = clientAppointments.findIndex(item => item.appointmentId === appointment.appointmentId);

            // Update the contact
            clientAppointments[index] = updatedAppointment;

            // Update the contacts
            this._clientAppointments$.next(clientAppointments);

            // Return the updated contact
            return updatedAppointment;
          }))
        ))
      )
    );
  }

  /**
   * Create appointment
   */
  createAppointment(appointment: Appointment): Observable<Appointment> {
    // return this._apiCaller.createAppointment(appointment);
    return this.appointments$.pipe(
      take(1),
      switchMap(appointments => this._apiCaller.createAppointment(appointment).pipe(
        map((newAppointment) => {

          // Update the products with the new product
          this._appointments$.next([newAppointment, ...appointments]);

          // Return the new product
          return newAppointment;
        }),
        switchMap(newAppointment => this.clientAppointments$.pipe(
          take(1),
          map((clientAppointments: Appointment[]) => {
            // Update the products with the new product
            this._clientAppointments$.next([newAppointment, ...clientAppointments]);

            // Return the new product
            return newAppointment;
          }))
        ))
      )
    );
  }

  /**
   * Delete appointment
   * 
   * @param uuid
   * 
   */
  deleteAppointment(uuid: string): Observable<boolean> {
    return this.appointments$.pipe(
      take(1),
      switchMap(appointments => this._apiCaller.deleteAppointment(uuid).pipe(
        map((isDeleted: boolean) => {
          // Find the index of the deleted contact
          if (isDeleted) {
            const index = appointments.findIndex(item => item.appointmentId === uuid);
            appointments.splice(index, 1);
            this._appointments$.next(appointments);
          }
          return isDeleted;
        }),
        switchMap(isDeleted => this.clientAppointments$.pipe(
          take(1),
          map((clientAppointments: Appointment[]) => {
            if (isDeleted) {
              const index = clientAppointments.findIndex(item => item.appointmentId === uuid);
              clientAppointments.splice(index, 1);
              this._clientAppointments$.next(clientAppointments);
            }
            return isDeleted;
          }))
        ))
      )
    );
  }

}