import {HttpClient, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, skip} from 'rxjs';
import {GruulsMockDomain, GruulsMockHttpRequest} from './gruuls-mock-domain';
import {map} from "rxjs/operators";

export class GruulsDomainBypassMockDomain extends GruulsMockDomain {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public static QUERY_NOT_FOUND = (data: GruulsMockHttpRequest): ([number, string | any]) | Observable<any> => {
        console.warn('Mock API called. DomainNotFound error: ' + data.request.body);
        return [
            500,
            {
                message: 'Query not found',
                data: data.request.body
            }
        ];
    };

    executeQuery(data: { request: HttpRequest<any>; urlParams: { [p: string]: string } }): [number, any] | Observable<any> {
        return this.httpClient.request(
            data.request.clone({
                headers: data.request.headers.set('skip', 'true')
            }))
            .pipe(
                skip(1),
                map((res: HttpResponse<any>) => {
                    const t = this;
                    return [
                        res.status,
                        res.body
                    ];
                })
            );
    }

}
