import {GruulsMockDomain} from './gruuls-mock-domain';
import {GruulsDomainNotFoundMockDomain} from './gruuls-domain-not-found-mock-domain';
import {GruulsDomainBypassMockDomain} from "./gruuls-domain-bypass-mock-domain";
import {HttpClient} from "@angular/common/http";

export class GruulsMockDomainsCatalog {

    private static domains: {
        [fqdn: string]: GruulsMockDomain;
    } = {};

    public static getDomain(fqdn: string, httpClient: HttpClient): GruulsMockDomain{
        const d: GruulsMockDomain = GruulsMockDomainsCatalog.domains[fqdn];
        //return d ? d : new GruulsDomainNotFoundMockDomain();
        return d ? d : new GruulsDomainBypassMockDomain(httpClient);
    }

    public static addDomain(fqdn: string, d:  GruulsMockDomain): void{
        GruulsMockDomainsCatalog.domains[fqdn] = d;
    }
}
