import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, NgForm, Validators } from '@angular/forms';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { GruulsConstants } from '../../../../mock-api/gruuls/gruuls-constants';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import { MedicalHistory, Person } from '../client.types';
import moment_ from 'moment';
import { EMPTY, Observable, Subject, catchError, debounceTime, distinctUntilChanged, map, of, switchMap, takeUntil, tap } from 'rxjs';
// import * as am5 from '@amcharts/amcharts5';
// import * as am5xy from '@amcharts/amcharts5/xy';
// import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { saveAs } from 'file-saver';
import { BeautyciansUtils } from 'app/beautycians/utils/utils';
import { filter, isArray, isObject } from 'lodash';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { GruulsAngularAppointmentsService } from '@gruuls-fe/services/gruuls-angular-appointments-service';
import _ from 'lodash';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FULL_ANAMNESI_ASSEMBLE, FULL_ANAMNESI_ASSEMBLE_NO_INDEXES } from 'app/beautycians/utils/assemble';
import { ProductSelectionDialogComponent } from './product-selection-dialog/product-selection-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfigService } from '@fuse/services/config';

@Component({
  selector: 'medicalhistory',
  templateUrl: './anamnesi.component.html',
  styleUrls: ['./anamnesi.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicalHistoryComponent implements OnInit, OnDestroy {

  @Input('client') client: Person;
  @Output() isLoadingEvent = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<any>();

  @ViewChild('anamnesiForm') anamnesiForm: NgForm;
  @ViewChild('snackbarErrorTemplate') snackbarErrorTemplate: TemplateRef<any>;

  SNACK_BAR_OPTIONS: MatSnackBarConfig = {
    duration: 2500,
    horizontalPosition: "left",
    verticalPosition: "bottom"
  }

  BIOLOGICAL_AGE_FACTOR_MIN: number = 0.7;
  BIOLOGICAL_AGE_FACTOR_MAX: number = 1.45;
  ANSWERS_RATIO_THRESHOLD = 0.65;
  medicalHistories: any[] = [];
  selectedMedicalHistory: any;
  currentMedicalHistory: any;
  translateStrings: any = { medicalHistory: {}, generic: {}, survey: {} };
  isEditing: boolean = false;
  isPartialLoading: boolean[] = [];
  isSpecificLoading: boolean[] = [];
  isSaving: boolean = false;
  isLoadingError: boolean = false;
  newSurvey: any = [];
  private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);
  private numberOfQuestions: number = 0;
  anamnesiFormGroup: any;
  private _jsonQuestions: string[] = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();


  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _formBuilder: FormBuilder,
    private _fuseConfigService: FuseConfigService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _httpClient: GruulsAngularHttpProxyService,
    private _translate: GruulsAngularTranslateService,
    private _snackBar: MatSnackBar,
    private _authService: GruulsAuthService,
    private _appointmentsService: GruulsAngularAppointmentsService,
    public dialog: MatDialog
  ) {
  }

  @HostBinding('class') get classList(): any {
    return {
      'w-full': true
    };
  }

  // @ViewChild('chartsBox', {static: false}) set content(content: ElementRef) {
  //    if(content) { // initially setter gets called with undefined
  //        this.handleMedicalHistoryChange();
  //    }
  // }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.setLoadingState('empty', true);
    this.setLoadingState('allAnamnesi', true);
    moment_.locale('it');

    this.compileEmptySurvey().subscribe({
      next: (res) => {
        this._jsonQuestions = this.newSurvey.filter((e) => e.type === 'JSON').map((e) => e.key);
        this.setLoadingState('empty', false);
      },
      error: (err) => {
        this.isLoadingError = true;
        this.setLoadingState('empty', false);
        console.error(err);
      }
    });

    const medicalHistoryTranslations = ['singular', 'plural', 'errorWhileSaving', 'errorWhileGeneratingReport', 'remoteConnectionError', 'INDEX_GENERAL_WELLNESS', 'INDEX_PHYSIOLOGICAL_WELLNESS', 'INDEX_PSYCHOPHYSICAL_WELLNESS', 'INDEX_DERMOESTHETIC_WELLNESS', 'INDEX_uC', 'INDEX_ReD', 'INDEX_AB', 'BIOLOGICAL_AGE', 'lowAnswersRatioThreshold', 'noBirthdateDefined', 'answersRatio', 'deleted', 'confirmPDFReportGeneration', 'generate', 'autoSaveOK', 'cantSave'];
    medicalHistoryTranslations.forEach((translation) => {
      this.translateStrings['medicalHistory'][translation] = this._translate.translate('medicalHistory.' + translation);
    });

    const genericTranslations = ['send', 'close', 'cancel', 'save', 'edit', 'error', 'warn', 'download', 'view', 'viewReport', 'clone', 'clone of', 'reset', 'saveAndClose', 'validatorLong', 'validatorDouble', 'yes', 'no', 'delete', 'generated', 'at', 'error503'];
    genericTranslations.forEach((translation) => {
      this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
    });

  }

  isLoading(): boolean {
    const state = Object.values(this.isPartialLoading).reduce((acc, curr) => acc || curr, false);
    return state;
  }

  setLoadingState(key: string, loading: boolean): void {
    this.isPartialLoading[key] = loading;
    this.isLoadingEvent.emit(this.isLoading());
    this._changeDetectorRef.markForCheck();
  }

  ngOnChanges(): void {
    if (this.client && 'personId' in this.client) {
      this.getAllAnamnesi(this.client.personId).subscribe({
        next: (res) => {
          if (this.medicalHistories.length > 0) {
            this.selectedMedicalHistory = this.medicalHistories[0];
            this.onChange.emit(this.selectedMedicalHistory);
            this.handleMedicalHistoryChange({ value: this.selectedMedicalHistory });
          } else {
            this.isEditing = true;
          }
          this._changeDetectorRef.markForCheck();
        },
        error: (err) => {
          this._fuseConfirmationService.open(
            BeautyciansUtils.getErrorDialogConfig(this.translateStrings['medicalHistory']['remoteConnectionError'])
          );
          console.error(err);
          this.isEditing = true;
          this.isLoadingError = true;
          this._changeDetectorRef.markForCheck();
        }
      });
    }
  }

  onCancel(): void {
    this.isEditing = false;
    this.handleMedicalHistoryChange({ value: this.selectedMedicalHistory });
    this._changeDetectorRef.markForCheck();
  }

  handleMedicalHistoryChange($event = null): void {
    // this.createIndexChart(this.selectedMedicalHistory.originalSurveyObject.INDEX_PHYSIOLOGICAL_WELLNESS_RATIO, "plwi", this.selectedMedicalHistory.id);
    // this.createIndexChart(this.selectedMedicalHistory.originalSurveyObject.INDEX_PSYCHOPHYSICAL_WELLNESS_RATIO, "ppwi", this.selectedMedicalHistory.id);
    // this.createIndexChart(this.selectedMedicalHistory.originalSurveyObject.INDEX_DERMOESTHETIC_WELLNESS_RATIO, "dewi", this.selectedMedicalHistory.id);
    // this.createIndexChart(this.selectedMedicalHistory.originalSurveyObject.INDEX_uC_RATIO, "uC", this.selectedMedicalHistory.id);
    // this.createIndexChart(this.selectedMedicalHistory.originalSurveyObject.INDEX_ReD_RATIO, "ReD", this.selectedMedicalHistory.id);
    // this.createIndexChart(this.selectedMedicalHistory.originalSurveyObject.INDEX_AB_RATIO, "AB", this.selectedMedicalHistory.id);
    if ($event && 'value' in $event) {
      this.currentMedicalHistory = this.parseMedicalHistory(_.cloneDeep($event.value));
      if (!('INDEX_PHYSIOLOGICAL_WELLNESS' in this.currentMedicalHistory.originalSurveyObject)) {
        this.setLoadingState('indexes', true);
        this._apiCaller.getAnamnesi({ clientId: this.client.personId, id: this.currentMedicalHistory.id }).pipe(
          map((res) => {
            return res[0];
          })
        ).subscribe({
          next: (res) => {
            this.medicalHistories[this.medicalHistories.findIndex((elem) => elem.MEDICAL_HISTORY_ID == res.MEDICAL_HISTORY_ID)] = res;
            this.selectedMedicalHistory = res;
            this.currentMedicalHistory = this.parseMedicalHistory(_.cloneDeep(res));
            this.setLoadingState('indexes', false);
            this._changeDetectorRef.markForCheck();
          },
          error: (err) => {
            this.setLoadingState('indexes', false);
            console.error(err);
          }
        });
      }
      this.isEditing = false;
      this._changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  trackByFn(index: number, item: any): any {
    return item.uuid || index;
  }

  private scrollToFirstInvalidControl(formName: string) {
    let form = document.getElementById(formName); // <-- your formID
    let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    firstInvalidControl.scrollIntoView();
    (firstInvalidControl as HTMLElement).focus();
  }

  submitAnamnesi($event): void {
    if (this.anamnesiFormGroup.invalid) {
      this.anamnesiFormGroup.markAllAsTouched();
      this.scrollToFirstInvalidControl('anamnesiForm');
      return;
    }

    this.isSaving = true;
    this.anamnesiFormGroup.disable();
    this.isSpecificLoading[$event.submitter.id] = true;
    this._changeDetectorRef.markForCheck();
    this.saveAnamnesi(false).subscribe({
      next: (res) => {
        this.isSaving = false;
        this.anamnesiFormGroup.enable();

        if ($event.submitter.id === "saveAndClose") {
          this.isEditing = false;
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
        if (this.medicalHistories[0].MEDICAL_HISTORY_ID === this.selectedMedicalHistory.MEDICAL_HISTORY_ID)
          this.onChange.emit(this.medicalHistories[0]);
        this.isSpecificLoading[$event.submitter.id] = false;
        this._changeDetectorRef.markForCheck();
      },
      error: (err) => {
        this.isSaving = false;
        this.anamnesiFormGroup.enable();
        this.isSpecificLoading[$event.submitter.id] = false;
        console.error(err);
        this._fuseConfirmationService.open(
          BeautyciansUtils.getErrorDialogConfig(this.translateStrings['medicalHistory']['errorWhileSaving'] + ": " + err.error, this.translateStrings['generic']['error'], this.translateStrings['generic']['close'])
        );
        this._changeDetectorRef.markForCheck();
      }
    });
  }

  saveAnamnesi(isAutoSave: boolean = false): Observable<any> {
    if (isAutoSave && (this.anamnesiFormGroup.disabled || !this.isEditing || !this.anamnesiFormGroup.dirty))
      // TODO: this is not problable the correct way to call it
      return EMPTY;
    if (this.anamnesiFormGroup.invalid) {
      this.anamnesiFormGroup.markAllAsTouched();
      return EMPTY;
    }

    const answerAggregate = {};

    for (const [question, answer] of Object.entries(this.anamnesiFormGroup.value)) {
      if (answer !== undefined) {
        const questionType = this.newSurvey.find(e => e.key == question).type;
        if (questionType === 'JSON' && answer !== null && answer !== "") {
          // TODO: improve with native array mgmt on BE
          answerAggregate[question] = { 'value': answer };
        } else {
          answerAggregate[question] = (answer === "") ? null : answer;
        }
      }
    }

    if (isAutoSave && Object.keys(answerAggregate).length < 5)
      return of();

    this.anamnesiFormGroup.markAsPristine();
    return this._apiCaller.saveAnamnesi(answerAggregate, this.client.personId, this.selectedMedicalHistory?.MEDICAL_HISTORY_ID, isAutoSave ? FULL_ANAMNESI_ASSEMBLE_NO_INDEXES : FULL_ANAMNESI_ASSEMBLE).pipe(
      tap((res) => {
        // Update DropDown
        const modifiedElementIndex = this.medicalHistories.findIndex((elem) => elem.MEDICAL_HISTORY_ID == res.MEDICAL_HISTORY_ID);
        if (modifiedElementIndex > -1) {
          this.medicalHistories[modifiedElementIndex] = res;
        } else {
          this.medicalHistories.unshift(res);
        }
        this.selectedMedicalHistory = res;

        if (isAutoSave) {
          // this.handleMedicalHistoryChange({ value: res }); // TODO: is necessary ?
          this._snackBar.open(this.translateStrings['medicalHistory']['autoSaveOK'], null, {
            duration: 1500,
            horizontalPosition: "left",
            verticalPosition: "bottom"
          });
        }
        this.currentMedicalHistory = this.parseMedicalHistory(_.cloneDeep(res));
      })
    )
  }

  compileEmptySurvey(): Observable<any> {
    return this.compileSurvey()
  }

  // compileSurveyForEdit(): Observable<any> {
  //   return this.compileSurvey(this.currentMedicalHistory)
  // }

  newAnamnesi(): void {
    this.selectedMedicalHistory = undefined;
    this.isEditing = true;
    this.anamnesiFormGroup.reset();
    this._changeDetectorRef.markForCheck();
  }

  cloneAnamnesi(): void {
    if (this.selectedMedicalHistory === undefined)
      return;
    this.editAnamnesi();
    this.anamnesiFormGroup.controls['REFERENCE_NAME'].setValue(this.translateStrings['generic']['clone of'] + this.selectedMedicalHistory.REFERENCE_NAME);
    this.selectedMedicalHistory = undefined;
  }

  compileSurvey(survey: any = null): Observable<any> {
    return (this.newSurvey.length > 0 ? of(this.newSurvey) : this._apiCaller.getEmptySurvey()).pipe(
      takeUntil(this._unsubscribeAll),
      map((res) => {
        // This define the survey structure
        // const excludQuestions = [ ];
        // this.newSurvey = res.hits[0]?.hits.filter((el) => !excludQuestions.includes(el.key));
        this.newSurvey = res;

        this.numberOfQuestions = this.newSurvey.filter(
          (e) => e.key.toLowerCase() !== 'reference' &&
            !e.key.toLowerCase().endsWith('_note') &&
            !e.key.toLowerCase().endsWith('_height') &&
            !e.key.toLowerCase().endsWith('_description')).length;

        const group = {};
        for (const formControl of res) {
          let validator = ['', []];
          switch (formControl.type.toLowerCase()) {
            case 'long': {
              validator = ['', [Validators.pattern('[0-9]*')]]; break;
            }
            case 'double': {
              validator = ['', [Validators.pattern('^[0-9]+(\\.[0-9]*)?$')]]; break;
            }
          }
          group[formControl.key] = validator;

          if (formControl.key in this.surveyAddon) {
            group[formControl.key][1].push(...this.surveyAddon[formControl.key].validators);
          }
        }

        this.anamnesiFormGroup = this._fb.group(group);
        if (this.client?.sex) {
          this.anamnesiFormGroup.controls['GENDER'].setValue(this.client.sex);
        }
        this._changeDetectorRef.markForCheck();

        this.anamnesiFormGroup.valueChanges.pipe(
          takeUntil(this._unsubscribeAll),
          debounceTime(3000),
          distinctUntilChanged(),
          switchMap(() => this.saveAnamnesi(true).pipe(catchError((error) => {
            // console.log('Error while saving is: ' + error.message);
            this._snackBar.openFromTemplate(this.snackbarErrorTemplate, this.SNACK_BAR_OPTIONS);
            return EMPTY;
          }))
          )).
          subscribe({
            next: (res) => {
              this._changeDetectorRef.markForCheck();
            },
          });
      })
    );
  }

  fillSurvey(survey: any): void {
    this.anamnesiFormGroup.reset();

    this.anamnesiFormGroup.controls['REFERENCE_NAME'].setValue(survey.reference);

    for (const surveyResponse of survey.answers) {

      this.anamnesiFormGroup.controls[surveyResponse.question_code].setValue(surveyResponse.answer_code);
      // Retro-compatibility
      if (surveyResponse.question_code == 'IBD_senz_gonf_add') {
        if (surveyResponse.answer_code === 'true')
          this.anamnesiFormGroup.controls[surveyResponse.question_code].setValue('ALWAYS');
        if (surveyResponse.answer_code === 'false')
          this.anamnesiFormGroup.controls[surveyResponse.question_code].setValue('NO');
      }
      if (surveyResponse.question_code == 'IBD_senz_pesant_art_inf') {
        if (surveyResponse.answer_code === 'true')
          this.anamnesiFormGroup.controls[surveyResponse.question_code].setValue('MORNING');
        if (surveyResponse.answer_code === 'false')
          this.anamnesiFormGroup.controls[surveyResponse.question_code].setValue('NO');
      }
    }
  }

  editAnamnesi(): void {
    if (this.selectedMedicalHistory === undefined)
      return;
    this.fillSurvey(this.currentMedicalHistory);
    this.isEditing = true;
    this._changeDetectorRef.markForCheck();
  }

  surveyAddon: any = {
    'IBP_figli': {
      validators: [this.generateRelatedTrueValidator(this, 'IBP_figli_num')]
    },
    'IBP_lavora': {
      validators: [this.generateRelatedTrueValidator(this, 'IBP_sodd_lavoro'), this.generateRelatedTrueValidator(this, 'IBP_tipo_lavoro_hours'), this.generateRelatedTrueValidator(this, 'IBP_tipo_lavoro'), this.generateRelatedTrueValidator(this, 'IBP_tipo_lavoro_Description')]
    },
    'IBP_tipo_lavoro': {
      validators: [this.generateRelatedTrueValidator(this, 'IBP_tipo_lavoro_Description')]
    },
    'IBP_att_fisica': {
      validators: [this.generateRelatedTrueValidator(this, 'IBP_att_fisica_hours'), this.generateRelatedTrueValidator(this, 'IBP_att_fisica_description')]
    },
    'IBP_hobby': {
      validators: [this.generateRelatedTrueValidator(this, 'IBP_hobby_Description')]
    },
    'IBP_sonno_farmaci': {
      validators: [this.generateRelatedTrueValidator(this, 'IBP_sonno_farmaci_note')]
    },
    'IBP_sonno_integratori': {
      validators: [this.generateRelatedTrueValidator(this, 'IBP_sonno_integratori_note')]
    },
    'IBP_sonno_disturbi': {
      validators: [this.generateRelatedTrueValidator(this, 'IBP_sonno_disturbi_note', 'OTHER')]
    },
    'IBD_presez_impur': {
      validators: [this.generateRelatedTrueValidator(this, 'IBD_presez_impur_note')]
    },
    'IBD_idratz': {
      validators: [this.generateRelatedTrueValidator(this, 'IBD_idratz_note')]
    },
    'IBD_elast': {
      validators: [this.generateRelatedTrueValidator(this, 'IBD_elast_note')]
    },
    'IBD_presz_crom': {
      validators: [this.generateRelatedTrueValidator(this, 'IBD_presz_crom_note')]
    },
    'IBF_fumo': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_fumo_Description')]
    },
    'IBF_alcolici': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_alcolici_Description')]
    },
    'IBF_fianchi': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_fianchi_height')]
    },
    'IBF_coscia': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_coscia_height')]
    },
    'IBF_ginocchio': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_ginocchio_height')]
    },
    'IBF_polpaccio': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_polpaccio_height')]
    },
    'IBF_caviglia': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_caviglia_height')]
    },
    'IBF_braccio': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_braccio_height')]
    },
    'IBF_circ_vita': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_circ_vita_height')]
    },
    'IBF_farma': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_farma_note')]
    },
    'IBF_allg_farm': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_allg_farm_Description')]
    },
    'IBF_patologie_attuali': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_patologie_attuali_note')]
    },
    'IBF_patologie_passate': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_patologie_passate_note')]
    },
    'IBF_allg_poll': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_allg_poll_Description')]
    },
    'IBF_allg_altre': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_allg_altre_Description')]
    },
    'IBF_allg_alimen': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_allg_alimen_Description')]
    },
    'IBF_allg_alimen_Description': {
      validators: []
    },
    'IBF_allg_metal': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_allg_metal_Description')]
    },
    'IBF_cibi_malessere': {
      validators: [this.generateRelatedTrueValidator(this, 'IBF_cibi_malessere_Description')]
    },
    'IBD_rughe': {
      validators: [this.generateRelatedTrueValidator(this, 'IBD_localiz_rughe'), this.generateRelatedTrueValidator(this, 'IBD_prof_rughe')]
    },
    'IBD_presenz_pann_adp': {
      validators: [this.generateRelatedTrueValidator(this, 'IBD_presenz_pann_adp_note')]
    },
    'IBD_presenz_edema_artSX': {
      validators: [this.generateRelatedTrueValidator(this, 'IBD_presenz_edema_note')]
    },
    'IBD_presenz_cellu_loc': {
      validators: [this.generateRelatedTrueValidator(this, 'IBD_presenz_cellu_loc_note'), this.generateRelatedTrueValidator(this, 'IBD_presenz_cellu_loc_tipologia')]
    },
  };

  generateRelatedTrueValidator(thisObject: this, relatedField: string, value: string = null) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value && (!value || control.value === value)) {
        if (relatedField in thisObject.anamnesiFormGroup.controls)
          thisObject.anamnesiFormGroup.get(relatedField).enable();
        else
          console.log('ERROR: ' + relatedField + ' not found in anamnesiFormGroup')
      } else if (thisObject.anamnesiFormGroup && thisObject.anamnesiFormGroup.get(relatedField)) {
        switch (thisObject.newSurvey[relatedField]) {
          case 'ENUM': {
            thisObject.anamnesiFormGroup.get(relatedField).setValue(0);
            break;
          }
          case 'STRING': {
            thisObject.anamnesiFormGroup.get(relatedField).setValue('');
            break;
          }
          case 'JSON': {
            thisObject.anamnesiFormGroup.get(relatedField).setValue([]);
            break;
          }
          default: {
            thisObject.anamnesiFormGroup.get(relatedField).setValue(null);
            break;
          }
        }
        thisObject.anamnesiFormGroup.get(relatedField).setValue('');
        thisObject.anamnesiFormGroup.get(relatedField).disable();
      }

      return null;
    };
  }

  getAllAnamnesi(clientId: string): Observable<any> {
    this.setLoadingState('allAnamnesi', true);

    return this._apiCaller.getAnamnesi({ clientId: clientId }).pipe(
      takeUntil(this._unsubscribeAll),
      map((anamnesi) => {
        // this.medicalHistories = anamnesi.map((medicalHistory) => this.parseMedicalHistory(medicalHistory));
        this.medicalHistories = anamnesi;
        this.setLoadingState('allAnamnesi', false);
      }),
      catchError((err) => {
        this.isLoadingError = true;
        console.error(err);
        this.setLoadingState('allAnamnesi', false);
        return of();
      })
    );
  }

  parseMedicalHistory(medicalHistory: any) {
    const printableSurvey = {
      id: medicalHistory.MEDICAL_HISTORY_ID,
      // client: medicalHistory.CLIENT.personId,
      creation: medicalHistory._creationTime ? moment_(medicalHistory._creationTime).format('DD-MM-YYYY') : undefined,
      creationDate: medicalHistory._creationTime ? moment_(medicalHistory._creationTime).format('dddd DD MMMM YYYY') : undefined,
      creationTime: medicalHistory._creationTime ? moment_(medicalHistory._creationTime).format('HH:MM') : undefined,
      reference: medicalHistory.REFERENCE_NAME,
      answers: [],
      answersRatio: 0,
      answersRatioThreshold: false,
      originalSurveyObject: medicalHistory,
      indexes: {}
    };
    delete medicalHistory.id;
    delete medicalHistory._creationTime;
    delete medicalHistory.MEDICAL_HISTORY_ID;
    delete medicalHistory.CLIENT;
    delete medicalHistory.REFERENCE_NAME;

    const exludedFields = ["INDEX_PHYSIOLOGICAL_WELLNESS", "INDEX_PSYCHOPHYSICAL_WELLNESS", "INDEX_DERMOESTHETIC_WELLNESS", "INDEX_uC", "INDEX_ReD", "INDEX_AB", "INDEX_PHYSIOLOGICAL_WELLNESS_RATIO", "INDEX_PSYCHOPHYSICAL_WELLNESS_RATIO", "INDEX_DERMOESTHETIC_WELLNESS_RATIO", "INDEX_uC_RATIO", "INDEX_ReD_RATIO", "INDEX_AB_RATIO"];
    const questionKeys = this.newSurvey.filter(
      (e) => !(
        e.key.toLowerCase() == "REFERENCE" ||
        e.key.toLowerCase().endsWith("_note") ||
        e.key.toLowerCase().endsWith("_height") ||
        e.key.toLowerCase().endsWith("_description"))).map((e) => e.key);
    const answeredQuestions = Object.keys(medicalHistory).filter((key) => questionKeys.includes(key)).length;
    const answersRatio = answeredQuestions / this.numberOfQuestions;
    const answersRatioThreshold = answersRatio > this.ANSWERS_RATIO_THRESHOLD;
    printableSurvey.answersRatioThreshold = answersRatioThreshold;
    printableSurvey.answersRatio = answersRatio;

    for (const [key, value] of Object.entries(medicalHistory)) {
      // TODO: improve with native array mgmt on BE
      const trueValue = (isObject(value) && 'value' in value) ? value['value'] : value
      if (!exludedFields.includes(key)) {
        const surveyObject = {
          question: this.translateAnamnesiQuestions(key),
          question_code: key,
          answer: this.translateAnamnesiAnswers(key, trueValue),
          answer_code: trueValue
        }
        printableSurvey.answers.push(surveyObject);
      }
      if (key.startsWith('INDEX') && answersRatioThreshold) {
        printableSurvey.indexes[key] = value;
      }
    }

    if (answersRatioThreshold) {
      printableSurvey.indexes['INDEX_GENERAL_WELLNESS_RATIO'] = Math.round(
        printableSurvey.indexes['INDEX_PHYSIOLOGICAL_WELLNESS_RATIO'] * 1.8 +
        printableSurvey.indexes['INDEX_PSYCHOPHYSICAL_WELLNESS_RATIO'] * 1.2 +
        printableSurvey.indexes['INDEX_DERMOESTHETIC_WELLNESS_RATIO'] * 1
      ) / 4;
      printableSurvey.indexes['INDEX_GENERAL_WELLNESS'] = Math.round((
        printableSurvey.indexes['INDEX_PHYSIOLOGICAL_WELLNESS'] * 1.8 +
        printableSurvey.indexes['INDEX_PSYCHOPHYSICAL_WELLNESS'] * 1.2 +
        printableSurvey.indexes['INDEX_DERMOESTHETIC_WELLNESS'] * 1
      ) / 4);

      if (this.client.age) {
        printableSurvey.indexes['BIOLOGICAL_AGE_FACTOR'] = this.getBiologicalAgeFactor(this.client.age, printableSurvey.indexes['INDEX_GENERAL_WELLNESS_RATIO']);
        printableSurvey.indexes['BIOLOGICAL_AGE_FACTOR_RATIO'] = (printableSurvey.indexes['BIOLOGICAL_AGE_FACTOR'] - this.BIOLOGICAL_AGE_FACTOR_MIN) / (this.BIOLOGICAL_AGE_FACTOR_MAX - this.BIOLOGICAL_AGE_FACTOR_MIN) * 100;
        printableSurvey.indexes['BIOLOGICAL_AGE'] = Math.round(printableSurvey.indexes['BIOLOGICAL_AGE_FACTOR'] * this.client.age);
      }
    }

    return printableSurvey;
  }

  translateAnamnesiQuestions(key): any {
    const translation = this._translate.translate('survey.' + key + '.' + 'question');
    return (translation.toString() !== 'survey.' + key + '.' + 'question') ? translation : key;
  }

  translateAnamnesiAnswers(key, valueKey): any {
    if (isArray(valueKey)) {
      return valueKey.map((value) => this.translateAnamnesiAnswers(key, value)).join(', ');
    }
    const translation = this._translate.translate('survey.' + key + '.values.' + valueKey);
    return (translation.toString() !== 'survey.' + key + '.values.' + valueKey) ? translation : valueKey;
  }

  deleteAnamnesi(): void {
    if (this.selectedMedicalHistory === undefined)
      return;
    const confirmation = this._fuseConfirmationService.open({
      title: this.translateStrings.medicalHistory.deleteWarning + " " + this.selectedMedicalHistory.REFERENCE_NAME + " ?",
      message: this.translateStrings.medicalHistory.deleteWarningMessage,
      actions: {
        confirm: {
          label: this.translateStrings.generic.delete
        }
      }
    });

    // Subscribe to the confirmation dialog closed action
    confirmation.afterClosed().subscribe((result) => {

      // if (result === 'confirmed') {
      //     this._apiCaller.deletePerson(contact.personId).subscribe((isDeleted) => {

      //         // Find the index of the deleted event
      //         const index = this.filteredContacts.findIndex(item => item.personId === contact.personId);

      //         this.filteredContacts.splice(index, 1);
      //         this._changeDetectorRef.markForCheck();
      //     });
      // }
      if (result === 'confirmed') {
        this.setLoadingState('deleting', true);
        this._apiCaller.deleteMedicalHistory(this.selectedMedicalHistory.MEDICAL_HISTORY_ID).subscribe({
          next: (res) => {
            const index = this.medicalHistories.findIndex(item => item.id === this.selectedMedicalHistory.id);
            this.medicalHistories.splice(index, 1);
            if (this.medicalHistories.length > 0) {
              if (index > 0)
                this.selectedMedicalHistory = this.medicalHistories[index - 1];
              else
                this.selectedMedicalHistory = this.medicalHistories[0];
              this.handleMedicalHistoryChange({ value: this.selectedMedicalHistory });
            } else {
              this.selectedMedicalHistory = undefined;
            }
            this.setLoadingState('deleting', false);
            this._snackBar.open(this.translateStrings.medicalHistory.deleted, this.translateStrings['generic']['cancel'], this.SNACK_BAR_OPTIONS);
            this._changeDetectorRef.markForCheck();
          },
          error: (err) => {
            console.error(err);
            this.setLoadingState('deleting', false);
            this._fuseConfirmationService.open(
              BeautyciansUtils.getErrorDialogConfig(this.translateStrings['medicalHistory']['errorWhileSaving'] + ": " + err.error, this.translateStrings['generic']['error'], this.translateStrings['generic']['close'])
            );
          }

        });
      }
    })

  }

  getBiologicalAgeFactor(age: number, generalWellnesIndexRatio: number): number {
    const AGE_FACTOR = [
      { minAge: 71, maxAge: 130, factors: [1, 1, 1, 1, 1, 1.05, 1.1, 1.15, 1.15, 1.15, 1.15] },
      { minAge: 61, maxAge: 70, factors: [0.7, 0.8, 0.9, 1, 1.05, 1.1, 1.15, 1.2, 1.2, 1.2, 1.2] },
      { minAge: 41, maxAge: 60, factors: [0.7, 0.8, 0.9, 1, 1.1, 1.15, 1.2, 1.25, 1.3, 1.35, 1.35] },
      { minAge: 20, maxAge: 40, factors: [0.9, 0.9, 1, 1.1, 1.15, 1.2, 1.25, 1.3, 1.35, 1.4, 1.45] }
    ];

    let factorBucket = Math.floor(generalWellnesIndexRatio / 11);
    if (factorBucket > 11) factorBucket = 11;
    if (factorBucket < 0) factorBucket = 0;

    const specificAgeFactors = AGE_FACTOR.find((factor) => factor.minAge <= age && factor.maxAge >= age);
    const specificAgeFactor = specificAgeFactors ? specificAgeFactors.factors[factorBucket] : 1;

    return specificAgeFactor;
  }


  proceedWithReportGeneration(): void {
    if (this.currentMedicalHistory.answersRatioThreshold === false) {
      this._fuseConfirmationService.open(
        BeautyciansUtils.getErrorDialogConfig(this.translateStrings['medicalHistory']['lowAnswersRatioThreshold'], this.translateStrings['generic']['warn'], "warning")
      );
      return;
    }

    const config = {
      "title": this.translateStrings['medicalHistory']['confirmPDFReportGeneration'],
      "message": "",
      "icon": {
        "show": true,
        "name": "science",
        "color": "primary"
      },
      "actions": {
        "confirm": {
          "show": true,
          "label": this.translateStrings['medicalHistory']['generate'],
          "color": "primary"
        },
        "cancel": {
          "show": true,
          "label": this.translateStrings['generic']['cancel'],
        }
      },
      "dismissible": true
    }

    const customDialog = this.dialog.open(ProductSelectionDialogComponent, {
      autoFocus: false,
      disableClose: !config.dismissible,
      data: config,
      panelClass: 'product-selection-dialog-panel'
    })
    customDialog.componentInstance.lastAnamnesi = this.medicalHistories[0];
    customDialog.componentInstance.clientId = this.client.personId;

    customDialog.afterClosed().subscribe((result) => {
      if (result?.message === 'confirm') {
        this.viewAnamnesiReportPDF(result?.suggestedProducts, result?.suggestedHealthPlan, result?.outputType);
      }
    });

  }


  viewAnamnesiPDF(): void {
    this.generatePDF("SURVEY").subscribe({
      next: (response) => {
        // return PDF object to the browser
        this.setLoadingState('reportGeneration', false);
        this._changeDetectorRef.markForCheck();
        if (response.url) {
          window.open(response.url, '_blank');
        } else if (response.blob) {
          // return PDF object to the browser
          const fileURL = URL.createObjectURL(response.blob);
          window.open(fileURL, '_blank');
        } else {
          const err = new Error("Error while generating report");
          throw err;
        }
      }
    })
  }

  viewAnamnesiReportPDF(suggestedProducts: string[] = [], suggestedHealthPlan: string | null = null, outputType: string): void {

    if (this.selectedMedicalHistory.answersRatioThreshold === false) {
      this._fuseConfirmationService.open(
        BeautyciansUtils.getErrorDialogConfig(this.translateStrings['medicalHistory']['lowAnswersRatioThreshold'])
      );
      return;
    }

    this.generatePDF("REPORT", suggestedProducts, suggestedHealthPlan, outputType).subscribe({
      next: (response) => {
        this.setLoadingState('reportGeneration', false);
        this._changeDetectorRef.markForCheck();
        if (response.url) {
          window.open(response.url, '_blank');
        } else if (response.blob) {
          // return PDF object to the browser
          const fileURL = URL.createObjectURL(response.blob);
          window.open(fileURL, '_blank');
        } else {
          const err = new Error("Error while generating report");
          throw err;
        }
      },
      error: (error) => {
        console.log("Error while generating report: " + error);
        this._fuseConfirmationService.open(
          BeautyciansUtils.getErrorDialogConfig(this.translateStrings['medicalHistory']['errorWhileGeneratingReport'] + ": " + error)
        );
        this.setLoadingState('reportGeneration', false);
        this._changeDetectorRef.markForCheck();
      }
    })
  }

  downloadAnamnesiPDF(suggestedProducts: string[]): void {
    this._changeDetectorRef.markForCheck();
    this.generatePDF("SURVEY", suggestedProducts).subscribe({
      next: (response) => {
        this.setLoadingState('reportGeneration', false);
        this._changeDetectorRef.markForCheck();
        if (response.url) {
          window.open(response.url, '_blank');
        } else {
          // Download the file
          const filename = (this.client.firstName + " " + this.client.lastName + "_" + this.selectedMedicalHistory.REFERENCE_NAME).replace(/[^a-z0-9]/gi, '_anamnesi').toLowerCase();
          saveAs(response.blob, filename + ".pdf");
        }
      },
      error: (error) => {
        console.log("Error while calling reporting function: " + error);
        this._fuseConfirmationService.open({
          "title": "Errore",
          "message": "Purtroppo qualcosa è andato storto nella generazione del report.",
          "icon": {
            "show": true,
            "name": "warning",
            "color": "error"
          },
          "actions": {
            "confirm": {
              "show": false,
              "label": "OK",
              "color": "primary"
            },
            "cancel": {
              "show": false,
              "label": "Cancel"
            }
          },
          "dismissible": true
        });

        this.setLoadingState('reportGeneration', false);
        this._changeDetectorRef.markForCheck();
      }
    })
  }

  generatePDF(template: string, suggestedProducts: string[] = [], suggestedHealthPlan: string | null = null, outputType: string | null = null): Observable<any> {
    this.setLoadingState('reportGeneration', true);
    let output = {};

    if (outputType && outputType === "whatsapp") {
      output = {
        type: "whatsapp",
        receiver: "+393282752159",
      }
    } else {
      output = {
        type: "url"
      }
    }
    return this._apiCaller.getMedicalHistoryScores(this.selectedMedicalHistory.MEDICAL_HISTORY_ID).pipe(
      takeUntil(this._unsubscribeAll),
      map((scoreResponse) => {
        let scores = [];
        Object.entries(scoreResponse).forEach(([key, value]) => scores.push({ key: key, score: value }));
        const translatedScores = scores.map((item) => {
          const translation = this._translate.translate('survey.' + item.key + '.' + 'param');
          const translatedString = (translation.toString() !== 'survey.' + item.key + '.' + 'param') ? translation : item.key;
          return { ...item, label: translatedString };
        });
        return translatedScores;
      }),
      catchError((error) => {
        console.error("Error while calling getMedicalHistoryScores: " + error.message);
        return of({});
      }),
      switchMap((medicalHistoryScores) => {
        return this._appointmentsService.getClientAppointments(this.client.personId).pipe(
          map((appointments) => {
            return {
              template: template,
              document: {
                name: this.client.firstName,
                surname: this.client.lastName,
                organizationName: this._authService.getCurrentLoggedUser().getSelectedOrganization().name,
                anamnesi: {
                  ...this.currentMedicalHistory,
                  scores: medicalHistoryScores
                },
                appointments,
                suggestedProducts,
                healthSuggestion: [suggestedHealthPlan]
              },
              output
            };
          }),
          catchError((error) => {
            console.error("Error while calling getClientAppointments: " + error.message);
            return of({});
          })
        )
      }),
      switchMap((aggregate) => {

        const command = {
          contextName: 'Beautycians',
          domainName: 'Core',
          commandName: 'CREATE_PDF',
          commandType: 'NORMAL_COMMAND',
          body: {
            aggregate
          }
        };

        return this._httpClient.doPost({
          url: GruulsConstants.COMMAND_API_URL,
          body: command
        }).pipe(
          map((res) => {
            // Block for PDF
            const resObj = res.hits[0];
            if (resObj.headers["Content-type"] == "application/json") {
              const bodyObj = JSON.parse(resObj.body);
              return { url: bodyObj.url };
            } else {
              var binary = atob(resObj.body.replace(/\s/g, ''));
              var len = binary.length;
              var buffer = new ArrayBuffer(len);
              var view = new Uint8Array(buffer);
              for (var i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
              }
              this.setLoadingState('reportGeneration', false);
              return { blob: new Blob([view], { type: res.hits[0].type }) };
            }
          }),
          catchError((error) => {
            this.setLoadingState('reportGeneration', false);
            console.log("Error while generating PDF: " + error.message);
            const err = new Error("Error calling PDF Generation Function");
            throw err;
          })
        );
      }),
    )
  }

  downloadAnamnesi(): void {
    // Prepare data to be downloaded
    var data = [];
    data[0] = { "Reference": this.selectedMedicalHistory.reference, "Creation": this.selectedMedicalHistory.creation }
    Object.assign(data[0], this.selectedMedicalHistory.originalSurveyObject);

    const filename = (this.client.firstName + " " + this.client.lastName + "_" + this.selectedMedicalHistory.reference).replace(/[^a-z0-9]/gi, '_anamnesi').toLowerCase();
    this.downloadCSVFile(data, filename);
  }

  downloadCSVFile(data: any, filename: string = 'data') {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, filename + ".csv");
  }

  // createIndexChart(value, indexName, id): void {
  //   if (value > 100) value = 100;

  //   // const chartId = indexName + "_" + id;
  //   const chartId = indexName;

  //   // Check if already present (deprecable)
  //   if (!document.getElementById(chartId)) return;

  //   let root = am5.Root.new(chartId);
  //   root.container.children.clear();

  //   // dispose am Logo
  //   root._logo.dispose();

  //   // Set themes
  //   // https://www.amcharts.com/docs/v5/concepts/themes/
  //   root.setThemes([
  //     am5themes_Animated.new(root)
  //   ]);


  //   var colorRed = 0xF00505;
  //   var colorYellow = 0xFFCE03;
  //   var colorOrange = 0xFD6104;
  //   var colorGreen = 0x6bc352;
  //   // Create chart
  //   // https://www.amcharts.com/docs/v5/charts/xy-chart/
  //   var chart = root.container.children.push(am5xy.XYChart.new(root, {
  //     panX: false,
  //     panY: false,
  //     wheelX: "none",
  //     wheelY: "none",
  //     layout: root.verticalLayout,
  //     paddingRight: 30
  //   }));


  //   // Add legend
  //   // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
  //   var legend = chart.children.push(
  //     am5.Legend.new(root, {
  //       centerX: am5.p50,
  //       x: am5.p50
  //     })
  //   );

  //   var data = [{
  //     category: "0",
  //     value: 100,
  //     currentBullet: value >= 0 && value < 10,
  //     columnSettings: {
  //       fill: am5.color(colorGreen)
  //     }
  //   }, {
  //     category: "10",
  //     value: 100,
  //     currentBullet: value >= 10 && value < 20,
  //     columnSettings: {
  //       fill: am5.color(colorGreen)
  //     }
  //   }, {
  //     category: "20",
  //     value: 100,
  //     currentBullet: value >= 20 && value < 30,
  //     columnSettings: {
  //       fill: am5.color(colorYellow)
  //     }
  //   }, {
  //     category: "30",
  //     value: 100,
  //     currentBullet: value >= 30 && value < 40,
  //     columnSettings: {
  //       fill: am5.color(colorYellow)
  //     }
  //   }, {
  //     category: "40",
  //     value: 100,
  //     currentBullet: value >= 40 && value < 50,
  //     columnSettings: {
  //       fill: am5.color(colorYellow)
  //     }
  //   }, {
  //     category: "50",
  //     value: 100,
  //     currentBullet: value >= 50 && value < 60,
  //     columnSettings: {
  //       fill: am5.color(colorOrange)
  //     }
  //   }, {
  //     category: "60",
  //     value: 100,
  //     currentBullet: value >= 60 && value < 70,
  //     columnSettings: {
  //       fill: am5.color(colorOrange)
  //     }
  //   }, {
  //     category: "70",
  //     value: 100,
  //     currentBullet: value >= 70 && value < 80,
  //     columnSettings: {
  //       fill: am5.color(colorOrange)
  //     }
  //   }, {
  //     category: "80",
  //     value: 100,
  //     currentBullet: value >= 80 && value < 90,
  //     columnSettings: {
  //       fill: am5.color(colorRed)
  //     }
  //   }, {
  //     category: "90",
  //     value: 100,
  //     currentBullet: value >= 90 && value <= 100,
  //     columnSettings: {
  //       fill: am5.color(colorRed)
  //     }
  //   }];
  //   // , {
  //   //     category: "100",
  //   //     value: 100,
  //   //     currentBullet: value >= 100,
  //   //     columnSettings: {
  //   //         fill: am5.color(colorRed)
  //   //     }
  //   // }


  //   // Create axes
  //   // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
  //   var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
  //     categoryField: "category",
  //     renderer: am5xy.AxisRendererX.new(root, {

  //     }),
  //     tooltip: am5.Tooltip.new(root, {})
  //   }));

  //   var xRenderer = xAxis.get("renderer");

  //   xRenderer.grid.template.set("forceHidden", true);
  //   xRenderer.labels.template.set("forceHidden", true);

  //   xAxis.data.setAll(data);

  //   var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
  //     min: 0,
  //     max: 400,
  //     strictMinMax: true,
  //     renderer: am5xy.AxisRendererY.new(root, {})
  //   }));

  //   var yRenderer = yAxis.get("renderer");

  //   yRenderer.grid.template.set("forceHidden", true);
  //   yRenderer.labels.template.set("forceHidden", true);


  //   // Add series
  //   // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

  //   var series = chart.series.push(am5xy.ColumnSeries.new(root, {
  //     xAxis: xAxis,
  //     yAxis: yAxis,
  //     valueYField: "value",
  //     categoryXField: "category",
  //     maskBullets: false
  //   }));

  //   series.columns.template.setAll({
  //     //tooltipText: "{categoryX}: {valueY}",
  //     width: am5.p100,
  //     tooltipY: 0,
  //     strokeOpacity: 1,
  //     strokeWidth: 2,
  //     stroke: am5.color(0xffffff),
  //     templateField: "columnSettings"
  //   });

  //   series.bullets.push((root, target, dataItem: any): any => {
  //     if (dataItem.dataContext.currentBullet) {
  //       var container = am5.Container.new(root, {});

  //       var pin = container.children.push(am5.Graphics.new(root, {
  //         fill: dataItem.dataContext.columnSettings.fill,
  //         dy: -5,
  //         centerY: am5.p100,
  //         centerX: am5.p50,
  //         svgPath: "M66.9 41.8c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4 0 11.3 20.4 32.4 20.4 32.4s20.4-21.1 20.4-32.4zM37 41.4c0-5.2 4.3-9.5 9.5-9.5s9.5 4.2 9.5 9.5c0 5.2-4.2 9.5-9.5 9.5-5.2 0-9.5-4.3-9.5-9.5z"
  //       }));

  //       var label = container.children.push(am5.Label.new(root, {
  //         text: value.toFixed(0).toString() + '%', //dataItem.get("categoryX"),
  //         dy: -38,
  //         centerY: am5.p50,
  //         centerX: am5.p50,
  //         populateText: true,
  //         paddingTop: 5,
  //         paddingRight: 5,
  //         paddingBottom: 5,
  //         paddingLeft: 5,
  //         background: am5.RoundedRectangle.new(root, {
  //           fill: am5.color(0xffffff),
  //           cornerRadiusTL: 20,
  //           cornerRadiusTR: 20,
  //           cornerRadiusBR: 20,
  //           cornerRadiusBL: 20,
  //         })
  //       }));

  //       return am5.Bullet.new(root, {
  //         locationY: 1,
  //         sprite: container
  //       });
  //     }
  //     return false;
  //   });

  //   series.data.setAll(data);

  //   // Add labels
  //   function addAxisLabel(category, text) {
  //     var rangeDataItem = xAxis.makeDataItem({
  //       category: category
  //     });

  //     var range = xAxis.createAxisRange(rangeDataItem);

  //     range.get("label").setAll({
  //       //fill: am5.color(0xffffff),
  //       text: text,
  //       forceHidden: false
  //     });

  //     range.get("grid").setAll({
  //       //stroke: color,
  //       strokeOpacity: 1,
  //       location: 1
  //     });
  //   }

  //   addAxisLabel("10", "10");
  //   addAxisLabel("30", "30");
  //   addAxisLabel("50", "50");
  //   addAxisLabel("70", "70");
  //   addAxisLabel("90", "90");

  //   // Make stuff animate on load
  //   // https://www.amcharts.com/docs/v5/concepts/animations/
  //   series.appear(1000, 100);
  //   chart.appear(1000, 100);
  // }

}