import {DataSchema} from '../../../@gruuls-core/domain/data-schema/data-schema';
import {Fields} from '../../../@gruuls-core/domain/data-schema/fields';
import {
    OneToManyFieldConfig
} from '../../../@gruuls-core/domain/data-schema/fields/one-to-many/one-to-many-field-config';
import {
    ListTemplate01Config
} from '../../../@gruuls-fe/angular-qrner-libraries/qrner-auto-ui-framework/ui-components/list/templates/template01-list-template/list-template-01-config';
import {DomainCatalog} from '../../../@gruuls-core/domain/domain/domain-catalog';
import {HttpClient} from '@angular/common/http';
import {CollectionType} from '../../../@gruuls-core/domain/data-schema/collection-type';
import {GruulsAngularHttpProxyService} from '../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import {
    GruulsTranslateServiceInterface
} from '../../../@gruuls-core/service-interfaces/gruuls-translate-service-interface';
import {GruulsAuthService} from '../../../@gruuls-fe/services/gruuls-auth.service';
import {SortDirection} from '../../../@gruuls-core/domain/repository/gruuls-sort';
import {Domain} from '../../../@gruuls-core/domain/domain/domain';
import {GruulsHttpProxyInterface} from '../../../@gruuls-core/service-interfaces/gruuls-http-proxy-interface';


export class CorePersonDomain {
    constructor() {
    }

    public static loadOnDomainCatalog(
        authService: GruulsAuthService,
        httpClient: GruulsHttpProxyInterface,
        _qrnerTranslationService: GruulsTranslateServiceInterface): void {

        // inject Core.Organization domain details into catalog
        const schema = DataSchema.ofConfig({
            fields: [
                Fields.uniqueKey({
                    name: 'personId'
                }),
                Fields.string_({
                    name: 'firstName'
                }),
                Fields.string_({
                    name: 'lastName'
                }),
                Fields.enum({
                    name: 'sex',
                    enumValues: ['M', 'F']
                }),
                Fields.valueObject({
                    name: 'roles',
                    collectionType: CollectionType.LIST,
                    subFields: [
                        Fields.boolean_({
                            name: 'isDefault'
                        }),
                        Fields.manyToOne({
                            name: 'inOrganization',
                            otherDomain: 'Core.Organization',
                            fieldConfig: {
                                fieldsToShow: ['name'],
                                fieldsToShowOnSelected: ['name'],
                                orderBy: [{name: SortDirection.asc}]
                            }
                        }),
                        Fields.oneToMany({
                            name: 'hasRole',
                            otherDomain: 'Core.RoleTemplate',
                            fieldConfig: {
                                fieldsToShow: ['name'],
                                fieldsToShowOnSelected: ['name'],
                                orderBy: [{name: 'asc'}],
                                tableConfig: {
                                    fieldsToShow: ['name']
                                }
                            } as OneToManyFieldConfig
                        })
                    ]
                }),
                Fields.valueObject({
                    name: 'account',
                    subFields: [
                        Fields.uniqueKey({
                            name: 'accountId'
                        }),
                        Fields.string_({
                            name: 'username'
                        }),
                        Fields.password({
                            name: 'password'
                        }),
                        Fields.string_({
                            name: 'passwordVerify'
                        }),
                        Fields.string_({
                            name: 'email'
                        })
                    ],
                    // fieldConfig: {
                    //     editFormConfig: {
                    //         groupSyncValidators: [{
                    //             name: "group_fieldEquals",
                    //             param: ["password", "passwordVerify"]
                    //         }]
                    //     }
                    // }
                }),
                Fields.enum({
                    name: 'localeLanguage',
                    enumValues: ['it', 'en']
                }),
                Fields.enum({
                    name: 'localeCountry',
                    enumValues: ['IT', 'EN']
                })
            ],
            defaultAssembleAs: {
                personId: true,
                firstName: true,
                lastName: true
            }
        });

        const domain = Domain.ofConfig({
            contextName: 'Core',
            domainName: 'Person',
            schema: schema,
            templatesConfig: {
                listTemplate: {
                    templateName: 'list-template-01',
                    fieldsToShow: ['personId', 'firstName', 'lastName', 'sex', 'roles', 'account', 'localeLanguage', 'localeCountry'],
                    tableConfig: {
                        fieldsToShow: ['personId', 'firstName', 'lastName'],
                        addButton: {
                            dialog: {
                                fieldsToShow: ['personId', 'firstName', 'lastName', 'sex', 'roles', 'localeLanguage', 'localeCountry'],
                            }
                        },
                        actionColumn: {
                            edit: {
                                dialog: {
                                    fieldsToShow: ['personId', 'firstName', 'lastName', 'sex', 'roles', 'localeLanguage', 'localeCountry'],
                                }
                            },
                            view: true
                        }
                    },
                } as ListTemplate01Config,
                viewTemplate: {
                    // template della modale x creazione
                    templateName: 'view-template-01',
                    fieldsToShow: ['personId', 'firstName', 'lastName', 'sex', 'roles', {
                        fieldName: 'account',
                        fieldsToShow: ['accountId', 'username', 'email']
                    }, 'localeLanguage', 'localeCountry'],
                    actionButton: ['addAccount']
                },
                loginTemplate: {
                    fieldsToShow: [{
                        fieldName: 'account',
                        hideFieldName: true,
                        fieldsToShow: [
                            {
                                fieldName: 'username',
                                syncValidators: [{name: 'required'}]
                            },
                            {
                                fieldName: 'password',
                                syncValidators: [{name: 'required'}]
                            }]
                    }],
                }
            },
            actions: {
                addAccount: {
                    actionName: 'AddAccount',
                    actionType: 'commandModal',
                    commandName: 'SET_ACCOUNT',
                    fieldsToShow: [{
                        fieldName: 'account',
                        fieldsToShow: ['username', 'email', 'password', 'passwordVerify']
                    }],
                    hidden: (context: any): boolean => !context.aggregate.account
                }
            },
            http: httpClient,
            auth: authService
        });

        DomainCatalog.getInstance().addDomain(domain);
        // translations are loaded with routing resolvers
        // _qrnerTranslationService.loadTranslationsFor("Core.Person");
    }
}
