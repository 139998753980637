export class OrderStatus {
  public static getStatusColor(status: string | null): string {
    let color: string = "gray";
    switch(status?.toUpperCase()) {
        case "WAITING": color = "gray"; break;
        case "PROCESSING": color = "orange"; break;
        case "SENT": color = "teal"; break;
        case "PARTIAL_DELIVERING": color = "indigo"; break;
        case "DELIVERING": color = "indigo"; break;
        case "DELIVERED": color = "green"; break;
        case "CANCELED": color = "red"; break;
        default: color = "gray"; break;
      }
      return color;
    }

  public static getStatusIcon(status: string| null): string {
    switch(status?.toUpperCase()) {
        case "WAITING": return "hourglass_bottom"; break;
        case "PROCESSING": return "done"; break;
        case "SENT": return "done_all"; break;
        case "PARTIAL_DELIVERING": return "local_shipping"; break;
        case "DELIVERING": return "local_shipping"; break;
        case "DELIVERED": return "gpp_good"; break;
        case "CANCELED": return "cancel"; break;
        default: return null; break;
      }
  }
}