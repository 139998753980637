import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit, TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, NgForm, Validators } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { Observable, Subject } from 'rxjs';
import { delay, map, takeUntil, tap } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { GruulsConstants, ItalianProvinces } from '../../../../mock-api/gruuls/gruuls-constants';
import { GruulsAngularHttpProxyService } from '../../../../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BeautyciansUtils } from '../../../utils/utils';
import { MatDialog } from '@angular/material/dialog';
import { GruulsAngularTranslateService } from '../../../../../@gruuls-fe/services/gruuls-angular-translate.service';
import { GruulsAuthService } from "../../../../../@gruuls-fe/services/gruuls-auth.service";
import { ApiCaller } from 'app/beautycians/utils/apiCaller';
import { GruulsAngularStoreService } from '@gruuls-fe/services/gruuls-angular-stores-service';
import { Store } from 'app/beautycians/utils/dataTypes';
import { FuseConfirmationService } from '@fuse/services/confirmation';

@Component({
    selector: 'stores-list',
    templateUrl: './stores-list.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoresListComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
    @ViewChild('newStoreForm') newStoreForm: NgForm;
    @ViewChild('storeModal') storeModal: TemplateRef<any>;

    drawerMode: 'side' | 'over';
    searchInputControl: FormControl = new FormControl();
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    @ViewChild('storesTable', { read: MatSort }) storesTableMatSort: MatSort;
    data: any;
    storesDataSource: MatTableDataSource<any> = new MatTableDataSource();
    storeTableColumns: string[] = ['store', 'address', 'update'];

    isLoading: boolean;
    isSpecificLoading: boolean[] = [];
    selectedStore: any;
    translateStrings: any = { store: {}, generic: {} };
    provinces: any[] = ItalianProvinces;
    provinceControl: FormControl = new FormControl();

    private _stores: Store[];

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router,
        private _httpClient: GruulsAngularHttpProxyService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fb: FormBuilder,
        private dialog: MatDialog,
        private _translate: GruulsAngularTranslateService,
        private _authService: GruulsAuthService,
        private _storeService: GruulsAngularStoreService,
        private _fuseConfirmationService: FuseConfirmationService
    ) {

    }

    @HostBinding('class') get classList(): any {
        return {
            'w-full': true
        };
    }

    addNewStoreForm = this._fb.group({
        name: ['', Validators.required],
        description: [''],
        address: [''],
        zip: [''],
        city: [''],
        province: [''],
        type: ['']
    });

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isLoading = true;

        this._storeService.stores$.pipe(
            takeUntil(this._unsubscribeAll),
            tap((stores) => {
                this._stores = stores;
                this.storesDataSource.data = this._stores;
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            })
        ).subscribe();

        this._storeService.refreshStores().pipe(
            takeUntil(this._unsubscribeAll),
        ).subscribe();

        // Subscribe to search input field value changes
        this.searchInputControl.valueChanges.pipe(
            takeUntil(this._unsubscribeAll),
            map(query => BeautyciansUtils.searchStringByKeys(this._stores, query, ['name'])),
            map(queryResult => BeautyciansUtils.sortByKeys(queryResult, ['name', 'address'])),
            tap(filteredContacts => this.storesDataSource.data = filteredContacts)
        ).subscribe();

        this.dialog.afterOpened.pipe(
            takeUntil(this._unsubscribeAll),
            delay(0)
        ).subscribe(() => {
            if (this.selectedStore) {
                this.addNewStoreForm.controls['name'].setValue(this.selectedStore.name);
                this.addNewStoreForm.controls['description'].setValue(this.selectedStore.description);
                this.addNewStoreForm.controls['address'].setValue(this.selectedStore.address);
                this.addNewStoreForm.controls['zip'].setValue(this.selectedStore.zip);
                this.addNewStoreForm.controls['city'].setValue(this.selectedStore.city);
                this.addNewStoreForm.controls['province'].setValue(this.provinces.find(province => province.key === this.selectedStore.province));
                this.addNewStoreForm.controls['type'].setValue(this.selectedStore.type);
            } else if (this.newStoreForm) {
                this.newStoreForm.resetForm();
            }
        });

        const storeTranslations = ['address', 'zip', 'city', 'name', 'description', 'type', 'singular', 'plural', 'noStores', 'search', 'noSearchResults', 'addNew', 'deleteWarning', 'deleteWarningMessage', 'province'];
        storeTranslations.forEach((translation) => {
            this.translateStrings['store'][translation] = this._translate.translate('stores.' + translation);
        });

        const genericTranslations = ['save', 'close', 'edit', 'delete'];
        genericTranslations.forEach((translation) => {
            this.translateStrings['generic'][translation] = this._translate.translate('generic.' + translation);
        });
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        // Make the data source sortable
        this.storesDataSource.sort = this.storesTableMatSort;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    addNewStoreFormSubmit($event): void {
        if (this.addNewStoreForm.invalid) {
            return;
        }

        this.isSpecificLoading[$event.submitter.id] = true;
        this.addNewStoreForm.disable();

        let store: Store = {
            name: this.addNewStoreForm.controls['name'].value,
            description: this.addNewStoreForm.controls['description'].value,
            address: this.addNewStoreForm.controls['address'].value,
            zip: this.addNewStoreForm.controls['zip'].value,
            city: this.addNewStoreForm.controls['city'].value,
            province: this.addNewStoreForm.controls['province'].value.key,
            organization: {
                organizationId: this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId
            }
        };
        Object.keys(store).forEach(key => {
            if (store[key] === null) {
                delete store[key];
            }
        });

        let storeObservable: Observable<Store>;
        if (this.selectedStore) {
            store.storeId = this.selectedStore.storeId;
            storeObservable = this._storeService.updateStore(store);
        } else {
            storeObservable = this._storeService.createStore(store);
        }
        storeObservable.pipe(
            takeUntil(this._unsubscribeAll),
            tap(() => {
                this.newStoreForm.resetForm();
                this.addNewStoreForm.enable();
                this.isSpecificLoading[$event.submitter.id] = false;
                this.dialog.closeAll();
                this._changeDetectorRef.markForCheck();
            })
        ).subscribe({
            error: (err) => {
                this.isSpecificLoading[$event.submitter.id] = false;
                this.addNewStoreForm.enable();
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    deleteStore(): void {
        if (!this.selectedStore)
            return;

        const confirmation = this._fuseConfirmationService.open({
            title: this.translateStrings.store.deleteWarning + " " + this.selectedStore.name,
            message: this.translateStrings.store.deleteWarningMessage,
            actions: {
                confirm: {
                    label: this.translateStrings.generic.delete
                }
            }
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) => {

            if (result === 'confirmed') {
                this.isLoading = true;
                this._changeDetectorRef.markForCheck();

                this._storeService.deleteStore(this.selectedStore.storeId).pipe(
                    takeUntil(this._unsubscribeAll),
                    tap(() => {
                        this._storeService.refreshStores().subscribe();
                        this._changeDetectorRef.markForCheck();
                    })
                ).subscribe({
                    next: () => {
                        this.isLoading = false;
                        this._changeDetectorRef.markForCheck();
                    },
                    error: (err) => {
                        this.isLoading = false;
                        this._changeDetectorRef.markForCheck();
                    }
                });
            }
        })
    }

    /**
     * On backdrop clicked
     */
    onBackdropClicked(): void {
        // Go back to the list
        this._router.navigate(['./'], { relativeTo: this._activatedRoute });

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }


    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    openModal(): void {
        this.dialog.open(this.storeModal, {
            panelClass: ['md:w-160', 'w-full']
        });
    }

}
