import { ElementRef } from '@angular/core';
import { utils, writeFileXLSX, WorkBook, WorkSheet } from 'xlsx';

export class XlsUtils {


    public static exportTableToXls(filename: string, tableId: string): void {
        /* table id is passed over here */
        let tableElement = document.getElementById(tableId); 
        const ws: WorkSheet = utils.table_to_sheet(tableElement);

        /* generate workbook and add the worksheet */
        const wb: WorkBook = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        writeFileXLSX(wb, filename);
    }

    public static exportMatrixToXls(filename: string, data: any[][]): void {
        /* table id is passed over here */
        const ws: WorkSheet = utils.aoa_to_sheet(data);

        /* generate workbook and add the worksheet */
        const wb: WorkBook = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        writeFileXLSX(wb, filename);
    }

}
