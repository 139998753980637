import { BehaviorSubject, Observable, map, of, switchMap, take, tap } from "rxjs";
import { Store } from "../utils/dataTypes";
import { ApiCaller } from "../utils/apiCaller";
import { GruulsAngularHttpProxyService } from "@gruuls-fe/services/gruuls-angular-http-proxy.service";
import { GruulsAuthService } from "@gruuls-fe/services/gruuls-auth.service";
import { BeautyciansUtils } from "../utils/utils";

export class StoreService {
    // decided to duplicate this as an Angular service in order to use it as an Injectable
    private _store: BehaviorSubject<Store[] | null> = new BehaviorSubject(null);
    private _stores: BehaviorSubject<Store[] | null> = new BehaviorSubject(null);
    private _lastSubscriptionTime: number = null;
    private _apiCaller: ApiCaller = new ApiCaller(this._httpClient, this._authService);


    constructor(
        private _httpClient: GruulsAngularHttpProxyService,
        private _authService: GruulsAuthService
    ) {

    }

    get store$(): Observable<Store[]> {
        return this._store.asObservable();
    }

    get stores$(): Observable<Store[]> {
        return this._stores.asObservable();
    }

    getStores(): Observable<Store[]> {
        if (this._authService && this._authService.isAuthenticated()) {
            return this._apiCaller.getStores(this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId).pipe(
                tap((stores) => {
                    this._lastSubscriptionTime = Date.now();
                    this._stores.next(BeautyciansUtils.sortByKeys(stores, ['name', 'address']));
                })
            );
        } else {
            this._stores.next([]);
            return of([]);
        }
    }

    refreshStores(): Observable<Store[]> {
        const now = Date.now();
        const UPDATE_DELTA = 15 * 60 * 1000;

        if (this._lastSubscriptionTime == null || (now - this._lastSubscriptionTime) > UPDATE_DELTA) {
            this._lastSubscriptionTime = Date.now();
            return this.getStores();
        } else {
            return of(this._stores.getValue());
        }
    }

    getStoreById(storeId: string): Observable<Store> {
        return this._stores.pipe(
            take(1),
            map((stores) => {
                if (!stores) {
                    return null;
                }
                return stores.find((store) => store.storeId === storeId);
            }),
            switchMap((store) => {
                if (!store)
                    throw new Error('Store not found');
                return of(store);

            })
        );
    }

    createStore(store: Store): Observable<Store> {
        store.organization.organizationId = this._authService.getCurrentLoggedUser().getSelectedOrganization().organizationId;
        return this.stores$.pipe(
            take(1),
            switchMap(stores => this._apiCaller.createStore(store).pipe(
                map((newStore: Store) => {

                    // Update the products with the new product
                    this._stores.next([newStore, ...stores]);

                    // Return the new product
                    return newStore;
                })
            ))
        );
    }


    updateStore(store: Store): Observable<Store> {
        if (!store.storeId) {
            throw new Error('Store id is required');
        }

        return this.stores$.pipe(
            take(1),
            switchMap(stores => this._apiCaller.updateStore(store).pipe(
                map((updatedStore) => {

                    // Find the index of the updated client
                    const index = stores.findIndex(item => item.storeId === store.storeId);
                    stores[index] = updatedStore;
                    this._stores.next(stores);

                    // Return the updated client
                    return updatedStore;
                }),
            )
            ))
    }

    deleteStore(storeId: string): Observable<boolean> {
        return this.stores$.pipe(
            take(1),
            switchMap(stores => this._apiCaller.deleteStore(storeId).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted client
                    const index = stores.findIndex(item => item.storeId === storeId);

                    // Delete the client
                    stores.splice(index, 1);

                    // Update the clients
                    this._stores.next(stores);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }
}