<!-- <form class="mb-8">
  <div class="flex flex-row items-center">
    <mat-select [(value)]="selectedBeautyPath" placeholder="{{translateStrings.beautyPath.singular}}"
      class="w-8/12 min-h-6 p-4 pl-6 rounded-full bg-hover">
      <mat-option class="text-black" *ngFor="let beautyPath of beautyPaths"
        [value]="beautyPath">{{beautyPath.name}}</mat-option>
    </mat-select>
    <div class="ml-4">
      <button mat-icon-button color="accent" (click)="1">
        <mat-icon>add</mat-icon>
      </button>
      <!-- <button mat-icon-button color="accent" (click)="1">
        <mat-icon>edit</mat-icon>
      </button> -#->
    </div>
  </div>
</form> -->

<div *ngIf="proposedTreatments.length>0" class="my-3">{{translateStrings.beautyPath.advisedSessions}}
  <ng-container *ngFor="let treatment of proposedTreatments; let i=index; let first = first ;let last = last;">
    <span *ngIf="!(first || last)">, </span><span *ngIf="last && proposedTreatments.length>1"> {{translateStrings.generic.and}} </span>
    <a class="cursor-pointer text-primary font-semibold"
      (click)="addSession(treatment)">{{_translate.translate('treatments.' + treatment)}}</a>
    <span *ngIf="last">.</span>
  </ng-container>
</div>

<!-- <div class="my-3 pl-6">In base ai risultati della tua ultima anamnesi, potrebbe essere necessario aumentare gli
  appuntamenti su: <a class="cursor-pointer text-primary">Idratazione</a>.</div> -->

<!-- sessions -->
<div>
  <ol id="sessionsList" class="w-full">
    <!-- <ol id="sessionsList" cdkDropList (cdkDropListDropped)="drop($event)"> -->
    <!-- new session button -->
    <li class="relative flex py-7 ng-star-inserted">
      <!-- <div class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600 ng-star-inserted"></div> -->
      <div class="relative flex flex-auto">
        <div class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full ng-star-inserted">
          <!-- icon on the left -->
          <button mat-flat-button [matTooltip]="translateStrings.beautyPath.addSession" matTooltipPosition="after"
            class="min-w-0 w-10 h-10" color="primary" (click)="addSession()" [disabled]="isSpecificLoading['add']">
            <mat-icon *ngIf="!isSpecificLoading['add']" class="mat-icon notranslate icon-size-5" aria-hidden="true"
              data-mat-icon-name="add" data-mat-icon-namespace="heroicons_solid">add</mat-icon>
            <mat-progress-spinner *ngIf="isSpecificLoading['add']" [diameter]="24"
              [mode]="'indeterminate'"></mat-progress-spinner>
          </button>
        </div>
      </div>
    </li>
    <ng-container *ngFor="let appointment of unassignedAppointments$ | async; trackBy: trackByFn">
      <treatment-box [appointment]="appointment" [treatments]="treatments" [translateStrings]="translateStrings"
        [isSpecificLoading]="isSpecificLoading"></treatment-box>

    </ng-container>
    <!-- <li class="relative flex py-7 ng-star-inserted">
      <div class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600 ng-star-inserted"></div>
      <div class="relative flex flex-auto"><img
          class="shrink-0 w-10 h-10 mr-4 rounded-full overflow-hidden object-cover object-center ng-star-inserted"
          src="assets/images/avatars/male-06.jpg" alt="Activity image">
        <div class="flex flex-col flex-auto items-start">
          <div class="ng-star-inserted"><strong>Roger Murray</strong> accepted your friend request</div>
          <div class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 sm:space-x-2 text-md leading-5">
            <div class="text-secondary"> Aug 25, 5:04 AM </div>
          </div>
          <div class="mt-4 py-4 px-5 rounded-lg bg-gray-200 dark:bg-gray-800 ng-star-inserted">You have <span
              class="font-semibold">8</span> mutual friends.</div>
        </div>
      </div>
    </li> -->
    <!-- <li class="relative flex py-7 ng-star-inserted">
      <div class="relative py-2 px-8 text-md font-medium leading-5 rounded-full bg-primary text-on-primary"> Ieri </div>
    </li> -->

  </ol>
</div>