import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import * as moment from 'moment';
import { FuseFindByKeyPipeModule } from '@fuse/pipes/find-by-key';
import { SharedModule } from 'app/shared/shared.module';
import {FuseCardModule} from '../../../../@fuse/components/card';
import { FuseAlertModule } from '@fuse/components/alert';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatStepperModule} from '@angular/material/stepper';
import { MyCalendarModule } from '../myCalendar/mycalendar.module';

import {ClientListComponent} from './list/client-list.component';
import {ClientDetailsComponent} from './details/client-details.component';
import { ClientPrecompileComponent } from './precompile/client-precompile.component';
import { BeautyPathComponent } from './details/beauty-path.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { MedicalHistoryComponent } from './details/anamnesi.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ElementsModule } from '../elements/elements';
import { CommonModule } from '@angular/common';
import { TreatmentBoxComponent } from './details/treatment-box/treatment-box.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatChipsModule } from '@angular/material/chips';
import { ProductSelectionDialogComponent } from './details/product-selection-dialog/product-selection-dialog.component';


@NgModule({
    declarations: [
        AppointmentComponent,
        ClientDetailsComponent,
        BeautyPathComponent,
        ClientListComponent,
        ClientPrecompileComponent,
        MedicalHistoryComponent,
        TreatmentBoxComponent,
        ProductSelectionDialogComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDividerModule,
        MyCalendarModule,
        MatFormFieldModule,
        MatChipsModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatMomentDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatTableModule,
        MatTooltipModule,
        FuseFindByKeyPipeModule,
        SharedModule,
        MatDatepickerModule,
        RouterModule,
        FuseCardModule,
        FuseAlertModule,
        MatDialogModule,
        MatTabsModule,
        MatExpansionModule,
        MatStepperModule,
        DragDropModule,
        ElementsModule
        ],
    providers   : [
        {
            provide : MAT_DATE_FORMATS,
            useValue: {
                parse  : {
                    dateInput: moment.ISO_8601
                },
                display: {
                    dateInput         : 'LL',
                    monthYearLabel    : 'MMM YYYY',
                    dateA11yLabel     : 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                }
            }
        }
    ]
})
export class ClientModule
{
    constructor() {
        console.log('ClientModule loaded');
    }
}
