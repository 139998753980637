<ng-container *ngIf="!isLoadingError; else loadingError">
  <form class="mb-8">
    <div class="flex flex-row items-center justify-between">
      <div class="flex flex-grow items-center">
        <mat-select [(value)]="selectedMedicalHistory" placeholder="{{translateStrings.medicalHistory.singular}}"
          (selectionChange)="handleMedicalHistoryChange($event)" class="w-9/12 min-h-6 p-4 pl-6 rounded-full bg-hover">
          <mat-option class="text-black" *ngFor="let mh of medicalHistories" [value]="mh">{{mh?.REFERENCE_NAME}}
            ({{mh?._creationTime | date: 'longDate'}})</mat-option>
        </mat-select>
        <div class="ml-4">
          <button mat-icon-button color="accent" (click)="newAnamnesi()">
            <mat-icon>add</mat-icon>
          </button>
          <button mat-icon-button color="accent" (click)="cloneAnamnesi()" [disabled]="!currentMedicalHistory">
            <mat-icon>content_copy</mat-icon>
            <!-- <span>{{translateStrings.generic.clone}}</span> -->
          </button>
          <button mat-icon-button color="accent" (click)="editAnamnesi()" [disabled]="!currentMedicalHistory">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
      <div class="flex flex-none items-center">
        <button mat-icon-button (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu" aria-label="Toggle menu"
          [disabled]="!currentMedicalHistory || isEditing">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <!-- <button mat-menu-item (click)="editAnamnesi(true)">
          <mat-icon>edit</mat-icon>
          <span>{{translateStrings.generic.edit}}</span>
        </button> -->
          <!-- <button mat-menu-item (click)="viewAnamnesiReportPDF()"> -->
          <button mat-menu-item (click)="proceedWithReportGeneration()">
            <mat-icon>picture_as_pdf</mat-icon>
            <span>{{translateStrings.generic.viewReport}} PDF</span>
          </button>
          <button mat-menu-item (click)="viewAnamnesiPDF()">
            <mat-icon>picture_as_pdf</mat-icon>
            <span>{{translateStrings.generic.view}} PDF</span>
          </button>
          <!-- <button mat-menu-item (click)="downloadAnamnesi()">
          <mat-icon>download</mat-icon>
          <span>{{translateStrings.generic.download}} CSV</span>
        </button> -->
          <button mat-menu-item (click)="deleteAnamnesi()" class="text-red-400">
            <mat-icon class="text-red-400">delete</mat-icon>
            <span>{{translateStrings.generic.delete}}</span>
          </button>
          <!-- <button mat-menu-item (click)="cloneAnamnesi()">
          <mat-icon>content_copy</mat-icon>
          <span>{{translateStrings.generic.clone}}</span>
        </button> -->
        </mat-menu>
      </div>
    </div>
  </form>
  <!-- 
<fuse-alert
    [appearance]="'soft'"
    [dismissible]="true"
    [dismissed]="false"
    [name]="'alertBox2'"
    [type]="'info'" class="my-4">
    This one has a lot of text so you can see how the text is going to be displayed.
    Also this is a dismissible alert box which can be dismissed by clicking the <b>dismiss</b> button.
</fuse-alert> -->

  <!-- Anamnesi Show -->
  <ng-container>
    <div class="mt-8 ng-star-inserted">
      <div *ngIf="currentMedicalHistory && !isEditing" #chartsBox>
        <div class="mb-4 text-stone-400">{{translateStrings.generic.generated}}
          <strong>{{currentMedicalHistory.creationDate}}</strong> {{translateStrings.generic.at}}
          <strong>{{currentMedicalHistory.creationTime}}</strong>
        </div>

        <!-- <ng-container *ngIf="isPartialLoading['indexes']">
        <svg version="1.1" class="mx-auto" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
          enable-background="new 0 0 100 100" width="40px" height="100px" xml:space="preserve">
          <rect fill="var(--fuse-primary)" width="3" height="100" transform="translate(0) rotate(180 3 50)">
            <animate attributeName="height" attributeType="XML" dur="1s" values="30; 100; 30"
              repeatCount="indefinite" />
          </rect>
          <rect x="17" fill="var(--fuse-primary)" width="3" height="100" transform="translate(0) rotate(180 20 50)">
            <animate attributeName="height" attributeType="XML" dur="1s" values="30; 100; 30" repeatCount="indefinite"
              begin="0.1s" />
          </rect>
          <rect x="40" fill="var(--fuse-primary)" width="3" height="100" transform="translate(0) rotate(180 40 50)">
            <animate attributeName="height" attributeType="XML" dur="1s" values="30; 100; 30" repeatCount="indefinite"
              begin="0.3s" />
          </rect>
          <rect x="60" fill="var(--fuse-primary)" width="3" height="100" transform="translate(0) rotate(180 58 50)">
            <animate attributeName="height" attributeType="XML" dur="1s" values="30; 100; 30" repeatCount="indefinite"
              begin="0.5s" />
          </rect>
          <rect x="80" fill="var(--fuse-primary)" width="3" height="100" transform="translate(0) rotate(180 76 50)">
            <animate attributeName="height" attributeType="XML" dur="1s" values="30; 100; 30" repeatCount="indefinite"
              begin="0.1s" />
          </rect>
        </svg>
      </ng-container> -->
        <ng-container
          *ngIf="!isPartialLoading['indexes'] && currentMedicalHistory.answersRatioThreshold; else lowAnswersRatioThreshold">
          <div *ngIf="currentMedicalHistory?.indexes.BIOLOGICAL_AGE; else noBirthdateDefined"
            class="py-4 md:grid md:grid-cols-3 border-b">
            <dt>{{translateStrings.medicalHistory.BIOLOGICAL_AGE}}:
              <strong>{{currentMedicalHistory?.indexes.BIOLOGICAL_AGE}}</strong>
            </dt>
            <!-- <dd class="md:col-span-2">
            <bar [ratio]="currentMedicalHistory?.indexes.BIOLOGICAL_AGE_FACTOR_RATIO"></bar>
          </dd> -->
          </div>
          <div class="py-4 md:grid md:grid-cols-3 border-b">
            <dt>{{translateStrings.medicalHistory.INDEX_GENERAL_WELLNESS}}:
              <strong>{{currentMedicalHistory?.indexes.INDEX_GENERAL_WELLNESS}}</strong>
            </dt>
            <dd class="md:col-span-2">
              <bar [value]="currentMedicalHistory?.indexes.INDEX_GENERAL_WELLNESS_RATIO"
                [ratio]="currentMedicalHistory?.indexes.INDEX_GENERAL_WELLNESS_RATIO"></bar>
            </dd>
          </div>
          <div class="py-4 md:grid md:grid-cols-3 border-b">
            <dt>{{translateStrings.medicalHistory.INDEX_PHYSIOLOGICAL_WELLNESS}}:
              <strong>{{currentMedicalHistory?.originalSurveyObject.INDEX_PHYSIOLOGICAL_WELLNESS}}</strong>
            </dt>
            <dd class="md:col-span-2">
              <bar [value]="currentMedicalHistory?.originalSurveyObject.INDEX_PHYSIOLOGICAL_WELLNESS"
                [ratio]="currentMedicalHistory?.originalSurveyObject.INDEX_PHYSIOLOGICAL_WELLNESS_RATIO"></bar>
            </dd>
          </div>
          <div class="py-4 md:grid md:grid-cols-3 border-b">
            <dt>{{translateStrings.medicalHistory.INDEX_PSYCHOPHYSICAL_WELLNESS}}:
              <strong>{{currentMedicalHistory?.originalSurveyObject.INDEX_PSYCHOPHYSICAL_WELLNESS}}</strong>
            </dt>
            <dd class="md:col-span-2">
              <bar [value]="currentMedicalHistory?.originalSurveyObject.INDEX_PSYCHOPHYSICAL_WELLNESS"
                [ratio]="currentMedicalHistory?.originalSurveyObject.INDEX_PSYCHOPHYSICAL_WELLNESS_RATIO"></bar>
            </dd>
          </div>
          <div class="py-4 md:grid md:grid-cols-3 border-b">
            <dt>{{translateStrings.medicalHistory.INDEX_DERMOESTHETIC_WELLNESS}}:
              <strong>{{currentMedicalHistory?.originalSurveyObject.INDEX_DERMOESTHETIC_WELLNESS}}</strong>
            </dt>
            <dd class="md:col-span-2">
              <bar [value]="currentMedicalHistory?.originalSurveyObject.INDEX_PSYCHOPHYSICAL_WELLNESS"
                [ratio]="currentMedicalHistory?.originalSurveyObject.INDEX_DERMOESTHETIC_WELLNESS_RATIO"></bar>
            </dd>
          </div>
          <div class="py-4 md:grid md:grid-cols-3 border-b">
            <dt>{{translateStrings.medicalHistory.INDEX_uC}}:
              <strong>{{currentMedicalHistory?.originalSurveyObject.INDEX_uC}}</strong>
            </dt>
            <dd class="md:col-span-2">
              <bar [value]="currentMedicalHistory?.originalSurveyObject.INDEX_uC"
                [ratio]="currentMedicalHistory?.originalSurveyObject.INDEX_uC_RATIO"></bar>
            </dd>
          </div>
          <div class="py-4 md:grid md:grid-cols-3 border-b">
            <dt>{{translateStrings.medicalHistory.INDEX_ReD}}:
              <strong>{{currentMedicalHistory?.originalSurveyObject.INDEX_ReD}}</strong>
            </dt>
            <dd class="md:col-span-2">
              <bar [value]="currentMedicalHistory?.originalSurveyObject.INDEX_ReD"
                [ratio]="currentMedicalHistory?.originalSurveyObject.INDEX_ReD_RATIO"></bar>
            </dd>
          </div>
          <div class="py-4 md:grid md:grid-cols-3 border-b">
            <dt>{{translateStrings.medicalHistory.INDEX_AB}}:
              <strong>{{currentMedicalHistory?.originalSurveyObject.INDEX_AB}}</strong>
            </dt>
            <dd class="md:col-span-2">
              <bar [value]="currentMedicalHistory?.originalSurveyObject.INDEX_AB"
                [ratio]="currentMedicalHistory?.originalSurveyObject.INDEX_AB_RATIO"></bar>
            </dd>
          </div>

          <!-- <div *ngIf="currentMedicalHistory && !isEditing" #chartsBox>
      <div class="py-4 md:grid md:grid-cols-3 border-b">
        <dt>Benessere Psicologico:
          <strong>{{currentMedicalHistory?.originalSurveyObject.INDEX_PHYSIOLOGICAL_WELLNESS}}</strong>
        </dt>
        <dd class="md:col-span-2">
          <div [attr.id]="'plwi'" style="width: 100%; height: 120px"></div>
        </dd>
      </div>
      <div class="py-4 md:grid md:grid-cols-3 border-b">
        <dt>Benessere Psicofisico:
          <strong>{{currentMedicalHistory?.originalSurveyObject.INDEX_PSYCHOPHYSICAL_WELLNESS}}</strong>
        </dt>
        <dd class="md:col-span-2">
          <div [attr.id]="'ppwi'" style="width: 100%; height: 120px"></div>
        </dd>
      </div>
      <div class="py-4 md:grid md:grid-cols-3 border-b">
        <dt>Benessere Dermoestetico:
          <strong>{{currentMedicalHistory?.originalSurveyObject.INDEX_DERMOESTHETIC_WELLNESS}}</strong>
        </dt>
        <dd class="md:col-span-2">
          <div [attr.id]="'dewi'" style="width: 100%; height: 120px"></div>
        </dd>
      </div>
      <div class="py-4 md:grid md:grid-cols-3 border-b">
        <dt>MicroCircolo <strong>{{currentMedicalHistory?.originalSurveyObject.INDEX_uC}}</strong></dt>
        <dd class="md:col-span-2">
          <div [attr.id]="'uC'" style="width: 100%; height: 120px"></div>
        </dd>
      </div>
      <div class="py-4 md:grid md:grid-cols-3 border-b">
        <dt>Ossidoriduttivo <strong>{{currentMedicalHistory?.originalSurveyObject.INDEX_ReD}}</strong></dt>
        <dd class="md:col-span-2">
          <div [attr.id]="'ReD'" style="width: 100%; height: 120px"></div>
        </dd>
      </div>
      <div class="py-4 md:grid md:grid-cols-3 border-b">
        <dt>Carico Allostatico <strong>{{currentMedicalHistory?.originalSurveyObject.INDEX_AB}}</strong></dt>
        <dd class="md:col-span-2">
          <div [attr.id]="'AB'" style="width: 100%; height: 120px"></div>
        </dd>
      </div> -->
        </ng-container>
        <ng-container *ngIf="!isPartialLoading['indexes'] && !currentMedicalHistory.answersRatioThreshold">
          <div
            class="flex items-center p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
            role="alert">
            <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="currentColor" viewBox="0 0 20 20">
              <path
                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span class="sr-only">Info</span>
            <div class="ml-2">
              {{translateStrings.medicalHistory.lowAnswersRatioThreshold}} <ng-container
                *ngIf="currentMedicalHistory?.answersRatio">({{translateStrings.medicalHistory.answersRatio}}
                {{currentMedicalHistory?.answersRatio | percent}})</ng-container>
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="currentMedicalHistory && !isEditing">
        <ng-container *ngFor="let answers of currentMedicalHistory.answers">
          <div class="border-b py-3">
            <div class="text-lg font-semibold pb-1">{{answers.question}}</div>
            <div class="text-lg">{{answers.answer}}</div>
          </div>
        </ng-container>
      </ng-container>

      <div *ngIf="(!isLoading() && isEditing)"
        class="flex-col sm:flex-row md:flex-col flex-auto justify-between py-8 border-t">
        <form id="anamnesiForm" [formGroup]="anamnesiFormGroup" (ngSubmit)="submitAnamnesi($event)"
          #anamnesiForm="ngForm" *ngIf="anamnesiFormGroup">

          <div class="flex flex-col gt-xs:flex-row">
            <ng-container *ngFor="let field of newSurvey" [ngSwitch]="field.type.toLowerCase()">

              <ng-container *ngSwitchCase="'string'">
                <mat-form-field class="flex-auto gt-xs:pr-3 pb-4" *ngIf="!anamnesiFormGroup.get(field.key).disabled || isSaving">
                  <mat-label class="text-black">{{translateAnamnesiQuestions(field.key)}}</mat-label>
                  <input class="text-black" matInput formControlName="{{field.key}}">
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="'long'">
                <!-- <mat-form-field class="flex-auto gt-xs:pr-3 pb-4" *ngIf="!anamnesiFormGroup.get(field.key).disabled"> -->
                <mat-form-field class="flex-auto gt-xs:pr-3 pb-4" *ngIf="!anamnesiFormGroup.get(field.key).disabled || isSaving">
                  <mat-label class="text-black">{{translateAnamnesiQuestions(field.key)}}</mat-label>
                  <input class="text-black" matInput formControlName="{{field.key}}">
                  <div
                    *ngIf="anamnesiFormGroup.get(field.key).invalid && (anamnesiFormGroup.get(field.key).dirty || anamnesiFormGroup.get(field.key).touched)"
                    class="text-red-600">
                    {{translateStrings['generic']['validatorLong']}}
                  </div>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="'double'">
                <mat-form-field class="flex-auto gt-xs:pr-3 pb-4" *ngIf="!anamnesiFormGroup.get(field.key).disabled || isSaving">
                  <mat-label class="text-black">{{translateAnamnesiQuestions(field.key)}}</mat-label>
                  <input class="text-black" matInput formControlName="{{field.key}}">
                  <div
                    *ngIf="anamnesiFormGroup.get(field.key).invalid && (anamnesiFormGroup.get(field.key).dirty || anamnesiFormGroup.get(field.key).touched)"
                    class="text-red-600">
                    {{translateStrings['generic']['validatorDouble']}}
                  </div>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="'enum'">
                <mat-form-field class="flex-auto gt-xs:pl-3 pb-4" *ngIf="!anamnesiFormGroup.get(field.key).disabled || isSaving">
                  <mat-label class="text-black">{{translateAnamnesiQuestions(field.key)}}</mat-label>
                  <mat-select formControlName="{{field.key}}">
                    <mat-option class="text-black" *ngFor="let option of field.values"
                      [value]="option">{{translateAnamnesiAnswers(field.key, option)}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="'json'">
                <mat-form-field class="flex-auto gt-xs:pl-3 pb-4" *ngIf="!anamnesiFormGroup.get(field.key).disabled || isSaving">
                  <mat-label class="text-black">{{translateAnamnesiQuestions(field.key)}}</mat-label>
                  <mat-select formControlName="{{field.key}}" multiple>
                    <mat-option class="text-black" *ngFor="let option of field.values"
                      [value]="option">{{translateAnamnesiAnswers(field.key, option)}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="'bool'">
                <div class="mat-form-field gt-xs:pr-3 mb-4 flex flex-col gap-1 pb-4" *ngIf="!anamnesiFormGroup.get(field.key).disabled || isSaving">
                  <mat-label class="font-medium text-black mb-1">{{translateAnamnesiQuestions(field.key)}}</mat-label>
                  <mat-radio-group class="flex flex-col gap-3 gt-xs:pl-3" formControlName="{{field.key}}">
                    <mat-radio-button class="text-black" [value]="true">{{translateStrings.generic.yes}}
                    </mat-radio-button>
                    <mat-radio-button class="text-black" [value]="false">{{translateStrings.generic.no}}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </ng-container>

            </ng-container>
          </div>

          <div
            class="flex flex-row justify-between py-6 gap-x-4 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
            <div>
              <button *ngIf="isEditing && !isSpecificLoading['save'] && !isSpecificLoading['saveAndClose']" id="cancel"
                type="button" mat-flat-button [color]="'primary'"
                class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                (click)="onCancel($event); $event.preventDefault()">
                {{translateStrings.generic.cancel}}
              </button>
            </div>
            <div class="flex flex-row gap-x-4">
              <button id="save" type="submit" mat-flat-button [color]="'primary'" class="rounded-lg"
                [disabled]="!anamnesiFormGroup.valid">
                <span *ngIf="!isSpecificLoading['save']">{{translateStrings.generic.save}}
                </span>
                <mat-progress-spinner *ngIf="isSpecificLoading['save']" [diameter]="24"
                  [mode]="'indeterminate'"></mat-progress-spinner>

              </button>
              <button id="saveAndClose" type="submit" mat-flat-button [color]="'primary'" class="rounded-lg"
                [disabled]="!anamnesiFormGroup.valid">
                <span *ngIf="!isSpecificLoading['saveAndClose']">
                  {{translateStrings.generic.saveAndClose}}
                </span>
                <mat-progress-spinner *ngIf="isSpecificLoading['saveAndClose']" [diameter]="24"
                  [mode]="'indeterminate'"></mat-progress-spinner>
              </button>
            </div>
          </div>

        </form>

      </div>
    </div>
  </ng-container>

  <ng-template #noBirthdateDefined>
    <div
      class="flex items-center p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
      role="alert">
      <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
        fill="currentColor" viewBox="0 0 20 20">
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>
      <span class="sr-only">Info</span>
      <div class="ml-2">
        {{translateStrings.medicalHistory.noBirthdateDefined}}
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #loadingError>
  <img src="/assets/images/ui/beautycians/error_503.jpg" alt="Error" class="w-2/6 mx-auto">
  <div class="text-2xl font-semibold tracking-tight text-center w-4/6 sm:w-full mx-auto">
    {{translateStrings.generic.error503}}</div>
</ng-template>

<ng-template #snackbarErrorTemplate>
  <span class="text-warn-700">{{translateStrings.medicalHistory.cantSave}}</span>
</ng-template>