import {BaseElement} from '../base-element/base-element';
import {ContextElement} from './context-element';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {GruulsTranslateServiceInterface} from '../../../service-interfaces/gruuls-translate-service-interface';
import {GruulsNumberFormatterServiceInterface} from '../../../service-interfaces/gruuls-number-formatter-service-interface';
import {GruulsRoutingServiceInterface} from '../../../service-interfaces/gruuls-routing-service-interface';
import {ElementCommandMessage} from '../base-element/element-command-message';
import {ElementCommandReply} from '../base-element/element-command-reply';
import { GruulsAuthService } from '@gruuls-fe/services/gruuls-auth.service';
import { GruulsAuthServiceInterface } from '@gruuls-core/service-interfaces/gruuls-auth-service-interface';

export class SandboxedContext {

    data: any;
    elements: {
        [elementName: string]: BaseElement;
    };
    translateService: GruulsTranslateServiceInterface;
    numberFormatterService: GruulsNumberFormatterServiceInterface;
    routingService: GruulsRoutingServiceInterface;
    authService: GruulsAuthServiceInterface;

    dataChangesSubject: Subject<any> = new Subject<any>();
    elementsChangesSubject: Subject<any> = new Subject<any>();

    constructor(private context: ContextElement) {
        this.data = context.data;
        this.elements = context.elementList.reduce((acc, value) => {
            acc[value.config.name] = value;
            return acc;
        }, {});
        this.translateService = context.translateService;
        this.numberFormatterService = context.numberFormatterService;
        this.routingService = context.routingService;
        this.authService = context.authService;
    }

    subscribeForChanges(path: string): Observable<any>{
        const pathArr = path.split('.');
        const el = pathArr[0];
        path = pathArr.splice(0,1).join('.');
        let changesSubject: Subject<any>;
        switch (el){
            case 'data':
                changesSubject = this.dataChangesSubject;
                break;
            case 'elements':
                changesSubject = this.elementsChangesSubject;
                break;
            default:
                changesSubject = new BehaviorSubject(undefined);
                break;
        }

        return changesSubject
            .asObservable()
            .pipe(
                filter(changes => changes && changes.currentPath === path),
                map(changes => changes ? changes.newValue : undefined)
            );
    }

    executeCommand(command: ElementCommandMessage): Observable<ElementCommandReply>{
        return this.context.executeCommand(command);
    }
}
