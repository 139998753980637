import { GruulsMockDomain, GruulsMockHttpRequest } from '../../gruuls-mock-domain';
import { HttpClient } from '@angular/common/http';

export class BeautyHealthPriorityMock extends GruulsMockDomain {

  constructor(httpClient: HttpClient) {
    super(httpClient);

    this.entities = this.GET_PRIORITY;
  }

  GET_PRIORITY = [
    { uuid: '639f1881-84d0-44e3-b2a5-d094cdca52a1', title: 'REIDRATAZIONE', label: 'Reidratazione', code: 'REIDR', factors: ['IBF_acq_tot', 'IBF_qnt_acq', 'IBF_calcoli_renali', 'IBD_idratz', 'POC_urine_prot', 'POC_urine_dens'], max: 13 },
    { uuid: 'f8b79dee-080f-49de-9e85-c58fda339b41', title: 'IPERSENSIBILITA', label: 'Ipersensibilita', code: 'IPERS', factors: ['IBF_allg_poll', 'IBF_allg_altre', 'IBD_sensibilita', 'IBD_sensaz_cliente_bruciore'], max: 7 },
    { uuid: '47f44116-c2b5-4f5a-874f-c0989db96fe1', title: 'INFIAMMAZIONE CRONICA DI BASSO GRADO', label: 'Infiammazione Cronica Di Basso Grado', code: 'INFIAM', factors: ['IBF_gamb_pst', 'IBF_crampi_muscl_nott', 'IBF_gastr_refl', 'IBP_stato_ben', 'IBP_sonno', 'IBD_presz_infmz'], max: 15 },
    { uuid: '7a7ea437-1eeb-48a5-af63-68c5d7d0c8ef', title: 'CARICO OSSIDATIVO', label: 'Carico Ossidativo', code: 'CARIC', factors: ['IBP_stato_ben', 'IBD_color_cutn', 'IBD_presz_crom', 'POC_fumo', 'POC_Car_Ox'], max: 9 },
    { uuid: 'a3875964-f24e-4f7e-8098-ea8d8e830c3e', title: 'BASSO INDICE GLICEMICO', label: 'Basso Indice Glicemico', code: 'GLI', factors: ['POC_urine_glu'], max: 1 },
    { uuid: '73bacace-924b-4ffc-9e4d-e4959cba8e89', title: 'DETOSSIFICAZIONE', label: 'Detossificazione', code: 'DETOX', factors: ['IBF_diff_prendere_peso', 'IBD_presenz_cellu_loc_tipologia', 'IBD_presenz_cellu_diff', 'IBD_edema_arti_inf', 'IBD_senz_gonf_add', 'POC_urine_bilru', 'POC_urine_urobil'], max: 13 },
    { uuid: '01e49859-6e67-49b1-87e1-2474f26c1712', title: 'SOVRAPPESO', label: 'Sovrappeso', code: 'SOVRA', factors: ['IBF_mas_grassa', 'IBF_BMI', 'IBD_presenz_pann_adp', 'IBD_presenz_adp_diff'], max: 10 },
    { uuid: '1e70e4ab-fc97-4d78-8eae-2f91734cc169', title: 'SALUTE CARDIOVASCOLARE', label: 'Salute Cardiovascolare', code: 'CARDIO', factors: ['IBF_circ_vita', 'IBF_aum_kg'], max: 6 }
  ];
}









