import {Inject, Injectable} from '@angular/core';
import {HashMap, TranslocoService} from '@ngneat/transloco';
import {GruulsTranslateServiceInterface} from '../../@gruuls-core/service-interfaces/gruuls-translate-service-interface';
import {Observable} from 'rxjs';

@Injectable()
export class GruulsAngularTranslateService implements GruulsTranslateServiceInterface{

    constructor(private translocoService: TranslocoService
    ) {}

    translate(value: string, params?: HashMap): Observable<string> {
        return this.translocoService.translate(value, params);
    }
}
