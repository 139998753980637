import {FieldType} from './field-type';
import {CollectionType} from './collection-type';
import _lodash from 'lodash';
import {Field} from './field';
import {FieldConfig} from '../domain/templates-config';
import {ReferenceOrganizationField} from './fields/reference-organization-field';
import {DataSchema} from './data-schema';
import {Observable} from 'rxjs';
import { FormElementsAngularUtils } from '@gruuls-fe/utils/form-elements-angular-utils';
import {AddressField} from './fields/address-field';
import { GruulsJsonFieldConfig } from './fields/gruuls-json-field-config';
import {EnumField} from './fields/enum-field';
import {GeopolygonFieldConfig} from './fields/geopolygon/geopolygon-field-config';
import {GeopolygonField} from './fields/geopolygon/geopolygon-field';
import {GeopointFieldConfig} from './fields/geopoint/geopoint-field-config';
import {GeopointField} from './fields/geopoint/geopoint-field';
import {DateRangeField} from './fields/date-range-field';
import {OneToManyFieldConfig} from './fields/one-to-many/one-to-many-field-config';
import {OneToManyField} from './fields/one-to-many/one-to-many-field';
import { InverseOneToManyFieldConfig } from './fields/inverse-one-to-many/inverse-one-to-many-field-config';
import { InverseOneToManyField } from './fields/inverse-one-to-many/inverse-one-to-many-field';
import {ManyToOneFieldConfig} from './fields/many-to-one/many-to-one-field-config';
import { ManyToOneField } from './fields/many-to-one/many-to-one-field';
import {OpeningHoursField} from './fields/opening-hours-field';
import {ConditionalFieldByEnumField} from './fields/conditional-field-by-enum-field';
import {InverseManyToOneFieldConfig} from './fields/inverse-many-to-one/inverse-many-to-one-field-config';
import { InverseManyToOneField } from './fields/inverse-many-to-one/inverse-many-to-one-field';

const _ = _lodash;

export class Fields {

    public static fieldsNameMapping = {
        UNIQUE_KEY : 'uniqueKey',
        STRING: 'string',
        DOUBLE: 'double',
        BOOLEAN: 'boolean',
        ENUM: 'enum',
        JSON : 'serializedJsonObject',
        MANY_TO_ONE: 'manyToOne' ,
        ONE_TO_MANY: 'oneToMany',
        INVERSE_MANY_TO_ONE: 'inverseManyToOne',
        PASSWORD: 'password',
        LONG: 'long',
        VALUE_OBJECT: 'valueObject',
        DATE_TIME: 'date',
        GEO_POLYGON: 'geoPolygon',
        GEO_POINT: 'geoPoint',
        CONDITIONAL_FIELDS_BY_ENUM: 'conditionalFieldsByEnumValues',
        HOUR_MINUTE: 'openingHours',

        // TODO!!
        ALIAS: 'string',
        CHILD_RELATIONSHIP: 'string',
        CUSTOM_KEY_VALUES: 'string',
        FILE: 'string',
        CALCULATED: 'string',
        INVERSE_ONE_TO_MANY: 'string',
        INVERSE_ONE_TO_MANY_VO: 'string',
        MAC_ADDRESS: 'string',
        PHANTOM_ORG: 'string',
        PARENT_RELATIONSHIP: 'string',
        ONE_TO_MANY_VO: 'string',
        REFERENCE_ORG: 'string',
        SERVICE_ROLES_NAVIGATION_RESOURCES: 'string',
        SERVICE_ROLES_RESOURCE_MAP: 'string',
        VO_PARENT: 'string',
        INVERSE_MANY_TO_ONE_VO: 'string',
        AGGREGATE_STATUS: 'string',
        BELONGING_ORGANIZATION: 'string',
        AGGREGATE_CREATION_TIMESTAMP: 'string',
        AGGREGATE_CREATION_USER: 'string',
        AGGREGATE_LOGICAL_DELETE_TIMESTAMP: 'string',
        AGGREGATE_UPDATE_TIMESTAMP: 'string',
        AGGREGATE_UPDATE_USER: 'string',
        SYSTEM_UNIQUE_KEY: 'string'
    };

    public static counter = 0;

    public static uniqueKey(config: {
        name: string;
        fieldConfig?: FieldConfig;
    }): Field {
        Fields.counter = Fields.counter++;
        return Field.ofConfig(_.defaultsDeep(
            {
                type: FieldType.uniqueKey
            },
            config,
            {
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.simpleFormControlElement
                }
            }));
    }

    public static address(config: {
        name: string;
        collectionType?: CollectionType;
        fieldConfig?: FieldConfig;
        modelType?: string;
        latFieldName?: string; // for geoPoint and address
        lonFieldName?: string; // for geoPoint and address
        zipFieldName?: string; // for address
        cityFieldName?: string;
        countryFieldName?: string;
        streetFieldName?: string;
        civicNumberFieldName?: string;
        textualAddressFieldName?: string;
    }): Field {
        Fields.counter = Fields.counter++;
        return AddressField.ofConfig(config);
    }

    public static string_(config: {
        name: string;
        collectionType?: CollectionType;
        fieldConfig?: FieldConfig;
        templateName?: string;
    }): Field {
        Fields.counter = Fields.counter++;
        return Field.ofConfig(_.defaultsDeep(
            {
                type: FieldType.string
            },
            config,
            {
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.simpleFormControlElement
                }
            }));
    }

    public static password(config: {
        name: string;
        collectionType?: CollectionType;
        fieldConfig?: FieldConfig;
        templateName?: string;
    }): Field {
        Fields.counter = Fields.counter++;
        return Field.ofConfig(_.defaultsDeep(
            {
                type: FieldType.password
            },
            config,
            {
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.simpleFormControlElement
                }
            }));
    }

    public static referenceOrganization(config: {
        name: string;
    }): ReferenceOrganizationField {
        Fields.counter = Fields.counter++;
        return ReferenceOrganizationField.ofConfig(_.defaultsDeep(
            {
                type: FieldType.referenceOrganization
            },
            config,
            {
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.simpleFormControlElement
                }
            }));
    }

    public static simpleText(config: {
        name: string;
        collectionType?: CollectionType;
        fieldConfig?: FieldConfig;
    }): Field {
        Fields.counter = Fields.counter++;
        return Field.ofConfig(_.defaultsDeep(
            {
                type: FieldType.simpleText
            },
            config,
            {
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.simpleFormControlElement
                }
            }));
    }

    public static boolean_(config: {
        name: string;
        collectionType?: CollectionType;
        fieldConfig?: FieldConfig;
    }): Field {
        Fields.counter = Fields.counter++;
        return Field.ofConfig(_.defaultsDeep(
            {
                type: FieldType.boolean
            },
            config,
            {
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.simpleFormControlElement
                }
            }));
    }

    public static long(config: {
        name: string;
        collectionType?: CollectionType;
        fieldConfig?: FieldConfig;
    }): Field {
        Fields.counter = Fields.counter++;
        return Field.ofConfig(_.defaultsDeep(
            {
                type: FieldType.long
            },
            config,
            {
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.simpleFormControlElement
                }
            }));
    }

    public static double(config: {
        name: string;
        collectionType?: CollectionType;
        fieldConfig?: FieldConfig;
    }): Field {
        Fields.counter = Fields.counter++;
        return Field.ofConfig(_.defaultsDeep(
            {
                type: FieldType.double
            },
            config,
            {
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.simpleFormControlElement
                }
            }));
    }

    public static serializedJsonObject(config: {
        name: string;
        collectionType?: CollectionType;
        fieldConfig?: GruulsJsonFieldConfig;
        subFields?: Field[];
    }): Field {
        Fields.counter = Fields.counter++;
        return Field.ofConfig(_.defaultsDeep(
            {
                type: FieldType.json
            },
            config,
            {
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: {
                        build: (schema: DataSchema, field: Field, fieldConfig: FieldConfig, data: any, considerAsSingle: boolean): Observable<any> => {
                            if (field.subFields?.length) {
                                return FormElementsAngularUtils.subFieldsFormControlElement.build(schema, field, fieldConfig, data, considerAsSingle);
                            } else {
                                return FormElementsAngularUtils.simpleFormControlElement.build(schema, field, fieldConfig, data, considerAsSingle);
                            }
                        }
                    },
                    useJsonSerde: true
                }
            }));
    }

    public static valueObject(config: {
        name: string;
        subFields: Field[];
        collectionType?: CollectionType;
        fieldConfig?: FieldConfig;
    }): Field {
        Fields.counter = Fields.counter++;
        return Field.ofConfig(_.defaultsDeep(
            {
                type: FieldType.valueObject
            },
            config,
            {
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.subFieldsFormControlElement
                },
                systemSubFields: [
                    Fields.string_({
                        name: '_id'
                    }),
                    Fields.date({
                        name: '_creationDate'
                    }),
                    Fields.date({
                        name: '_updateDate'
                    })
                ]

            }));
    }

    public static enum(config: {
        name: string;
        enumValues: string[];
        collectionType?: CollectionType;
        fieldConfig?: FieldConfig;
    }): Field {
        return EnumField.ofConfig(config);
    }

    public static conditionalFieldsByEnumValues(config: {
        name: string;
        conditionalFieldsByEnumValues: { [enumValue: string]: string[] };
        collectionType?: CollectionType;
        fieldConfig?: FieldConfig;
    }): Field {
        return ConditionalFieldByEnumField.ofConfig(config);
    }

    public static geoPolygon(config: {
        name: string;
        modelType?: string;
        templateName?: string;
        collectionType?: CollectionType;
        fieldConfig?: GeopolygonFieldConfig;
        storeGeohashesFieldName?: string;
    }): Field {
        return GeopolygonField.ofConfig(config);
    }

    public static geoPoint(config: {
        name: string;
        modelType?: string;
        templateName?: string;
        latFieldName: string;
        lonFieldName: string;
        collectionType?: CollectionType;
        fieldConfig?: GeopointFieldConfig;
    }): Field {
        return GeopointField.ofConfig(config);
    }

    public static date(config: {
        name: string;
        collectionType?: CollectionType;
        fieldConfig?: FieldConfig;
    }): Field {
        Fields.counter = Fields.counter++;
        return Field.ofConfig(_.defaultsDeep(
            {
                type: FieldType.date
            },
            config,
            {
                collectionType: CollectionType.NONE,
                fieldConfig: {
                    ordinal: Fields.counter++,
                    uiFrameworkFormElementBuilder: FormElementsAngularUtils.simpleFormControlElement
                }
            }));
    }

    public static rangeDate(config: {
        name: string;
        modelType?: string;
        startFieldName: string;
        endFieldName: string;
        collectionType?: CollectionType;
        fieldConfig?: FieldConfig;
    }): Field {
        Fields.counter = Fields.counter++;

        return DateRangeField.ofConfig(config);
    }

    public static oneToMany(config: {
        name: string;
        otherDomain: string;
        otherDomainInverseFieldName?: string; // ??? should not be here
        fieldConfig?: OneToManyFieldConfig;
    }): Field {
        return OneToManyField.ofConfig(config);
    }

    public static inverseOneToMany(config: {
        name: string;
        otherDomain: string;
        fieldConfig?: InverseOneToManyFieldConfig;
    }): Field {
        return InverseOneToManyField.ofConfig(config);
    }

    public static inverseManyToOne(config: {
        name: string;
        otherDomain: string;
        fieldConfig?: InverseManyToOneFieldConfig;
    }): Field {
        return InverseManyToOneField.ofConfig(config);
    }

    public static manyToOne(config: {
        name: string;
        otherDomain: string;
        fieldConfig: ManyToOneFieldConfig;
    }): Field {
        return ManyToOneField.ofConfig(config);
    }

    public static openingHours(config: {
        name: string;
        modelType?: string;
        monFieldName?: string;
        tueFieldName?: string;
        wedFieldName?: string;
        thuFieldName?: string;
        friFieldName?: string;
        satFieldName?: string;
        sunFieldName?: string;
        fieldConfig?: FieldConfig;
    }): Field {
        return OpeningHoursField.ofConfig(config);
    }


    public static ofJsonConfig(config: any): Field {
        const f = Fields[Fields.fieldsNameMapping[config.type]];
        if (f) {
            if (config.subFields && config.subFields.length) {
                const fields: Field[] = [];
                for (const subF of config.subFields) {
                    const field: Field = Fields.ofJsonConfig(subF);
                    if (field) {
                        fields.push(field);
                    }
                }
                config.subFields = fields;
            }
            return f(config);
        } else {
            console.warn('could not find field of type \'' + config.type + '\' with config:', config);
        }
    }



}
