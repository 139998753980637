export enum FieldType {
    // eslint-disable-next-line id-blacklist
    string = 'string',
    long = 'long',
    valueObject = 'valueObject',
    // eslint-disable-next-line id-blacklist
    boolean = 'boolean',
    uniqueKey = 'uniqueKey',
    simpleText = 'simpleText',
    json = 'json',
    inverseOneToMany = 'inverseOneToMany',
    oneToMany = 'oneToMany',
    enum = 'enum',
    conditionalFieldsByEnumValues = 'conditionalFieldsByEnumValues',
    date = 'date',
    dateRange = 'dateRange',
    geoPolygon = 'geoPolygon',
    geoPoint = 'geoPoint',
    address = 'address',
    openingHours = 'openingHours',
    manyToOne = 'manyToOne',
    referenceOrganization = 'referenceOrganization',
    password = 'password',
    double= 'double',
}
