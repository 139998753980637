import {BaseElement} from './misc/base-element/base-element';
import {Field} from '../domain/data-schema/field';
import {FieldType} from '../domain/data-schema/field-type';
import {BaseElementConfig, BaseElementConfigInterface} from './misc/base-element/base-element-config';
import {defer, from, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {GroupElement} from './misc/group-element/group-element';
import {GruulsFrameworkServicesInterface} from '@gruuls-core/service-interfaces/gruuls-framework-services-interface';
import {GruulsTranslateServiceInterface} from '../service-interfaces/gruuls-translate-service-interface';
import {GruulsNumberFormatterServiceInterface} from '../service-interfaces/gruuls-number-formatter-service-interface';
import {GruulsRoutingServiceInterface} from '../service-interfaces/gruuls-routing-service-interface';
import {GruulsAngularHttpProxyService} from '../../@gruuls-fe/services/gruuls-angular-http-proxy.service';
import { GruulsAuthServiceInterface } from '@gruuls-core/service-interfaces/gruuls-auth-service-interface';

export class ElementsFactory {

// Returns the observable of the specific element to subscribe to
    public create(config: BaseElementConfigInterface, frameworkServices?: GruulsFrameworkServicesInterface): Observable<BaseElement> {
        switch (config.type) {
            // Elemento base
            case 'BaseElement':
                return of(new BaseElement(config));
            case 'DrawerElement':
                return defer(() => import('@gruuls-core/elements/ui/drawer-element/drawer-element'))
                    .pipe(
                        map(el => new (el.DrawerElement as new (cfg) => any)(config))
                    );
            case 'RouterOutletElement':
                return defer(() => import('@gruuls-core/elements/misc/router-outlet-element/router-outlet-element'))
                    .pipe(
                        map(el => new (el.RouterOutletElement as new (cfg) => any)(config))
                    );
                // è l'elemento di contesto dove tutti gli elementi all'interno possono sfruttare il contesto per fare altre cose.
            case 'ContextElement':
                return defer(() => import('@gruuls-core/elements/misc/context-element/context-element'))
                    .pipe(
                        map(el =>
                            new (el.ContextElement as new (
                                cfg: BaseElementConfigInterface,
                                translateService: GruulsTranslateServiceInterface,
                                numberFormatterService: GruulsNumberFormatterServiceInterface,
                                routingService: GruulsRoutingServiceInterface,
                                httpService: GruulsAngularHttpProxyService,
                                authService: GruulsAuthServiceInterface
                            ) => any)
                            (
                                config,
                                frameworkServices?.translateService,
                                frameworkServices?.numberFormatterService,
                                frameworkServices?.routingService,
                                frameworkServices?.httpService,
                                frameworkServices?.authService
                            )
                        )
                    );
            // è un gruppo applicato a un layouting (come dispone gli elementi di un gruppo?)
            case 'DynamicFlexContainer':
                return defer(() => import('@gruuls-core/elements/ui-builders/dynamic-flex-container-element/dynamic-flex-container-element'))
                    .pipe(
                        map(el => new (el.DynamicFlexContainerElement as new (cfg) => any)(config))
                    );
                // è un raggruppamento di elementi
            case 'GroupElement':
                return defer(() => import('@gruuls-core/elements/misc/group-element/group-element'))
                    .pipe(
                        map(el => new (el.GroupElement as new (cfg) => any)(config))
                    );
            case 'HttpElement':
                return defer(() => import('@gruuls-core/elements/data-sources/http-element/http-element'))
                    .pipe(
                        map(el => new (el.HttpElement as new (cfg) => any)(config))
                    );
            // case 'TwoWayBind':
            //     return defer(() => import('@gruuls-core/elements/misc/two-way-bind-element/two-way-bind-element'))
            //         .pipe(
            //             map(el => new (el.TwoWayBindElement as new (cfg) => any)(config))
            //         );
            case 'ListElement':
                return defer(() => import('@gruuls-core/elements/misc/list-element/list-element'))
                    .pipe(
                        map(el => new (el.ListElement as new (cfg) => any)(config))
                    );
            case 'CoerceNumberElement':
                return defer(() => import('@gruuls-core/elements/coercers/coerce-number-element/coerce-number-element'))
                    .pipe(
                        map(el => new (el.CoerceNumberElement as new (cfg) => any)(config))
                    );
            case 'CoerceBooleanElement':
                return defer(() => import('@gruuls-core/elements/coercers/coerce-boolean-element/coerce-boolean-element'))
                    .pipe(
                        map(el => new (el.CoerceBooleanElement as new (cfg) => any)(config))
                    );
            case 'FormatNumberElement':
                return defer(() => import('@gruuls-core/elements/formatters/format-number-element/format-number-element'))
                    .pipe(
                        map(el => new (el.FormatNumberElement as new (cfg) => any)(config))
                    );
            case 'ColorPickerElement':
                return defer(() => import('@gruuls-core/elements/ui/color-picker-element/color-picker-element'))
                    .pipe(
                        map(el => new (el.ColorPickerElement as new (cfg) => any)(config))
                    );
            case 'JsonElement':
                return defer(() => import('@gruuls-core/elements/ui/json-element/json-element'))
                    .pipe(
                        map(el => new (el.JsonElement as new (cfg) => any)(config))
                    );
            case 'IconElement':
                return defer(() => import('@gruuls-core/elements/icon-element/icon-element'))
                    .pipe(
                        map(el => new (el.IconElement as new (cfg) => any)(config))
                    );
            case 'ImageElement':
                return defer(() => import('@gruuls-core/elements/image-element/image-element'))
                    .pipe(
                        map(el => new (el.ImageElement as new (cfg) => any)(config))
                    );
            case 'InputElement':
                return defer(() => import('@gruuls-core/elements/ui/input-element/input-element'))
                    .pipe(
                        map(el => new (el.InputElement as new (cfg) => any)(config))
                    );
            case 'InputNumberElement':
                return defer(() => import('@gruuls-core/elements/ui/input-number-element/input-number-element'))
                    .pipe(
                        map(el => new (el.InputNumberElement as new (cfg) => any)(config))
                    );
            case 'TextareaElement':
                return defer(() => import('@gruuls-core/elements/ui/textarea-element/textarea-element'))
                    .pipe(
                        map(el => new (el.TextareaElement as new (cfg) => any)(config))
                    );
            case 'ElementCatalog':
                return defer(() => import('@gruuls-core/elements/ui/element-catalog/element-catalog'))
                    .pipe(
                        map(el => new (el.ElementCatalog as new (cfg) => any)(config))
                    );
            case 'ElementCatalogList':
                return defer(() => import('@gruuls-core/elements/ui/element-catalog-list/element-catalog-list'))
                    .pipe(
                        map(el => new (el.ElementCatalogList as new (cfg) => any)(config))
                    );
            case 'ElementTab':
                return defer(() => import('@gruuls-core/elements/ui/tab-element/element-tab'))
                    .pipe(
                        map(el => new (el.ElementTab as new (cfg) => any)(config))
                    );
            case 'ButtonElement':
                return defer(() => import('@gruuls-core/elements/ui/button-element/button-element'))
                    .pipe(
                        map(el => new (el.ButtonElement as new (cfg) => any)(config))
                    );
            case 'RadioElement':
                return defer(() => import('@gruuls-core/elements/ui/radio-element/radio-element'))
                    .pipe(
                        map(el => new (el.RadioElement as new (cfg) => any)(config))
                    );
            case 'SelectElement':
                return defer(() => import('@gruuls-core/elements/ui/select-element/select-element'))
                    .pipe(
                        map(el => new (el.SelectElement as new (cfg) => any)(config))
                    );
            case 'ToggleElement':
                return defer(() => import('@gruuls-core/elements/ui/toggle-element/toggle-element'))
                    .pipe(
                        map(el => new (el.ToggleElement as new (cfg) => any)(config))
                    );
            case 'DividerElement':
                return defer(() => import('@gruuls-core/elements/ui/divider-element/divider-element'))
                    .pipe(
                        map(el => new (el.DividerElement as new (cfg) => any)(config))
                    );
            case 'VerticalNavigationElement':
                return defer(() => import('@gruuls-core/elements/ui/vertical-navigation-element/vertical-navigation-element'))
                    .pipe(
                        map(el => new (el.VerticalNavigationElement as new (cfg) => any)(config))
                    );
            case 'TopHeaderForVerticalLayoutElement':
                return defer(() => import('@gruuls-core/elements/ui/top-header-for-vertical-layout-element/top-header-for-vertical-layout-element'))
                    .pipe(
                        map(el => new (el.TopHeaderForVerticalLayoutElement as new (cfg) => any)(config))
                    );
            case 'ModalElement':
                return defer(() => import('@gruuls-core/elements/ui/modal-element/modal-element'))
                    .pipe(
                        map(el => new (el.ModalElement as new (cfg) => any)(config))
                    );
            // case FormatNumberElement.type:
            //     return new FormatNumberElement(config as FormatNumberElementConfig);
            default:
                return of(new BaseElement(config));
        }
    }

    public createFromField(field: Field): BaseElement {
        switch (field.type) {
            case FieldType.long:
                return new BaseElement({
                    config: {
                        name: 'LongDomainFieldElement',
                        type: 'LongDomainFieldElement',
                        category: 'domainFieldElement',
                        subCategory: 'long',
                        internalInputChainElements: [
                            // {
                            //     type: SelectValueElement.type,
                            //     valueToSelect: field.name,
                            // } as SelectValueElementConfig,
                            // {
                            //     type: ValidatorElement.type,
                            //     validatorFn: field.
                            // } as ValidatorElementConfig,
                            // {
                            //     type: FormatNumberElement.type,
                            //     templateFormatPreset: TemplateFormatPreset.long,
                            // } as FormatNumberElementConfig
                        ]
                    }
                });
                break;
            default:
                return new BaseElement({config: {}});
        }
    }
}
