import {UserGroup} from '../acl/user-group';
import {OrganizationSettings} from './organization-settings';

// @dynamic
export class OrganizationAcl{

  constructor(
    public organizationId: string,
    public organizationName: string,
    public userGroups: UserGroup[],
    public settings: OrganizationSettings
  ){

  }


  public static of(o: any): OrganizationAcl{
    return new OrganizationAcl(
      o.organizationId,
      o.organizationName,
      o.userGroups ? o.userGroups.map((el: any)=> UserGroup.of(el)) : [],
      o.settings ? o.settings.map : undefined
    );
  }


  public getHomePageUrl(): string|undefined{
    if (this.settings && this.settings.home &&  this.settings.home.route){
      return this.settings.home.route;
    }else{
      let route;
      for (const u of this.userGroups){
        const h = u.getHomePageUrl();
        if (h){
          route = h;
          break;
        }
      }
      return route ? route : undefined;
    }
  }

  public isFavourite(): boolean{
    for (const g in this.userGroups){
      if (this.userGroups[g].isFavourite()){
        return true;
      }
    }
    return false;

  }

}
