import {GruulsTranslateServiceInterface} from '../../../service-interfaces/gruuls-translate-service-interface';
import {GruulsNumberFormatterServiceInterface} from '../../../service-interfaces/gruuls-number-formatter-service-interface';
import {Observable} from 'rxjs';
import {ContextElementConfig, ContextElementConfigInterface} from './context-element-config';
import {BaseElement} from '../base-element/base-element';
import {DynamicFlexContainerElement} from '../../ui-builders/dynamic-flex-container-element/dynamic-flex-container-element';
import {GruulsRoutingServiceInterface} from '../../../service-interfaces/gruuls-routing-service-interface';
import {GruulsHttpProxyInterface} from '../../../service-interfaces/gruuls-http-proxy-interface';
import { GruulsAuthServiceInterface } from '@gruuls-core/service-interfaces/gruuls-auth-service-interface';

// è l'elemento di contesto dove tutti gli elementi all'interno possono sfruttare il contesto per fare altre cose.
// Viene solitamente utilizzato per fare la pagina stessa. Possono esistere contesti nei contesti.
// Alla richiesta del contesto viene richiesto il primo contesto diretto.
// Il contesto è un oggetto che ha facilities, servizi...
// può essere view con il suo componente angular
export class ContextElement extends DynamicFlexContainerElement {

    data: any;
    parentContext: ContextElement;

    constructor(
        config: ContextElementConfigInterface,
        public translateService: GruulsTranslateServiceInterface,
        public numberFormatterService: GruulsNumberFormatterServiceInterface,
        public routingService?: GruulsRoutingServiceInterface,
        public httpService?: GruulsHttpProxyInterface,
        public authService?: GruulsAuthServiceInterface) {
        super(config);
        this.config = new ContextElementConfig(config, this);
    }


    init(parentContext?: ContextElement, options?: any): Observable<BaseElement> {
        this.data = {};
        parentContext = parentContext ? parentContext : this;
        this.parentContext = parentContext;
        return super.init(this, options);
    }

    getContext(): ContextElement {
        return this.parentContext;
    }

    protected _deInit(): Observable<BaseElement> {
        this.data = undefined;
        return super._deInit();
    }
}
