import {ErrorCategory} from "../error/error-category";

export enum ErrorCode{
    INTERNAL = "INTERNAL",
    VALIDATION = "VALIDATION"
}

export interface ErrorDetailsInterface {
    [key: string]: any,
    errorCode : string, // ErrorCode,
    errorDescription?: string,
    errorDetails? : any
}
export interface DataResultInterface<T>{

    hasErrors : boolean;
    errorCategory? : ErrorCategory;
    error? : ErrorDetailsInterface;
    result?: T;
    itemId:string;

    getResult():any;

    succeeded():boolean;
    failed():boolean;
    getErrorDetails():ErrorDetailsInterface|undefined;
    getErrorCategory():ErrorCategory|undefined;

    getItemId():string;
    setItemId(id:string):DataResultInterface<T>;
}
