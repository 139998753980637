import { GruulsCookieInterface } from "@gruuls-core/service-interfaces/gruuls-cookie-interface";

export class GruulsCookie implements GruulsCookieInterface {

  setItem(key: any, value: any, expires?: number, path?: string, domain?: string): any {
    let cookieString = key + "=" + value + ";";

    if (expires) {
      const date = new Date();
      date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000));
      cookieString += "expires=" + date.toUTCString() + ";";
    }

    if (path) {
      cookieString += "path=" + path + ";";
    }

    if (domain) {
      cookieString += "domain=" + domain + ";";
    }
    
    cookieString += "SameSite=Lax;";

    document.cookie = cookieString;
  }

  getItem(key: any): any {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const cookieName = cookie.split("=")[0];

      if (cookieName === key) {
        return cookie.split("=")[1];
      }
    }

    return null;
  }

  removeItem(key: any, domain?: string): any {
    let cookieString = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    
    if (domain) {
      cookieString += "domain=" + domain + ";";
    }
    document.cookie = cookieString;
  }

  getFirstLevelDomain(hostname: string): string {
    var TLDs = new RegExp(/\.[^\.]*(\.[^\.]{2,3}){0,1}\.(com|net|org|biz|ltd|plc|edu|mil|asn|adm|adv|arq|art|bio|cng|cnt|ecn|eng|esp|etc|eti|fot|fst|g12|ind|inf|jor|lel|med|nom|ntr|odo|ppg|pro|psc|psi|rec|slg|tmp|tur|vet|zlg|asso|presse|k12|gov|muni|ernet|res|store|firm|arts|info|mobi|maori|iwi|travel|asia|web|tel|([^\.]{2,3}))$/);
    var f = hostname.match(TLDs);
    if (f!==null) {
        return f[0];
    } else 
        return "." + hostname;
  }
}